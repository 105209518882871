import { z } from 'zod';
import { AipIds } from '@harvestiq/constants';
import { AipEventTypes } from '../aip-event/aip-event-types';
import { lrpPremiumEventReqSchema } from './lrp-premium-event-req';

export const lrpPremiumEventMsgSchema = z.object({
  eventType: z.literal(AipEventTypes.lrpPremiumUpdate),
  orgId: z.string(),
  aip: z.nativeEnum(AipIds),
  request: lrpPremiumEventReqSchema,
});

export type LrpPremiumEventMsg = z.infer<typeof lrpPremiumEventMsgSchema>;
