import PropTypes from 'prop-types'
import {
  Checkbox,
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
} from '@mui/material'
import HeaderCell from './HeaderCell'

export function TableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
    isSelectable,
  } = props

  return (
    <MuiTableHead>
      <TableRow>
        {isSelectable && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        )}
        {columns.map(({ isSortable = false, ...column }) => (
          <HeaderCell
            key={`header-cell-${column.id || column.field}`}
            cell={column}
            orderBy={orderBy}
            order={order}
            isSortable={isSortable}
            onRequestSort={onRequestSort}
          />
        ))}
      </TableRow>
    </MuiTableHead>
  )
}

TableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default TableHead
