import { useCallback } from 'react'
import { FormControl, FormHelperText, FormLabel } from '@mui/material'
import classNames from 'classnames'
import { NumberInput } from './NumberInput'

export const NumberGroup = ({
  value = {},
  options = [],
  onChange,
  name,
  label = '',
  labelProps,
  helperText = '',
  helperTextProps,
  formControlProps,
  ...props
}) => {
  const handleChange = useCallback(
    ({ target: { name: inputName, value: val } }) => {
      const formattedValue = val && val !== '' ? +val : null
      onChange({
        target: {
          name,
          value: { ...value, [inputName]: formattedValue },
        },
      })
    },
    [onChange, name, value],
  )

  return (
    <>
      {options?.length ? (
        <FormControl component="fieldset" {...formControlProps}>
          {label && (
            <FormLabel {...labelProps} component="legend">
              {label}
            </FormLabel>
          )}
          <div
            className={classNames(
              'flex flex-row flex-1 justify-center gap-1 mt1',
            )}
          >
            {options.map((option, index) => {
              const { value: v, label: l } = option
              return (
                <NumberInput
                  key={`number-${name}-${index}`}
                  name={v}
                  value={value[v] || ''}
                  onChange={handleChange}
                  variant="outlined"
                  type="number"
                  label={l}
                  {...props}
                />
              )
            })}
          </div>
          {!!helperText && (
            <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
          )}
        </FormControl>
      ) : null}
    </>
  )
}

export default NumberGroup
