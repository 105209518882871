import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import classNames from 'classnames'

import classes from './Table.module.scss'
import { Cell } from './TableCell'

export const SubTable = ({
  className,
  columns,
  parentId,
  isCollapsed,
  rows,
}) => {
  return (
    <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
      <Table
        size="small"
        className={classNames(
          className,
          classes.SubTable,
          'flex-1 border-b1 border-color-shade-20',
        )}
      >
        <TableHead>
          <TableRow>
            {columns.map(({ field, id = field, label }) => (
              <TableCell key={`${parentId}-${id}`}>{label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            const subTableRowSelector = `${parentId}-subTable-row`
            return (
              <TableRow
                key={`${subTableRowSelector}-${index}`}
                data-cy={subTableRowSelector}
              >
                {columns.map(column => {
                  const { field, id = field } = column
                  return (
                    <Cell key={`${parentId}-${id}`} column={column} row={row} />
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Collapse>
  )
}
