import { useCallback } from 'react'
import { toTitleCase } from 'common'
import { FormLabel } from '@mui/material'
import ColorPicker from './ColorPicker'

export const ColorPaletteEditor = ({ label, name, value = {}, onChange }) => {
  const handleChange = useCallback(
    ({ target: { name: colorName, value: colorValue } }) => {
      const [paletteName, ...rest] = colorName.split('.')
      onChange?.({
        target: {
          name: paletteName,
          value: { ...value, [rest.join('.')]: colorValue },
        },
      })
    },
    [onChange, value],
  )

  return (
    <>
      {label && <FormLabel>{label}</FormLabel>}
      {Object.entries(value).map(([colorType, colorValue]) => {
        const key = `${name}.${colorType}`
        return (
          <ColorPicker
            key={key}
            name={key}
            label={toTitleCase(colorType)}
            value={colorValue}
            onChange={handleChange}
          />
        )
      })}
    </>
  )
}
