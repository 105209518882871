import { z } from 'zod';
import { AipIds } from '@harvestiq/constants';
import { AipEventTypes } from '../aip-event/aip-event-types';
import { lgmPremiumEventReqSchema } from './lgm-premium-event-req';

export const lgmPremiumEventMsgSchema = z.object({
  eventType: z.literal(AipEventTypes.lgmPremiumUpdate),
  orgId: z.string(),
  aip: z.nativeEnum(AipIds),
  request: lgmPremiumEventReqSchema,
});

export type LgmPremiumEventMsg = z.infer<typeof lgmPremiumEventMsgSchema>;
