import { z } from 'zod';
import { dbCompanySchema } from '../companies/db-companies';
import { dbAgentSchema } from '../agents/db-agent';
import { dbEntityEventSchema } from '../entity-events/db-entity-event';
import { dbUserCompanySchema } from '../user-companies/db-user-company';
import { dbEntityOwnerSchema } from '../entity-owners/db-entity-owner';

// @todo double-check why it is undefined/null for some props: capabilities, createdAt, updatedAt, deletedAt and User_Role
const roleSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().nullable(),
  capabilities: z.array(z.string()).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  User_Role: z
    .object({
      id: z.string(),
      userId: z.string(),
      roleId: z.string(),
      createdAt: z.coerce.date(),
      updatedAt: z.coerce.date(),
    })
    .optional(),
});

export const phoneSchema = z.object({
  type: z.string(),
  number: z.string(),
  isPrimary: z.boolean(),
});

export const profileSchema = z.object({
  ssn: z.string().nullable(),
  primaryPhone: z.string().optional(),
  id: z.string(),
  userId: z.string(),
  maritalStatus: z.string().nullable(),
  liveStock: z.string().nullable(),
  farmerType: z.string().nullable(),
  dob: z.coerce.date().nullable(),
  phones: z.array(phoneSchema),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
});

export const userCompanyResponseSchema = dbCompanySchema.extend({
  deletedAt: z.coerce.date().nullable().optional(),
  User_Company: dbUserCompanySchema.optional(),
});

export const userEntityOwnerResponseSchema = dbEntityOwnerSchema.extend({
  entityId: z.string().optional(),
  id: z.string().optional(),
  ownerId: z.string().optional(),
});

export const userAgentResponseSchema = dbAgentSchema.partial().extend({
  isConfirmed: z.boolean().optional(),
  isDisabled: z.boolean().optional(),
  fullName: z.string(),
  id: z.string().uuid(),
  firstName: z.string().nullable(),
  middleName: z.string().nullable().optional(),
  lastName: z.string().nullable(),
  email: z.string().nullable(),
  confirmedAt: z.coerce.date().optional(),
  suffix: z.string().nullable().optional(),
  timezone: z.string().nullable().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional().optional(),
  deletedAt: z.coerce.date().nullable().optional(),
  Entity_Owner: userEntityOwnerResponseSchema.optional(),
});

export const legacySequelizeUserSchema = z.object({
  isConfirmed: z.boolean(),
  isDisabled: z.boolean(),
  fullName: z.string(),
  id: z.string(),
  firstName: z.string(),
  middleName: z.string().nullable(),
  lastName: z.string(),
  email: z.string().email(),
  confirmedAt: z.coerce.date(),
  suffix: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  roles: z.array(roleSchema),
  profile: profileSchema,
  // @note legacy cookie props
  events: z.array(dbEntityEventSchema).optional(),
  timezone: z.string().nullable(),
  agents: z.array(userAgentResponseSchema).optional(),
  avatar: z.string().url().optional().nullable(),
  avatarUrl: z.string().url().optional(),
  companies: z.array(userCompanyResponseSchema).optional(),
});

export type User = z.infer<typeof legacySequelizeUserSchema>;
