import { Fragment, useCallback, useState } from 'react'
import { Button, IconButton, Divider } from '@mui/material'
import classNames from 'classnames'
import pluralize from 'pluralize'
import { RemoveCircleOutline, RemoveCircle } from '@mui/icons-material'
import { noop, toTitleCase } from 'common'
import FieldGroup from '../FieldGroup'
import classes from './RepeatableFieldGroup.module.scss'
import { useAppSettings } from '../../contexts'

type FieldType = 'number' | 'switch' | 'checkbox' | 'boolean' | 'string'

type FieldValue = {
  type: FieldType
  defaultValue?: any
}

type FieldGroupFields = Record<string, FieldValue> & {
  id?: FieldValue
}

type RemoveFieldGroupProps = {
  onClick?: (groupIndex: number) => void
  groupIndex?: number
  allowEmpty?: boolean
}

type FieldGroupState = Record<string, string | number | boolean>

export type RepeatableFieldGroupProps = {
  state: FieldGroupState[]
  onChange: (
    event: { target: { name: string; value: any } },
    index?: number,
  ) => void
  requiredFields?: string[]
  className?: string
  fieldGroupName: string
  errors?: Record<number, Record<string, string>>
  fieldGroupFields: FieldGroupFields
  limit?: number
  showDivider?: boolean
  allowEmpty?: boolean
  disabled?: boolean
}

const RemoveFieldGroup = ({
  onClick = noop,
  groupIndex = 0,
  allowEmpty = false,
}: RemoveFieldGroupProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const { isMobile } = useAppSettings()

  if (!groupIndex && !allowEmpty) {
    return null
  }

  return (
    <>
      {isMobile ? (
        <Button
          className="width-100 mt1"
          variant="outlined"
          onClick={() => onClick(groupIndex)}
        >
          Remove
        </Button>
      ) : (
        <IconButton
          className={classes.removeButton}
          aria-label="remove"
          component="span"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => onClick(groupIndex)}
          data-cy="remove-field-group"
        >
          {isHovered ? <RemoveCircle /> : <RemoveCircleOutline />}
        </IconButton>
      )}
    </>
  )
}

const fieldTypeValueMap: Record<string, string | number | boolean> = {
  number: 1,
  'switch|checkbox|boolean': false,
  string: '',
}

const getFieldTypeDefaultValue = (
  fieldType: string,
  fieldDefaultValue?: any,
): string | number | boolean => {
  return (
    fieldDefaultValue ||
    Object.entries(fieldTypeValueMap).reduce<string | number | boolean>(
      (acc, [key, val]) => (key.includes(fieldType) ? val : acc),
      '',
    )
  )
}

const fieldGroupFieldsToState = (
  fieldGroupFields: FieldGroupFields,
): FieldGroupState => {
  return Object.entries(fieldGroupFields).reduce(
    (acc, [fieldKey, fieldValue]) => {
      acc[fieldKey] = getFieldTypeDefaultValue(
        fieldValue.type,
        fieldValue.defaultValue,
      )
      return acc
    },
    {} as FieldGroupState,
  )
}

export const RepeatableFieldGroup = ({
  state,
  onChange,
  requiredFields,
  className,
  fieldGroupName,
  errors,
  fieldGroupFields: { id: _id, ...fieldGroupFields },
  limit,
  showDivider,
  allowEmpty,
  disabled = false,
  ...repeatableFieldGroupProps
}: RepeatableFieldGroupProps) => {
  const defaultState = fieldGroupFieldsToState(fieldGroupFields)

  const makeEvent = useCallback(
    (value: any) => ({
      target: { name: fieldGroupName, value },
    }),
    [fieldGroupName],
  )

  const handleAddFieldGroup = useCallback(() => {
    const { id: _defaultStateId, ...rest } = defaultState
    onChange(makeEvent([...state, rest]))
  }, [onChange, state, defaultState, makeEvent])

  const handleChange = useCallback(
    (
      { target: { name, value } }: { target: { name: string; value: any } },
      index: number,
    ) => {
      const val = { ...state[index], [name]: value }
      state.splice(index, 1, val)

      onChange(
        {
          target: { name: fieldGroupName, value: state },
        },
        index,
      )
    },
    [onChange, fieldGroupName, state],
  )

  const handleRemoveGroup = useCallback(
    (groupIndex: number) => {
      state.splice(groupIndex, 1)
      onChange(makeEvent(state))
    },
    [state, makeEvent, onChange],
  )

  const addMoreLabel = toTitleCase(pluralize.singular(fieldGroupName))

  const canAddMore = limit ? limit && state.length <= limit : true
  const fieldGroups = state.length ? state : [defaultState]

  return (
    <div
      className={classNames(className, 'flex flex-col width-100')}
      {...repeatableFieldGroupProps}
    >
      {state.length > 0 &&
        fieldGroups.map(({ id: _fieldGroupId, ...fields }, index) => (
          <Fragment key={`repeatable-field-group-${fieldGroupName}-${index}`}>
            <div
              data-cy={`repeatable-field-group-${fieldGroupName}`}
              className={classNames(
                classes.repeatableFieldgroup,
                'flex flex-row width-100 mb1',
              )}
            >
              <FieldGroup
                fields={fieldGroupFields}
                state={fields}
                onChange={evt => handleChange(evt, index)}
                requiredFields={requiredFields}
                errors={(errors && errors[index]) || {}}
              />
              <RemoveFieldGroup
                allowEmpty={allowEmpty}
                groupIndex={index}
                onClick={handleRemoveGroup}
              />
            </div>

            {showDivider &&
            fieldGroups.length > 1 &&
            index !== fieldGroups.length - 1 ? (
              <Divider
                variant="fullWidth"
                orientation="horizontal"
                sx={{ mb: 4, mt: 3 }}
              />
            ) : null}
          </Fragment>
        ))}

      <div className="flex justify-start">
        <Button
          variant="contained"
          onClick={handleAddFieldGroup}
          disabled={!canAddMore || disabled}
        >
          Add {addMoreLabel}
        </Button>
      </div>
    </div>
  )
}

export default RepeatableFieldGroup
