export const MonthNames = () => {
  const formatter = new Intl.DateTimeFormat('en-US', { month: 'long' });
  return Array.from({ length: 12 }, (_, i) => formatter.format(new Date(0, i)));
};

export const DateFormatFull = 'YYYY-MM-DD';
export const DateFormatShort = 'MMM YYYY';
export const BarChartDateFormat = 'YYYYMMDD';
export const DateFormatFullWithTime = 'YYYY-MM-DD h:mmA z';
export const defaultTimeZone = 'America/Chicago';
