import { z } from 'zod';

// request
export const lrpIndemnityRequestSchema = z.object({
  coverages: z.array(
    z
      .object({
        referenceId: z.string().optional(),
        numberOfHead: z.coerce.number().min(0),
        targetWeight: z.coerce.number().min(0),
        coveragePricePerCwt: z.coerce.number().min(0),
        actualEndingValuePerCwt: z.coerce.number(),
        expectedEndingValuePerCwt: z.coerce.number(),
      })
      .refine(
        ({ actualEndingValuePerCwt, expectedEndingValuePerCwt }) => {
          if (
            !actualEndingValuePerCwt &&
            actualEndingValuePerCwt != 0 &&
            !expectedEndingValuePerCwt &&
            expectedEndingValuePerCwt != 0
          ) {
            return false;
          }
          return true;
        },
        {
          message:
            'Expected ending value is required when actual ending value is not provided.',
        }
      )
  ),
});

export type LrpIndemnityRequest = z.infer<typeof lrpIndemnityRequestSchema>;

// response
export const lrpIndemnityResponseSchema = z.object({
  data: z.array(
    z.object({
      referenceId: z.string().optional(),
      numberOfHead: z.number(),
      targetWeight: z.number(),
      coveragePricePerCwt: z.number(),
      actualEndingValuePerCwt: z.number(),
      expectedEndingValuePerCwt: z.number(),
      actualIndemnityPerCwt: z.number(),
      actualIndemnityPerHead: z.number(),
      actualIndemnityTotal: z.number(),
      expectedIndemnityPerCwt: z.number(),
      expectedIndemnityPerHead: z.number(),
      expectedIndemnityTotal: z.number(),
    })
  ),
});

export type LrpIndemnityResponse = z.infer<typeof lrpIndemnityResponseSchema>;
