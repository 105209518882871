import { api } from '../utils'

export const getSettings = () =>
  api
    .get('/settings')
    .then(response => response?.data)
    .catch(err => {
      console.error('Error fetching settings:', err)
      return null
    })

export const saveSettings = (params: Record<string, any> = {}) =>
  api
    .patch<any>('/settings', params)
    .then(({ data, errors }) => ({ ...data, errors }))
