import { isNullOrUndefined } from 'common'

const localStorageCache = {}
export const localStorage = {
  set: (key, value) => {
    if (isNullOrUndefined(value)) {
      return localStorage.remove(key)
    }
    localStorageCache[key] = value
    window.localStorage.setItem(key, JSON.stringify(value))
  },

  get: key => {
    if (!isNullOrUndefined(localStorageCache[key])) {
      return localStorageCache[key]
    }
    const localStorageValue = window.localStorage.getItem(key)
    try {
      localStorageCache[key] = JSON.parse(localStorageValue)
    } catch (e) {
      localStorageCache[key] = localStorageValue
    }
    return localStorageCache[key]
  },
  remove: key => {
    delete localStorageCache[key]
    window.localStorage.removeItem(key)
  },

  clear: () => window.localStorage.clear(),
}
