import { useCallback, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import { FormHelperText } from '@mui/material'
import { parseDate } from './DatePicker'

export const DateTimePickerInput = ({
  name,
  value,
  onChange,
  required,
  helperText = '',
  error,
  ...rest
}) => {
  const [cleared, setCleared] = useState(false)
  const handleDateChange = useCallback(
    newValue => {
      onChange({ target: { name, value: newValue || '' } })
    },
    [onChange, name],
  )
  const dateValue = parseDate(value)

  return (
    <div>
      <DateTimePicker
        {...rest}
        value={dateValue || null}
        onAccept={newValue => handleDateChange(newValue)}
        onChange={val => handleDateChange(val)}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        fullWidth
        required={required}
        error={error}
        slotProps={{
          textField: { name },
          field: { clearable: true, onClear: () => handleDateChange(null) },
          actionBar: {
            actions: ['cancel', 'clear', 'today', 'accept'],
          },
        }}
      />

      {!!helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </div>
  )
}

export default DateTimePickerInput
