import { z } from 'zod';
import { policyProducerEventMsgSchema } from '../policy-producer/policy-producer-event-msg';
import { lrpPremiumEventMsgSchema } from '../lrp-premium/lrp-premium-event-msg';
import { lgmPremiumEventMsgSchema } from '../lgm-premium/lgm-premium-event-msg';
import {
  submitAipLgmCoverageMsgSchema,
  submitAipLrpCoverageMsgSchema,
} from '../submit-aip-coverage/submit-aip-coverage-msg';
import { syncAipMsgSchema } from '../sync/sync-aip-msg';

export const aipEventMessageSchema = z.discriminatedUnion('eventType', [
  policyProducerEventMsgSchema,
  lrpPremiumEventMsgSchema,
  lgmPremiumEventMsgSchema,
  submitAipLrpCoverageMsgSchema,
  submitAipLgmCoverageMsgSchema,
  syncAipMsgSchema,
]);
export type AipEventMessage = z.infer<typeof aipEventMessageSchema>;
