import { useCallback, useState, ReactNode, MouseEvent } from 'react'
import { Button, List, Collapse, ListItemButton, Popover } from '@mui/material'
import classNames from 'classnames'
import { slugify } from 'common'
import { NavLink, matchPath, useLocation, Location, To } from 'react-router-dom'
import { useThemeManager } from '../../contexts'

type MenuItem = {
  title: string
  label?: string
  path?: string | To
  links?: string[]
  children?: MenuItem[]
  onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  className?: string
}

type MenuProps = {
  items: MenuItem[]
  id?: string
  menuItemClassName?: string
  variant?: 'text' | 'contained' | 'outlined'
  className?: string
}

const linkStyle = ({
  isActive,
  theme,
  links,
  location,
}: {
  isActive: boolean
  theme: ReturnType<typeof useThemeManager>['theme']
  links?: string[]
  location: Location
}) => {
  let isLinkActive = isActive

  // this activate just the correct parent menu item when childs have the same base pathname but are in different menus
  // (e.g. /insurance/lrp-quote in Quotes and /insurance/my-coverages in Coverages)
  if (links && location) {
    isLinkActive = links.some(link => matchPath(location.pathname, link))
  }

  return {
    color: isLinkActive
      ? theme.palette.getContrastText(theme.palette.primary.light)
      : theme.palette.primary.main,
    backgroundColor: isLinkActive ? theme.palette.primary.light : 'transparent',
  }
}

const Menu = ({
  items,
  id = 'menu',
  menuItemClassName,
  variant = 'text',
  ...menuProps
}: MenuProps) => {
  const { theme } = useThemeManager()
  const location = useLocation()
  const [anchorEls, setAnchorEls] = useState<
    Record<string, HTMLElement | null>
  >({})

  const setAnchorEl = useCallback(
    (elId: string, el: HTMLElement | null) =>
      setAnchorEls({ ...anchorEls, [elId]: el }),
    [anchorEls],
  )

  const handleAnchorSet = useCallback(
    (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) =>
      setAnchorEl(event.currentTarget.id, event.currentTarget),
    [setAnchorEl],
  )

  const handleClose = useCallback(
    (elId: string) => setAnchorEl(elId, null),
    [setAnchorEl],
  )
  const handleClick = useCallback(
    (
      evt: MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
      { onClick }: MenuItem,
    ) => onClick?.(evt),
    [],
  )

  const getMenuListItems = (
    listItems: MenuItem[],
    parentId: string,
  ): ReactNode => {
    return (
      <List
        component="div"
        disablePadding
        onMouseLeave={() => setAnchorEls({})}
      >
        {listItems.map(item => {
          const { title, label = title, path, children } = item
          const menuListId = slugify(`${parentId}-${label}`)

          return [
            <ListItemButton
              key={menuListId}
              id={menuListId}
              onClick={(
                evt: MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
              ) => (children ? handleAnchorSet(evt) : handleClick(evt, item))}
              onMouseEnter={handleAnchorSet}
              className="p0 block"
              component={path !== undefined ? NavLink : 'div'}
              to={path!}
            >
              {label}
            </ListItemButton>,
            children && (
              <Collapse
                key={`${menuListId}-Collapse`}
                in={Boolean(anchorEls[menuListId])}
              >
                {getMenuListItems(children, menuListId)}
              </Collapse>
            ),
          ]
        })}
      </List>
    )
  }

  const getMenuList = (item: MenuItem): ReactNode => {
    const { title, label = title, children, className, path, links } = item
    const menuItemId = slugify(`${id}-${label}`)

    return [
      <Button
        key={menuItemId}
        aria-controls={menuItemId}
        aria-haspopup="true"
        id={menuItemId}
        onClick={(evt: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) =>
          children ? handleAnchorSet(evt) : handleClick(evt, item)
        }
        className={classNames('p0', menuItemClassName, className)}
        variant={variant}
      >
        {path !== undefined ? (
          <NavLink
            to={path as To}
            className="py_25 px1 br_25"
            style={({ isActive }) =>
              linkStyle({ theme, isActive, links, location })
            }
          >
            {label}
          </NavLink>
        ) : (
          <span
            className="py_25 px1"
            style={{ color: theme.palette.primary.main }}
          >
            {label}
          </span>
        )}
      </Button>,
      children && (
        <Popover
          key={`${menuItemId}-Popover`}
          open={Boolean(anchorEls[menuItemId])}
          anchorEl={anchorEls[menuItemId]}
          onClose={() => handleClose(menuItemId)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {getMenuListItems(children, menuItemId)}
        </Popover>
      ),
    ]
  }

  return <div {...menuProps}>{items?.map(item => getMenuList(item))}</div>
}

export default Menu
