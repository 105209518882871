import { parseFieldProps } from './object'
import { toTitleCase } from './string'

export const objsBy = (arr, by = 'id', defaultValue = {}) =>
  arr.reduce((acc, crr) => {
    const { [by]: key } = crr
    acc[key] = crr
    return acc
  }, defaultValue)

/**
 * Groups an array of objects by a specified key.
 *
 * @module groupObjectsBy
 * @param {Array} arr - The array of objects to be grouped.
 * @param {string} [by='id'] - The key to group the objects by.
 * @param {Object} [defaultValue={}] - The default value to be used if the key is not found in the object.
 * @returns {Object} An object where the keys are the values of the specified key in the objects, and the values are arrays of objects that have that key's value.
 * @example
 * const arr = [{ id: 1, name: 'John' }, { id: 2, name: 'Jane' }, { id: 1, name: 'Doe' }];
 * groupObjectsBy(arr);
 * // Returns: { '1': [{ id: 1, name: 'John' }, { id: 1, name: 'Doe' }], '2': [{ id: 2, name: 'Jane' }] }
 */
export const groupObjectsBy = (arr, by = 'id', defaultValue = {}) =>
  arr.reduce((acc, crr) => {
    const { [by]: key } = crr
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(crr)
    return acc
  }, defaultValue)

export const extendFieldGroup = (
  fieldGroup,
  fieldGroupExtensions,
  state,
  prevState,
) =>
  Object.entries(fieldGroup).reduce(
    (acc, [name, field]) => {
      const extender = fieldGroupExtensions[name] || {}
      const fieldProps = parseFieldProps({ field, state, prevState })
      if (!fieldProps) {
        return acc
      }
      acc[name] =
        typeof extender === 'function'
          ? extender(fieldProps)
          : { ...fieldProps, ...extender }
      return acc
    },
    { ...fieldGroup },
  )

/**
 * @deprecated field groups can be conditionally defined as function that takes the form state
 */
export const applyFieldGroupExtensions = (
  fieldGroups,
  fieldGroupExtensions,
  state,
  prevState,
) =>
  fieldGroups.reduce((acc, crr) => {
    if (Array.isArray(crr)) {
      const [fieldGroupId, fieldGroup, repeatableFieldGroupProps] = crr
      const { [fieldGroupId]: extendedFieldGroup } = extendFieldGroup(
        { [fieldGroupId]: fieldGroup },
        fieldGroupExtensions,
        state,
        prevState,
      )
      acc.push([fieldGroupId, extendedFieldGroup, repeatableFieldGroupProps])
    } else {
      acc.push(extendFieldGroup(crr, fieldGroupExtensions, state, prevState))
    }

    return acc
  }, [])

const layoutElements = new Set(['heading', 'paragraph', 'divider'])

const appDataDefaultData = (fieldGroup, appData) =>
  Object.entries(fieldGroup).reduce((acc, [key, val]) => {
    const type = typeof val === 'object' ? val.type : val
    if (layoutElements.has(type)) {
      return acc
    }
    if (typeof appData[key] === 'boolean') {
      acc[key] = appData[key] ? 'yes' : 'no'
      return acc
    }
    acc[key] = appData[key] || ''
    return acc
  }, {})

export const getApplicationData = (applicationData, fieldGroups = []) =>
  applicationData
    ? fieldGroups.reduce((acc, cur) => {
        if (Array.isArray(cur)) {
          const [key, fieldGroup] = cur
          const data = applicationData[key] || [{}]
          const defaultData = data.map(obj =>
            appDataDefaultData(fieldGroup, obj),
          )

          return { ...acc, [key]: defaultData }
        }

        return {
          ...acc,
          ...appDataDefaultData(cur, applicationData),
        }
      }, {})
    : {}

export const getFieldNamesFromFieldGroups = fieldGroups =>
  fieldGroups.reduce((acc, cur) => {
    if (Array.isArray(cur)) {
      const [key] = cur
      return [...acc, key]
    }

    return [...acc, ...Object.keys(cur)]
  }, [])

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export const getComparator = (order, orderBy) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

// returns an array from 'start' digit to 'stop' digit with 'step' difference
// range(1, 10, 2) // [1, 3, 5, 9]
export const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

export const parseOptions = options =>
  Array.isArray(options)
    ? options.map(option =>
        typeof option === 'object'
          ? option
          : { value: option, label: toTitleCase(option) },
      )
    : Object.entries(options).map(([value, label]) => ({
        value,
        label,
      }))

export const sum = vals => vals.reduce((currentSum, v) => currentSum + v, 0)

export const intersects = (a, b) => {
  const setB = new Set(b)
  return [...new Set(a)].filter(x => setB.has(x))
}

/**
 * Checks if two arrays are equal by comparing their elements.
 *
 * @param {Array} a - The first array to compare.
 * @param {Array} b - The second array to compare.
 * @returns {boolean} - Returns true if the arrays are equal, false otherwise.
 */
export const arraysEqual = (a, b) => {
  if (a.length !== b.length) {
    return false
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false
    }
  }

  return true
}
