import React from 'react'
import { Snackbar, Alert } from '@mui/material'

export function Toast({
  open,
  severity,
  autoHideDuration = 3000,
  handleClose,
  text,
  content = text,
  ...props
}) {
  const vertical = 'top'
  const horizontal = 'right'
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
      {...props}
    >
      <Alert onClose={handleClose} severity={severity}>
        {content}
      </Alert>
    </Snackbar>
  )
}
