import { useCallback, useMemo } from 'react'
import { noop, parseOptions } from 'common'
import {
  FormHelperText,
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'

import classes from './ToggleButton.module.scss'

export const ToggleButton = ({
  className,
  label = '',
  name = '',
  options: optionsProp = [],
  value = '',
  onChange = noop,
  labelProps = {},
  helperText,
  optionsDisabled,
  error,
  ...rest
}) => {
  const options = useMemo(() => parseOptions(optionsProp), [optionsProp])

  const handleChange = useCallback(
    (_, changedValue) => {
      if (changedValue) {
        onChange({ target: { name, value: changedValue } })
      }
    },
    [name, onChange],
  )

  return (
    <div className={`flex items-center gap-1 ${className}`}>
      {label ? <Typography {...labelProps}>{label}:</Typography> : null}
      <ToggleButtonGroup
        color="primary"
        name={name}
        data-cy={`toggle-${name}`}
        value={value}
        onChange={handleChange}
        exclusive
        classes={{ root: classes.groupRoot }}
        error={error || null}
        {...rest}
      >
        {options.map(({ value: optionValue, label: optionLabel }) => (
          <MuiToggleButton
            key={optionValue}
            value={optionValue}
            data-cy={`${name}-${optionValue}`}
            disabled={optionsDisabled}
          >
            {optionLabel}
          </MuiToggleButton>
        ))}
      </ToggleButtonGroup>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  )
}

export default ToggleButton
