export const isNumber = (val: string) => /^\d+$/.test(val)

export const isZeroLeadingNumber = (val: string) => /^0(\d+|\.\d+)?$/.test(val)

export const isDecimal = (val: string) => /^-?\d+\.?\d*$/.test(val)

export const isUnSignedDecimal = (val: string) =>
  /^0?([1-9]+\d*)?(\.\d{0,2})?$/.test(val)

export const randomDecimalByRange = (min: number, max: number) =>
  (Math.random() * (max - min) + min).toFixed(2)
