import { z } from 'zod';
import { InsureIQCommodityNames } from './InsuranceTypes';
import { RmaCommodityCodes } from './rma/CommodityCodes';
import { CommodityId } from './CommodityId';
import { RmaTypeCodes } from './rma/TypeCodes';

export enum RmaLrpCommodityCodes {
  FEEDER_CATTLE = RmaCommodityCodes.FEEDER_CATTLE,
  FED_CATTLE = RmaCommodityCodes.FED_CATTLE,
  SWINE = RmaCommodityCodes.SWINE,
}

export enum InsureIQLrpCommodityNames {
  FEEDER_CATTLE = InsureIQCommodityNames.FEEDER_CATTLE,
  FED_CATTLE = InsureIQCommodityNames.FED_CATTLE,
  SWINE = InsureIQCommodityNames.SWINE,
}

export const InsureIQLrpCommodityCodeByName = {
  [InsureIQLrpCommodityNames.FEEDER_CATTLE]: RmaCommodityCodes.FEEDER_CATTLE,
  [InsureIQLrpCommodityNames.FED_CATTLE]: RmaCommodityCodes.FED_CATTLE,
  [InsureIQLrpCommodityNames.SWINE]: RmaCommodityCodes.SWINE,
};

type LrpLivestockCommodityId =
  | CommodityId.FEEDER_CATTLE
  | CommodityId.LIVE_CATTLE
  | CommodityId.LEAN_HOGS;

export const LrpCommodityNamesByCommodityId: Record<
  LrpLivestockCommodityId,
  InsureIQLrpCommodityNames
> = {
  [CommodityId.FEEDER_CATTLE]: InsureIQLrpCommodityNames.FEEDER_CATTLE,
  [CommodityId.LIVE_CATTLE]: InsureIQLrpCommodityNames.FED_CATTLE,
  [CommodityId.LEAN_HOGS]: InsureIQLrpCommodityNames.SWINE,
};

export const LrpCommodityNamesByRmaCommodityCode: Record<
  string, // allow lookup by any rmaCommodityCode
  InsureIQLrpCommodityNames
> = {
  [RmaLrpCommodityCodes.FEEDER_CATTLE]: InsureIQLrpCommodityNames.FEEDER_CATTLE,
  [RmaLrpCommodityCodes.FED_CATTLE]: InsureIQLrpCommodityNames.FED_CATTLE,
  [RmaLrpCommodityCodes.SWINE]: InsureIQLrpCommodityNames.SWINE,
};

// Taken from https://www.rma.usda.gov/ftp/Publications/M13_Handbook/2022/approved/EXH119.PDF
// and https://public.rma.usda.gov/livestockreports/main.aspx
/**
 * @deprecated: Use Rma/RmaCommodityTypeCodes enum instead
 */
export enum RmaLrpCommodityTypeCodes {
  STEERS_WEIGHT_1 = 809,
  STEERS_WEIGHT_2 = 810,
  HEIFERS_WEIGHT_1 = 811,
  HEIFERS_WEIGHT_2 = 812,
  BRAHMAN_WEIGHT_1 = 813,
  BRAHMAN_WEIGHT_2 = 814,
  DAIRY_WEIGHT_1 = 815,
  DAIRY_WEIGHT_2 = 816,
  UNBORN_STEERS_AND_HEIFERS = 817,
  UNBORN_BRAHMAN = 818,
  UNBORN_DAIRY = 819,
  STEERS_AND_HEIFERS = 820,
  UNBORN_SWINE = 821,
  NO_TYPE_SPECIFIED = 997,
}

export enum InsureIQLrpCommodityTypeNames {
  BRAHMAN_LIGHT = 'Brahman, <600 lbs',
  DAIRY_LIGHT = 'Dairy, <600 lbs',
  STEERS = 'Steers, 600-1000 lbs',
  UNBORN_DAIRY = 'Unborn Dairy',
  UNBORN_STEERS_HEIFERS = 'Unborn Steers & Heifers',
  UNKNOWN = 'No Type Specified',
  UNBORN_SWINE = 'Unborn Swine',
  STEERS_HEIFERS = 'Steers & Heifers',
  BRAHMAN = 'Brahman, 600-1000 lbs',
  DAIRY = 'Dairy, 600-1000 lbs',
  HEIFERS = 'Heifers, 600-1000 lbs',
  HEIFERS_LIGHT = 'Heifers, <600 lbs',
  STEERS_LIGHT = 'Steers, <600 lbs',
  UNBORN_BRAHMAN = 'Unborn Brahman',
}

export const InsureIQLrpCommodityTypeCodeByName = {
  [InsureIQLrpCommodityTypeNames.STEERS_LIGHT]:
    RmaLrpCommodityTypeCodes.STEERS_WEIGHT_1,
  [InsureIQLrpCommodityTypeNames.STEERS]:
    RmaLrpCommodityTypeCodes.STEERS_WEIGHT_2,
  [InsureIQLrpCommodityTypeNames.HEIFERS_LIGHT]:
    RmaLrpCommodityTypeCodes.HEIFERS_WEIGHT_1,
  [InsureIQLrpCommodityTypeNames.HEIFERS]:
    RmaLrpCommodityTypeCodes.HEIFERS_WEIGHT_2,
  [InsureIQLrpCommodityTypeNames.BRAHMAN_LIGHT]:
    RmaLrpCommodityTypeCodes.BRAHMAN_WEIGHT_1,
  [InsureIQLrpCommodityTypeNames.BRAHMAN]:
    RmaLrpCommodityTypeCodes.BRAHMAN_WEIGHT_2,
  [InsureIQLrpCommodityTypeNames.DAIRY_LIGHT]:
    RmaLrpCommodityTypeCodes.DAIRY_WEIGHT_1,
  [InsureIQLrpCommodityTypeNames.DAIRY]:
    RmaLrpCommodityTypeCodes.DAIRY_WEIGHT_2,
  [InsureIQLrpCommodityTypeNames.UNBORN_STEERS_HEIFERS]:
    RmaLrpCommodityTypeCodes.UNBORN_STEERS_AND_HEIFERS,
  [InsureIQLrpCommodityTypeNames.UNBORN_BRAHMAN]:
    RmaLrpCommodityTypeCodes.UNBORN_BRAHMAN,
  [InsureIQLrpCommodityTypeNames.UNBORN_DAIRY]:
    RmaLrpCommodityTypeCodes.UNBORN_DAIRY,
  [InsureIQLrpCommodityTypeNames.STEERS_HEIFERS]:
    RmaLrpCommodityTypeCodes.STEERS_AND_HEIFERS,
  [InsureIQLrpCommodityTypeNames.UNBORN_SWINE]:
    RmaLrpCommodityTypeCodes.UNBORN_SWINE,
  [InsureIQLrpCommodityTypeNames.UNKNOWN]:
    RmaLrpCommodityTypeCodes.NO_TYPE_SPECIFIED,
};

export const TypeCodeByInsureIQLrpCommodityTypeName = {
  [InsureIQLrpCommodityTypeNames.STEERS_LIGHT]: RmaTypeCodes.STEERS_WEIGHT_1,
  [InsureIQLrpCommodityTypeNames.STEERS]: RmaTypeCodes.STEERS_WEIGHT_2,
  [InsureIQLrpCommodityTypeNames.HEIFERS_LIGHT]: RmaTypeCodes.HEIFERS_WEIGHT_1,
  [InsureIQLrpCommodityTypeNames.HEIFERS]: RmaTypeCodes.HEIFERS_WEIGHT_2,
  [InsureIQLrpCommodityTypeNames.BRAHMAN_LIGHT]: RmaTypeCodes.BRAHMAN_WEIGHT_1,
  [InsureIQLrpCommodityTypeNames.BRAHMAN]: RmaTypeCodes.BRAHMAN_WEIGHT_2,
  [InsureIQLrpCommodityTypeNames.DAIRY_LIGHT]: RmaTypeCodes.DAIRY_WEIGHT_1,
  [InsureIQLrpCommodityTypeNames.DAIRY]: RmaTypeCodes.DAIRY_WEIGHT_2,
  [InsureIQLrpCommodityTypeNames.UNBORN_STEERS_HEIFERS]:
    RmaTypeCodes.UNBORN_STEERS_AND_HEIFERS,
  [InsureIQLrpCommodityTypeNames.UNBORN_BRAHMAN]: RmaTypeCodes.UNBORN_BRAHMAN,
  [InsureIQLrpCommodityTypeNames.UNBORN_DAIRY]: RmaTypeCodes.UNBORN_DAIRY,
  [InsureIQLrpCommodityTypeNames.STEERS_HEIFERS]:
    RmaTypeCodes.STEERS_AND_HEIFERS,
  [InsureIQLrpCommodityTypeNames.UNBORN_SWINE]: RmaTypeCodes.UNBORN_SWINE,
  [InsureIQLrpCommodityTypeNames.UNKNOWN]: RmaTypeCodes.NO_TYPE_SPECIFIED,
};

export const InsureIQLrpCommodityTargetWeightRange = {
  [InsureIQLrpCommodityNames.FEEDER_CATTLE]: {
    WEIGHT_1: {
      min: 0,
      max: 5.9,
    },
    WEIGHT_2: {
      min: 6,
      max: 10,
    },
  },
  [InsureIQLrpCommodityNames.FED_CATTLE]: {
    min: 10,
    max: 16,
  },
  [InsureIQLrpCommodityNames.SWINE]: {
    min: 1.4,
    max: 2.6,
  },
};

export const RmaLrpCommodityTypeInfoSchema = z.object({
  typeCode: z.nativeEnum(RmaLrpCommodityTypeCodes),
  rmaName: z.string(),
  rmaCommodityCode: z.nativeEnum(RmaLrpCommodityCodes),
  sgName: z.nativeEnum(InsureIQLrpCommodityTypeNames),
  sgCommodityName: z.nativeEnum(InsureIQLrpCommodityNames),
  commodityId: z.nativeEnum(CommodityId),
  commodityTargetWeightRange: z.object({
    min: z.number(),
    max: z.number(),
  }),
});
export type RmaLrpCommodityTypeInfo = z.infer<
  typeof RmaLrpCommodityTypeInfoSchema
>;

export const RmaLrpCommodityTypes: Record<
  RmaLrpCommodityTypeCodes,
  RmaLrpCommodityTypeInfo
> = {
  [RmaLrpCommodityTypeCodes.STEERS_WEIGHT_1]: {
    typeCode: RmaLrpCommodityTypeCodes.STEERS_WEIGHT_1,
    rmaName: 'Steers Weight 1',
    rmaCommodityCode: RmaLrpCommodityCodes.FEEDER_CATTLE,
    sgName: InsureIQLrpCommodityTypeNames.STEERS_LIGHT,
    sgCommodityName: InsureIQLrpCommodityNames.FEEDER_CATTLE,
    commodityId: CommodityId.FEEDER_CATTLE,
    commodityTargetWeightRange:
      InsureIQLrpCommodityTargetWeightRange[
        InsureIQLrpCommodityNames.FEEDER_CATTLE
      ].WEIGHT_1,
  },
  [RmaLrpCommodityTypeCodes.STEERS_WEIGHT_2]: {
    typeCode: RmaLrpCommodityTypeCodes.STEERS_WEIGHT_2,
    rmaName: 'Steers Weight 2',
    rmaCommodityCode: RmaLrpCommodityCodes.FEEDER_CATTLE,
    sgName: InsureIQLrpCommodityTypeNames.STEERS,
    sgCommodityName: InsureIQLrpCommodityNames.FEEDER_CATTLE,
    commodityId: CommodityId.FEEDER_CATTLE,
    commodityTargetWeightRange:
      InsureIQLrpCommodityTargetWeightRange[
        InsureIQLrpCommodityNames.FEEDER_CATTLE
      ].WEIGHT_2,
  },
  [RmaLrpCommodityTypeCodes.HEIFERS_WEIGHT_1]: {
    typeCode: RmaLrpCommodityTypeCodes.HEIFERS_WEIGHT_1,
    rmaName: 'Heifers Weight 1',
    rmaCommodityCode: RmaLrpCommodityCodes.FEEDER_CATTLE,
    sgName: InsureIQLrpCommodityTypeNames.HEIFERS_LIGHT,
    sgCommodityName: InsureIQLrpCommodityNames.FEEDER_CATTLE,
    commodityId: CommodityId.FEEDER_CATTLE,
    commodityTargetWeightRange:
      InsureIQLrpCommodityTargetWeightRange[
        InsureIQLrpCommodityNames.FEEDER_CATTLE
      ].WEIGHT_1,
  },
  [RmaLrpCommodityTypeCodes.HEIFERS_WEIGHT_2]: {
    typeCode: RmaLrpCommodityTypeCodes.HEIFERS_WEIGHT_2,
    rmaName: 'Heifers Weight 2',
    rmaCommodityCode: RmaLrpCommodityCodes.FEEDER_CATTLE,
    sgName: InsureIQLrpCommodityTypeNames.HEIFERS,
    sgCommodityName: InsureIQLrpCommodityNames.FEEDER_CATTLE,
    commodityId: CommodityId.FEEDER_CATTLE,
    commodityTargetWeightRange:
      InsureIQLrpCommodityTargetWeightRange[
        InsureIQLrpCommodityNames.FEEDER_CATTLE
      ].WEIGHT_2,
  },
  [RmaLrpCommodityTypeCodes.BRAHMAN_WEIGHT_1]: {
    typeCode: RmaLrpCommodityTypeCodes.BRAHMAN_WEIGHT_1,
    rmaName: 'Brahman Weight 1',
    rmaCommodityCode: RmaLrpCommodityCodes.FEEDER_CATTLE,
    sgName: InsureIQLrpCommodityTypeNames.BRAHMAN_LIGHT,
    sgCommodityName: InsureIQLrpCommodityNames.FEEDER_CATTLE,
    commodityId: CommodityId.FEEDER_CATTLE,
    commodityTargetWeightRange:
      InsureIQLrpCommodityTargetWeightRange[
        InsureIQLrpCommodityNames.FEEDER_CATTLE
      ].WEIGHT_1,
  },
  [RmaLrpCommodityTypeCodes.BRAHMAN_WEIGHT_2]: {
    typeCode: RmaLrpCommodityTypeCodes.BRAHMAN_WEIGHT_2,
    rmaName: 'Brahman Weight 2',
    rmaCommodityCode: RmaLrpCommodityCodes.FEEDER_CATTLE,
    sgName: InsureIQLrpCommodityTypeNames.BRAHMAN,
    sgCommodityName: InsureIQLrpCommodityNames.FEEDER_CATTLE,
    commodityId: CommodityId.FEEDER_CATTLE,
    commodityTargetWeightRange:
      InsureIQLrpCommodityTargetWeightRange[
        InsureIQLrpCommodityNames.FEEDER_CATTLE
      ].WEIGHT_2,
  },
  [RmaLrpCommodityTypeCodes.DAIRY_WEIGHT_1]: {
    typeCode: RmaLrpCommodityTypeCodes.DAIRY_WEIGHT_1,
    rmaName: 'Dairy Weight 1',
    rmaCommodityCode: RmaLrpCommodityCodes.FEEDER_CATTLE,
    sgName: InsureIQLrpCommodityTypeNames.DAIRY_LIGHT,
    sgCommodityName: InsureIQLrpCommodityNames.FEEDER_CATTLE,
    commodityId: CommodityId.FEEDER_CATTLE,
    commodityTargetWeightRange:
      InsureIQLrpCommodityTargetWeightRange[
        InsureIQLrpCommodityNames.FEEDER_CATTLE
      ].WEIGHT_1,
  },
  [RmaLrpCommodityTypeCodes.DAIRY_WEIGHT_2]: {
    typeCode: RmaLrpCommodityTypeCodes.DAIRY_WEIGHT_2,
    rmaName: 'Dairy Weight 2',
    rmaCommodityCode: RmaLrpCommodityCodes.FEEDER_CATTLE,
    sgName: InsureIQLrpCommodityTypeNames.DAIRY,
    sgCommodityName: InsureIQLrpCommodityNames.FEEDER_CATTLE,
    commodityId: CommodityId.FEEDER_CATTLE,
    commodityTargetWeightRange:
      InsureIQLrpCommodityTargetWeightRange[
        InsureIQLrpCommodityNames.FEEDER_CATTLE
      ].WEIGHT_2,
  },
  [RmaLrpCommodityTypeCodes.UNBORN_STEERS_AND_HEIFERS]: {
    typeCode: RmaLrpCommodityTypeCodes.UNBORN_STEERS_AND_HEIFERS,
    rmaName: 'Unborn Steers & Heifers',
    rmaCommodityCode: RmaLrpCommodityCodes.FEEDER_CATTLE,
    sgName: InsureIQLrpCommodityTypeNames.UNBORN_STEERS_HEIFERS,
    sgCommodityName: InsureIQLrpCommodityNames.FEEDER_CATTLE,
    commodityId: CommodityId.FEEDER_CATTLE,
    commodityTargetWeightRange:
      InsureIQLrpCommodityTargetWeightRange[
        InsureIQLrpCommodityNames.FEEDER_CATTLE
      ].WEIGHT_1,
  },
  [RmaLrpCommodityTypeCodes.UNBORN_BRAHMAN]: {
    typeCode: RmaLrpCommodityTypeCodes.UNBORN_BRAHMAN,
    rmaName: 'Unborn Brahman',
    rmaCommodityCode: RmaLrpCommodityCodes.FEEDER_CATTLE,
    sgName: InsureIQLrpCommodityTypeNames.UNBORN_BRAHMAN,
    sgCommodityName: InsureIQLrpCommodityNames.FEEDER_CATTLE,
    commodityId: CommodityId.FEEDER_CATTLE,
    commodityTargetWeightRange:
      InsureIQLrpCommodityTargetWeightRange[
        InsureIQLrpCommodityNames.FEEDER_CATTLE
      ].WEIGHT_1,
  },
  [RmaLrpCommodityTypeCodes.UNBORN_DAIRY]: {
    typeCode: RmaLrpCommodityTypeCodes.UNBORN_DAIRY,
    rmaName: 'Unborn Dairy',
    rmaCommodityCode: RmaLrpCommodityCodes.FEEDER_CATTLE,
    sgName: InsureIQLrpCommodityTypeNames.UNBORN_DAIRY,
    sgCommodityName: InsureIQLrpCommodityNames.FEEDER_CATTLE,
    commodityId: CommodityId.FEEDER_CATTLE,
    commodityTargetWeightRange:
      InsureIQLrpCommodityTargetWeightRange[
        InsureIQLrpCommodityNames.FEEDER_CATTLE
      ].WEIGHT_1,
  },
  [RmaLrpCommodityTypeCodes.STEERS_AND_HEIFERS]: {
    typeCode: RmaLrpCommodityTypeCodes.STEERS_AND_HEIFERS,
    rmaName: 'Steers & Heifers',
    rmaCommodityCode: RmaLrpCommodityCodes.FED_CATTLE,
    sgName: InsureIQLrpCommodityTypeNames.STEERS_HEIFERS,
    sgCommodityName: InsureIQLrpCommodityNames.FED_CATTLE,
    commodityId: CommodityId.LIVE_CATTLE,
    commodityTargetWeightRange:
      InsureIQLrpCommodityTargetWeightRange[
        InsureIQLrpCommodityNames.FED_CATTLE
      ],
  },
  [RmaLrpCommodityTypeCodes.UNBORN_SWINE]: {
    typeCode: RmaLrpCommodityTypeCodes.UNBORN_SWINE,
    rmaName: 'Unborn Swine',
    rmaCommodityCode: RmaLrpCommodityCodes.SWINE,
    sgName: InsureIQLrpCommodityTypeNames.UNBORN_SWINE,
    sgCommodityName: InsureIQLrpCommodityNames.SWINE,
    commodityId: CommodityId.LEAN_HOGS,
    commodityTargetWeightRange:
      InsureIQLrpCommodityTargetWeightRange[InsureIQLrpCommodityNames.SWINE],
  },
  [RmaLrpCommodityTypeCodes.NO_TYPE_SPECIFIED]: {
    typeCode: RmaLrpCommodityTypeCodes.NO_TYPE_SPECIFIED,
    rmaName: 'No Type Specified',
    rmaCommodityCode: RmaLrpCommodityCodes.SWINE,
    sgName: InsureIQLrpCommodityTypeNames.UNKNOWN,
    sgCommodityName: InsureIQLrpCommodityNames.SWINE,
    commodityId: CommodityId.LEAN_HOGS,
    commodityTargetWeightRange:
      InsureIQLrpCommodityTargetWeightRange[InsureIQLrpCommodityNames.SWINE],
  },
};

export const RmaLrpFeederCattleCommodityTypeNames = Object.values(
  RmaLrpCommodityTypes
)
  .filter(
    (type) => type.sgCommodityName === InsureIQLrpCommodityNames.FEEDER_CATTLE
  )
  .map((type) => type.sgName) as string[];

export const RmaLrpFedCattleCommodityTypeNames = Object.values(
  RmaLrpCommodityTypes
)
  .filter(
    (type) => type.sgCommodityName === InsureIQLrpCommodityNames.FED_CATTLE
  )
  .map((type) => type.sgName) as string[];

export const RmaLrpSwineCommodityTypeNames = Object.values(RmaLrpCommodityTypes)
  .filter((type) => type.sgCommodityName === InsureIQLrpCommodityNames.SWINE)
  .map((type) => type.sgName) as string[];

export const RmaLrpCommodityTypeMap = {
  [InsureIQLrpCommodityNames.FEEDER_CATTLE]:
    RmaLrpFeederCattleCommodityTypeNames,
  [InsureIQLrpCommodityNames.FED_CATTLE]: RmaLrpFedCattleCommodityTypeNames,
  [InsureIQLrpCommodityNames.SWINE]: RmaLrpSwineCommodityTypeNames,
};

export const rmaLrpCommodityTypeOptions = Object.values(
  RmaLrpCommodityTypes
).map((type) => ({
  label: type.sgName,
  value: type.typeCode,
  commodityId: type.commodityId,
}));

export const LrpCommodityTargetWeightByCommodityType = Object.values(
  RmaLrpCommodityTypes
).reduce((acc, type) => {
  return {
    ...acc,
    [type.sgName]: type.commodityTargetWeightRange,
  };
}, {});

export const validSgCommodityTypesByCommodityName = (
  commodityName: InsureIQLrpCommodityNames
) =>
  Object.values(RmaLrpCommodityTypes)
    .filter((type) => type.sgCommodityName === commodityName)
    .map((type) => type.sgName);

export const getLivestockCommodityName = (
  targetCommodityId: CommodityId
): string =>
  LrpCommodityNamesByCommodityId[
    targetCommodityId as LrpLivestockCommodityId
  ] || '';

export const getLrpCommodityTypeInfo = (commodityTypeCode: string) => {
  const commodityTypeCodeInt = parseInt(commodityTypeCode, 10);
  if (Object.values(RmaLrpCommodityTypeCodes).includes(commodityTypeCodeInt)) {
    return RmaLrpCommodityTypes[
      commodityTypeCodeInt as RmaLrpCommodityTypeCodes
    ];
  }
  return null;
};

export const getDefaultLrpEndorsementLength = (
  commodityCode: RmaCommodityCodes,
  typeCode: RmaTypeCodes
): number => {
  if (commodityCode === RmaCommodityCodes.FEEDER_CATTLE) {
    return 13;
  }
  if (commodityCode === RmaCommodityCodes.FED_CATTLE) {
    return 26;
  }
  if (commodityCode === RmaCommodityCodes.SWINE) {
    if (typeCode === RmaTypeCodes.UNBORN_SWINE) {
      return 39;
    }
    return 13;
  }
  return 13;
};

export const lrpNumberOfHeadParameters: Record<
  RmaCommodityCodes | '',
  {
    limit: number;
    helperText: string;
  }
> = {
  [RmaCommodityCodes.SWINE]: {
    limit: 70000,
    helperText: 'Swine max limit is 70,000',
  },
  [RmaCommodityCodes.FED_CATTLE]: {
    limit: 12000,
    helperText: 'Fed Cattle max limit is 12,000',
  },
  [RmaCommodityCodes.FEEDER_CATTLE]: {
    limit: 12000,
    helperText: 'Feeder Cattle max limit is 12,000',
  },
  [RmaCommodityCodes.CATTLE]: {
    limit: 0,
    helperText: 'Cattle is not supported',
  },
  [RmaCommodityCodes.DAIRY_CATTLE]: {
    limit: 0,
    helperText: 'Dairy Cattle is not supported',
  },
  '': {
    limit: 0,
    helperText: '',
  },
};
