import { TableCell, TableSortLabel, Box } from '@mui/material'
import { useMemo } from 'react'
import { visuallyHidden } from '@mui/utils'

export function HeaderCell({
  cell,
  orderBy,
  order,
  onRequestSort,
  isSortable,
}) {
  const createSortHandler = property => event => {
    if (isSortable) {
      onRequestSort(event, property)
    }
  }

  const sortingLabel = useMemo(
    () => (order === 'desc' ? 'sorted descending' : 'sorted ascending'),
    [order],
  )

  const { numeric, align = numeric ? 'right' : 'left' } = cell
  const padding = cell.disablePadding ? 'none' : 'normal'

  return (
    <TableCell
      key={cell.id}
      align={align}
      padding={padding}
      sortDirection={orderBy === cell.id ? order : false}
    >
      <TableSortLabel
        active={isSortable && orderBy === cell.id}
        direction={orderBy === cell.id ? order : 'asc'}
        onClick={createSortHandler(cell.id)}
        hideSortIcon={!isSortable}
      >
        {cell.label}
        {onRequestSort && orderBy === cell.id ? (
          <Box component="span" sx={visuallyHidden}>
            {sortingLabel}
          </Box>
        ) : null}
      </TableSortLabel>
    </TableCell>
  )
}

export default HeaderCell
