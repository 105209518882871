import { z } from 'zod';

export const tokenOAuthRequestSchema = z.object({
  grant_type: z.literal('client_credentials'),
});
export type TokenOAuthRequest = z.infer<typeof tokenOAuthRequestSchema>;

export const tokenOAuthRequestHeadersSchema = z.object({
  authorization: z.string(),
});

export const tokenOAuthResponseSchema = z.object({
  access_token: z.string(),
  token_type: z.string(),
  expires_in: z.number(),
});
export type TokenOAuthResponse = z.infer<typeof tokenOAuthResponseSchema>;

export const tokenOAuthErrorResponseSchema = z.object({
  error: z.string(),
  error_description: z.string(),
});
