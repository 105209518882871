export enum FmhEntityStatus {
  AgentIncomplete = 1,
  ISIncomplete = 2,
  RMAPending = 3,
  Accepted = 4,
  Rejected = 5,
  Cancelled = 6,
}

export const FmhEntityStatusLabels: Record<FmhEntityStatus, string> = {
  [FmhEntityStatus.AgentIncomplete]: 'Agent Incomplete',
  [FmhEntityStatus.ISIncomplete]: 'IS Incomplete',
  [FmhEntityStatus.RMAPending]: 'RMA Pending',
  [FmhEntityStatus.Accepted]: 'Active',
  [FmhEntityStatus.Rejected]: 'Rejected',
  [FmhEntityStatus.Cancelled]: 'Cancelled',
};

export enum FmhPartyTypeCode {
  Agent = 'AGT',
  Producer = 'ENT',
}

export enum FmhEndorsementLengthCode {
  Weeks = 1,
}
