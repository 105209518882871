import { z } from 'zod';

export const lrpCoverageTotalValuesSchema = z.object({
  coveragePricePerHead: z.number(),
  coveragePricePerCwt: z.number(),
  producerPremium: z.number(),
  producerPremiumPerHead: z.number(),
});
export type LrpCoverageTotalValues = z.infer<
  typeof lrpCoverageTotalValuesSchema
>;

export const lgmCoverageTotalValuesSchema = z.object({
  totalGrossMarginGuarantee: z.number(),
  premiumTotal: z.number(),
  numberofHeadTotal: z.number(), // [sic]
  marketingMonthCount: z.number(),
});
export type LgmCoverageTotalValues = z.infer<
  typeof lgmCoverageTotalValuesSchema
>;
