import { paginationDefault, MultiSelectAll } from './constants'

export function getVal(
  pathString: string | string[],
  obj: Record<string, any>,
): any {
  let path: string[]
  if (!Array.isArray(pathString)) {
    /*
      If pathString is not an array, then this statement replaces any
      instances of `[<digit>]` with `.<digit>` using a regular expression
      (/\[(\d)\]/g) and then splits pathString into an array using the
      delimiter `.`. The g flag in the regex indicates that we want to
      replace all instances of [<digit>] (not just the first instance).
      We're doing this because the walker needs each path segment to be
      a valid dot notation property.

      For example, if we have a path string of 'foo[0].bar', we need to
      convert it to 'foo.0.bar' so that we can split it into an array
      of ['foo', '0', 'bar'].
     */
    path = pathString.replace(/\[(\d)\]/g, '.$1').split('.')
  } else {
    path = pathString
  }

  return path.reduce((acc, part) => {
    const currentVal = acc[part]
    path.shift()
    if (Array.isArray(currentVal)) {
      const [nextPart] = path
      const nextPartIndex = Number(nextPart)
      path.shift()
      if (nextPart) {
        if (isNaN(nextPartIndex)) {
          return getVal(path, currentVal.map(aObj => aObj[nextPart]).flat())
        }

        return getVal(path, obj[part][nextPartIndex])
      }

      return currentVal
    }

    if (!currentVal) {
      path.splice(0)
    }

    return getVal(path, currentVal)
  }, obj)
}

/**
 * @function pluck
 * @description
 * Takes an array of properties or an object with key-value pairs and returns a new object containing only
 * the specified properties from the input data object.
 * @param {(Array<string>|Object<string, (string|string[])>)} properties - An array of properties or an object with key-value pairs.
 *   If an array of properties is provided, it should contain strings representing the properties to be retrieved
 *   from the data object. These properties should not be dot paths/nested. For nesting pass an object instead.
 *   If an object is provided, it should contain key-value pairs where the key is the property name in the new object,
 *   and the value is the path to retrieve the value from the input data object.
 * @param {Object} data - An object containing the data from which to pluck the specified properties.
 * @returns {Object} A new object containing only the specified properties from the input data object.
 * @example
  const obj = { data: { hasAgency: true } }
  pluck(['data.hasAgency'], obj)
  // { 'data.hasAgency': true }
  OR
  pluck({ hasAgency: 'data.hasAgency' }, obj)
  // { hasAgency: true }
*/
export const pluck = (
  properties: string[] | Record<string, string | string[]> = [],
  data: Record<string, any> = {},
) => {
  if (Array.isArray(properties)) {
    return properties.reduce((acc, crr) => {
      acc[crr] = getVal(crr, data)
      return acc
    }, {} as Record<string, any>)
  }
  return Object.entries(properties).reduce((acc, [key, path]) => {
    acc[key] = getVal(path, data)
    return acc
  }, {} as Record<string, any>)
}

export const isNull = (val: unknown) => {
  return val === null
}

const falsyArray = [null, undefined, '']
const falsySet = new Set(falsyArray)
export const trimObj = (obj: Record<string, any>, trimSet = falsySet) =>
  Object.entries(obj).reduce((acc, [key, val]) => {
    if (trimSet.has(val) || (Array.isArray(val) && !val.length)) {
      return acc
    }
    acc[key] = val
    return acc
  }, {} as Record<string, any>)

export const paginate = (
  page = paginationDefault.page,
  pageSize = paginationDefault.pageSize,
) => ({
  limit: +pageSize,
  offset: +page * +pageSize,
})

export const parseFieldProps = ({
  field,
  state = {},
  prevState = {},
}: {
  field: unknown
  state: Record<string, any>
  prevState: Record<string, any>
}): unknown => {
  switch (typeof field) {
    case 'string':
      return { type: field }
    case 'object':
    case 'boolean':
      return field
    case 'function':
      return field(state, prevState)
    default:
      throw new Error(`Invalid field type: ${typeof field}`)
  }
}

export const getFirstKeys = (obj: Record<string, any>, keys: string[] = []) => {
  const [firstKey] = Object.keys(obj)
  const firstVal = obj[firstKey]
  keys.push(firstKey)
  if (typeof firstVal === 'object') {
    getFirstKeys(firstVal, keys)
  }
  return keys
}

export const flattenObj = (
  obj: Record<string, Record<string, any>>,
  parent: string,
): any => {
  return Object.entries(obj).reduce((acc, [key, val]) => {
    const composedKey = parent ? `${parent}.${key}` : key
    if (typeof val === 'object') {
      return { ...acc, ...flattenObj(val, composedKey) }
    }
    return { ...acc, [composedKey]: val }
  }, {} as Record<string, any>)
}

// Deeply converts objects string values of yes/no, true/false to booleans
export const transformStringBooleans = (inputObj: Record<string, any>) => {
  return Object.entries(inputObj).reduce((acc, [key, val]) => {
    const isDate = val instanceof Date
    let updatedValue = val
    if (
      typeof val === 'object' &&
      !isNull(val) &&
      !Array.isArray(val) &&
      !isDate
    ) {
      updatedValue = transformStringBooleans(val)
    }

    if (typeof val === 'string') {
      const lowerVal = val.toLowerCase()
      if (/^(no|false)$/.test(lowerVal)) {
        updatedValue = false
      } else if (/^(yes|true)$/.test(lowerVal)) {
        updatedValue = true
      }
    }
    acc[key] = updatedValue
    return acc
  }, {} as Record<string, any>)
}

export const isLiteralObject = (value: unknown) => {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

export const filterObj = (
  obj: Record<string, any>,
  filterFn: (args: { val: any; key: string }) => boolean,
) => {
  return Object.entries(obj).reduce((acc, [key, val]) => {
    if (filterFn({ val, key })) {
      acc[key] = val
    }
    return acc
  }, {} as Record<string, any>)
}

export const convertMultiSelectAllToValues = (
  values: string[],
  options: { value?: any }[],
) => {
  if (values.includes(MultiSelectAll)) {
    return options.map(option => {
      return option.value ? option.value : option
    })
  }
  return values
}
