import { Autocomplete, TextField, FormHelperText } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'react-use'
import { useQuery } from '@tanstack/react-query'

import { noop, debounceTimeout, makeAddress } from 'common'
import { getPlaceDetails, getPlaces } from '../../http'

export const PlacesAutocomplete = ({
  name = '',
  value: valueProp,
  onChange = noop,
  label = '',
  onPick,
  error,
  ...props
}) => {
  const [value, setValue] = useState('')

  // in case of async initial state (e.g. application page starts with empty address but fill based on locastorage after some seconds)
  useEffect(() => {
    if (valueProp) {
      setValue(valueProp)
    }
  }, [valueProp])

  const {
    isInitialLoading,
    data: places = [],
    refetch: fetchPlaces,
  } = useQuery(
    ['getPlaces', value],
    async () => {
      const response = await getPlaces({ input: value })
      return response?.places || []
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: false,
    },
  )

  useDebounce(
    () => {
      if (value) {
        onChange({ target: { name, value } })
        fetchPlaces()
      }
    },
    debounceTimeout * 2,
    [value, fetchPlaces],
  )

  const handleInputChange = (_, val) => {
    setValue(val)
  }

  const handlePick = useCallback(
    async (_, selected) => {
      if (selected?.value) {
        const response = await getPlaceDetails(selected.value)
        if (response && response?.details) {
          setValue(
            makeAddress({
              address: response.details.addressLine1,
              number: response.details.addressNumber,
            }),
          )
          onPick(response.details)
        }
      } else {
        setValue('')
        onPick({})
      }
    },
    [onPick],
  )

  const options = useMemo(
    () =>
      places.map(({ description, place_id: placeId }) => ({
        label: description,
        value: placeId,
      })),
    [places],
  )

  return (
    <>
      <Autocomplete
        disablePortal
        filterOptions={x => x}
        value={value}
        loading={isInitialLoading}
        onChange={handlePick}
        onInputChange={handleInputChange}
        options={options}
        className="width-100"
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            inputProps={{
              name,
              ...params.inputProps,
              autoComplete: 'new-password', // new-password is the only option that chrome was recognizing as no autofill - 'off', 'no', and others had no effect
            }}
            required={props.required ?? false}
            error={error}
          />
        )}
        {...props}
      />
    </>
  )
}
