import { z } from 'zod';
import { sharedEndorsementSchema } from './shared';
import { lrpCoverageTotalValuesSchema } from './coverage-total-values';
import { dbLrpEndorsementSchema } from './db-lrp-endorsement';

export const lrpEndorsementSchema = dbLrpEndorsementSchema
  .merge(sharedEndorsementSchema)
  .extend({
    // Virtual fields that need to be moved to DB
    totalValues: lrpCoverageTotalValuesSchema.optional(),
  });

export type LrpEndorsement = z.infer<typeof lrpEndorsementSchema>;
