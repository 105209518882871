import { z } from 'zod';
import { LrpEndorsement, lrpEndorsementSchema } from './lrp-endorsement';
import { LgmEndorsement, lgmEndorsementSchema } from './lgm-endorsement';
import { InsureIQPolicyTypes } from '@harvestiq/constants';
import {
  DbLrpEndorsementInsert,
  DbLrpEndorsementUpdate,
} from './db-lrp-endorsement';
import {
  DbLgmEndorsementInsert,
  DbLgmEndorsementUpdate,
} from './db-lgm-endorsement';

export const coverageSchema = z.discriminatedUnion('type', [
  lrpEndorsementSchema,
  lgmEndorsementSchema,
]);

export type Coverage = z.infer<typeof coverageSchema>;

export const isLrpEndorsement = (
  coverage: Coverage
): coverage is LrpEndorsement => coverage.type === InsureIQPolicyTypes.LRP;

export const isLgmEndorsement = (
  coverage: Coverage
): coverage is LgmEndorsement => coverage.type === InsureIQPolicyTypes.LGM;

export function isDbLrpEndorsementInsertOrUpdate(
  coverage:
    | DbLrpEndorsementInsert
    | DbLrpEndorsementUpdate
    | DbLgmEndorsementInsert
    | DbLgmEndorsementUpdate
): coverage is DbLrpEndorsementInsert | DbLrpEndorsementUpdate {
  return coverage.type === InsureIQPolicyTypes.LRP;
}
