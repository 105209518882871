import { z } from 'zod';
import { apiKeySchema } from './get-api-keys';

export const updateApiKeyRequestSchema = z.object({
  label: z.string().optional(),
  enabled: z.boolean().optional(),
});
export type UpdateApiKeyRequest = z.infer<typeof updateApiKeyRequestSchema>;

export const updateApiKeyRequestParamsSchema = z.object({
  apiKeyId: z.string().uuid(),
});
export type UpdateApiKeyRequestParams = z.infer<
  typeof updateApiKeyRequestParamsSchema
>;

export const updateApiKeyResponseSchema = z.object({
  data: z.array(apiKeySchema),
});

export const updateApiKeyErrorResponseSchema = z.object({
  data: z.tuple([]),
  errors: z.array(z.object({ message: z.string() })),
});
