import { ZodObject, ZodRawShape, ZodType, z } from 'zod';
import {
  aipIdentifierRowSchema,
  insertableAipIdentifierRowSchema,
  updateableAipIdentifierRowSchema,
} from './db-aip-identifier';
import { AipIds } from '@harvestiq/constants';

// NOTE: must extend kysely schemas as they don't allow for complex types, i.e. enums
export const aipIdentifierSchema = aipIdentifierRowSchema.extend({
  aipSlug: z.nativeEnum(AipIds),
});
export type AipIdentifier = z.infer<typeof aipIdentifierSchema>;

export const newAipIdentifierSchema = extendWithParent(
  insertableAipIdentifierRowSchema,
  {
    aipSlug: z.nativeEnum(AipIds),
  }
);
export type NewAipIdentifier = z.infer<typeof newAipIdentifierSchema>;

export const aipIdentifierUpdateSchema =
  updateableAipIdentifierRowSchema.extend({
    aipSlug: z.nativeEnum(AipIds).optional(),
  });
export type AipIdentifierUpdate = z.infer<typeof aipIdentifierUpdateSchema>;

function extendWithParent<T extends ZodObject<any, any>>(
  parentSchema: T,
  extension: ZodRawShape
) {
  const extendedSchema = parentSchema.extend(extension) as T & {
    parent: T;
  };
  extendedSchema.parent = parentSchema; // Assigning the parent schema
  return extendedSchema;
}
