import { z } from 'zod';

import {
  InsureIQLrpCommodityNames,
  InsureIQLrpCommodityTypeNames,
} from '@harvestiq/constants';
import { zIsoDate } from '@harvestiq/zod';

export const lrpPlotsRequestSchema = z.object({
  optionType: z.string(),
  strikePrice: z.coerce.number(),
  coveragePrice: z.coerce.number(),
  commodity: z.nativeEnum(InsureIQLrpCommodityNames),
  commodityType: z.nativeEnum(InsureIQLrpCommodityTypeNames),
  marketingDate: zIsoDate(),
});

export type LrpPlotsRequest = z.infer<typeof lrpPlotsRequestSchema>;
