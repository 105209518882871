export const countyFipsCodes: Record<string, Record<string, string>> = {
  AL: {
    Alabama: '01000',
    'Autauga County': '01001',
    'Baldwin County': '01003',
    'Barbour County': '01005',
    'Bibb County': '01007',
    'Blount County': '01009',
    'Bullock County': '01011',
    'Butler County': '01013',
    'Calhoun County': '01015',
    'Chambers County': '01017',
    'Cherokee County': '01019',
    'Chilton County': '01021',
    'Choctaw County': '01023',
    'Clarke County': '01025',
    'Clay County': '01027',
    'Cleburne County': '01029',
    'Coffee County': '01031',
    'Colbert County': '01033',
    'Conecuh County': '01035',
    'Coosa County': '01037',
    'Covington County': '01039',
    'Crenshaw County': '01041',
    'Cullman County': '01043',
    'Dale County': '01045',
    'Dallas County': '01047',
    'DeKalb County': '01049',
    'Elmore County': '01051',
    'Escambia County': '01053',
    'Etowah County': '01055',
    'Fayette County': '01057',
    'Franklin County': '01059',
    'Geneva County': '01061',
    'Greene County': '01063',
    'Hale County': '01065',
    'Henry County': '01067',
    'Houston County': '01069',
    'Jackson County': '01071',
    'Jefferson County': '01073',
    'Lamar County': '01075',
    'Lauderdale County': '01077',
    'Lawrence County': '01079',
    'Lee County': '01081',
    'Limestone County': '01083',
    'Lowndes County': '01085',
    'Macon County': '01087',
    'Madison County': '01089',
    'Marengo County': '01091',
    'Marion County': '01093',
    'Marshall County': '01095',
    'Mobile County': '01097',
    'Monroe County': '01099',
    'Montgomery County': '01101',
    'Morgan County': '01103',
    'Perry County': '01105',
    'Pickens County': '01107',
    'Pike County': '01109',
    'Randolph County': '01111',
    'Russell County': '01113',
    'St. Clair County': '01115',
    'Shelby County': '01117',
    'Sumter County': '01119',
    'Talladega County': '01121',
    'Tallapoosa County': '01123',
    'Tuscaloosa County': '01125',
    'Walker County': '01127',
    'Washington County': '01129',
    'Wilcox County': '01131',
    'Winston County': '01133',
  },
  AK: {
    Alaska: '02000',
    'Aleutians East Borough': '02013',
    'Aleutians West Census Area': '02016',
    'Anchorage Borough': '02020',
    'Bethel Census Area': '02050',
    'Bristol Bay Borough': '02060',
    'Denali Borough': '02068',
    'Dillingham Census Area': '02070',
    'Fairbanks North Star Borough': '02090',
    'Haines Borough': '02100',
    'Juneau Borough': '02110',
    'Kenai Peninsula Borough': '02122',
    'Ketchikan Gateway Borough': '02130',
    'Kodiak Island Borough': '02150',
    'Lake and Peninsula Borough': '02164',
    'Matanuska-Susitna Borough': '02170',
    'Nome Census Area': '02180',
    'North Slope Borough': '02185',
    'Northwest Arctic Borough': '02188',
    'Prince of Wales-Outer Ketchikan Census Area': '02201',
    'Sitka Borough': '02220',
    'Skagway-Yakutat-Angoon Census Area': '02231',
    'Skagway-Hoonah-Angoon Census Area': '02232',
    'Southeast Fairbanks Census Area': '02240',
    'Valdez-Cordova Census Area': '02261',
    'Wade Hampton Census Area': '02270',
    'Wrangell-Petersburg Census Area': '02280',
    'Yakutat Borough': '02282',
    'Yukon-Koyukuk Census Area': '02290',
  },
  AZ: {
    Arizona: '04000',
    'Apache County': '04001',
    'Cochise County': '04003',
    'Coconino County': '04005',
    'Gila County': '04007',
    'Graham County': '04009',
    'Greenlee County': '04011',
    'La Paz County': '04012',
    'Maricopa County': '04013',
    'Mohave County': '04015',
    'Navajo County': '04017',
    'Pima County': '04019',
    'Pinal County': '04021',
    'Santa Cruz County': '04023',
    'Yavapai County': '04025',
    'Yuma County': '04027',
  },
  AR: {
    Arkansas: '05000',
    'Arkansas County': '05001',
    'Ashley County': '05003',
    'Baxter County': '05005',
    'Benton County': '05007',
    'Boone County': '05009',
    'Bradley County': '05011',
    'Calhoun County': '05013',
    'Carroll County': '05015',
    'Chicot County': '05017',
    'Clark County': '05019',
    'Clay County': '05021',
    'Cleburne County': '05023',
    'Cleveland County': '05025',
    'Columbia County': '05027',
    'Conway County': '05029',
    'Craighead County': '05031',
    'Crawford County': '05033',
    'Crittenden County': '05035',
    'Cross County': '05037',
    'Dallas County': '05039',
    'Desha County': '05041',
    'Drew County': '05043',
    'Faulkner County': '05045',
    'Franklin County': '05047',
    'Fulton County': '05049',
    'Garland County': '05051',
    'Grant County': '05053',
    'Greene County': '05055',
    'Hempstead County': '05057',
    'Hot Spring County': '05059',
    'Howard County': '05061',
    'Independence County': '05063',
    'Izard County': '05065',
    'Jackson County': '05067',
    'Jefferson County': '05069',
    'Johnson County': '05071',
    'Lafayette County': '05073',
    'Lawrence County': '05075',
    'Lee County': '05077',
    'Lincoln County': '05079',
    'Little River County': '05081',
    'Logan County': '05083',
    'Lonoke County': '05085',
    'Madison County': '05087',
    'Marion County': '05089',
    'Miller County': '05091',
    'Mississippi County': '05093',
    'Monroe County': '05095',
    'Montgomery County': '05097',
    'Nevada County': '05099',
    'Newton County': '05101',
    'Ouachita County': '05103',
    'Perry County': '05105',
    'Phillips County': '05107',
    'Pike County': '05109',
    'Poinsett County': '05111',
    'Polk County': '05113',
    'Pope County': '05115',
    'Prairie County': '05117',
    'Pulaski County': '05119',
    'Randolph County': '05121',
    'St. Francis County': '05123',
    'Saline County': '05125',
    'Scott County': '05127',
    'Searcy County': '05129',
    'Sebastian County': '05131',
    'Sevier County': '05133',
    'Sharp County': '05135',
    'Stone County': '05137',
    'Union County': '05139',
    'Van Buren County': '05141',
    'Washington County': '05143',
    'White County': '05145',
    'Woodruff County': '05147',
    'Yell County': '05149',
  },
  CA: {
    California: '06000',
    'Alameda County': '06001',
    'Alpine County': '06003',
    'Amador County': '06005',
    'Butte County': '06007',
    'Calaveras County': '06009',
    'Colusa County': '06011',
    'Contra Costa County': '06013',
    'Del Norte County': '06015',
    'El Dorado County': '06017',
    'Fresno County': '06019',
    'Glenn County': '06021',
    'Humboldt County': '06023',
    'Imperial County': '06025',
    'Inyo County': '06027',
    'Kern County': '06029',
    'Kings County': '06031',
    'Lake County': '06033',
    'Lassen County': '06035',
    'Los Angeles County': '06037',
    'Madera County': '06039',
    'Marin County': '06041',
    'Mariposa County': '06043',
    'Mendocino County': '06045',
    'Merced County': '06047',
    'Modoc County': '06049',
    'Mono County': '06051',
    'Monterey County': '06053',
    'Napa County': '06055',
    'Nevada County': '06057',
    'Orange County': '06059',
    'Placer County': '06061',
    'Plumas County': '06063',
    'Riverside County': '06065',
    'Sacramento County': '06067',
    'San Benito County': '06069',
    'San Bernardino County': '06071',
    'San Diego County': '06073',
    'San Francisco County': '06075',
    'San Joaquin County': '06077',
    'San Luis Obispo County': '06079',
    'San Mateo County': '06081',
    'Santa Barbara County': '06083',
    'Santa Clara County': '06085',
    'Santa Cruz County': '06087',
    'Shasta County': '06089',
    'Sierra County': '06091',
    'Siskiyou County': '06093',
    'Solano County': '06095',
    'Sonoma County': '06097',
    'Stanislaus County': '06099',
    'Sutter County': '06101',
    'Tehama County': '06103',
    'Trinity County': '06105',
    'Tulare County': '06107',
    'Tuolumne County': '06109',
    'Ventura County': '06111',
    'Yolo County': '06113',

    'Yuba County': '06115',
  },
  CO: {
    Colorado: '08000',
    'Adams County': '08001',
    'Alamosa County': '08003',
    'Arapahoe County': '08005',
    'Archuleta County': '08007',
    'Baca County': '08009',
    'Bent County': '08011',
    'Boulder County': '08013',
    'Chaffee County': '08015',
    'Cheyenne County': '08017',
    'Clear Creek County': '08019',
    'Conejos County': '08021',
    'Costilla County': '08023',
    'Crowley County': '08025',
    'Custer County': '08027',
    'Delta County': '08029',
    'Denver County': '08031',
    'Dolores County': '08033',
    'Douglas County': '08035',
    'Eagle County': '08037',
    'Elbert County': '08039',
    'El Paso County': '08041',
    'Fremont County': '08043',
    'Garfield County': '08045',
    'Gilpin County': '08047',
    'Grand County': '08049',
    'Gunnison County': '08051',
    'Hinsdale County': '08053',
    'Huerfano County': '08055',
    'Jackson County': '08057',
    'Jefferson County': '08059',
    'Kiowa County': '08061',
    'Kit Carson County': '08063',
    'Lake County': '08065',
    'La Plata County': '08067',
    'Larimer County': '08069',
    'Las Animas County': '08071',
    'Lincoln County': '08073',
    'Logan County': '08075',
    'Mesa County': '08077',
    'Mineral County': '08079',
    'Moffat County': '08081',
    'Montezuma County': '08083',
    'Montrose County': '08085',
    'Morgan County': '08087',
    'Otero County': '08089',
    'Ouray County': '08091',
    'Park County': '08093',
    'Phillips County': '08095',
    'Pitkin County': '08097',
    'Prowers County': '08099',
    'Pueblo County': '08101',
    'Rio Blanco County': '08103',
    'Rio Grande County': '08105',
    'Routt County': '08107',
    'Saguache County': '08109',
    'San Juan County': '08111',
    'San Miguel County': '08113',
    'Sedgwick County': '08115',
    'Summit County': '08117',
    'Teller County': '08119',
    'Washington County': '08121',
    'Weld County': '08123',
    'Yuma County': '08125',
  },
  CT: {
    Connecticut: '09000',
    'Fairfield County': '09001',
    'Hartford County': '09003',
    'Litchfield County': '09005',
    'Middlesex County': '09007',
    'New Haven County': '09009',
    'New London County': '09011',
    'Tolland County': '09013',
    'Windham County': '09015',
  },
  DE: {
    'Kent County': '10001',
    'New Castle County': '10003',
    'Sussex County': '10005',
  },
  DC: {
    'District of Columbia': '11001',
  },
  FL: {
    Florida: '12000',

    'Alachua County': '12001',
    'Baker County': '12003',
    'Bay County': '12005',
    'Bradford County': '12007',
    'Brevard County': '12009',
    'Broward County': '12011',
    'Calhoun County': '12013',
    'Charlotte County': '12015',
    'Citrus County': '12017',

    'Clay County': '12019',
    'Collier County': '12021',
    'Columbia County': '12023',
    'DeSoto County': '12027',
    'Dixie County': '12029',
    'Duval County': '12031',
    'Escambia County': '12033',
    'Flagler County': '12035',
    'Franklin County': '12037',
    'Gadsden County': '12039',
    'Gilchrist County': '12041',
    'Glades County': '12043',
    'Gulf County': '12045',
    'Hamilton County': '12047',
    'Hardee County': '12049',
    'Hendry County': '12051',
    'Hernando County': '12053',
    'Highlands County': '12055',
    'Hillsborough County': '12057',
    'Holmes County': '12059',
    'Indian River County': '12061',
    'Jackson County': '12063',
    'Jefferson County': '12065',
    'Lafayette County': '12067',
    'Lake County': '12069',
    'Lee County': '12071',
    'Leon County': '12073',
    'Levy County': '12075',
    'Liberty County': '12077',

    'Madison County': '12079',
    'Manatee County': '12081',
    'Marion County': '12083',
    'Martin County': '12085',
    'Miami-Dade County': '12086',
    'Monroe County': '12087',
    'Nassau County': '12089',
    'Okaloosa County': '12091',
    'Okeechobee County': '12093',
    'Orange County': '12095',
    'Osceola County': '12097',
    'Palm Beach County': '12099',
    'Pasco County': '12101',
    'Pinellas County': '12103',
    'Polk County': '12105',
    'Putnam County': '12107',
    'St. Johns County': '12109',
    'St. Lucie County': '12111',
    'Santa Rosa County': '12113',
    'Sarasota County': '12115',
    'Seminole County': '12117',
    'Sumter County': '12119',
    'Suwannee County': '12121',
    'Taylor County': '12123',
    'Union County': '12125',
    'Volusia County': '12127',
    'Wakulla County': '12129',
    'Walton County': '12131',
    'Washington County': '12133',
  },
  GA: {
    Georgia: '13000',
    'Appling County': '13001',
    'Atkinson County': '13003',
    'Bacon County': '13005',
    'Baker County': '13007',
    'Baldwin County': '13009',
    'Banks County': '13011',
    'Barrow County': '13013',
    'Bartow County': '13015',
    'Ben Hill County': '13017',
    'Berrien County': '13019',
    'Bibb County': '13021',
    'Bleckley County': '13023',
    'Brantley County': '13025',
    'Brooks County': '13027',
    'Bryan County': '13029',
    'Bulloch County': '13031',
    'Burke County': '13033',
    'Butts County': '13035',
    'Calhoun County': '13037',
    'Camden County': '13039',
    'Candler County': '13043',
    'Carroll County': '13045',
    'Catoosa County': '13047',
    'Charlton County': '13049',
    'Chatham County': '13051',
    'Chattahoochee County': '13053',
    'Chattooga County': '13055',
    'Cherokee County': '13057',
    'Clarke County': '13059',
    'Clay County': '13061',
    'Clayton County': '13063',
    'Clinch County': '13065',
    'Cobb County': '13067',
    'Coffee County': '13069',
    'Colquitt County': '13071',
    'Columbia County': '13073',
    'Cook County': '13075',
    'Coweta County': '13077',
    'Crawford County': '13079',
    'Crisp County': '13081',
    'Dade County': '13083',
    'Dawson County': '13085',
    'Decatur County': '13087',
    'DeKalb County': '13089',
    'Dodge County': '13091',
    'Dooly County': '13093',
    'Dougherty County': '13095',
    'Douglas County': '13097',
    'Early County': '13099',
    'Echols County': '13101',
    'Effingham County': '13103',
    'Elbert County': '13105',
    'Emanuel County': '13107',
    'Evans County': '13109',
    'Fannin County': '13111',
    'Fayette County': '13113',
    'Floyd County': '13115',
    'Forsyth County': '13117',
    'Franklin County': '13119',
    'Fulton County': '13121',
    'Gilmer County': '13123',
    'Glascock County': '13125',
    'Glynn County': '13127',
    'Gordon County': '13129',
    'Grady County': '13131',
    'Greene County': '13133',
    'Gwinnett County': '13135',
    'Habersham County': '13137',
    'Hall County': '13139',
    'Hancock County': '13141',
    'Haralson County': '13143',
    'Harris County': '13145',
    'Hart County': '13147',
    'Heard County': '13149',
    'Henry County': '13151',
    'Houston County': '13153',
    'Irwin County': '13155',
    'Jackson County': '13157',
    'Jasper County': '13159',
    'Jeff Davis County': '13161',
    'Jefferson County': '13163',
    'Jenkins County': '13165',
    'Johnson County': '13167',
    'Jones County': '13169',
    'Lamar County': '13171',
    'Lanier County': '13173',
    'Laurens County': '13175',
    'Lee County': '13177',
    'Liberty County': '13179',
    'Lincoln County': '13181',
    'Long County': '13183',
    'Lowndes County': '13185',
    'Lumpkin County': '13187',
    'McDuffie County': '13189',
    'McIntosh County': '13191',
    'Macon County': '13193',
    'Madison County': '13195',
    'Marion County': '13197',
    'Meriwether County': '13199',
    'Miller County': '13201',
    'Mitchell County': '13205',
    'Monroe County': '13207',
    'Montgomery County': '13209',
    'Morgan County': '13211',
    'Murray County': '13213',
    'Muscogee County': '13215',
    'Newton County': '13217',
    'Oconee County': '13219',
    'Oglethorpe County': '13221',
    'Paulding County': '13223',
    'Peach County': '13225',
    'Pickens County': '13227',
    'Pierce County': '13229',
    'Pike County': '13231',
    'Polk County': '13233',
    'Pulaski County': '13235',
    'Putnam County': '13237',
    'Quitman County': '13239',
    'Rabun County': '13241',
    'Randolph County': '13243',
    'Richmond County': '13245',
    'Rockdale County': '13247',
    'Schley County': '13249',
    'Screven County': '13251',
    'Seminole County': '13253',
    'Spalding County': '13255',
    'Stephens County': '13257',
    'Stewart County': '13259',
    'Sumter County': '13261',
    'Talbot County': '13263',
    'Taliaferro County': '13265',
    'Tattnall County': '13267',
    'Taylor County': '13269',
    'Telfair County': '13271',
    'Terrell County': '13273',
    'Thomas County': '13275',
    'Tift County': '13277',
    'Toombs County': '13279',
    'Towns County': '13281',
    'Treutlen County': '13283',
    'Troup County': '13285',
    'Turner County': '13287',
    'Twiggs County': '13289',
    'Union County': '13291',
    'Upson County': '13293',
    'Walker County': '13295',
    'Walton County': '13297',
    'Ware County': '13299',
    'Warren County': '13301',
    'Washington County': '13303',
    'Wayne County': '13305',
    'Webster County': '13307',
    'Wheeler County': '13309',
    'White County': '13311',
    'Whitfield County': '13313',
    'Wilcox County': '13315',
    'Wilkes County': '13317',
    'Wilkinson County': '13319',
    'Worth County': '13321',
  },
  HI: {
    Hawaii: '15000',
    'Hawaii County': '15001',
    'Honolulu County': '15003',
    'Kalawao County': '15005',
    'Kauai County': '15007',
    'Maui County': '15009',
  },
  ID: {
    Idaho: '16000',
    'Ada County': '16001',
    'Adams County': '16003',
    'Bannock County': '16005',
    'Bear Lake County': '16007',
    'Benewah County': '16009',
    'Bingham County': '16011',
    'Blaine County': '16013',
    'Boise County': '16015',
    'Bonner County': '16017',
    'Bonneville County': '16019',
    'Boundary County': '16021',
    'Butte County': '16023',
    'Camas County': '16025',
    'Canyon County': '16027',
    'Caribou County': '16029',
    'Cassia County': '16031',
    'Clark County': '16033',
    'Clearwater County': '16035',
    'Custer County': '16037',
    'Elmore County': '16039',
    'Franklin County': '16041',
    'Fremont County': '16043',
    'Gem County': '16045',
    'Gooding County': '16047',
    'Idaho County': '16049',
    'Jefferson County': '16051',
    'Jerome County': '16053',
    'Kootenai County': '16055',
    'Latah County': '16057',
    'Lemhi County': '16059',
    'Lewis County': '16061',
    'Lincoln County': '16063',
    'Madison County': '16065',
    'Minidoka County': '16067',
    'Nez Perce County': '16069',
    'Oneida County': '16071',
    'Owyhee County': '16073',
    'Payette County': '16075',
    'Power County': '16077',
    'Shoshone County': '16079',
    'Teton County': '16081',
    'Twin Falls County': '16083',
    'Valley County': '16085',
    'Washington County': '16087',
  },
  IL: {
    Illinois: '17000',
    'Bond County': '17005',
    'Boone County': '17007',
    'Brown County': '17009',
    'Bureau County': '17011',
    'Calhoun County': '17013',
    'Carroll County': '17015',
    'Cass County': '17017',
    'Champaign County': '17019',
    'Christian County': '17021',
    'Clark County': '17023',
    'Clay County': '17025',
    'Clinton County': '17027',
    'Coles County': '17029',
    'Cook County': '17031',
    'Crawford County': '17033',
    'Cumberland County': '17035',
    'DeKalb County': '17037',
    'De Witt County': '17039',
    'Douglas County': '17041',
    'DuPage County': '17043',
    'Edgar County': '17045',
    'Edwards County': '17047',
    'Effingham County': '17049',
    'Fayette County': '17051',
    'Ford County': '17053',
    'Franklin County': '17055',
    'Fulton County': '17057',
    'Gallatin County': '17059',
    'Greene County': '17061',
    'Grundy County': '17063',
    'Hamilton County': '17065',
    'Hancock County': '17067',
    'Hardin County': '17069',
    'Henderson County': '17071',
    'Henry County': '17073',
    'Iroquois County': '17075',
    'Jackson County': '17077',
    'Jasper County': '17079',
    'Jefferson County': '17081',
    'Jersey County': '17083',
    'Jo Daviess County': '17085',
    'Johnson County': '17087',
    'Kane County': '17089',
    'Kankakee County': '17091',
    'Kendall County': '17093',
    'Knox County': '17095',
    'Lake County': '17097',
    'La Salle County': '17099',
    'Lawrence County': '17101',
    'Lee County': '17103',
    'Livingston County': '17105',
    'Logan County': '17107',
    'McDonough County': '17109',
    'McHenry County': '17111',
    'McLean County': '17113',
    'Macon County': '17115',
    'Macoupin County': '17117',
    'Madison County': '17119',
    'Marion County': '17121',
    'Marshall County': '17123',
    'Mason County': '17125',
    'Massac County': '17127',
    'Menard County': '17129',
    'Mercer County': '17131',
    'Monroe County': '17133',
    'Montgomery County': '17135',
    'Morgan County': '17137',
    'Moultrie County': '17139',
    'Ogle County': '17141',
    'Peoria County': '17143',
    'Perry County': '17145',
    'Piatt County': '17147',
    'Pike County': '17149',
    'Pope County': '17151',
    'Pulaski County': '17153',
    'Putnam County': '17155',
    'Randolph County': '17157',
    'Richland County': '17159',
    'Rock Island County': '17161',
    'St. Clair County': '17163',
    'Saline County': '17165',
    'Sangamon County': '17167',
    'Schuyler County': '17169',
    'Scott County': '17171',
    'Shelby County': '17173',
    'Stark County': '17175',
    'Stephenson County': '17177',
    'Tazewell County': '17179',
    'Union County': '17181',
    'Vermilion County': '17183',
    'Wabash County': '17185',
    'Warren County': '17187',
    'Washington County': '17189',
    'Wayne County': '17191',
    'White County': '17193',
    'Whiteside County': '17195',
    'Will County': '17197',
    'Williamson County': '17199',
    'Winnebago County': '17201',
    'Woodford County': '17203',
  },
  IN: {
    Indiana: '18000',
    'Adams County': '18001',
    'Allen County': '18003',
    'Bartholomew County': '18005',
    'Benton County': '18007',
    'Blackford County': '18009',
    'Boone County': '18011',
    'Brown County': '18013',
    'Carroll County': '18015',
    'Cass County': '18017',
    'Clark County': '18019',
    'Clay County': '18021',
    'Clinton County': '18023',
    'Crawford County': '18025',
    'Daviess County': '18027',
    'Dearborn County': '18029',
    'Decatur County': '18031',
    'De Kalb County': '18033',
    'Delaware County': '18035',
    'Dubois County': '18037',
    'Elkhart County': '18039',
    'Fayette County': '18041',
    'Floyd County': '18043',
    'Fountain County': '18045',
    'Franklin County': '18047',
    'Fulton County': '18049',
    'Gibson County': '18051',
    'Grant County': '18053',
    'Greene County': '18055',
    'Hamilton County': '18057',
    'Hancock County': '18059',
    'Harrison County': '18061',
    'Hendricks County': '18063',
    'Henry County': '18065',
    'Howard County': '18067',
    'Huntington County': '18069',
    'Jackson County': '18071',
    'Jasper County': '18073',
    'Jay County': '18075',
    'Jefferson County': '18077',
    'Jennings County': '18079',
    'Johnson County': '18081',
    'Knox County': '18083',
    'Kosciusko County': '18085',
    'Lagrange County': '18087',
    'Lake County': '18089',
    'La Porte County': '18091',
    'Lawrence County': '18093',
    'Madison County': '18095',
    'Marion County': '18097',
    'Marshall County': '18099',
    'Martin County': '18101',
    'Miami County': '18103',
    'Monroe County': '18105',
    'Montgomery County': '18107',
    'Morgan County': '18109',
    'Newton County': '18111',
    'Noble County': '18113',
    'Ohio County': '18115',
    'Orange County': '18117',
    'Owen County': '18119',
    'Parke County': '18121',
    'Perry County': '18123',
    'Pike County': '18125',
    'Porter County': '18127',
    'Posey County': '18129',
    'Pulaski County': '18131',
    'Putnam County': '18133',
    'Randolph County': '18135',
    'Ripley County': '18137',
    'Rush County': '18139',
    'St. Joseph County': '18141',
    'Scott County': '18143',
    'Shelby County': '18145',
    'Spencer County': '18147',
    'Starke County': '18149',
    'Steuben County': '18151',
    'Sullivan County': '18153',
    'Switzerland County': '18155',
    'Tippecanoe County': '18157',
    'Tipton County': '18159',
    'Union County': '18161',
    'Vanderburgh County': '18163',
    'Vermillion County': '18165',
    'Vigo County': '18167',
    'Wabash County': '18169',
    'Warren County': '18171',
    'Warrick County': '18173',
    'Washington County': '18175',
    'Wayne County': '18177',
    'Wells County': '18179',
    'White County': '18181',
    'Whitley County': '18183',
  },
  IA: {
    Iowa: '19000',
    'Adair County': '19001',
    'Adams County': '19003',
    'Allamakee County': '19005',
    'Appanoose County': '19007',
    'Audubon County': '19009',
    'Benton County': '19011',
    'Black Hawk County': '19013',
    'Boone County': '19015',
    'Bremer County': '19017',
    'Buchanan County': '19019',
    'Buena Vista County': '19021',
    'Butler County': '19023',
    'Calhoun County': '19025',
    'Carroll County': '19027',
    'Cass County': '19029',
    'Cedar County': '19031',
    'Cerro Gordo County': '19033',
    'Cherokee County': '19035',
    'Chickasaw County': '19037',
    'Clarke County': '19039',
    'Clay County': '19041',
    'Clayton County': '19043',
    'Clinton County': '19045',
    'Crawford County': '19047',
    'Dallas County': '19049',
    'Davis County': '19051',
    'Decatur County': '19053',
    'Delaware County': '19055',
    'Des Moines County': '19057',
    'Dickinson County': '19059',
    'Dubuque County': '19061',
    'Emmet County': '19063',
    'Fayette County': '19065',
    'Franklin County': '19069',
    'Fremont County': '19071',
    'Greene County': '19073',
    'Grundy County': '19075',
    'Guthrie County': '19077',
    'Hamilton County': '19079',
    'Hancock County': '19081',
    'Hardin County': '19083',
    'Harrison County': '19085',
    'Henry County': '19087',
    'Howard County': '19089',
    'Humboldt County': '19091',
    'Ida County': '19093',
    'Iowa County': '19095',
    'Jackson County': '19097',
    'Jasper County': '19099',
    'Jefferson County': '19101',
    'Johnson County': '19103',
    'Jones County': '19105',
    'Keokuk County': '19107',
    'Kossuth County': '19109',
    'Lee County': '19111',
    'Linn County': '19113',
    'Louisa County': '19115',
    'Lucas County': '19117',
    'Lyon County': '19119',
    'Madison County': '19121',
    'Mahaska County': '19123',
    'Marion County': '19125',
    'Marshall County': '19127',
    'Mills County': '19129',
    'Mitchell County': '19131',
    'Monona County': '19133',
    'Monroe County': '19135',
    'Montgomery County': '19137',
    'Muscatine County': '19139',
    "O'Brien County": '19141',
    'Osceola County': '19143',
    'Page County': '19145',
    'Palo Alto County': '19147',
    'Plymouth County': '19149',
    'Pocahontas County': '19151',
    'Polk County': '19153',
    'Pottawattamie County': '19155',
    'Poweshiek County': '19157',
    'Ringgold County': '19159',
    'Sac County': '19161',
    'Scott County': '19163',
    'Shelby County': '19165',
    'Sioux County': '19167',
    'Story County': '19169',
    'Tama County': '19171',
    'Taylor County': '19173',
    'Union County': '19175',
    'Van Buren County': '19177',
    'Wapello County': '19179',
    'Warren County': '19181',
    'Washington County': '19183',
    'Wayne County': '19185',
    'Webster County': '19187',
    'Winnebago County': '19189',
    'Winneshiek County': '19191',
    'Woodbury County': '19193',
    'Worth County': '19195',
    'Wright County': '19197',
  },
  KS: {
    Kansas: '20000',
    'Allen County': '20001',
    'Anderson County': '20003',
    'Atchison County': '20005',
    'Barber County': '20007',
    'Barton County': '20009',
    'Bourbon County': '20011',
    'Brown County': '20013',
    'Butler County': '20015',
    'Chase County': '20017',
    'Chautauqua County': '20019',
    'Cherokee County': '20021',
    'Cheyenne County': '20023',
    'Clark County': '20025',
    'Clay County': '20027',
    'Cloud County': '20029',
    'Coffey County': '20031',
    'Comanche County': '20033',
    'Cowley County': '20035',
    'Crawford County': '20037',
    'Decatur County': '20039',
    'Dickinson County': '20041',
    'Doniphan County': '20043',
    'Douglas County': '20045',
    'Edwards County': '20047',
    'Elk County': '20049',
    'Ellis County': '20051',
    'Ellsworth County': '20053',
    'Finney County': '20055',
    'Ford County': '20057',
    'Franklin County': '20059',
    'Geary County': '20061',
    'Gove County': '20063',
    'Graham County': '20065',
    'Grant County': '20067',
    'Gray County': '20069',
    'Greeley County': '20071',
    'Greenwood County': '20073',
    'Hamilton County': '20075',
    'Harper County': '20077',
    'Harvey County': '20079',
    'Haskell County': '20081',
    'Hodgeman County': '20083',
    'Jackson County': '20085',
    'Jefferson County': '20087',
    'Jewell County': '20089',
    'Johnson County': '20091',
    'Kearny County': '20093',
    'Kingman County': '20095',
    'Kiowa County': '20097',
    'Labette County': '20099',
    'Lane County': '20101',
    'Leavenworth County': '20103',
    'Lincoln County': '20105',
    'Linn County': '20107',
    'Logan County': '20109',
    'Lyon County': '20111',
    'McPherson County': '20113',
    'Marion County': '20115',
    'Marshall County': '20117',
    'Meade County': '20119',
    'Miami County': '20121',
    'Mitchell County': '20123',
    'Montgomery County': '20125',
    'Morris County': '20127',
    'Morton County': '20129',
    'Nemaha County': '20131',
    'Neosho County': '20133',
    'Ness County': '20135',
    'Norton County': '20137',
    'Osage County': '20139',
    'Osborne County': '20141',
    'Ottawa County': '20143',
    'Pawnee County': '20145',
    'Phillips County': '20147',
    'Pottawatomie County': '20149',
    'Pratt County': '20151',
    'Rawlins County': '20153',
    'Reno County': '20155',
    'Republic County': '20157',
    'Rice County': '20159',
    'Riley County': '20161',
    'Rooks County': '20163',
    'Rush County': '20165',
    'Russell County': '20167',
    'Saline County': '20169',
    'Scott County': '20171',
    'Sedgwick County': '20173',
    'Seward County': '20175',
    'Shawnee County': '20177',
    'Sheridan County': '20179',
    'Sherman County': '20181',
    'Smith County': '20183',
    'Stafford County': '20185',
    'Stanton County': '20187',
    'Stevens County': '20189',
    'Sumner County': '20191',
    'Thomas County': '20193',
    'Trego County': '20195',
    'Wabaunsee County': '20197',
    'Wallace County': '20199',
    'Washington County': '20201',
    'Wichita County': '20203',
    'Wilson County': '20205',
    'Woodson County': '20207',
    'Wyandotte County': '20209',
  },
  KY: {
    Kentucky: '21000',
    'Adair County': '21001',
    'Allen County': '21003',
    'Anderson County': '21005',
    'Ballard County': '21007',
    'Barren County': '21009',
    'Bath County': '21011',
    'Bell County': '21013',
    'Boone County': '21015',
    'Bourbon County': '21017',
    'Boyd County': '21019',
    'Boyle County': '21021',
    'Bracken County': '21023',
    'Breathitt County': '21025',
    'Breckinridge County': '21027',
    'Bullitt County': '21029',
    'Butler County': '21031',
    'Caldwell County': '21033',
    'Calloway County': '21035',
    'Campbell County': '21037',
    'Carlisle County': '21039',
    'Carroll County': '21041',
    'Carter County': '21043',
    'Casey County': '21045',
    'Christian County': '21047',
    'Clark County': '21049',
    'Clay County': '21051',
    'Clinton County': '21053',
    'Crittenden County': '21055',
    'Cumberland County': '21057',
    'Daviess County': '21059',
    'Edmonson County': '21061',
    'Elliott County': '21063',
    'Estill County': '21065',
    'Fayette County': '21067',
    'Fleming County': '21069',
    'Floyd County': '21071',
    'Franklin County': '21073',
    'Fulton County': '21075',
    'Gallatin County': '21077',
    'Garrard County': '21079',
    'Grant County': '21081',
    'Graves County': '21083',
    'Grayson County': '21085',
    'Green County': '21087',
    'Greenup County': '21089',
    'Hancock County': '21091',
    'Hardin County': '21093',
    'Harlan County': '21095',
    'Harrison County': '21097',
    'Hart County': '21099',
    'Henderson County': '21101',
    'Henry County': '21103',
    'Hickman County': '21105',
    'Hopkins County': '21107',
    'Jackson County': '21109',
    'Jefferson County': '21111',
    'Jessamine County': '21113',
    'Johnson County': '21115',
    'Kenton County': '21117',
    'Knott County': '21119',
    'Knox County': '21121',
    'Larue County': '21123',
    'Laurel County': '21125',
    'Lawrence County': '21127',
    'Lee County': '21129',
    'Leslie County': '21131',
    'Letcher County': '21133',
    'Lewis County': '21135',
    'Lincoln County': '21137',
    'Livingston County': '21139',
    'Logan County': '21141',
    'Lyon County': '21143',
    'McCracken County': '21145',
    'McCreary County': '21147',
    'McLean County': '21149',
    'Madison County': '21151',
    'Magoffin County': '21153',
    'Marion County': '21155',
    'Marshall County': '21157',
    'Martin County': '21159',
    'Mason County': '21161',
    'Meade County': '21163',
    'Menifee County': '21165',
    'Mercer County': '21167',
    'Metcalfe County': '21169',
    'Monroe County': '21171',
    'Montgomery County': '21173',
    'Morgan County': '21175',
    'Muhlenberg County': '21177',
    'Nelson County': '21179',
    'Nicholas County': '21181',
    'Ohio County': '21183',
    'Oldham County': '21185',
    'Owen County': '21187',
    'Owsley County': '21189',
    'Pendleton County': '21191',
    'Perry County': '21193',
    'Pike County': '21195',
    'Powell County': '21197',
    'Pulaski County': '21199',
    'Robertson County': '21201',
    'Rockcastle County': '21203',
    'Rowan County': '21205',
    'Russell County': '21207',
    'Scott County': '21209',
    'Shelby County': '21211',
    'Simpson County': '21213',
    'Spencer County': '21215',
    'Taylor County': '21217',
    'Todd County': '21219',
    'Trigg County': '21221',
    'Trimble County': '21223',
    'Union County': '21225',
    'Warren County': '21227',
    'Washington County': '21229',
    'Wayne County': '21231',
    'Webster County': '21233',
    'Whitley County': '21235',
    'Wolfe County': '21237',
    'Woodford County': '21239',
  },
  LA: {
    Louisiana: '22000',
    'Acadia Parish': '22001',
    'Allen Parish': '22003',
    'Ascension Parish': '22005',
    'Assumption Parish': '22007',
    'Avoyelles Parish': '22009',
    'Beauregard Parish': '22011',
    'Bienville Parish': '22013',
    'Bossier Parish': '22015',
    'Caddo Parish': '22017',
    'Calcasieu Parish': '22019',
    'Caldwell Parish': '22021',
    'Cameron Parish': '22023',
    'Catahoula Parish': '22025',
    'Claiborne Parish': '22027',
    'Concordia Parish': '22029',
    'De Soto Parish': '22031',
    'East Baton Rouge Parish': '22033',
    'East Carroll Parish': '22035',
    'East Feliciana Parish': '22037',
    'Evangeline Parish': '22039',
    'Franklin Parish': '22041',
    'Grant Parish': '22043',
    'Iberia Parish': '22045',
    'Iberville Parish': '22047',
    'Jackson Parish': '22049',
    'Jefferson Parish': '22051',
    'Jefferson Davis Parish': '22053',
    'Lafayette Parish': '22055',
    'Lafourche Parish': '22057',
    'LaSalle Parish': '22059',
    'Lincoln Parish': '22061',
    'Livingston Parish': '22063',
    'Madison Parish': '22065',
    'Morehouse Parish': '22067',
    'Natchitoches Parish': '22069',
    'Orleans Parish': '22071',
    'Ouachita Parish': '22073',
    'Plaquemines Parish': '22075',
    'Pointe Coupee Parish': '22077',
    'Rapides Parish': '22079',
    'Red River Parish': '22081',
    'Richland Parish': '22083',
    'Sabine Parish': '22085',
    'St. Bernard Parish': '22087',
    'St. Charles Parish': '22089',
    'St. Helena Parish': '22091',
    'St. James Parish': '22093',
    'St. John the Baptist Parish': '22095',
    'St. Landry Parish': '22097',
    'St. Martin Parish': '22099',
    'St. Mary Parish': '22101',
    'St. Tammany Parish': '22103',
    'Tangipahoa Parish': '22105',
    'Tensas Parish': '22107',
    'Terrebonne Parish': '22109',
    'Union Parish': '22111',
    'Vermilion Parish': '22113',
    'Vernon Parish': '22115',
    'Washington Parish': '22117',
    'Webster Parish': '22119',
    'West Baton Rouge Parish': '22121',
    'West Carroll Parish': '22123',
    'West Feliciana Parish': '22125',
    'Winn Parish': '22127',
  },
  ME: {
    Maine: '23000',
    'Androscoggin County': '23001',
    'Aroostook County': '23003',
    'Cumberland County': '23005',
    'Franklin County': '23007',
    'Hancock County': '23009',
    'Kennebec County': '23011',
    'Knox County': '23013',
    'Lincoln County': '23015',
    'Oxford County': '23017',
    'Penobscot County': '23019',
    'Piscataquis County': '23021',
    'Sagadahoc County': '23023',
    'Somerset County': '23025',
    'Waldo County': '23027',
    'Washington County': '23029',
    'York County': '23031',
  },
  MD: {
    Maryland: '24000',
    'Allegany County': '24001',
    'Anne Arundel County': '24003',
    'Baltimore County': '24005',
    'Calvert County': '24009',
    'Caroline County': '24011',
    'Carroll County': '24013',
    'Cecil County': '24015',
    'Charles County': '24017',
    'Dorchester County': '24019',
    'Frederick County': '24021',
    'Garrett County': '24023',
    'Harford County': '24025',
    'Howard County': '24027',
    'Kent County': '24029',
    'Montgomery County': '24031',
    "Prince George's County": '24033',
    "Queen Anne's County": '24035',
    "St. Mary's County": '24037',
    'Somerset County': '24039',
    'Talbot County': '24041',
    'Washington County': '24043',
    'Wicomico County': '24045',
    'Worcester County': '24047',
    'Baltimore city': '24510',
  },
  MA: {
    Massachusetts: '25000',
    'Barnstable County': '25001',
    'Berkshire County': '25003',
    'Bristol County': '25005',
    'Dukes County': '25007',
    'Essex County': '25009',
    'Franklin County': '25011',
    'Hampden County': '25013',
    'Hampshire County': '25015',
    'Middlesex County': '25017',
    'Nantucket County': '25019',
    'Norfolk County': '25021',
    'Plymouth County': '25023',
    'Suffolk County': '25025',
    'Worcester County': '25027',
  },
  MI: {
    Michigan: '26000',
    'Alcona County': '26001',
    'Alger County': '26003',
    'Allegan County': '26005',
    'Alpena County': '26007',
    'Antrim County': '26009',
    'Arenac County': '26011',
    'Baraga County': '26013',
    'Barry County': '26015',
    'Bay County': '26017',
    'Benzie County': '26019',
    'Berrien County': '26021',
    'Branch County': '26023',
    'Calhoun County': '26025',
    'Cass County': '26027',
    'Charlevoix County': '26029',
    'Cheboygan County': '26031',
    'Chippewa County': '26033',
    'Clare County': '26035',
    'Clinton County': '26037',
    'Crawford County': '26039',
    'Delta County': '26041',
    'Dickinson County': '26043',
    'Eaton County': '26045',
    'Emmet County': '26047',
    'Genesee County': '26049',
    'Gladwin County': '26051',
    'Gogebic County': '26053',
    'Grand Traverse County': '26055',
    'Gratiot County': '26057',
    'Hillsdale County': '26059',
    'Houghton County': '26061',
    'Huron County': '26063',
    'Ingham County': '26065',
    'Ionia County': '26067',
    'Iosco County': '26069',
    'Iron County': '26071',
    'Isabella County': '26073',
    'Jackson County': '26075',
    'Kalamazoo County': '26077',
    'Kalkaska County': '26079',
    'Kent County': '26081',
    'Keweenaw County': '26083',
    'Lake County': '26085',
    'Lapeer County': '26087',
    'Leelanau County': '26089',
    'Lenawee County': '26091',
    'Livingston County': '26093',
    'Luce County': '26095',
    'Mackinac County': '26097',
    'Macomb County': '26099',
    'Manistee County': '26101',
    'Marquette County': '26103',
    'Mason County': '26105',
    'Mecosta County': '26107',
    'Menominee County': '26109',
    'Midland County': '26111',
    'Missaukee County': '26113',
    'Monroe County': '26115',
    'Montcalm County': '26117',
    'Montmorency County': '26119',
    'Muskegon County': '26121',
    'Newaygo County': '26123',
    'Oakland County': '26125',
    'Oceana County': '26127',
    'Ogemaw County': '26129',
    'Ontonagon County': '26131',
    'Osceola County': '26133',
    'Oscoda County': '26135',
    'Otsego County': '26137',
    'Ottawa County': '26139',
    'Presque Isle County': '26141',
    'Roscommon County': '26143',
    'Saginaw County': '26145',
    'St. Clair County': '26147',
    'St. Joseph County': '26149',
    'Sanilac County': '26151',
    'Schoolcraft County': '26153',
    'Shiawassee County': '26155',
    'Tuscola County': '26157',
    'Van Buren County': '26159',
    'Washtenaw County': '26161',
    'Wayne County': '26163',
    'Wexford County': '26165',
  },
  MN: {
    Minnesota: '27000',
    'Aitkin County': '27001',
    'Anoka County': '27003',
    'Becker County': '27005',
    'Beltrami County': '27007',
    'Benton County': '27009',
    'Big Stone County': '27011',
    'Blue Earth County': '27013',
    'Brown County': '27015',
    'Carlton County': '27017',
    'Carver County': '27019',
    'Cass County': '27021',
    'Chippewa County': '27023',
    'Chisago County': '27025',
    'Clay County': '27027',
    'Clearwater County': '27029',
    'Cook County': '27031',
    'Cottonwood County': '27033',
    'Crow Wing County': '27035',
    'Dakota County': '27037',
    'Dodge County': '27039',
    'Douglas County': '27041',
    'Faribault County': '27043',
    'Fillmore County': '27045',
    'Freeborn County': '27047',
    'Goodhue County': '27049',
    'Grant County': '27051',
    'Hennepin County': '27053',
    'Houston County': '27055',
    'Hubbard County': '27057',
    'Isanti County': '27059',
    'Itasca County': '27061',
    'Jackson County': '27063',
    'Kanabec County': '27065',
    'Kandiyohi County': '27067',
    'Kittson County': '27069',
    'Koochiching County': '27071',
    'Lac qui Parle County': '27073',
    'Lake County': '27075',
    'Lake of the Woods County': '27077',
    'Le Sueur County': '27079',
    'Lincoln County': '27081',
    'Lyon County': '27083',
    'McLeod County': '27085',
    'Mahnomen County': '27087',
    'Marshall County': '27089',
    'Martin County': '27091',
    'Meeker County': '27093',
    'Mille Lacs County': '27095',
    'Morrison County': '27097',
    'Mower County': '27099',
    'Murray County': '27101',
    'Nicollet County': '27103',
    'Nobles County': '27105',
    'Norman County': '27107',
    'Olmsted County': '27109',
    'Otter Tail County': '27111',
    'Pennington County': '27113',
    'Pine County': '27115',
    'Pipestone County': '27117',
    'Polk County': '27119',
    'Pope County': '27121',
    'Ramsey County': '27123',
    'Red Lake County': '27125',
    'Redwood County': '27127',
    'Renville County': '27129',
    'Rice County': '27131',
    'Rock County': '27133',
    'Roseau County': '27135',
    'St. Louis County': '27137',
    'Scott County': '27139',
    'Sherburne County': '27141',
    'Sibley County': '27143',
    'Stearns County': '27145',
    'Steele County': '27147',
    'Stevens County': '27149',
    'Swift County': '27151',
    'Todd County': '27153',
    'Traverse County': '27155',
    'Wabasha County': '27157',
    'Wadena County': '27159',
    'Waseca County': '27161',
    'Washington County': '27163',
    'Watonwan County': '27165',
    'Wilkin County': '27167',
    'Winona County': '27169',
    'Wright County': '27171',
    'Yellow Medicine County': '27173',
  },
  MS: {
    Mississippi: '28000',
    'Adams County': '28001',
    'Alcorn County': '28003',
    'Amite County': '28005',
    'Attala County': '28007',
    'Benton County': '28009',
    'Bolivar County': '28011',
    'Calhoun County': '28013',
    'Carroll County': '28015',
    'Chickasaw County': '28017',
    'Choctaw County': '28019',
    'Claiborne County': '28021',
    'Clarke County': '28023',
    'Clay County': '28025',
    'Coahoma County': '28027',
    'Copiah County': '28029',
    'Covington County': '28031',
    'DeSoto County': '28033',
    'Forrest County': '28035',
    'Franklin County': '28037',
    'George County': '28039',
    'Greene County': '28041',
    'Grenada County': '28043',
    'Hancock County': '28045',
    'Harrison County': '28047',
    'Hinds County': '28049',
    'Holmes County': '28051',
    'Humphreys County': '28053',
    'Issaquena County': '28055',
    'Itawamba County': '28057',
    'Jackson County': '28059',
    'Jasper County': '28061',
    'Jefferson County': '28063',
    'Jefferson Davis County': '28065',
    'Jones County': '28067',
    'Kemper County': '28069',
    'Lafayette County': '28071',
    'Lamar County': '28073',
    'Lauderdale County': '28075',
    'Lawrence County': '28077',
    'Leake County': '28079',
    'Lee County': '28081',
    'Leflore County': '28083',
    'Lincoln County': '28085',
    'Lowndes County': '28087',
    'Madison County': '28089',
    'Marion County': '28091',
    'Marshall County': '28093',
    'Monroe County': '28095',
    'Montgomery County': '28097',
    'Neshoba County': '28099',
    'Newton County': '28101',
    'Noxubee County': '28103',
    'Oktibbeha County': '28105',
    'Panola County': '28107',
    'Pearl River County': '28109',
    'Perry County': '28111',
    'Pike County': '28113',
    'Pontotoc County': '28115',
    'Prentiss County': '28117',
    'Quitman County': '28119',
    'Rankin County': '28121',
    'Scott County': '28123',
    'Sharkey County': '28125',
    'Simpson County': '28127',
    'Smith County': '28129',
    'Stone County': '28131',
    'Sunflower County': '28133',
    'Tallahatchie County': '28135',
    'Tate County': '28137',
    'Tippah County': '28139',
    'Tishomingo County': '28141',
    'Tunica County': '28143',
    'Union County': '28145',
    'Walthall County': '28147',
    'Warren County': '28149',
    'Washington County': '28151',
    'Wayne County': '28153',
    'Webster County': '28155',
    'Wilkinson County': '28157',
    'Winston County': '28159',
    'Yalobusha County': '28161',
    'Yazoo County': '28163',
  },
  MO: {
    Missouri: '29000',
    'Adair County': '29001',
    'Andrew County': '29003',
    'Atchison County': '29005',
    'Audrain County': '29007',
    'Barry County': '29009',
    'Barton County': '29011',
    'Bates County': '29013',
    'Benton County': '29015',
    'Bollinger County': '29017',
    'Boone County': '29019',
    'Buchanan County': '29021',
    'Butler County': '29023',
    'Caldwell County': '29025',
    'Callaway County': '29027',
    'Camden County': '29029',
    'Cape Girardeau County': '29031',
    'Carroll County': '29033',
    'Carter County': '29035',
    'Cass County': '29037',
    'Cedar County': '29039',
    'Chariton County': '29041',
    'Christian County': '29043',
    'Clark County': '29045',
    'Clay County': '29047',
    'Clinton County': '29049',
    'Cole County': '29051',
    'Cooper County': '29053',
    'Crawford County': '29055',
    'Dade County': '29057',
    'Dallas County': '29059',
    'Daviess County': '29061',
    'DeKalb County': '29063',
    'Dent County': '29065',
    'Douglas County': '29067',
    'Dunklin County': '29069',
    'Franklin County': '29071',
    'Gasconade County': '29073',
    'Gentry County': '29075',
    'Greene County': '29077',
    'Grundy County': '29079',
    'Harrison County': '29081',
    'Henry County': '29083',
    'Hickory County': '29085',
    'Holt County': '29087',
    'Howard County': '29089',
    'Howell County': '29091',
    'Iron County': '29093',
    'Jackson County': '29095',
    'Jasper County': '29097',
    'Jefferson County': '29099',
    'Johnson County': '29101',
    'Knox County': '29103',
    'Laclede County': '29105',
    'Lafayette County': '29107',
    'Lawrence County': '29109',
    'Lewis County': '29111',
    'Lincoln County': '29113',
    'Linn County': '29115',
    'Livingston County': '29117',
    'McDonald County': '29119',
    'Macon County': '29121',
    'Madison County': '29123',
    'Maries County': '29125',
    'Marion County': '29127',
    'Mercer County': '29129',
    'Miller County': '29131',
    'Mississippi County': '29133',
    'Moniteau County': '29135',
    'Monroe County': '29137',
    'Montgomery County': '29139',
    'Morgan County': '29141',
    'New Madrid County': '29143',
    'Newton County': '29145',
    'Nodaway County': '29147',
    'Oregon County': '29149',
    'Osage County': '29151',
    'Ozark County': '29153',
    'Pemiscot County': '29155',
    'Perry County': '29157',
    'Pettis County': '29159',
    'Phelps County': '29161',
    'Pike County': '29163',
    'Platte County': '29165',
    'Polk County': '29167',
    'Pulaski County': '29169',
    'Putnam County': '29171',
    'Ralls County': '29173',
    'Randolph County': '29175',
    'Ray County': '29177',
    'Reynolds County': '29179',
    'Ripley County': '29181',
    'St. Charles County': '29183',
    'St. Clair County': '29185',
    'Ste. Genevieve County': '29186',
    'St. Francois County': '29187',
    'St. Louis County': '29189',
    'Saline County': '29195',
    'Schuyler County': '29197',
    'Scotland County': '29199',
    'Scott County': '29201',
    'Shannon County': '29203',
    'Shelby County': '29205',
    'Stoddard County': '29207',
    'Stone County': '29209',
    'Sullivan County': '29211',
    'Taney County': '29213',
    'Texas County': '29215',
    'Vernon County': '29217',
    'Warren County': '29219',
    'Washington County': '29221',
    'Wayne County': '29223',
    'Webster County': '29225',
    'Worth County': '29227',
    'Wright County': '29229',
    'St. Louis city': '29510',
  },
  MT: {
    Montana: '30000',
    'Beaverhead County': '30001',
    'Big Horn County': '30003',
    'Blaine County': '30005',
    'Broadwater County': '30007',
    'Carbon County': '30009',
    'Carter County': '30011',
    'Cascade County': '30013',
    'Chouteau County': '30015',
    'Custer County': '30017',
    'Daniels County': '30019',
    'Dawson County': '30021',
    'Deer Lodge County': '30023',
    'Fallon County': '30025',
    'Fergus County': '30027',
    'Flathead County': '30029',
    'Gallatin County': '30031',
    'Garfield County': '30033',
    'Glacier County': '30035',
    'Golden Valley County': '30037',
    'Granite County': '30039',
    'Hill County': '30041',
    'Jefferson County': '30043',
    'Judith Basin County': '30045',
    'Lake County': '30047',
    'Lewis and Clark County': '30049',
    'Liberty County': '30051',
    'Lincoln County': '30053',
    'McCone County': '30055',
    'Madison County': '30057',
    'Meagher County': '30059',
    'Mineral County': '30061',
    'Missoula County': '30063',
    'Musselshell County': '30065',
    'Park County': '30067',
    'Petroleum County': '30069',
    'Phillips County': '30071',
    'Pondera County': '30073',
    'Powder River County': '30075',
    'Powell County': '30077',
    'Prairie County': '30079',
    'Ravalli County': '30081',
    'Richland County': '30083',
    'Roosevelt County': '30085',
    'Rosebud County': '30087',
    'Sanders County': '30089',
    'Sheridan County': '30091',
    'Silver Bow County': '30093',
    'Stillwater County': '30095',
    'Sweet Grass County': '30097',
    'Teton County': '30099',
    'Toole County': '30101',
    'Treasure County': '30103',
    'Valley County': '30105',
    'Wheatland County': '30107',
    'Wibaux County': '30109',
    'Yellowstone County': '30111',
  },
  NE: {
    Nebraska: '31000',
    'Adams County': '31001',
    'Antelope County': '31003',
    'Arthur County': '31005',
    'Banner County': '31007',
    'Blaine County': '31009',
    'Boone County': '31011',
    'Box Butte County': '31013',
    'Boyd County': '31015',
    'Brown County': '31017',
    'Buffalo County': '31019',
    'Burt County': '31021',
    'Butler County': '31023',
    'Cass County': '31025',
    'Cedar County': '31027',
    'Chase County': '31029',
    'Cherry County': '31031',
    'Cheyenne County': '31033',
    'Clay County': '31035',
    'Colfax County': '31037',
    'Cuming County': '31039',
    'Custer County': '31041',
    'Dakota County': '31043',
    'Dawes County': '31045',
    'Dawson County': '31047',
    'Deuel County': '31049',
    'Dixon County': '31051',
    'Dodge County': '31053',
    'Douglas County': '31055',
    'Dundy County': '31057',
    'Fillmore County': '31059',
    'Franklin County': '31061',
    'Frontier County': '31063',
    'Furnas County': '31065',
    'Gage County': '31067',
    'Garden County': '31069',
    'Garfield County': '31071',
    'Gosper County': '31073',
    'Grant County': '31075',
    'Greeley County': '31077',
    'Hall County': '31079',
    'Hamilton County': '31081',
    'Harlan County': '31083',
    'Hayes County': '31085',
    'Hitchcock County': '31087',
    'Holt County': '31089',
    'Hooker County': '31091',
    'Howard County': '31093',
    'Jefferson County': '31095',
    'Johnson County': '31097',
    'Kearney County': '31099',
    'Keith County': '31101',
    'Keya Paha County': '31103',
    'Kimball County': '31105',
    'Knox County': '31107',
    'Lancaster County': '31109',
    'Lincoln County': '31111',
    'Logan County': '31113',
    'Loup County': '31115',
    'McPherson County': '31117',
    'Madison County': '31119',
    'Merrick County': '31121',
    'Morrill County': '31123',
    'Nance County': '31125',
    'Nemaha County': '31127',
    'Nuckolls County': '31129',
    'Otoe County': '31131',
    'Pawnee County': '31133',
    'Perkins County': '31135',
    'Phelps County': '31137',
    'Pierce County': '31139',
    'Platte County': '31141',
    'Polk County': '31143',
    'Red Willow County': '31145',
    'Richardson County': '31147',
    'Rock County': '31149',
    'Saline County': '31151',
    'Sarpy County': '31153',
    'Saunders County': '31155',
    'Scotts Bluff County': '31157',
    'Seward County': '31159',
    'Sheridan County': '31161',
    'Sherman County': '31163',
    'Sioux County': '31165',
    'Stanton County': '31167',
    'Thayer County': '31169',
    'Thomas County': '31171',
    'Thurston County': '31173',
    'Valley County': '31175',
    'Washington County': '31177',
    'Wayne County': '31179',
    'Webster County': '31181',
    'Wheeler County': '31183',
    'York County': '31185',
  },
  NV: {
    Nevada: '32000',
    'Churchill County': '32001',
    'Clark County': '32003',
    'Douglas County': '32005',
    'Elko County': '32007',
    'Esmeralda County': '32009',
    'Eureka County': '32011',
    'Humboldt County': '32013',
    'Lander County': '32015',
    'Lincoln County': '32017',
    'Lyon County': '32019',
    'Mineral County': '32021',
    'Nye County': '32023',
    'Pershing County': '32027',
    'Storey County': '32029',
    'Washoe County': '32031',
    'White Pine County': '32033',
    'Carson City': '32510',
  },
  NH: {
    'New Hampshire': '33000',
    'Belknap County': '33001',
    'Carroll County': '33003',
    'Cheshire County': '33005',
    'Coos County': '33007',
    'Grafton County': '33009',
    'Hillsborough County': '33011',
    'Merrimack County': '33013',
    'Rockingham County': '33015',
    'Strafford County': '33017',
    'Sullivan County': '33019',
  },
  NJ: {
    'New Jersey': '34000',
    'Atlantic County': '34001',
    'Bergen County': '34003',
    'Burlington County': '34005',
    'Camden County': '34007',
    'Cape May County': '34009',
    'Cumberland County': '34011',
    'Essex County': '34013',
    'Gloucester County': '34015',
    'Hudson County': '34017',
    'Hunterdon County': '34019',
    'Mercer County': '34021',
    'Middlesex County': '34023',
    'Monmouth County': '34025',
    'Morris County': '34027',
    'Ocean County': '34029',
    'Passaic County': '34031',
    'Salem County': '34033',
    'Somerset County': '34035',
    'Sussex County': '34037',
    'Union County': '34039',
    'Warren County': '34041',
  },
  NM: {
    'New Mexico': '35000',
    'Bernalillo County': '35001',
    'Catron County': '35003',
    'Chaves County': '35005',
    'Cibola County': '35006',
    'Colfax County': '35007',
    'Curry County': '35009',
    'De Baca County': '35011',
    'Dona Ana County': '35013',
    'Eddy County': '35015',
    'Grant County': '35017',
    'Guadalupe County': '35019',
    'Harding County': '35021',
    'Hidalgo County': '35023',
    'Lea County': '35025',
    'Lincoln County': '35027',
    'Los Alamos County': '35028',
    'Luna County': '35029',
    'McKinley County': '35031',
    'Mora County': '35033',
    'Otero County': '35035',
    'Quay County': '35037',
    'Rio Arriba County': '35039',
    'Roosevelt County': '35041',
    'Sandoval County': '35043',
    'San Juan County': '35045',
    'San Miguel County': '35047',
    'Santa Fe County': '35049',
    'Sierra County': '35051',
    'Socorro County': '35053',
    'Taos County': '35055',
    'Torrance County': '35057',
    'Union County': '35059',
    'Valencia County': '35061',
  },
  NY: {
    'New York': '36000',
    'Albany County': '36001',
    'Allegany County': '36003',
    'Bronx County': '36005',
    'Broome County': '36007',
    'Cattaraugus County': '36009',
    'Cayuga County': '36011',
    'Chautauqua County': '36013',
    'Chemung County': '36015',
    'Chenango County': '36017',
    'Clinton County': '36019',
    'Columbia County': '36021',
    'Cortland County': '36023',
    'Delaware County': '36025',
    'Dutchess County': '36027',
    'Erie County': '36029',
    'Essex County': '36031',
    'Franklin County': '36033',
    'Fulton County': '36035',
    'Genesee County': '36037',
    'Greene County': '36039',
    'Hamilton County': '36041',
    'Herkimer County': '36043',
    'Jefferson County': '36045',
    'Kings County (Brooklyn)': '36047',
    'Lewis County': '36049',
    'Livingston County': '36051',
    'Madison County': '36053',
    'Monroe County': '36055',
    'Montgomery County': '36057',
    'Nassau County': '36059',
    'New York County (Manhattan)': '36061',
    'Niagara County': '36063',
    'Oneida County': '36065',
    'Onondaga County': '36067',
    'Ontario County': '36069',
    'Orange County': '36071',
    'Orleans County': '36073',
    'Oswego County': '36075',
    'Otsego County': '36077',
    'Putnam County': '36079',
    'Queens County': '36081',
    'Rensselaer County': '36083',
    'Richmond County (Staten Island)': '36085',
    'Rockland County': '36087',
    'Saratoga County': '36091',
    'Schenectady County': '36093',
    'Schoharie County': '36095',
    'Schuyler County': '36097',
    'Seneca County': '36099',
    'St. Lawrence County': '36089',
    'Steuben County': '36101',
    'Suffolk County': '36103',
    'Sullivan County': '36105',
    'Tioga County': '36107',
    'Tompkins County': '36109',
    'Ulster County': '36111',
    'Warren County': '36113',
    'Washington County': '36115',
    'Wayne County': '36117',
    'Westchester County': '36119',
    'Wyoming County': '36121',
    'Yates County': '36123',
  },
  NC: {
    'North Carolina': '37000',
    'Alamance County': '37001',
    'Alexander County': '37003',
    'Alleghany County': '37005',
    'Anson County': '37007',
    'Ashe County': '37009',
    'Avery County': '37011',
    'Beaufort County': '37013',
    'Bertie County': '37015',
    'Bladen County': '37017',
    'Brunswick County': '37019',
    'Buncombe County': '37021',
    'Burke County': '37023',
    'Cabarrus County': '37025',
    'Caldwell County': '37027',
    'Camden County': '37029',
    'Carteret County': '37031',
    'Caswell County': '37033',
    'Catawba County': '37035',
    'Chatham County': '37037',
    'Cherokee County': '37039',
    'Chowan County': '37041',
    'Clay County': '37043',
    'Cleveland County': '37045',
    'Columbus County': '37047',
    'Craven County': '37049',
    'Cumberland County': '37051',
    'Currituck County': '37053',
    'Dare County': '37055',
    'Davidson County': '37057',
    'Davie County': '37059',
    'Duplin County': '37061',
    'Durham County': '37063',
    'Edgecombe County': '37065',
    'Forsyth County': '37067',
    'Franklin County': '37069',
    'Gaston County': '37071',
    'Gates County': '37073',
    'Graham County': '37075',
    'Granville County': '37077',
    'Greene County': '37079',
    'Guilford County': '37081',
    'Halifax County': '37083',
    'Harnett County': '37085',
    'Haywood County': '37087',
    'Henderson County': '37089',
    'Hertford County': '37091',
    'Hoke County': '37093',
    'Hyde County': '37095',
    'Iredell County': '37097',
    'Jackson County': '37099',
    'Johnston County': '37101',
    'Jones County': '37103',
    'Lee County': '37105',
    'Lenoir County': '37107',
    'Lincoln County': '37109',
    'McDowell County': '37111',
    'Macon County': '37113',
    'Madison County': '37115',
    'Martin County': '37117',
    'Mecklenburg County': '37119',
    'Mitchell County': '37121',
    'Montgomery County': '37123',
    'Moore County': '37125',
    'Nash County': '37127',
    'New Hanover County': '37129',
    'Northampton County': '37131',
    'Onslow County': '37133',
    'Orange County': '37135',
    'Pamlico County': '37137',
    'Pasquotank County': '37139',
    'Pender County': '37141',
    'Perquimans County': '37143',
    'Person County': '37145',
    'Pitt County': '37147',
    'Polk County': '37149',
    'Randolph County': '37151',
    'Richmond County': '37153',
    'Robeson County': '37155',
    'Rockingham County': '37157',
    'Rowan County': '37159',
    'Rutherford County': '37161',
    'Sampson County': '37163',
    'Scotland County': '37165',
    'Stanly County': '37167',
    'Stokes County': '37169',
    'Surry County': '37171',
    'Swain County': '37173',
    'Transylvania County': '37175',
    'Tyrrell County': '37177',
    'Union County': '37179',
    'Vance County': '37181',
    'Wake County': '37183',
    'Warren County': '37185',
    'Washington County': '37187',
    'Watauga County': '37189',
    'Wayne County': '37191',
    'Wilkes County': '37193',
    'Wilson County': '37195',
    'Yadkin County': '37197',
    'Yancey County': '37199',
  },
  ND: {
    'North Dakota': '38000',
    'Adams County': '38001',
    'Barnes County': '38003',
    'Benson County': '38005',
    'Billings County': '38007',
    'Bottineau County': '38009',
    'Bowman County': '38011',
    'Burke County': '38013',
    'Burleigh County': '38015',
    'Cass County': '38017',
    'Cavalier County': '38019',
    'Dickey County': '38021',
    'Divide County': '38023',
    'Dunn County': '38025',
    'Eddy County': '38027',
    'Emmons County': '38029',
    'Foster County': '38031',
    'Golden Valley County': '38033',
    'Grand Forks County': '38035',
    'Grant County': '38037',
    'Griggs County': '38039',
    'Hettinger County': '38041',
    'Kidder County': '38043',
    'LaMoure County': '38045',
    'Logan County': '38047',
    'McHenry County': '38049',
    'McIntosh County': '38051',
    'McKenzie County': '38053',
    'McLean County': '38055',
    'Mercer County': '38057',
    'Morton County': '38059',
    'Mountrail County': '38061',
    'Nelson County': '38063',
    'Oliver County': '38065',
    'Pembina County': '38067',
    'Pierce County': '38069',
    'Ramsey County': '38071',
    'Ransom County': '38073',
    'Renville County': '38075',
    'Richland County': '38077',
    'Rolette County': '38079',
    'Sargent County': '38081',
    'Sheridan County': '38083',
    'Sioux County': '38085',
    'Slope County': '38087',
    'Stark County': '38089',
    'Steele County': '38091',
    'Stutsman County': '38093',
    'Towner County': '38095',
    'Traill County': '38097',
    'Walsh County': '38099',
    'Ward County': '38101',
    'Wells County': '38103',
    'Williams County': '38105',
  },
  OH: {
    Ohio: '39000',
    'Adams County': '39001',
    'Allen County': '39003',
    'Ashland County': '39005',
    'Ashtabula County': '39007',
    'Athens County': '39009',
    'Auglaize County': '39011',
    'Belmont County': '39013',
    'Brown County': '39015',
    'Butler County': '39017',
    'Carroll County': '39019',
    'Champaign County': '39021',
    'Clark County': '39023',
    'Clermont County': '39025',
    'Clinton County': '39027',
    'Columbiana County': '39029',
    'Coshocton County': '39031',
    'Crawford County': '39033',
    'Cuyahoga County': '39035',
    'Darke County': '39037',
    'Defiance County': '39039',
    'Delaware County': '39041',
    'Erie County': '39043',
    'Fairfield County': '39045',
    'Fayette County': '39047',
    'Franklin County': '39049',
    'Fulton County': '39051',
    'Gallia County': '39053',
    'Geauga County': '39055',
    'Greene County': '39057',
    'Guernsey County': '39059',
    'Hamilton County': '39061',
    'Hancock County': '39063',
    'Hardin County': '39065',
    'Harrison County': '39067',
    'Henry County': '39069',
    'Highland County': '39071',
    'Hocking County': '39073',
    'Holmes County': '39075',
    'Huron County': '39077',
    'Jackson County': '39079',
    'Jefferson County': '39081',
    'Knox County': '39083',
    'Lake County': '39085',
    'Lawrence County': '39087',
    'Licking County': '39089',
    'Logan County': '39091',
    'Lorain County': '39093',
    'Lucas County': '39095',
    'Madison County': '39097',
    'Mahoning County': '39099',
    'Marion County': '39101',
    'Medina County': '39103',
    'Meigs County': '39105',
    'Mercer County': '39107',
    'Miami County': '39109',
    'Monroe County': '39111',
    'Montgomery County': '39113',
    'Morgan County': '39115',
    'Morrow County': '39117',
    'Muskingum County': '39119',
    'Noble County': '39121',
    'Ottawa County': '39123',
    'Paulding County': '39125',
    'Perry County': '39127',
    'Pickaway County': '39129',
    'Pike County': '39131',
    'Portage County': '39133',
    'Preble County': '39135',
    'Putnam County': '39137',
    'Richland County': '39139',
    'Ross County': '39141',
    'Sandusky County': '39143',
    'Scioto County': '39145',
    'Seneca County': '39147',
    'Shelby County': '39149',
    'Stark County': '39151',
    'Summit County': '39153',
    'Trumbull County': '39155',
    'Tuscarawas County': '39157',
    'Union County': '39159',
    'Van Wert County': '39161',
    'Vinton County': '39163',
    'Warren County': '39165',
    'Washington County': '39167',
    'Wayne County': '39169',
    'Williams County': '39171',
    'Wood County': '39173',
    'Wyandot County': '39175',
  },
  OK: {
    Oklahoma: '40000',
    'Adair County': '40001',
    'Alfalfa County': '40003',
    'Atoka County': '40005',
    'Beaver County': '40007',
    'Beckham County': '40009',
    'Blaine County': '40011',
    'Bryan County': '40013',
    'Caddo County': '40015',
    'Canadian County': '40017',
    'Carter County': '40019',
    'Cherokee County': '40021',
    'Choctaw County': '40023',
    'Cimarron County': '40025',
    'Cleveland County': '40027',
    'Coal County': '40029',
    'Comanche County': '40031',
    'Cotton County': '40033',
    'Craig County': '40035',
    'Creek County': '40037',
    'Custer County': '40039',
    'Delaware County': '40041',
    'Dewey County': '40043',
    'Ellis County': '40045',
    'Garfield County': '40047',
    'Garvin County': '40049',
    'Grady County': '40051',
    'Grant County': '40053',
    'Greer County': '40055',
    'Harmon County': '40057',
    'Harper County': '40059',
    'Haskell County': '40061',
    'Hughes County': '40063',
    'Jackson County': '40065',
    'Jefferson County': '40067',
    'Johnston County': '40069',
    'Kay County': '40071',
    'Kingfisher County': '40073',
    'Kiowa County': '40075',
    'Latimer County': '40077',
    'Le Flore County': '40079',
    'Lincoln County': '40081',
    'Logan County': '40083',
    'Love County': '40085',
    'McClain County': '40087',
    'McCurtain County': '40089',
    'McIntosh County': '40091',
    'Major County': '40093',
    'Marshall County': '40095',
    'Mayes County': '40097',
    'Murray County': '40099',
    'Muskogee County': '40101',
    'Noble County': '40103',
    'Nowata County': '40105',
    'Okfuskee County': '40107',
    'Oklahoma County': '40109',
    'Okmulgee County': '40111',
    'Osage County': '40113',
    'Ottawa County': '40115',
    'Pawnee County': '40117',
    'Payne County': '40119',
    'Pittsburg County': '40121',
    'Pontotoc County': '40123',
    'Pottawatomie County': '40125',
    'Pushmataha County': '40127',
    'Roger Mills County': '40129',
    'Rogers County': '40131',
    'Seminole County': '40133',
    'Sequoyah County': '40135',
    'Stephens County': '40137',
    'Texas County': '40139',
    'Tillman County': '40141',
    'Tulsa County': '40143',
    'Wagoner County': '40145',
    'Washington County': '40147',
    'Washita County': '40149',
    'Woods County': '40151',
    'Woodward County': '40153',
  },
  OR: {
    Oregon: '41000',
    'Baker County': '41001',
    'Benton County': '41003',
    'Clackamas County': '41005',
    'Clatsop County': '41007',
    'Columbia County': '41009',
    'Coos County': '41011',
    'Crook County': '41013',
    'Curry County': '41015',
    'Deschutes County': '41017',
    'Douglas County': '41019',
    'Gilliam County': '41021',
    'Grant County': '41023',
    'Harney County': '41025',
    'Hood River County': '41027',
    'Jackson County': '41029',
    'Jefferson County': '41031',
    'Josephine County': '41033',
    'Klamath County': '41035',
    'Lake County': '41037',
    'Lane County': '41039',
    'Lincoln County': '41041',
    'Linn County': '41043',
    'Malheur County': '41045',
    'Marion County': '41047',
    'Morrow County': '41049',
    'Multnomah County': '41051',
    'Polk County': '41053',
    'Sherman County': '41055',
    'Tillamook County': '41057',
    'Umatilla County': '41059',
    'Union County': '41061',
    'Wallowa County': '41063',
    'Wasco County': '41065',
    'Washington County': '41067',
    'Wheeler County': '41069',
    'Yamhill County': '41071',
  },
  PA: {
    Pennsylvania: '42000',
    'Adams County': '42001',
    'Allegheny County': '42003',
    'Armstrong County': '42005',
    'Beaver County': '42007',
    'Bedford County': '42009',
    'Berks County': '42011',
    'Blair County': '42013',
    'Bradford County': '42015',
    'Bucks County': '42017',
    'Butler County': '42019',
    'Cambria County': '42021',
    'Cameron County': '42023',
    'Carbon County': '42025',
    'Centre County': '42027',
    'Chester County': '42029',
    'Clarion County': '42031',
    'Clearfield County': '42033',
    'Clinton County': '42035',
    'Columbia County': '42037',
    'Crawford County': '42039',
    'Cumberland County': '42041',
    'Dauphin County': '42043',
    'Delaware County': '42045',
    'Elk County': '42047',
    'Erie County': '42049',
    'Fayette County': '42051',
    'Forest County': '42053',
    'Franklin County': '42055',
    'Fulton County': '42057',
    'Greene County': '42059',
    'Huntingdon County': '42061',
    'Indiana County': '42063',
    'Jefferson County': '42065',
    'Juniata County': '42067',
    'Lackawanna County': '42069',
    'Lancaster County': '42071',
    'Lawrence County': '42073',
    'Lebanon County': '42075',
    'Lehigh County': '42077',
    'Luzerne County': '42079',
    'Lycoming County': '42081',
    'McKean County': '42083',
    'Mercer County': '42085',
    'Mifflin County': '42087',
    'Monroe County': '42089',
    'Montgomery County': '42091',
    'Montour County': '42093',
    'Northampton County': '42095',
    'Northumberland County': '42097',
    'Perry County': '42099',
    'Philadelphia County': '42101',
    'Pike County': '42103',
    'Potter County': '42105',
    'Schuylkill County': '42107',
    'Snyder County': '42109',
    'Somerset County': '42111',
    'Sullivan County': '42113',
    'Susquehanna County': '42115',
    'Tioga County': '42117',
    'Union County': '42119',
    'Venango County': '42121',
    'Warren County': '42123',
    'Washington County': '42125',
    'Wayne County': '42127',
    'Westmoreland County': '42129',
    'Wyoming County': '42131',
    'York County': '42133',
  },
  RI: {
    'Rhode Island': '44000',
    'Bristol County': '44001',
    'Kent County': '44003',
    'Newport County': '44005',
    'Providence County': '44007',
    'Washington County': '44009',
  },
  SC: {
    'South Carolina': '45000',
    'Abbeville County': '45001',
    'Aiken County': '45003',
    'Allendale County': '45005',
    'Anderson County': '45007',
    'Bamberg County': '45009',
    'Barnwell County': '45011',
    'Beaufort County': '45013',
    'Berkeley County': '45015',
    'Calhoun County': '45017',
    'Charleston County': '45019',
    'Cherokee County': '45021',
    'Chester County': '45023',
    'Chesterfield County': '45025',
    'Clarendon County': '45027',
    'Colleton County': '45029',
    'Darlington County': '45031',
    'Dillon County': '45033',
    'Dorchester County': '45035',
    'Edgefield County': '45037',
    'Fairfield County': '45039',
    'Florence County': '45041',
    'Georgetown County': '45043',
    'Greenville County': '45045',
    'Greenwood County': '45047',
    'Hampton County': '45049',
    'Horry County': '45051',
    'Jasper County': '45053',
    'Kershaw County': '45055',
    'Lancaster County': '45057',
    'Laurens County': '45059',
    'Lee County': '45061',
    'Lexington County': '45063',
    'McCormick County': '45065',
    'Marion County': '45067',
    'Marlboro County': '45069',
    'Newberry County': '45071',
    'Oconee County': '45073',
    'Orangeburg County': '45075',
    'Pickens County': '45077',
    'Richland County': '45079',
    'Saluda County': '45081',
    'Spartanburg County': '45083',
    'Sumter County': '45085',
    'Union County': '45087',
    'Williamsburg County': '45089',
    'York County': '45091',
  },
  SD: {
    'South Dakota': '46000',
    'Aurora County': '46003',
    'Beadle County': '46005',
    'Bennett County': '46007',
    'Bon Homme County': '46009',
    'Brookings County': '46011',
    'Brown County': '46013',
    'Brule County': '46015',
    'Buffalo County': '46017',
    'Butte County': '46019',
    'Campbell County': '46021',
    'Charles Mix County': '46023',
    'Clark County': '46025',
    'Clay County': '46027',
    'Codington County': '46029',
    'Corson County': '46031',
    'Custer County': '46033',
    'Davison County': '46035',
    'Day County': '46037',
    'Deuel County': '46039',
    'Dewey County': '46041',
    'Douglas County': '46043',
    'Edmunds County': '46045',
    'Fall River County': '46047',
    'Faulk County': '46049',
    'Grant County': '46051',
    'Gregory County': '46053',
    'Haakon County': '46055',
    'Hamlin County': '46057',
    'Hand County': '46059',
    'Hanson County': '46061',
    'Harding County': '46063',
    'Hughes County': '46065',
    'Hutchinson County': '46067',
    'Hyde County': '46069',
    'Jackson County': '46071',
    'Jerauld County': '46073',
    'Jones County': '46075',
    'Kingsbury County': '46077',
    'Lake County': '46079',
    'Lawrence County': '46081',
    'Lincoln County': '46083',
    'Lyman County': '46085',
    'McCook County': '46087',
    'McPherson County': '46089',
    'Marshall County': '46091',
    'Meade County': '46093',
    'Mellette County': '46095',
    'Miner County': '46097',
    'Minnehaha County': '46099',
    'Moody County': '46101',
    'Pennington County': '46103',
    'Perkins County': '46105',
    'Potter County': '46107',
    'Roberts County': '46109',
    'Sanborn County': '46111',
    'Shannon County': '46113',
    'Spink County': '46115',
    'Stanley County': '46117',
    'Sully County': '46119',
    'Todd County': '46121',
    'Tripp County': '46123',
    'Turner County': '46125',
    'Union County': '46127',
    'Walworth County': '46129',
    'Yankton County': '46135',
    'Ziebach County': '46137',
  },
  TN: {
    Tennessee: '47000',
    'Anderson County': '47001',
    'Bedford County': '47003',
    'Benton County': '47005',
    'Bledsoe County': '47007',
    'Blount County': '47009',
    'Bradley County': '47011',
    'Campbell County': '47013',
    'Cannon County': '47015',
    'Carroll County': '47017',
    'Carter County': '47019',
    'Cheatham County': '47021',
    'Chester County': '47023',
    'Claiborne County': '47025',
    'Clay County': '47027',
    'Cocke County': '47029',
    'Coffee County': '47031',
    'Crockett County': '47033',
    'Cumberland County': '47035',
    'Davidson County': '47037',
    'Decatur County': '47039',
    'DeKalb County': '47041',
    'Dickson County': '47043',
    'Dyer County': '47045',
    'Fayette County': '47047',
    'Fentress County': '47049',
    'Franklin County': '47051',
    'Gibson County': '47053',
    'Giles County': '47055',
    'Grainger County': '47057',
    'Greene County': '47059',
    'Grundy County': '47061',
    'Hamblen County': '47063',
    'Hamilton County': '47065',
    'Hancock County': '47067',
    'Hardeman County': '47069',
    'Hardin County': '47071',
    'Hawkins County': '47073',
    'Haywood County': '47075',
    'Henderson County': '47077',
    'Henry County': '47079',
    'Hickman County': '47081',
    'Houston County': '47083',
    'Humphreys County': '47085',
    'Jackson County': '47087',
    'Jefferson County': '47089',
    'Johnson County': '47091',
    'Knox County': '47093',
    'Lake County': '47095',
    'Lauderdale County': '47097',
    'Lawrence County': '47099',
    'Lewis County': '47101',
    'Lincoln County': '47103',
    'Loudon County': '47105',
    'McMinn County': '47107',
    'McNairy County': '47109',
    'Macon County': '47111',
    'Madison County': '47113',
    'Marion County': '47115',
    'Marshall County': '47117',
    'Maury County': '47119',
    'Meigs County': '47121',
    'Monroe County': '47123',
    'Montgomery County': '47125',
    'Moore County': '47127',
    'Morgan County': '47129',
    'Obion County': '47131',
    'Overton County': '47133',
    'Perry County': '47135',
    'Pickett County': '47137',
    'Polk County': '47139',
    'Putnam County': '47141',
    'Rhea County': '47143',
    'Roane County': '47145',
    'Robertson County': '47147',
    'Rutherford County': '47149',
    'Scott County': '47151',
    'Sequatchie County': '47153',
    'Sevier County': '47155',
    'Shelby County': '47157',
    'Smith County': '47159',
    'Stewart County': '47161',
    'Sullivan County': '47163',
    'Sumner County': '47165',
    'Tipton County': '47167',
    'Trousdale County': '47169',
    'Unicoi County': '47171',
    'Union County': '47173',
    'Van Buren County': '47175',
    'Warren County': '47177',
    'Washington County': '47179',
    'Wayne County': '47181',
    'Weakley County': '47183',
    'White County': '47185',
    'Williamson County': '47187',
    'Wilson County': '47189',
  },
  TX: {
    Texas: '48000',
    'Anderson County': '48001',
    'Andrews County': '48003',
    'Angelina County': '48005',
    'Aransas County': '48007',
    'Archer County': '48009',
    'Armstrong County': '48011',
    'Atascosa County': '48013',
    'Austin County': '48015',
    'Bailey County': '48017',
    'Bandera County': '48019',
    'Bastrop County': '48021',
    'Baylor County': '48023',
    'Bee County': '48025',
    'Bell County': '48027',
    'Bexar County': '48029',
    'Blanco County': '48031',
    'Borden County': '48033',
    'Bosque County': '48035',
    'Bowie County': '48037',
    'Brazoria County': '48039',
    'Brazos County': '48041',
    'Brewster County': '48043',
    'Briscoe County': '48045',
    'Brooks County': '48047',
    'Brown County': '48049',
    'Burleson County': '48051',
    'Burnet County': '48053',
    'Caldwell County': '48055',
    'Calhoun County': '48057',
    'Callahan County': '48059',
    'Cameron County': '48061',
    'Camp County': '48063',
    'Carson County': '48065',
    'Cass County': '48067',
    'Castro County': '48069',
    'Chambers County': '48071',
    'Cherokee County': '48073',
    'Childress County': '48075',
    'Clay County': '48077',
    'Cochran County': '48079',
    'Coke County': '48081',
    'Coleman County': '48083',
    'Collin County': '48085',
    'Collingsworth County': '48087',
    'Colorado County': '48089',
    'Comal County': '48091',
    'Comanche County': '48093',
    'Concho County': '48095',
    'Cooke County': '48097',
    'Coryell County': '48099',
    'Cottle County': '48101',
    'Crane County': '48103',
    'Crockett County': '48105',
    'Crosby County': '48107',
    'Culberson County': '48109',
    'Dallam County': '48111',
    'Dallas County': '48113',
    'Dawson County': '48115',
    'Deaf Smith County': '48117',
    'Delta County': '48119',
    'Denton County': '48121',
    'DeWitt County': '48123',
    'Dickens County': '48125',
    'Dimmit County': '48127',
    'Donley County': '48129',
    'Duval County': '48131',
    'Eastland County': '48133',
    'Ector County': '48135',
    'Edwards County': '48137',
    'Ellis County': '48139',
    'El Paso County': '48141',
    'Erath County': '48143',
    'Falls County': '48145',
    'Fannin County': '48147',
    'Fayette County': '48149',
    'Fisher County': '48151',
    'Floyd County': '48153',
    'Foard County': '48155',
    'Fort Bend County': '48157',
    'Franklin County': '48159',
    'Freestone County': '48161',
    'Frio County': '48163',
    'Gaines County': '48165',
    'Galveston County': '48167',
    'Garza County': '48169',
    'Gillespie County': '48171',
    'Glasscock County': '48173',
    'Goliad County': '48175',
    'Gonzales County': '48177',
    'Gray County': '48179',
    'Grayson County': '48181',
    'Gregg County': '48183',
    'Grimes County': '48185',
    'Guadalupe County': '48187',
    'Hale County': '48189',
    'Hall County': '48191',
    'Hamilton County': '48193',
    'Hansford County': '48195',
    'Hardeman County': '48197',
    'Hardin County': '48199',
    'Harris County': '48201',
    'Harrison County': '48203',
    'Hartley County': '48205',
    'Haskell County': '48207',
    'Hays County': '48209',
    'Hemphill County': '48211',
    'Henderson County': '48213',
    'Hidalgo County': '48215',
    'Hill County': '48217',
    'Hockley County': '48219',
    'Hood County': '48221',
    'Hopkins County': '48223',
    'Houston County': '48225',
    'Howard County': '48227',
    'Hudspeth County': '48229',
    'Hunt County': '48231',
    'Hutchinson County': '48233',
    'Irion County': '48235',
    'Jack County': '48237',
    'Jackson County': '48239',
    'Jasper County': '48241',
    'Jeff Davis County': '48243',
    'Jefferson County': '48245',
    'Jim Hogg County': '48247',
    'Jim Wells County': '48249',
    'Johnson County': '48251',
    'Jones County': '48253',
    'Karnes County': '48255',
    'Kaufman County': '48257',
    'Kendall County': '48259',
    'Kenedy County': '48261',
    'Kent County': '48263',
    'Kerr County': '48265',
    'Kimble County': '48267',
    'King County': '48269',
    'Kinney County': '48271',
    'Kleberg County': '48273',
    'Knox County': '48275',
    'Lamar County': '48277',
    'Lamb County': '48279',
    'Lampasas County': '48281',
    'La Salle County': '48283',
    'Lavaca County': '48285',
    'Lee County': '48287',
    'Leon County': '48289',
    'Liberty County': '48291',
    'Limestone County': '48293',
    'Lipscomb County': '48295',
    'Live Oak County': '48297',
    'Llano County': '48299',
    'Loving County': '48301',
    'Lubbock County': '48303',
    'Lynn County': '48305',
    'McCulloch County': '48307',
    'McLennan County': '48309',
    'McMullen County': '48311',
    'Madison County': '48313',
    'Marion County': '48315',
    'Martin County': '48317',
    'Mason County': '48319',
    'Matagorda County': '48321',
    'Maverick County': '48323',
    'Medina County': '48325',
    'Menard County': '48327',
    'Midland County': '48329',
    'Milam County': '48331',
    'Mills County': '48333',
    'Mitchell County': '48335',
    'Montague County': '48337',
    'Montgomery County': '48339',
    'Moore County': '48341',
    'Morris County': '48343',
    'Motley County': '48345',
    'Nacogdoches County': '48347',
    'Navarro County': '48349',
    'Newton County': '48351',
    'Nolan County': '48353',
    'Nueces County': '48355',
    'Ochiltree County': '48357',
    'Oldham County': '48359',
    'Orange County': '48361',
    'Palo Pinto County': '48363',
    'Panola County': '48365',
    'Parker County': '48367',
    'Parmer County': '48369',
    'Pecos County': '48371',
    'Polk County': '48373',
    'Potter County': '48375',
    'Presidio County': '48377',
    'Rains County': '48379',
    'Randall County': '48381',
    'Reagan County': '48383',
    'Real County': '48385',
    'Red River County': '48387',
    'Reeves County': '48389',
    'Refugio County': '48391',
    'Roberts County': '48393',
    'Robertson County': '48395',
    'Rockwall County': '48397',
    'Runnels County': '48399',
    'Rusk County': '48401',
    'Sabine County': '48403',
    'San Augustine County': '48405',
    'San Jacinto County': '48407',
    'San Patricio County': '48409',
    'San Saba County': '48411',
    'Schleicher County': '48413',
    'Scurry County': '48415',
    'Shackelford County': '48417',
    'Shelby County': '48419',
    'Sherman County': '48421',
    'Smith County': '48423',
    'Somervell County': '48425',
    'Starr County': '48427',
    'Stephens County': '48429',
    'Sterling County': '48431',
    'Stonewall County': '48433',
    'Sutton County': '48435',
    'Swisher County': '48437',
    'Tarrant County': '48439',
    'Taylor County': '48441',
    'Terrell County': '48443',
    'Terry County': '48445',
    'Throckmorton County': '48447',
    'Titus County': '48449',
    'Tom Green County': '48451',
    'Travis County': '48453',
    'Trinity County': '48455',
    'Tyler County': '48457',
    'Upshur County': '48459',
    'Upton County': '48461',
    'Uvalde County': '48463',
    'Val Verde County': '48465',
    'Van Zandt County': '48467',
    'Victoria County': '48469',
    'Walker County': '48471',
    'Waller County': '48473',
    'Ward County': '48475',
    'Washington County': '48477',
    'Webb County': '48479',
    'Wharton County': '48481',
    'Wheeler County': '48483',
    'Wichita County': '48485',
    'Wilbarger County': '48487',
    'Willacy County': '48489',
    'Williamson County': '48491',
    'Wilson County': '48493',
    'Winkler County': '48495',
    'Wise County': '48497',
    'Wood County': '48499',
    'Yoakum County': '48501',
    'Young County': '48503',
    'Zapata County': '48505',
    'Zavala County': '48507',
  },
  UT: {
    Utah: '49000',
    'Beaver County': '49001',
    'Box Elder County': '49003',
    'Cache County': '49005',
    'Carbon County': '49007',
    'Daggett County': '49009',
    'Davis County': '49011',
    'Duchesne County': '49013',
    'Emery County': '49015',
    'Garfield County': '49017',
    'Grand County': '49019',
    'Iron County': '49021',
    'Juab County': '49023',
    'Kane County': '49025',
    'Millard County': '49027',
    'Morgan County': '49029',
    'Piute County': '49031',
    'Rich County': '49033',
    'Salt Lake County': '49035',
    'San Juan County': '49037',
    'Sanpete County': '49039',
    'Sevier County': '49041',
    'Summit County': '49043',
    'Tooele County': '49045',
    'Uintah County': '49047',
    'Utah County': '49049',
    'Wasatch County': '49051',
    'Washington County': '49053',
    'Wayne County': '49055',
    'Weber County': '49057',
  },
  VT: {
    Vermont: '50000',
    'Addison County': '50001',
    'Bennington County': '50003',
    'Caledonia County': '50005',
    'Chittenden County': '50007',
    'Essex County': '50009',
    'Franklin County': '50011',
    'Grand Isle County': '50013',
    'Lamoille County': '50015',
    'Orange County': '50017',
    'Orleans County': '50019',
    'Rutland County': '50021',
    'Washington County': '50023',
    'Windham County': '50025',
    'Windsor County': '50027',
  },
  VA: {
    Virginia: '51000',
    'Accomack County': '51001',
    'Albemarle County': '51003',
    'Alleghany County': '51005',
    'Amelia County': '51007',
    'Amherst County': '51009',
    'Appomattox County': '51011',
    'Arlington County': '51013',
    'Augusta County': '51015',
    'Bath County': '51017',
    'Bedford County': '51019',
    'Bland County': '51021',
    'Botetourt County': '51023',
    'Brunswick County': '51025',
    'Buchanan County': '51027',
    'Buckingham County': '51029',
    'Campbell County': '51031',
    'Caroline County': '51033',
    'Carroll County': '51035',
    'Charles City County': '51036',
    'Charlotte County': '51037',
    'Chesterfield County': '51041',
    'Clarke County': '51043',
    'Craig County': '51045',
    'Culpeper County': '51047',
    'Cumberland County': '51049',
    'Dickenson County': '51051',
    'Dinwiddie County': '51053',
    'Essex County': '51057',
    'Fairfax County': '51059',
    'Fauquier County': '51061',
    'Floyd County': '51063',
    'Fluvanna County': '51065',
    'Franklin County': '51067',
    'Frederick County': '51069',
    'Giles County': '51071',
    'Gloucester County': '51073',
    'Goochland County': '51075',
    'Grayson County': '51077',
    'Greene County': '51079',
    'Greensville County': '51081',
    'Halifax County': '51083',
    'Hanover County': '51085',
    'Henrico County': '51087',
    'Henry County': '51089',
    'Highland County': '51091',
    'Isle of Wight County': '51093',
    'James City County': '51095',
    'King and Queen County': '51097',
    'King George County': '51099',
    'King William County': '51101',
    'Lancaster County': '51103',
    'Lee County': '51105',
    'Loudoun County': '51107',
    'Louisa County': '51109',
    'Lunenburg County': '51111',
    'Madison County': '51113',
    'Mathews County': '51115',
    'Mecklenburg County': '51117',
    'Middlesex County': '51119',
    'Montgomery County': '51121',
    'Nelson County': '51125',
    'New Kent County': '51127',
    'Northampton County': '51131',
    'Northumberland County': '51133',
    'Nottoway County': '51135',
    'Orange County': '51137',
    'Page County': '51139',
    'Patrick County': '51141',
    'Pittsylvania County': '51143',
    'Powhatan County': '51145',
    'Prince Edward County': '51147',
    'Prince George County': '51149',
    'Prince William County': '51153',
    'Pulaski County': '51155',
    'Rappahannock County': '51157',
    'Richmond County': '51159',
    'Roanoke County': '51161',
    'Rockbridge County': '51163',
    'Rockingham County': '51165',
    'Russell County': '51167',
    'Scott County': '51169',
    'Shenandoah County': '51171',
    'Smyth County': '51173',
    'Southampton County': '51175',
    'Spotsylvania County': '51177',
    'Stafford County': '51179',
    'Surry County': '51181',
    'Sussex County': '51183',
    'Tazewell County': '51185',
    'Warren County': '51187',
    'Washington County': '51191',
    'Westmoreland County': '51193',
    'Wise County': '51195',
    'Wythe County': '51197',
    'York County': '51199',
    'Alexandria city': '51510',
    'Bedford city': '51515',
    'Bristol city': '51520',
    'Buena Vista city': '51530',
    'Charlottesville city': '51540',
    'Chesapeake city': '51550',
    'Colonial Heights city': '51570',
    'Covington city': '51580',
    'Danville city': '51590',
    'Emporia city': '51595',
    'Fairfax city': '51600',
    'Falls Church city': '51610',
    'Franklin city': '51620',
    'Fredericksburg city': '51630',
    'Galax city': '51640',
    'Hampton city': '51650',
    'Harrisonburg city': '51660',
    'Hopewell city': '51670',
    'Lexington city': '51678',
    'Lynchburg city': '51680',
    'Manassas city': '51683',
    'Manassas Park city': '51685',
    'Martinsville city': '51690',
    'Newport News city': '51700',
    'Norfolk city': '51710',
    'Norton city': '51720',
    'Petersburg city': '51730',
    'Poquoson city': '51735',
    'Portsmouth city': '51740',
    'Radford city': '51750',
    'Richmond city': '51760',
    'Roanoke city': '51770',
    'Salem city': '51775',
    'Staunton city': '51790',
    'Suffolk city': '51800',
    'Virginia Beach city': '51810',
    'Waynesboro city': '51820',
    'Williamsburg city': '51830',
    'Winchester city': '51840',
  },
  WA: {
    Washington: '53000',
    'Adams County': '53001',
    'Asotin County': '53003',
    'Benton County': '53005',
    'Chelan County': '53007',
    'Clallam County': '53009',
    'Clark County': '53011',
    'Columbia County': '53013',
    'Cowlitz County': '53015',
    'Douglas County': '53017',
    'Ferry County': '53019',
    'Franklin County': '53021',
    'Garfield County': '53023',
    'Grant County': '53025',
    'Grays Harbor County': '53027',
    'Island County': '53029',
    'Jefferson County': '53031',
    'King County': '53033',
    'Kitsap County': '53035',
    'Kittitas County': '53037',
    'Klickitat County': '53039',
    'Lewis County': '53041',
    'Lincoln County': '53043',
    'Mason County': '53045',
    'Okanogan County': '53047',
    'Pacific County': '53049',
    'Pend Oreille County': '53051',
    'Pierce County': '53053',
    'San Juan County': '53055',
    'Skagit County': '53057',
    'Skamania County': '53059',
    'Snohomish County': '53061',
    'Spokane County': '53063',
    'Stevens County': '53065',
    'Thurston County': '53067',
    'Wahkiakum County': '53069',
    'Walla Walla County': '53071',
    'Whatcom County': '53073',
    'Whitman County': '53075',
    'Yakima County': '53077',
  },
  WV: {
    'West Virginia': '54000',
    'Barbour County': '54001',
    'Berkeley County': '54003',
    'Boone County': '54005',
    'Braxton County': '54007',
    'Brooke County': '54009',
    'Cabell County': '54011',
    'Calhoun County': '54013',
    'Clay County': '54015',
    'Doddridge County': '54017',
    'Fayette County': '54019',
    'Gilmer County': '54021',
    'Grant County': '54023',
    'Greenbrier County': '54025',
    'Hampshire County': '54027',
    'Hancock County': '54029',
    'Hardy County': '54031',
    'Harrison County': '54033',
    'Jackson County': '54035',
    'Jefferson County': '54037',
    'Kanawha County': '54039',
    'Lewis County': '54041',
    'Lincoln County': '54043',
    'Logan County': '54045',
    'McDowell County': '54047',
    'Marion County': '54049',
    'Marshall County': '54051',
    'Mason County': '54053',
    'Mercer County': '54055',
    'Mineral County': '54057',
    'Mingo County': '54059',
    'Monongalia County': '54061',
    'Monroe County': '54063',
    'Morgan County': '54065',
    'Nicholas County': '54067',
    'Ohio County': '54069',
    'Pendleton County': '54071',
    'Pleasants County': '54073',
    'Pocahontas County': '54075',
    'Preston County': '54077',
    'Putnam County': '54079',
    'Raleigh County': '54081',
    'Randolph County': '54083',
    'Ritchie County': '54085',
    'Roane County': '54087',
    'Summers County': '54089',
    'Taylor County': '54091',
    'Tucker County': '54093',
    'Tyler County': '54095',
    'Upshur County': '54097',
    'Wayne County': '54099',
    'Webster County': '54101',
    'Wetzel County': '54103',
    'Wirt County': '54105',
    'Wood County': '54107',
    'Wyoming County': '54109',
  },
  WI: {
    Wisconsin: '55000',
    'Adams County': '55001',
    'Ashland County': '55003',
    'Barron County': '55005',
    'Bayfield County': '55007',
    'Brown County': '55009',
    'Buffalo County': '55011',
    'Burnett County': '55013',
    'Calumet County': '55015',
    'Chippewa County': '55017',
    'Clark County': '55019',
    'Columbia County': '55021',
    'Crawford County': '55023',
    'Dane County': '55025',
    'Dodge County': '55027',
    'Door County': '55029',
    'Douglas County': '55031',
    'Dunn County': '55033',
    'Eau Claire County': '55035',
    'Florence County': '55037',
    'Fond du Lac County': '55039',
    'Forest County': '55041',
    'Grant County': '55043',
    'Green County': '55045',
    'Green Lake County': '55047',
    'Iowa County': '55049',
    'Iron County': '55051',
    'Jackson County': '55053',
    'Jefferson County': '55055',
    'Juneau County': '55057',
    'Kenosha County': '55059',
    'Kewaunee County': '55061',
    'La Crosse County': '55063',
    'Lafayette County': '55065',
    'Langlade County': '55067',
    'Lincoln County': '55069',
    'Manitowoc County': '55071',
    'Marathon County': '55073',
    'Marinette County': '55075',
    'Marquette County': '55077',
    'Menominee County': '55078',
    'Milwaukee County': '55079',
    'Monroe County': '55081',
    'Oconto County': '55083',
    'Oneida County': '55085',
    'Outagamie County': '55087',
    'Ozaukee County': '55089',
    'Pepin County': '55091',
    'Pierce County': '55093',
    'Polk County': '55095',
    'Portage County': '55097',
    'Price County': '55099',
    'Racine County': '55101',
    'Richland County': '55103',
    'Rock County': '55105',
    'Rusk County': '55107',
    'St. Croix County': '55109',
    'Sauk County': '55111',
    'Sawyer County': '55113',
    'Shawano County': '55115',
    'Sheboygan County': '55117',
    'Taylor County': '55119',
    'Trempealeau County': '55121',
    'Vernon County': '55123',
    'Vilas County': '55125',
    'Walworth County': '55127',
    'Washburn County': '55129',
    'Washington County': '55131',
    'Waukesha County': '55133',
    'Waupaca County': '55135',
    'Waushara County': '55137',
    'Winnebago County': '55139',
    'Wood County': '55141',
  },
  WY: {
    Wyoming: '56000',
    'Albany County': '56001',
    'Big Horn County': '56003',
    'Campbell County': '56005',
    'Carbon County': '56007',
    'Converse County': '56009',
    'Crook County': '56011',
    'Fremont County': '56013',
    'Goshen County': '56015',
    'Hot Springs County': '56017',
    'Johnson County': '56019',
    'Laramie County': '56021',
    'Lincoln County': '56023',
    'Natrona County': '56025',
    'Niobrara County': '56027',
    'Park County': '56029',
    'Platte County': '56031',
    'Sheridan County': '56033',
    'Sublette County': '56035',
    'Sweetwater County': '56037',
    'Teton County': '56039',
    'Uinta County': '56041',
    'Washakie County': '56043',
    'Weston County': '56045',
  },
};
