import { z } from 'zod';

export const tokenAuthRequestSchema = z.object({
  clientId: z.string(),
  clientSecret: z.string(),
});
export type TokenAuthRequest = z.infer<typeof tokenAuthRequestSchema>;

export const tokenAuthResponseSchema = z.object({
  accessToken: z.string(),
  tokenType: z.string(),
  expiresIn: z.number(),
});
export type TokenAuthResponse = z.infer<typeof tokenAuthResponseSchema>;

export const tokenAuthErrorResponseSchema = z.object({
  errors: z.string().array(),
});
