import {
  CircularProgress,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material'

type TextInputProps = TextFieldProps & {
  isLoading?: boolean
}

export const TextInput = ({
  InputProps = {},
  isLoading = false,
  ...props
}: TextInputProps) => {
  const inputProps = { ...InputProps }

  if (isLoading) {
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <CircularProgress />
      </InputAdornment>
    )
  }

  return <TextField InputProps={inputProps} {...props} />
}

export default TextInput
