import { useState, useEffect, useContext, createContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { pages } from 'common'
import { getLgmTargetWeightInitialValues } from '../../components/shared/lgmTargetWeight'

const defaultCattleTargetWeights =
  getLgmTargetWeightInitialValues('Calf Finishing')
const initialContext = {
  commodity: 'Cattle',
  commodityType: 'Calf Finishing',
  deductible: 0,
  liveCattleTargetCwtPerHead:
    defaultCattleTargetWeights.liveCattleTargetCwtPerHead,
  feederCattleTargetCwtPerHead:
    defaultCattleTargetWeights.feederCattleTargetCwtPerHead,
  cornTargetBuPerHead: defaultCattleTargetWeights.cornTargetBuPerHead,
}

const LGMContext = createContext()
const { Provider } = LGMContext

const useProvideLGM = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [state, setState] = useState(initialContext)

  useEffect(() => {
    if (location.pathname === pages.lgm.path) {
      navigate(pages.lgmQuote.path, { replace: true })
    }
  }, [navigate, location])

  return { initialLGMValues: state, setLGMInitialValues: setState }
}

export const useLGM = () => useContext(LGMContext)

const LGMProvider = ({ children }) => {
  const { initialLGMValues, setLGMInitialValues } = useProvideLGM()
  return (
    <Provider value={{ initialLGMValues, setLGMInitialValues }}>
      {children}
    </Provider>
  )
}

export { LGMProvider }
