export enum EntityOwnerEntityTypes {
  user = 'User',
  company = 'Company',
  policy = 'Policy',
  coverage = 'Coverage',
  customer = 'customer',
}

export enum EntityOwnerOwnerTypes {
  user = 'User',
  agent = 'agent',
  company = 'Company',
}
