import { z } from 'zod';
import {
  ColumnType,
  GeneratedAlways,
  Insertable,
  Selectable,
  Updateable,
} from 'kysely';
import { expectTypeOf } from 'expect-type';

export type AipSettingsTable = {
  id: GeneratedAlways<string>;
  aip: ColumnType<string>;
  agencyCode: ColumnType<string>;
  addressLine1: ColumnType<string>;
  addressLine2: ColumnType<string | null>;
  city: ColumnType<string>;
  state: ColumnType<string>;
  zip: ColumnType<string>;
  email: ColumnType<string>;
  phone: ColumnType<string>;
  fax: ColumnType<string | null>;
  isActive: ColumnType<boolean>;
  isDefault: ColumnType<boolean>;
  sftpHostName: ColumnType<string | null>;
  sftpUsernameEncrypted: ColumnType<string | null>;
  sftpPasswordEncrypted: ColumnType<string | null>;
  sftpPort: ColumnType<number | null>;
  sftpFolderPath: ColumnType<string | null>;
  isSftpEnabled: ColumnType<boolean | null>;
  createdAt: ColumnType<Date | null>;
  updatedAt: ColumnType<Date | null>;
};

export type DbAipSettings = Selectable<AipSettingsTable>;
export type DbAipSettingsInsert = Insertable<AipSettingsTable>;
export type DbAipSettingsUpdate = Updateable<AipSettingsTable>;

export const dbAipSettingsSchema = z.object({
  id: z.string(),
  aip: z.string(),
  agencyCode: z.string(),
  addressLine1: z.string(),
  addressLine2: z.string().nullable(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  email: z.string().email(),
  phone: z.string(),
  fax: z.string().nullable(),
  isActive: z.boolean(),
  isDefault: z.boolean(),
  sftpHostName: z.string().nullable().optional(),
  sftpUsernameEncrypted: z.string().nullable().optional(),
  sftpPasswordEncrypted: z.string().nullable().optional(),
  sftpPort: z.coerce.number().nullable().optional(),
  sftpFolderPath: z.string().nullable().optional(),
  isSftpEnabled: z.boolean().nullable().optional(),
  createdAt: z.date().nullable(),
  updatedAt: z.date().nullable(),
});

export const dbAipSettingsInsertSchema = dbAipSettingsSchema
  .omit({
    id: true,
    createdAt: true,
  })
  .partial()
  .extend({
    aip: z.string(),
    agencyCode: z.string(),
    addressLine1: z.string(),
    city: z.string(),
    state: z.string(),
    zip: z.string(),
    email: z.string(),
    phone: z.string(),
    isActive: z.boolean(),
    isDefault: z.boolean().optional().default(false),
  });

export const dbAipSettingsUpdateSchema = dbAipSettingsSchema
  .omit({
    createdAt: true,
    updatedAt: true,
  })
  .partial();

export const safeDbAipSettingsSchema = dbAipSettingsSchema.omit({
  sftpPasswordEncrypted: true,
  sftpUsernameEncrypted: true,
});

expectTypeOf<DbAipSettings>().toMatchTypeOf<
  z.infer<typeof dbAipSettingsSchema>
>();
expectTypeOf<DbAipSettingsInsert>().toMatchTypeOf<
  z.infer<typeof dbAipSettingsInsertSchema>
>();
expectTypeOf<DbAipSettingsUpdate>().toMatchTypeOf<
  z.infer<typeof dbAipSettingsUpdateSchema>
>();
