import Joi from 'joi'

const orderSchema = Joi.array()
  .items(
    Joi.array().ordered(
      Joi.string().required(),
      Joi.string().valid('ASC', 'DESC').default('DESC').optional(),
    ),
  )
  .optional()

const limitSchema = Joi.number().min(1).optional()
const whereSchema = Joi.object().optional().unknown(false)

export const sequelizeBaseQuerySchemas = {
  order: orderSchema,
  limit: limitSchema,
  where: whereSchema,
}

const joiTypeMap = {
  STRING: 'string',
  INTEGER: 'number',
  BOOLEAN: 'boolean',
  DATE: 'date',
  TEXT: 'string',
  FLOAT: 'number',
  DECIMAL: 'number',
  BIGINT: 'number',
  ENUM: 'string',
  ARRAY: 'array',
  JSON: 'object',
  JSONB: 'object',
  BLOB: 'string',
  UUID: 'string.guid',
  TIME: 'date',
  NOW: 'date',
  VIRTUAL: 'any',
}

export const allowedOperators = [
  'eq',
  'ne',
  'gte',
  'gt',
  'lte',
  'lt',
  'not',
  'is',
  'in',
  'notIn',
  'like',
  'notLike',
  'iLike',
  'notILike',
  'startsWith',
  'endsWith',
  'regexp',
  'notRegexp',
  'between',
  'notBetween',
  'contains',
  'and',
  'or',
]

const operatorValidations = allowedOperators.map(
  (acc, operator) => ({ ...acc, [operator]: Joi.any().optional() }),
  {},
)

/*
Usage:
  getValidator('string.email') === Joi.string().email()
  getValidator('string.guid.optional') === Joi.string().guid().optional()
*/
function getValidator(inputString) {
  const segments = inputString.split('.')
  return segments.reduce((acc, segment) => acc[segment](), Joi)
}

const getJoiType = columnType =>
  joiTypeMap[columnType] || columnType.toLowerCase()

/*
Creates a model schema for use with Joi that incorporates
the model's column types and where query conditions. For example:
const roleSchema = generateModelSchema(Role)
allows where queries like:
{
  where: {
    title: {
      like: 'someTitle',
    },
  },
}
or simply:
{
  where: {
    title: 'someTitle',
  }
}
*/
export const generateModelSchema = model => {
  const columnNames = Object.keys(model.rawAttributes)
  const modelSchema = columnNames.reduce((acc, columnName) => {
    const { schema, type } = model.rawAttributes[columnName]
    const joiType = getJoiType(type.key)
    const columnValidator = schema || getValidator(joiType).optional()
    return {
      ...acc,
      [columnName]: Joi.alternatives([
        Joi.object(operatorValidations),
        columnValidator,
      ]),
    }
  }, {})

  const columnSchema = Joi.object({
    ...sequelizeBaseQuerySchemas,
    where: Joi.object(modelSchema).optional(),
  })

  model.columnNames = columnNames
  model.columnSchema = columnSchema

  return { columnNames, columnSchema }
}

export const paginationSchema = Joi.object({
  page: Joi.number().min(0).required(),
  pageSize: Joi.number().min(1).required(),
})
