import { z } from 'zod';
import { dbEntityHistoryInsertSchema } from './db-entity-history';
import { entityHistorySchema } from './entity-history';

// @todo revisit as manually created entities should not be entity_history record

export const createEntityRequestSchema = z.object({
  entity: dbEntityHistoryInsertSchema,
  secondaryEntities: z.array(dbEntityHistoryInsertSchema).optional(),
});

export type CreateEntityRequest = z.infer<typeof createEntityRequestSchema>;

export const createEntityResponseSchema = entityHistorySchema;

export type CreateEntityResponse = z.infer<typeof createEntityResponseSchema>;
