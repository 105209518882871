import { RmaTypeCodes } from '@harvestiq/constants';
import { z } from 'zod';
import { zIsoDate, zCoercedBoolean, zDecimalRequired } from '@harvestiq/zod';
import { addLgmTargetSchemaValidation } from './helpers';

export const lgmQuoteRequestSchemaObj = z.object({
  deductible: z.coerce
    .number()
    .gte(0)
    .lte(150)
    .multipleOf(10)
    .or(z.coerce.number().gte(0).lte(20).multipleOf(2)),
  commodityTypeCode: z.nativeEnum(RmaTypeCodes, {
    message: 'Invalid commodity type code',
  }),
  salesEffectiveDate: zIsoDate(),
  targetMarketings: z.coerce.number().gte(0).array(),
  isBeginningOrVeteranFarmerRancher: zCoercedBoolean().optional(),
  liveCattleTargetCwtPerHead: zDecimalRequired().optional(),
  feederCattleTargetCwtPerHead: zDecimalRequired().optional(),
  cornTargetBuPerHead: zDecimalRequired().optional(),
});

// /rma/lgm/quote
export const lgmQuoteRequestSchema = addLgmTargetSchemaValidation({
  lgmCommodityTypeCodeField: 'commodityTypeCode',
  schema: lgmQuoteRequestSchemaObj,
});

// Using different schema than above to avoid zod issue with returning
// wrong type from refine see: https://github.com/colinhacks/zod/issues/2474
export type LgmQuoteRequest = z.infer<typeof lgmQuoteRequestSchemaObj>;
// The input type for the LGM quote request is different from output due to Decimal types
// The input type allows for numbers and strings to be passed in, while the output is always a Decimal
export type LgmQuoteRequestInput = z.input<typeof lgmQuoteRequestSchemaObj>;

const lgmQuoteDataSchema = lgmQuoteRequestSchemaObj
  .omit({ targetMarketings: true })
  .extend({
    // Request
    liveCattleTargetCwtPerHead: zDecimalRequired().nullable(),
    feederCattleTargetCwtPerHead: zDecimalRequired().nullable(),
    cornTargetBuPerHead: zDecimalRequired().nullable(),
    targetMarketAmounts: z.number().array(),
    totalTargetMarketAmt: z.number(),
    // Premium
    grossMarginGuarantee: zDecimalRequired(),
    avgGrossMarginGuaranteePerHead: zDecimalRequired(),
    totalPremium: zDecimalRequired(),
    avgTotalPremiumPerHead: zDecimalRequired(),
    producerPremium: zDecimalRequired(),
    avgProducerPremiumPerHead: zDecimalRequired(),
    subsidy: zDecimalRequired(),
    avgSubsidyPerHead: zDecimalRequired(),
    subsidyRatio: zDecimalRequired(),
    // Gross Margins
    marketingDates: zIsoDate().array(),
    expectedGrossMarginsPerHead: zDecimalRequired().array(),
    grossMarginGuaranteesPerHead: zDecimalRequired().array(),
    expectedGrossMarginTotals: zDecimalRequired().array(),
    // Expected Prices
    liveCattlePricesPerCwt: zDecimalRequired().array(),
    liveCattlePricesPerHead: zDecimalRequired().array(),
    feederCattlePricesPerCwt: zDecimalRequired().array(),
    feederCattlePricesPerHead: zDecimalRequired().array(),
    cornPricesPerBu: zDecimalRequired().array(),
    cornPricesPerHead: zDecimalRequired().array(),
    leanHogPricesPerCwt: zDecimalRequired().array(),
    leanHogPricesPerHead: zDecimalRequired().array(),
    soybeanMealPricesPerTon: zDecimalRequired().array(),
    soybeanMealPricesPerHead: zDecimalRequired().array(),
  });

export type LgmQuoteData = z.infer<typeof lgmQuoteDataSchema>;

export const getLgmQuoteResponseSchema = z.object({
  data: lgmQuoteDataSchema.array(),
});

export type GetLgmQuoteResponse = z.infer<typeof getLgmQuoteResponseSchema>;
