import { z } from 'zod';

export const loginLinkRequestSchema = z.object({
  email: z.string().email(),
  redirectTo: z.string(),
});
export type LoginLinkRequest = z.infer<typeof loginLinkRequestSchema>;

export const loginLinkResponseSchema = z.object({
  success: z.boolean(),
});
export type LoginLinkResponse = z.infer<typeof loginLinkResponseSchema>;

export const loginLinkErrorResponseSchema = z.object({
  success: z.boolean(),
  errors: z.object({ message: z.string() }),
});
