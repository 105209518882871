import { cloneElement, Component, ReactElement, ReactNode } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import {
  DefaultFallbackComponent,
  isFailedToFetchModuleError,
} from './DefaultFallbackComponent'

const defaultErrorBoundaryState = Object.freeze({
  error: null,
  componentStack: null,
})

type ErrorBoundaryProps = {
  children: ReactNode
  fallback?: ReactNode
  onReset?: () => void
}

type ErrorBoundaryState = Readonly<{
  error: unknown
  componentStack: string | null
}>

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  readonly state: ErrorBoundaryState = defaultErrorBoundaryState

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.resetBoundary = this.resetBoundary.bind(this)
  }

  componentDidCatch(error: unknown, info: { componentStack: string }) {
    if (isFailedToFetchModuleError(error)) {
      console.warn('Failed to fetch module, reloading...')
    } else if (error instanceof Error) {
      // https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm

      const renderingError = new Error(error.message)
      renderingError.name = error.name || 'ReactRenderingError'
      renderingError.stack = info.componentStack
      renderingError.cause = error

      datadogRum.addError(renderingError)
    }

    this.setState({
      error,
      componentStack: info.componentStack,
    })
  }

  resetBoundary() {
    this.setState(defaultErrorBoundaryState)
    this.props.onReset?.()
  }

  render() {
    const {
      fallback = (
        <DefaultFallbackComponent
          {...this.state}
          resetError={this.resetBoundary}
        />
      ),
    } = this.props

    if (this.state.error) {
      return cloneElement(fallback as ReactElement, {
        ...this.state,
        resetError: this.resetBoundary,
      })
    }

    return this.props.children
  }
}
