import { z } from 'zod';
import { zCoercedBoolean } from '@harvestiq/zod';
import { coverageSchema } from './coverage';

export const getCoverageQuerySchema = z.object({
  enableEntities: zCoercedBoolean().optional(),
});
export type GetCoverageQuery = z.infer<typeof getCoverageQuerySchema>;

export const getCoverageResponseSchema = z.object({
  coverage: coverageSchema,
});
export type GetCoverageResponse = z.infer<typeof getCoverageResponseSchema>;
