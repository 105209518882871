import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  Checkbox,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'
import { getComparator, orderTypes, stableSort } from 'common'

import classNames from 'classnames'
import { TableContext } from './context'
import Loading from '../Loading/Loading'

import classes from './Table.module.scss'
import { SubTable } from './SubTable'
import { TableHead } from './TableHead'
import { Cell } from './TableCell'

const orderTypesArray = Object.values(orderTypes)

const setActions = ['add', 'delete']
const toggleSetKey = (set, key) => {
  const action = setActions[Number(set.has(key))]
  set[action](key)
}

export const Body = ({
  children,
  rows,
  isLoading,
  onSortChange,
  actions: actionsProp = {},
  hasTableHeader = true,
  cellFormatters = {},
  isCompact = false,
  sort: sortProp = ['createdAt', 'desc'],
  className,
}) => {
  const {
    selectedRows,
    setSelectedRows,
    searchColumns,
    filterValue,
    columns,
    expandedRows,
    setExpandedRows,
    isCollapsed,
    tableId,
    isSelectable,
  } = useContext(TableContext)

  const [sort, setSort] = useState(sortProp)
  const [orderBy, order] = sort
  const [orderByProp, orderProp] = sortProp

  // need to apply updates from outside of the component
  useEffect(() => {
    setSort([orderByProp, orderProp])
  }, [orderByProp, orderProp])

  const data = useMemo(() => {
    const filteredData = searchColumns
      ? rows.filter(row =>
          searchColumns.some(column =>
            row[column].toLowerCase().includes(filterValue.toLowerCase()),
          ),
        )
      : rows

    return filteredData
  }, [rows, filterValue, searchColumns])

  const selectKey = useMemo(() => {
    if (data?.length) {
      const [firstRow] = data
      if (firstRow.id) {
        return 'id'
      }
      const [key] = Object.keys(firstRow)
      return key
    }
  }, [data])

  const handleRequestSort = useCallback(
    (_event, column) => {
      const isAsc = orderBy === column && order === orderTypes.asc
      const newOrder = orderTypesArray[Number(isAsc)]
      if (onSortChange) {
        onSortChange({ order: newOrder, orderBy: column })
      } else {
        setSort([column, newOrder])
      }
    },
    [order, orderBy, onSortChange, setSort],
  )

  const handleSelectAllClick = useCallback(
    event => {
      if (event.target.checked) {
        return setSelectedRows(new Set(data.map(n => n[selectKey])))
      }
      return setSelectedRows(new Set())
    },
    [selectKey, data, setSelectedRows],
  )

  const handleSelectChange = useCallback(
    key => {
      if (!isSelectable) {
        return null
      }

      toggleSetKey(selectedRows, key)
      setSelectedRows(new Set(selectedRows))
    },
    [isSelectable, selectedRows, setSelectedRows],
  )

  const handleExpandToggle = useCallback(
    key => {
      toggleSetKey(expandedRows, key)
      setExpandedRows(new Set(expandedRows))
    },
    [expandedRows, setExpandedRows],
  )

  const isSelected = key => selectedRows.has(key)
  const isExpanded = key => expandedRows.has(key)

  const actions = useMemo(
    () => ({
      ...actionsProp,
      onSelectChange: handleSelectChange,
      onExpandToggle: handleExpandToggle,
    }),
    [actionsProp, handleSelectChange, handleExpandToggle],
  )

  const comparator = useMemo(
    () => getComparator(order, orderBy),
    [order, orderBy],
  )

  const colCount = isSelectable ? columns.length + 1 : columns.length

  return (
    <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
      {children}
      <TableContainer component={Paper} className={className}>
        <Table
          aria-labelledby="tableTitle"
          size={isCompact ? 'small' : 'medium'}
          data-cy="enhanced-table"
        >
          {hasTableHeader && (
            <TableHead
              numSelected={selectedRows.size}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              columns={columns}
              isSelectable={isSelectable}
            />
          )}
          <TableBody
            className={classNames('relative', {
              disabled: isLoading,
            })}
          >
            {stableSort(data, comparator).map((row, rowIndex) => {
              const rowKey = row[selectKey]
              const isItemSelected = isSelected(rowKey)
              if (row.subTable) {
                row.isExpanded = isExpanded(rowKey)
              }
              const rowId = `table-${tableId}-row-${rowKey}`
              return [
                <TableRow
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={rowId}
                  id={rowId}
                  selected={isItemSelected}
                  className={classNames(classes.TableRow, {
                    disabled: row.isDisabled,
                    [classes.even]: rowIndex % 2 === 0,
                  })}
                  onClick={evt => {
                    if (row.subTable && evt.target.tagName === 'TD') {
                      handleExpandToggle(rowKey)
                    }
                  }}
                  data-cy={`table-row-${row[selectKey]}`}
                >
                  {isSelectable && (
                    <TableCell
                      padding="checkbox"
                      className={
                        row.isSelectorDisabled ? 'events-none' : 'events-all'
                      }
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': rowId,
                        }}
                        onChange={() => handleSelectChange(rowKey)}
                        disabled={row.isSelectorDisabled}
                      />
                    </TableCell>
                  )}
                  {columns.map(column => {
                    const dataKey = column.field || column.id
                    return (
                      <Cell
                        key={`${rowId}-cell-${dataKey}`}
                        row={row}
                        column={column}
                        cellFormatter={cellFormatters[dataKey]}
                        actions={actions}
                      />
                    )
                  })}
                </TableRow>,
                row.subTable && (
                  <TableRow key={`${rowId}-subTable`}>
                    <TableCell colSpan={colCount} className="p0 border-0">
                      <SubTable
                        {...row.subTable}
                        isCollapsed={!row.isExpanded}
                        parentId={rowId}
                        actions={actions}
                      />
                    </TableCell>
                  </TableRow>
                ),
              ]
            })}
            {isLoading &&
              (data.length ? (
                <Loading className="absolute absolute-center z-4 p0" />
              ) : (
                <TableRow className={classes.loadingRow}>
                  <TableCell colSpan={colCount}>
                    <Loading className="absolute absolute-center z-4 p0" />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Collapse>
  )
}
