import { z } from 'zod';
import {
  GeneratedAlways,
  Selectable,
  Insertable,
  Updateable,
  Generated,
  ColumnType,
} from 'kysely';
import {
  RmaEntityTypeCodes,
  RmaProgramIndicatorCodes,
  RmaTaxIdTypes,
} from '@harvestiq/constants';
import Decimal from 'decimal.js';
import { zDecimalRequired } from '@harvestiq/zod';
import { expectTypeOf } from 'expect-type';

export interface EntitiesHistoryTable {
  id: GeneratedAlways<string>;
  ownerId: string | null;
  primaryEntityId: string | null;
  aipCode: string | null;
  reinsuranceYear: number | null;
  aipPolicyProducerKey: string | null;
  aipPolicyProducerAddressKey: string | null;
  aipPolicyProducerOtherPersonKey: string | null;
  type: RmaEntityTypeCodes;
  ownershipShare: ColumnType<Decimal, number, number>;
  taxId: string | null;
  taxIdType: RmaTaxIdTypes | null;
  email: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  stateAbbreviation: string | null;
  zip: string | null;
  zipExtensionCode: string | null;
  phone: string | null;
  phoneNumberExceptionCode: string | null;
  phoneExtensionNumber: string | null;
  internationalAddress: string | null;
  internationalCountryCode: string | null;
  internationalPhoneCountryCode: string | null;
  programIndicator: RmaProgramIndicatorCodes | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  suffix: string | null;
  title: string | null;
  picCode: string | null;
  locationStateCode: string | null;
  authorizedRepPoa: string | null;
  stateOfIncorporation: string | null;
  businessName: string | null;
  importSourceId: string | null;
  policyNumber: string | null;
  createdAt: GeneratedAlways<Date>;
  createdBy: string | null;
  updatedAt: Generated<Date>;
  updatedBy: string | null;
  deletedAt: Date | null;
}

export type DbEntityHistory = Selectable<EntitiesHistoryTable>;
export const dbEntityHistorySchema = z.object({
  id: z.string().uuid(),
  ownerId: z.string().uuid().nullable(),
  primaryEntityId: z.string().uuid().nullable(),
  aipCode: z.string().nullable(),
  reinsuranceYear: z.number().nullable(),
  aipPolicyProducerKey: z.string().nullable(),
  aipPolicyProducerAddressKey: z.string().nullable(),
  aipPolicyProducerOtherPersonKey: z.string().nullable(),
  type: z.nativeEnum(RmaEntityTypeCodes),
  ownershipShare: zDecimalRequired(),
  taxId: z.string().nullable(),
  taxIdType: z.nativeEnum(RmaTaxIdTypes).nullable(),
  email: z.string().nullable(),
  addressLine1: z.string().nullable(),
  addressLine2: z.string().nullable(),
  city: z.string().nullable(),
  stateAbbreviation: z.string().nullable(),
  zip: z.string().nullable(),
  zipExtensionCode: z.string().nullable(),
  phone: z.string().nullable(),
  phoneNumberExceptionCode: z.string().nullable(),
  phoneExtensionNumber: z.string().nullable(),
  internationalAddress: z.string().nullable(),
  internationalCountryCode: z.string().nullable(),
  internationalPhoneCountryCode: z.string().nullable(),
  programIndicator: z.nativeEnum(RmaProgramIndicatorCodes).nullable(),
  firstName: z.string().nullable(),
  middleName: z.string().nullable(),
  lastName: z.string().nullable(),
  suffix: z.string().nullable(),
  title: z.string().nullable(),
  picCode: z.string().nullable(),
  locationStateCode: z.string().nullable(),
  authorizedRepPoa: z.string().nullable(),
  stateOfIncorporation: z.string().nullable(),
  businessName: z.string().nullable(),
  importSourceId: z.string().nullable(),
  policyNumber: z.string().nullable(),
  createdAt: z.coerce.date(),
  createdBy: z.string().nullable(),
  updatedAt: z.coerce.date(),
  updatedBy: z.string().nullable(),
  deletedAt: z.coerce.date().nullable(),
});
expectTypeOf<DbEntityHistory>().toMatchTypeOf<
  z.infer<typeof dbEntityHistorySchema>
>();

// Remove the sensitive fields from the entity
export const safeDbEntityHistorySchema = dbEntityHistorySchema.omit({
  taxId: true,
});
export type SafeDbEntityHistory = Selectable<
  z.infer<typeof safeDbEntityHistorySchema>
>;

export type DbEntityHistoryInsert = Insertable<EntitiesHistoryTable>;
export const dbEntityHistoryInsertSchema = dbEntityHistorySchema
  .omit({
    id: true,
    createdAt: true,
  })
  .partial()
  // Required fields
  .extend({
    ownershipShare: z.number(),
    type: z.nativeEnum(RmaEntityTypeCodes),
    taxIdType: z.nativeEnum(RmaTaxIdTypes).nullish(),
  });

expectTypeOf<DbEntityHistoryInsert>().toMatchTypeOf<
  z.infer<typeof dbEntityHistoryInsertSchema>
>();

export type DbEntityHistoryUpdate = Updateable<EntitiesHistoryTable>;
export const dbEntityHistoryUpdateSchema = dbEntityHistorySchema
  .omit({
    id: true,
    createdAt: true,
  })
  .extend({
    ownershipShare: z.number(),
  })
  .partial();

expectTypeOf<DbEntityHistoryUpdate>().toMatchTypeOf<
  z.infer<typeof dbEntityHistoryUpdateSchema>
>();
