export enum CommodityId {
  UNKNOWN = 0,
  CORN = 1,
  SOYBEANS = 2,
  KC_WINTER_WHEAT_HRW = 3,
  MN_SPRING_WHEAT_HRSW = 4,
  CHI_SOFT_RED_WINTER_SRW = 5,
  OATS = 6,
  SORGHUM = 7,
  ZUC = 8,
  LEAN_HOGS = 9,
  FEEDER_CATTLE = 10,
  LIVE_CATTLE = 11,
  SOYBEAN_MEAL = 12,
}
