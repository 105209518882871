import { zPhoneNumber } from '@harvestiq/zod';
import { z } from 'zod';

const userProfilePhonesEntrySchema = z.object({
  // TODO: Add phone number type enum
  type: z.string(),
  number: zPhoneNumber(),
  isPrimary: z.boolean(),
});

export const userProfilePhonesSchema = z.array(userProfilePhonesEntrySchema);

export type UserProfilePhones = z.infer<typeof userProfilePhonesSchema>;
