import { expectTypeOf } from 'expect-type';
import {
  ColumnType,
  Generated,
  GeneratedAlways,
  Insertable,
  Selectable,
  Updateable,
} from 'kysely';
import { z } from 'zod';

export type ImportSourcesTable = {
  id: GeneratedAlways<string>;
  filename: ColumnType<string>;
  reinsuranceYear: ColumnType<number>;
  createdAt: GeneratedAlways<Date>;
  updatedAt: Generated<Date>;
};

export type DbImportSource = Selectable<ImportSourcesTable>;
export type DbImportSourceInsert = Insertable<ImportSourcesTable>;
export type DbImportSourceUpdate = Updateable<ImportSourcesTable>;

export const dbImportSourceSchema = z.object({
  id: z.string().uuid(),
  filename: z.string(),
  reinsuranceYear: z.number(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const dbImportSourceInsertSchema = dbImportSourceSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  })
  .partial()
  .extend({
    filename: z.string(),
    reinsuranceYear: z.number(),
  });

export const dbImportSourceUpdateSchema = dbImportSourceSchema
  .omit({
    id: true,
    createdAt: true,
  })
  .partial();

expectTypeOf<DbImportSource>().toMatchTypeOf<
  z.infer<typeof dbImportSourceSchema>
>();
expectTypeOf<DbImportSourceInsert>().toMatchTypeOf<
  z.infer<typeof dbImportSourceInsertSchema>
>();
expectTypeOf<DbImportSourceUpdate>().toMatchTypeOf<
  z.infer<typeof dbImportSourceUpdateSchema>
>();
