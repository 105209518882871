import { GeneratedAlways, Selectable, Insertable, Updateable } from 'kysely';
import {
  EntityOwnerEntityTypes,
  EntityOwnerOwnerTypes,
} from './entity-owner-types';
import { z } from 'zod';
import { expectTypeOf } from 'expect-type';

export interface EntityOwnersTable {
  id: GeneratedAlways<string>;
  ownerId: string;
  ownerType: EntityOwnerOwnerTypes;
  entityId: string;
  entityType: EntityOwnerEntityTypes;
}

export const dbEntityOwnerSchema = z.object({
  id: z.string(),
  ownerId: z.string(),
  ownerType: z.nativeEnum(EntityOwnerOwnerTypes),
  entityId: z.string(),
  entityType: z.nativeEnum(EntityOwnerEntityTypes),
});

export type DbEntityOwner = Selectable<EntityOwnersTable>;
export type DbEntityOwnerInsert = Insertable<EntityOwnersTable>;
export type DbEntityOwnerUpdate = Updateable<EntityOwnersTable>;

expectTypeOf<DbEntityOwner>().branded.toEqualTypeOf<
  z.infer<typeof dbEntityOwnerSchema>
>();
