import onboardingTour from './onboardingTour'

const allTours = { onboardingTour }

export const findTour = tourConditions => {
  const [tourId, { tour }] = Object.entries(allTours).find(
    ([id, { condition }]) => {
      const meetsCondition = !!condition?.(tourConditions)
      if (tourConditions.tourId === id && meetsCondition) {
        return true
      }
      return meetsCondition
    },
  ) || [null, {}]
  return [tourId, tour]
}

export default allTours
