import { createPortal } from 'react-dom'
import { Container } from '@mui/material'
import classNames from 'classnames'

import classes from './Banner.module.scss'
import { ReactNode } from 'react'

type BannerProps = {
  children?: ReactNode
  bgImgSrc?: string
  bgImgColor?: string
}

export const Banner = ({
  children,
  bgImgSrc,
  bgImgColor = 'lightblue',
}: BannerProps) => {
  const bannerElement = (
    <div
      style={{
        backgroundColor: bgImgColor,
        backgroundImage: bgImgSrc ? `url(${bgImgSrc})` : undefined,
      }}
      className={classNames(
        classes.Banner,
        'flex flex-col items-center justify-end',
      )}
    >
      <Container className="mb2">{children}</Container>
    </div>
  )

  const portalElement = document.getElementById('banner-portal')

  if (!portalElement) {
    console.warn('Banner portal element not found')
    return null
  }

  return createPortal(bannerElement, portalElement)
}
