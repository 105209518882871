import React, { useEffect, useState } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

export function Password({
  showPassword: showPasswordProp = false,
  onChangeShowPassword = () => null,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(showPasswordProp)

  const handleClickShowPassword = () => {
    const sp = !showPassword
    setShowPassword(sp)
    onChangeShowPassword(sp)
  }

  const handleMouseDownPassword = evt => {
    evt.preventDefault()
  }

  useEffect(() => {
    setShowPassword(showPasswordProp)
  }, [showPasswordProp])

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  )

  return (
    <TextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{ endAdornment }}
    />
  )
}

export default Password
