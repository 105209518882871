import { useCallback, useState, useEffect, useMemo } from 'react'
import { singular } from 'pluralize'
import { sessionExpiredMessage } from '@harvestiq/constants'
import { api } from '../utils'
import { useToast } from '../contexts'

export const useAutoCompleteValue = ({
  entityId,
  dataKey,
  labelKey,
  onChange,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState(null)
  const { errorToast } = useToast()

  const fetchInitialValue = useCallback(async () => {
    const response = await api.get(`/${dataKey}/${entityId}`)
    if (response.status === 401) {
      errorToast(sessionExpiredMessage)
      setTimeout(() => {
        window.location.reload()
      }, 3000)
      return null
    }
    const { [singular(dataKey)]: record } = response.data
    return { label: record[labelKey], value: record }
  }, [dataKey, labelKey, entityId, errorToast])

  const handleSetValue = useCallback(
    newValue => {
      setValue(newValue)
      onChange(newValue)
    },
    [onChange],
  )

  const shouldFetchInitialValue = useMemo(
    () => entityId && entityId.toLowerCase() !== 'all' && !value && !isLoading,
    [entityId, value, isLoading],
  )

  useEffect(() => {
    if (shouldFetchInitialValue) {
      setIsLoading(true)
      fetchInitialValue(entityId)
        .then(val => {
          handleSetValue(val)
          return val
        })
        .catch(err => {
          console.error(err)
          errorToast(
            'An error ocurred when fetching initial values for autocomplete field.',
          )
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    // Handle the case where the entityId is removed
    if (!entityId) {
      handleSetValue(null)
    }
  }, [fetchInitialValue, shouldFetchInitialValue, handleSetValue, entityId])

  return {
    isLoading,
    value,
    setValue: handleSetValue,
  }
}
