import {
  useState,
  createContext,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import { CssBaseline, GlobalStyles } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import createPalette from '@mui/material/styles/createPalette'
import baseTheme, {
  formatColorPalette,
  setCSSVariables,
} from '../../styles/theme'
import { useAppSettings } from '../AppSettingsProvider'

const ThemeContext = createContext()

export function ThemeManager({ children }) {
  const {
    appSettings: { colorScheme },
  } = useAppSettings()
  const [themeOptions, setThemeOptions] = useState(baseTheme)

  const updateTheme = useCallback(
    newThemeOptions =>
      setThemeOptions(prevOptions => ({
        ...prevOptions,
        ...newThemeOptions,
      })),
    [setThemeOptions],
  )

  const contextValue = useMemo(() => {
    const muiTheme = createTheme({
      ...themeOptions,
      palette: createPalette(formatColorPalette(colorScheme)),
    })

    return { theme: muiTheme, updateTheme }
  }, [themeOptions, updateTheme, colorScheme])

  const { theme } = contextValue

  useEffect(() => {
    setCSSVariables(theme.palette)
  }, [theme])

  return (
    <ThemeContext.Provider value={contextValue}>
      <GlobalStyles
        styles={{
          a: {
            textDecoration: 'none',
            color: theme.palette.primary.main,
          },
        }}
      />
      <CssBaseline />

      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  )
}

export function useThemeManager() {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useThemeManager must be used within a ThemeManager')
  }
  return context
}
