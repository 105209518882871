import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useRedirect, useUser } from '../contexts'
import { api } from '../utils'

const removeSearchParams = (...paramNames) => {
  const urlObject = new URL(window.location.href)
  paramNames.forEach(paramName => urlObject.searchParams.delete(paramName))
  window.history.replaceState({}, document.title, urlObject.toString())
}

const getSearchParams = (...paramNames) => {
  const searchParams = new URLSearchParams(window.location.search)
  return paramNames.reduce((acc, paramName) => {
    acc[paramName] = searchParams.get(paramName)
    return acc
  }, {})
}

export const useVerifyToken = () => {
  const { user, setUser } = useUser()
  const { redirectOrNavigate } = useRedirect()
  const { code, redirect: redirectFromQueryParams } = getSearchParams(
    'code',
    'redirect',
  )

  const verifyToken = useCallback(() => api.post('/confirm', { code }), [code])

  useQuery(
    ['verifyToken'],
    async () => {
      removeSearchParams('code')
      const verifyTokenResponse = await verifyToken()

      if (verifyTokenResponse.status === 200) {
        if (verifyTokenResponse.data?.user) {
          setUser(verifyTokenResponse.data.user)
        }

        if (redirectFromQueryParams) {
          redirectOrNavigate(redirectFromQueryParams, { replace: true })
        }
      }

      return verifyTokenResponse.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!code,
      retry: false,
      staleTime: Infinity,
      refetchOnReconnect: false,
    },
  )

  return {
    code,
    user,
  }
}
