import { forwardRef } from 'react'
import { IMaskInput } from 'react-imask'
import { TextField } from '@mui/material'
import { isPhoneNumber } from 'common'

function SsnMask({ value, onChange, mask, unmask = true, ...rest }, ref) {
  const { name } = rest
  const handleOnAccept = val => {
    if (value !== val) {
      onChange({ target: { name, value: val } })
    }
  }

  return (
    <IMaskInput
      {...rest}
      value={value}
      mask={mask || '000-00-0000'}
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      unmask={unmask}
      onAccept={handleOnAccept}
      overwrite
    />
  )
}

const SsnMaskInput = forwardRef(SsnMask)

export const MaskInput = ({ InputProps = {}, ...props }) => {
  const inputProps = { inputComponent: SsnMaskInput, ...InputProps }
  return (
    <TextField
      InputProps={inputProps}
      placeholder={props?.placeholder || '000-00-0000'}
      {...props}
    />
  )
}

export const PhoneMaskInput = ({
  inputProps = {},
  error,
  helperText,
  ...props
}) => {
  const phoneProps = {
    placeholder: '000-000-0000',
    inputProps: { ...inputProps, mask: '000-000-0000' },
    ...props,
  }
  const isValid =
    props.value === undefined ||
    props.value === '' ||
    (isPhoneNumber(props.value) && !error)
  const localProps = {
    helperText: helperText || (!isValid && 'Invalid phone number'),
    error: !isValid,
  }
  return MaskInput({ ...localProps, ...phoneProps })
}

export const EINMaskInput = ({ inputProps = {}, ...props }) => {
  const einProps = {
    placeholder: '00-0000000',
    inputProps: { ...inputProps, mask: '00-0000000' },
    ...props,
  }
  return MaskInput(einProps)
}

export const ZipMaskInput = ({ inputProps = {}, ...props }) => {
  const zipProps = {
    placeholder: '00000',
    inputProps: { ...inputProps, mask: '00000-0000', unmask: false },
    ...props,
  }
  return MaskInput(zipProps)
}

export const maskType = {
  ssn: MaskInput,
  phone: PhoneMaskInput,
  ein: EINMaskInput,
  zip: ZipMaskInput,
}

export const SSN = MaskInput
