import { useCallback } from 'react'
import {
  FormControlLabel,
  Switch as MuiSwitch,
  FormHelperText,
  FormControl,
} from '@mui/material'
import classNames from 'classnames'

export const Switch = ({
  name,
  label,
  value,
  onChange,
  helperText = '',
  error,
  labelProps = {},
  className,
  fullWidth: _,
  ...switchProps
}) => {
  const handleChange = useCallback(
    e => {
      const {
        target: { checked },
      } = e
      onChange({ target: { name, value: checked } })
    },
    [name, onChange],
  )
  return (
    <FormControl
      error={error}
      className={classNames('justify-center', className)}
    >
      <FormControlLabel
        name={name}
        checked={!!value || false}
        onChange={handleChange}
        control={<MuiSwitch {...switchProps} />}
        label={label}
        {...labelProps}
      />
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default Switch
