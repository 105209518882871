import {
  defaultFilterValue,
  policyTypes,
  policyOwnerTypes,
  paginationDefault,
} from './constants'

export const makeFilterOptionValues = options => {
  if (Array.isArray(options)) {
    return [defaultFilterValue, ...options]
  }
  return {
    [defaultFilterValue]: defaultFilterValue,
    ...options,
  }
}

export const makeOptions = optionsObject =>
  Object.values(optionsObject).map(label => ({ value: label, label }))

export const policyOwnerTypeOptions = makeOptions(policyOwnerTypes)
export const policyTypeOptions = {
  [policyTypes.lrp]: 'Livestock Risk Protection',
  [policyTypes.lgm]: 'Livestock Gross Margin',
}

export const pageSizeOptions = [...Array(3)].map((_val, index) =>
  index ? index * 3 * paginationDefault.pageSize : paginationDefault.pageSize,
)
