import { useCallback } from 'react'
import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  FormHelperText,
  FormControl,
} from '@mui/material'
import classNames from 'classnames'

export const Checkbox = ({
  name,
  label,
  value,
  CheckboxProps = {},
  onChange,
  helperText = '',
  error = null,
  className = '',
  ...FormControlLabelProps
}) => {
  const handleChange = useCallback(
    e => {
      const {
        target: { checked },
      } = e
      onChange({ target: { name, value: checked } })
    },
    [name, onChange],
  )
  return (
    <FormControl
      error={error}
      className={classNames('justify-center', className)}
    >
      <FormControlLabel
        checked={!!value || false}
        onChange={handleChange}
        control={<MuiCheckbox {...CheckboxProps} name={name} />}
        label={CheckboxProps?.required ? `${label} *` : label} // mui dont change style for required checkbox
        {...FormControlLabelProps}
      />
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default Checkbox
