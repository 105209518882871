import React, { Suspense } from 'react'
import { Box, IconButton, Modal } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import classNames from 'classnames'

import { noop } from 'common'
import { ModalTitle } from './ModalTitle'

export const style = {
  bgcolor: 'background.paper',
  boxShadow: 24,
}

export const ModalContainer = ({
  modal,
  modalProps = {},
  closeModal = noop,
  children,
}) => {
  const { className, title, hideCloseButton, ...props } = modalProps
  // Allow for modal to contain a render prop or children
  const modalContent = modal !== undefined ? modal : children

  return (
    <Modal
      open={!!modalContent}
      onClose={() => closeModal(modalProps)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-cy="modal"
      {...props}
    >
      <Box
        sx={style}
        className={classNames('sg-modal absolute p2 flex', className)}
      >
        <Box className="flex flex-1 relative">
          {!hideCloseButton && (
            <IconButton
              aria-label="Close Modal"
              className="button-close"
              onClick={() => closeModal(modalProps)}
              data-cy="close-modal"
            >
              <CloseIcon data-cy="CloseIcon" />
            </IconButton>
          )}
          <div className="flex-1 scroll-y">
            <Suspense fallback={'loading'}>
              <ModalTitle title={title} />
              {modalContent}
            </Suspense>
          </div>
        </Box>
      </Box>
    </Modal>
  )
}
