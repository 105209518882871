// See: RMA https://pubfs-rma.fpac.usda.gov/pub/References/insurance_control_elements/PASS/2024/2024_D00012_IceEntityType_YTD.txt
export enum RmaEntityTypeCodes {
  PublicSchools = 'A',
  BureauOfIndianAffairsAndIndianTribeTreaties = 'B',
  Corporation = 'C',
  Estates = 'D',
  NonProfitOrTaxExemptOrganizations = 'E',
  TransferOfRightToIndemnity = 'F',
  ReceiverOrLiquidator = 'G',
  StateLocalGovernment = 'H',
  Individuals = 'I',
  JointOperationsVenturesAndCoOwnerships = 'J',
  Partnership = 'P',
  TrustRevocable = 'R',
  SpousalMarried = 'S',
  TrustIrrevocable = 'T',
  IndividualOperatingAsABusiness = 'X',
  LimitedLiabilityCompany = 'Y',
}
