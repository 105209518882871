import { stringify } from 'qs'
import { apiRoot } from 'common'

const routePatterns = [
  /.+media\/(.+)\/(url|download|signedUrl)/,
  /.+policies\/(.+)\/(application)/,
  /.+login$/,
]
const getHandlerKey = (url, method) => {
  const matches = routePatterns.reduce((acc, pattern) => {
    const match = url.match(pattern)

    if (match) {
      acc.push(match[1])
    }
    return acc
  }, [])

  url = matches.reduce((acc, match) => acc.replace(match, ''), url)

  const apiKey = url
    .replace(new RegExp(`^(.*?)${apiRoot}/`), '')
    .replace(/^[a-z]/g, m => m.toUpperCase())
    .replace(/(\/[a-z])/g, m => m.slice(1).toUpperCase())
    .replace(/(\/)/g, m => m.slice(1).toUpperCase())

  return `${method.toLowerCase()}${apiKey}`
}

const handleRawErrors = async res => {
  const textBody = await res.clone().text(res.body)
  const { error, status, ...data } = await res.json(res.body).catch(() => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(textBody, 'text/html')
    return {
      status: res.status,
      data: null,
      error: doc.body.innerText.trim(),
    }
  })

  return {
    status: status || res.status,
    data: Object.keys(data).length ? data : null,
    error,
  }
}

const responseHandlers = {
  getMediaDownload: async res => {
    let filename = res.headers.get('x-filename')
    if (!filename) {
      filename = res.headers.get('content-disposition')
      if (filename) {
        filename = filename.split('filename=')[1]
      } else {
        const match = res.url.match(/.+media\/(.*)\/download/)
        filename = match?.[1] || 'download'
      }
    }

    const blob = await res.blob()
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    if (filename) {
      link.download = decodeURIComponent(filename)
    }
    link.click()
  },
  getMediaSignedUrl: res => res.text(res.body),
  getMediaUrl: async res => {
    const url = await res.text(res.body)
    window.open(url)
  },
  getPoliciesApplicationUrl(res) {
    return this.getMediaUrl(res)
  },
  postConfirm: handleRawErrors,
  postLogin: handleRawErrors,
}

const defaultHeaders = Object.freeze({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-client-id': 'portal-webapp',
})

const http = {
  getHeaders: (additionalHeaders = {}) => ({
    ...defaultHeaders,
    ...additionalHeaders,
  }),
}

const defaultOptions = { method: 'GET', credentials: 'include' }

export const fetcher = (
  requestUrl,
  { handler, ...optionsParam } = defaultOptions,
) => {
  const options = { ...defaultOptions, ...optionsParam }
  return fetch(requestUrl, options).then(async res => {
    const { body, url, status } = res
    if (status === 401) {
      // users/me 401s when not logged in
      // which triggers login prompt on page load
      if (!url.includes('users/me')) {
        window.location.reload()
      }
      return {
        url,
        status,
        data: null,
        errors: undefined,
        error: undefined,
      }
    }
    const handlerKey = getHandlerKey(url, options.method)

    if (responseHandlers[handlerKey]) {
      return responseHandlers[handlerKey](res)
    }

    if (handler) {
      return handler(res)
    }

    const { errors, error, ...restData } = await res
      .json(body)
      .catch(() => ({}))

    return {
      url,
      status,
      data: Object.keys(restData).length ? restData : null,
      errors: errors || (error && [error]),
      error: error || (errors && errors[0]),
    }
  })
}

export const api = {
  urlString: path => {
    const hostname = window.location.origin
    return `${hostname}${apiRoot}${path}`
  },
  assign: (path, params) => {
    let urlString = api.urlString(path)
    if (params) {
      urlString += `?${stringify(params)}`
    }
    window.location.assign(urlString)
  },
  get: (path, params, headers = {}) => {
    let urlString = api.urlString(path)
    if (params && !!Object.keys(params).length) {
      urlString += `?${stringify(params)}`
    }
    return fetcher(urlString, {
      method: 'GET',
      headers: http.getHeaders(headers),
    })
  },
  post: (path, params, headers = {}, handler = null) => {
    const urlString = api.urlString(path)
    return fetcher(urlString, {
      method: 'POST',
      headers: http.getHeaders(headers),
      body: JSON.stringify(params),
      handler,
    })
  },
  upload: (path, { files, ...data }) => {
    const urlString = api.urlString(path)
    const formData = new FormData()
    formData.append('files', files[0])

    Object.entries(data).forEach(([key, val]) => {
      formData.append(key, val)
    })

    return fetcher(urlString, {
      method: 'POST',
      body: formData,
    })
  },
  download: (path, params, headers = {}) => {
    let urlString = api.urlString(path)
    if (params) {
      urlString += `?${stringify(params)}`
    }
    return fetcher(urlString, {
      method: 'GET',
      headers: http.getHeaders(headers),
      handler: responseHandlers.getMediaDownload,
    })
  },
  patch: (path, params, headers = {}) => {
    const urlString = api.urlString(path)
    return fetcher(urlString, {
      method: 'PATCH',
      headers: http.getHeaders(headers),
      body: JSON.stringify(params),
    })
  },
  put: (path, params, headers = {}) => {
    const urlString = api.urlString(path)
    return fetcher(urlString, {
      method: 'PUT',
      headers: http.getHeaders(headers),
      body: JSON.stringify(params),
    })
  },
  delete: (path, params, headers = {}) => {
    const urlString = api.urlString(path)
    return fetcher(urlString, {
      method: 'DELETE',
      headers: http.getHeaders(headers),
      body: JSON.stringify(params),
    })
  },
}
