import { forwardRef, useMemo } from 'react'
import {
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio as MuiRadio,
  FormHelperText,
} from '@mui/material'
import { parseOptions, policyOwnerTypeOptions, toTitleCase } from 'common'
import { ProgramIndicatorDescriptions } from '@harvestiq/constants'
import classes from './Radio.module.scss'

const radioOptions = {
  maritalStatus: ['Not Married', 'Married'],
  insuredType: ['Farmer', 'Landlord'],
  nameType: ['Personal', 'Business'],
  applyType: policyOwnerTypeOptions,
  applicationOwnerType: ['Farmer', 'Landlord'],
  applicationTypeOption: Object.values(ProgramIndicatorDescriptions),
}

const getOptions = name => radioOptions[name] || []

function RadioInput(
  {
    name,
    label,
    value,
    options: optionsProp = getOptions(name),
    row = false,
    tooltipIcon = null,
    labelProps = {},
    helperText = '',
    InputProps: inputProps = {},
    ...radioProps
  },
  ref,
) {
  const options = useMemo(() => parseOptions(optionsProp), [optionsProp])
  return (
    <FormControl
      component="fieldset"
      className="flex justify-start items-center"
      {...radioProps}
      ref={ref}
    >
      {label && (
        <FormLabel
          {...labelProps}
          component="legend"
          classes={{ focused: classes.focused }}
        >
          {label}
          {tooltipIcon}
        </FormLabel>
      )}
      <RadioGroup
        label={label}
        name={name}
        value={value}
        row={row}
        className="justify-center"
      >
        {options.map((option, index) => {
          const { value: v, label: l } =
            typeof option === 'object'
              ? option
              : { value: option, label: toTitleCase(option) }
          return (
            <FormControlLabel
              key={`radio-${name}-${index}`}
              value={v}
              control={<MuiRadio inputProps={inputProps} />}
              label={l}
            />
          )
        })}
      </RadioGroup>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export const Radio = forwardRef(RadioInput)

export default Radio
