import { z } from 'zod';
import { InsureIQCommodityNames } from './InsuranceTypes';
import { RmaCommodityCodes } from './rma/CommodityCodes';

import dayjs from 'dayjs';
import { CommodityId } from './CommodityId';
import { RmaTypeCodes } from './rma/TypeCodes';
import { InsureIQLgmCommodityTypeNames } from './InsureIQLgmCommodityTypeNames';

export enum RmaLgmCommodityCodes {
  CATTLE = RmaCommodityCodes.CATTLE,
  SWINE = RmaCommodityCodes.SWINE,
  DAIRY_CATTLE = RmaCommodityCodes.DAIRY_CATTLE,
}

export enum InsureIQLgmCommodityNames {
  CATTLE = InsureIQCommodityNames.CATTLE,
  SWINE = InsureIQCommodityNames.SWINE,
}

export const LgmCommodityNamesByRmaCommodityCode: Record<
  string, // allow lookup by any rmaCommodityCode
  InsureIQLgmCommodityNames
> = {
  [RmaCommodityCodes.CATTLE]: InsureIQLgmCommodityNames.CATTLE,
  [RmaCommodityCodes.SWINE]: InsureIQLgmCommodityNames.SWINE,
};

// allow lookup by LGM commodity name like 'Cattle'
export const InsureIQLgmCommodityCodeByName = {
  [InsureIQLgmCommodityNames.CATTLE]: RmaCommodityCodes.CATTLE,
  [InsureIQLgmCommodityNames.SWINE]: RmaCommodityCodes.SWINE,
};

// Taken from https://www.rma.usda.gov/ftp/Publications/M13_Handbook/2022/approved/EXH119.PDF
// and https://public.rma.usda.gov/livestockreports/main.aspx
/**
 * @deprecated: Use Rma/RmaCommodityTypeCodes enum instead
 */
export enum RmaLgmCommodityTypeCodes {
  FARROW_TO_FINISH = 804,
  FINISHING = 805,
  SEW_PIG_FINISHING = 806,
  CALF_FINISHING = 807,
  YEARLING_FINISHING = 808,
}

// name to code mapping
export const InsureIQLgmCommodityTypeCodeByName = {
  [InsureIQLgmCommodityTypeNames.FARROW_TO_FINISH]:
    RmaLgmCommodityTypeCodes.FARROW_TO_FINISH,
  [InsureIQLgmCommodityTypeNames.FINISHING_OPERATION]:
    RmaLgmCommodityTypeCodes.FINISHING,
  [InsureIQLgmCommodityTypeNames.SEW_OPERATION]:
    RmaLgmCommodityTypeCodes.SEW_PIG_FINISHING,
  [InsureIQLgmCommodityTypeNames.CALF_FINISHING]:
    RmaLgmCommodityTypeCodes.CALF_FINISHING,
  [InsureIQLgmCommodityTypeNames.YEARLING_FINISHING]:
    RmaLgmCommodityTypeCodes.YEARLING_FINISHING,
};

export const RmaCommodityTypeCodeFromIIQLgmCommodityTypeName = {
  [InsureIQLgmCommodityTypeNames.FARROW_TO_FINISH]:
    RmaTypeCodes.FARROW_TO_FINISH,
  [InsureIQLgmCommodityTypeNames.FINISHING_OPERATION]: RmaTypeCodes.FINISHING,
  [InsureIQLgmCommodityTypeNames.SEW_OPERATION]: RmaTypeCodes.SEW_PIG_FINISHING,
  [InsureIQLgmCommodityTypeNames.CALF_FINISHING]: RmaTypeCodes.CALF_FINISHING,
  [InsureIQLgmCommodityTypeNames.YEARLING_FINISHING]:
    RmaTypeCodes.YEARLING_FINISHING,
};

export const RmaLgmCommodityTypeInfoSchema = z.object({
  typeCode: z.nativeEnum(RmaLgmCommodityTypeCodes),
  rmaName: z.string(),
  rmaCommodityCode: z.nativeEnum(RmaLgmCommodityCodes),
  sgName: z.nativeEnum(InsureIQLgmCommodityTypeNames),
  sgCommodityName: z.nativeEnum(InsureIQLgmCommodityNames),
  commodityId: z.nativeEnum(CommodityId),
});
export type RmaLgmCommodityTypeInfo = z.infer<
  typeof RmaLgmCommodityTypeInfoSchema
>;

export const RmaLgmCommodityTypes: Record<
  RmaLgmCommodityTypeCodes,
  RmaLgmCommodityTypeInfo
> = {
  [RmaLgmCommodityTypeCodes.FARROW_TO_FINISH]: {
    typeCode: RmaLgmCommodityTypeCodes.FARROW_TO_FINISH,
    rmaName: 'Farrow To Finish',
    rmaCommodityCode: RmaLgmCommodityCodes.SWINE,
    sgName: InsureIQLgmCommodityTypeNames.FARROW_TO_FINISH,
    sgCommodityName: InsureIQLgmCommodityNames.SWINE,
    commodityId: CommodityId.LEAN_HOGS,
  },
  [RmaLgmCommodityTypeCodes.FINISHING]: {
    typeCode: RmaLgmCommodityTypeCodes.FINISHING,
    rmaName: 'Finishing',
    rmaCommodityCode: RmaLgmCommodityCodes.SWINE,
    sgName: InsureIQLgmCommodityTypeNames.FINISHING_OPERATION,
    sgCommodityName: InsureIQLgmCommodityNames.SWINE,
    commodityId: CommodityId.LEAN_HOGS,
  },
  [RmaLgmCommodityTypeCodes.SEW_PIG_FINISHING]: {
    typeCode: RmaLgmCommodityTypeCodes.SEW_PIG_FINISHING,
    rmaName: 'SEW Pig Finishing',
    rmaCommodityCode: RmaLgmCommodityCodes.SWINE,
    sgName: InsureIQLgmCommodityTypeNames.SEW_OPERATION,
    sgCommodityName: InsureIQLgmCommodityNames.SWINE,
    commodityId: CommodityId.LEAN_HOGS,
  },
  [RmaLgmCommodityTypeCodes.CALF_FINISHING]: {
    typeCode: RmaLgmCommodityTypeCodes.CALF_FINISHING,
    rmaName: 'Calf Finishing',
    rmaCommodityCode: RmaLgmCommodityCodes.CATTLE,
    sgName: InsureIQLgmCommodityTypeNames.CALF_FINISHING,
    sgCommodityName: InsureIQLgmCommodityNames.CATTLE,
    commodityId: CommodityId.LIVE_CATTLE,
  },
  [RmaLgmCommodityTypeCodes.YEARLING_FINISHING]: {
    typeCode: RmaLgmCommodityTypeCodes.YEARLING_FINISHING,
    rmaName: 'Yearling Finishing',
    rmaCommodityCode: RmaLgmCommodityCodes.CATTLE,
    sgName: InsureIQLgmCommodityTypeNames.YEARLING_FINISHING,
    sgCommodityName: InsureIQLgmCommodityNames.CATTLE,
    commodityId: CommodityId.LIVE_CATTLE,
  },
};

// currently there is not a clean way to get the array of enum values typed as the enum
// so we just declare it the right type
// RmaLgmCommodityTypes is typed as Record<RmaLgmCommodityTypeCodes, RmaLgmCommodityTypeInfo>
// so I claim this isn't dangerous
export const RmaLgmCommodityTypeCodesList = Object.keys(
  RmaLgmCommodityTypes
) as unknown as RmaLgmCommodityTypeCodes[];

export const StockguardLgmCattleCommodityTypeNames = Object.values(
  RmaLgmCommodityTypes
)
  .filter((type) => type.sgCommodityName === InsureIQLgmCommodityNames.CATTLE)
  .map((type) => type.sgName);

export const StockguardLgmSwineCommodityTypeNames = Object.values(
  RmaLgmCommodityTypes
)
  .filter((type) => type.sgCommodityName === InsureIQLgmCommodityNames.SWINE)
  .map((type) => type.sgName);

export const RmaLgmCommodityTypeMap = {
  [InsureIQLgmCommodityNames.CATTLE]: StockguardLgmCattleCommodityTypeNames,
  [InsureIQLgmCommodityNames.SWINE]: StockguardLgmSwineCommodityTypeNames,
};

export enum StockguardLgmQuoteCategories {
  PRODUCER_PREMIUM = 'Producer Premium',
  SUBSIDY = 'Subsidy',
  BASE_PREMIUM = 'Base Premium',
}

export enum StockguardLgmPricesTitles {
  LEAN_HOG = 'Lean Hog',
  SOYBEAN_MEAL = 'Soybean Meal',
  CORN = 'Corn',
  EXPECTED_GROSS_MARGIN = 'Expected Gross Margin',
}

export const LgmDeductibles = {
  [InsureIQLgmCommodityNames.CATTLE]: [
    ...Array.from({ length: 16 }, (_, i) => 0 + i * 10),
  ],
  [InsureIQLgmCommodityNames.SWINE]: [
    ...Array.from({ length: 11 }, (_, i) => 0 + i * 2),
  ],
};

export const LgmTargetMarketingLength = {
  [InsureIQLgmCommodityNames.CATTLE]: 10,
  [InsureIQLgmCommodityNames.SWINE]: 5,
};

export const getLgmTypeName = (commodityTypeCode: RmaLgmCommodityTypeCodes) => {
  return RmaLgmCommodityTypes[
    commodityTypeCode as keyof typeof RmaLgmCommodityTypes
  ]?.sgCommodityName;
};

export const getLgmMarketingMonthOptionsByTypeCode = (
  commodityTypeCode: RmaLgmCommodityTypeCodes,
  startDate: Date
) => {
  const commodityTypeName = getLgmTypeName(commodityTypeCode);
  const monthLength = LgmTargetMarketingLength[commodityTypeName];
  const month = startDate.getMonth();
  const year = startDate.getFullYear();
  const newStartDate = dayjs(new Date(year, month + 2, 1));
  const options = [
    ...Array.from({ length: monthLength }, (_, i) =>
      newStartDate.add(i, 'month').toDate()
    ),
  ];
  return options;
};

export const getLgmDeductiblesByTypeCode = (
  commodityTypeCode: RmaLgmCommodityTypeCodes
) => {
  const commodityTypeName = getLgmTypeName(commodityTypeCode);
  return LgmDeductibles[commodityTypeName as keyof typeof LgmDeductibles];
};

type LgmLivestockCommodityId = CommodityId.LIVE_CATTLE | CommodityId.LEAN_HOGS;
export const LgmCommodityNamesByCommodityId: Record<
  LgmLivestockCommodityId,
  InsureIQLgmCommodityNames
> = {
  [CommodityId.LIVE_CATTLE]: InsureIQLgmCommodityNames.CATTLE,
  [CommodityId.LEAN_HOGS]: InsureIQLgmCommodityNames.SWINE,
};

export const getLgmCommodityTypeInfo = (commodityTypeCode: string) => {
  const commodityTypeCodeInt = parseInt(commodityTypeCode, 10);
  if (Object.values(RmaLgmCommodityTypeCodes).includes(commodityTypeCodeInt)) {
    return RmaLgmCommodityTypes[
      commodityTypeCodeInt as RmaLgmCommodityTypeCodes
    ];
  }
  return null;
};

export const commodityTypeHasTargetWeights = (
  commodityTypeCode: RmaLgmCommodityTypeCodes
): boolean => {
  return (
    commodityTypeCode === RmaLgmCommodityTypeCodes.CALF_FINISHING ||
    commodityTypeCode === RmaLgmCommodityTypeCodes.YEARLING_FINISHING
  );
};
