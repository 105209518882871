import { z } from 'zod';
import { dbEntityHistoryUpdateSchema } from './db-entity-history';
import { entityHistorySchema } from './entity-history';

// @todo revisit as manually updated entities should not be entity_history record

export type UpdateEntityParams = {
  entityId: string;
  body: UpdateEntityRequest;
};

export const updateEntityRequestSchema = z.object({
  entity: dbEntityHistoryUpdateSchema,
});

export type UpdateEntityRequest = z.infer<typeof updateEntityRequestSchema>;

export const updateEntityResponseSchema = entityHistorySchema;

export type UpdateEntityResponse = z.infer<typeof updateEntityResponseSchema>;
