import { useCallback, useRef, useState } from 'react'
import { Button } from '@mui/material'
import { noop } from 'common'
import { uploadFile } from '../../http/media'
import Avatar from '../../components/Avatar'
import { useToast, useUser } from '../../contexts'
import { LoadingIndicator } from '../LoadingIndicator'

export function AvatarInput({ name, value: valueProp, onChange = noop }) {
  const { user } = useUser()
  const [value, setValue] = useState(valueProp)
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const inputRef = useRef()
  const { warningToast } = useToast()

  const setFileValue = useCallback(file => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener('load', () => setValue(reader.result), false)
  }, [])

  const handleChange = useCallback(
    async evt => {
      const { target } = evt
      const { files } = target
      const [file] = files
      setFileValue(file)
      if (files) {
        setIsUploadingFile(true)
        const { data, errors } = await uploadFile({ files })

        setIsUploadingFile(false)

        if (errors?.length) {
          console.error('Error uploading file:', errors[0].message)
          warningToast(errors[0].message)
          setValue(valueProp)
          return
        }

        const {
          files: [avatar],
        } = data
        onChange?.({
          ...evt,
          target: { name, value: avatar.id },
        })
      }
    },
    [
      name,
      valueProp,
      setIsUploadingFile,
      onChange,
      setFileValue,
      setValue,
      warningToast,
    ],
  )

  return (
    <div className="flex flex-1 justify-start items-center gap-2">
      <input
        name={name}
        accept=".jpg, .png"
        style={{ display: 'none' }}
        type="file"
        onChange={handleChange}
        ref={inputRef}
      />
      <Avatar
        className="avatar-square"
        fullName={user?.fullName}
        avatarUrl={value}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={() => inputRef.current.click()}
        endIcon={isUploadingFile ? <LoadingIndicator size={24} /> : null}
      >
        {isUploadingFile ? 'Uploading Photo' : 'Add Photo'}
      </Button>
    </div>
  )
}

export default AvatarInput
