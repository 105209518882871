import { useCallback, useRef, useState } from 'react'
import { Button, Tooltip } from '@mui/material'
import { CloudUpload as UploadIcon } from '@mui/icons-material'
import { noop } from 'common'
import { uploadFile } from '../../http/media'
import { LoadingIndicator } from '../LoadingIndicator'
import { useToast } from '../../contexts'

export function UploadInput({ name, onChange = noop }) {
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const inputRef = useRef()
  const { warningToast } = useToast()

  const handleChange = useCallback(
    async evt => {
      const { files } = evt.target

      if (files) {
        setIsUploadingFile(true)
        const { data, errors } = await uploadFile({ files })

        setIsUploadingFile(false)

        if (errors?.length) {
          console.error('Error uploading file:', errors[0].message)
          warningToast(errors[0].message)
          return
        }

        if (data?.files?.length) {
          const [uploadedFile] = data.files

          onChange?.({
            ...evt,
            target: { name, value: uploadedFile.filename },
          })
        }
      }
    },
    [setIsUploadingFile, name, onChange],
  )

  return (
    <>
      <div className="flex flex-1 flex-row gap-1">
        <input
          name={name}
          accept=".xlsx"
          style={{ display: 'none' }}
          type="file"
          onChange={handleChange}
          ref={inputRef}
        />
        <Button
          disabled={isUploadingFile}
          variant="outlined"
          color="primary"
          onClick={() => inputRef.current.click()}
          endIcon={isUploadingFile ? <LoadingIndicator size={24} /> : null}
        >
          <Tooltip title="Upload File" arrow>
            <UploadIcon data-cy="UploadIcon" />
          </Tooltip>
        </Button>
      </div>
    </>
  )
}

export default UploadInput
