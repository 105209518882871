import { GeneratedAlways, Generated, Selectable } from 'kysely';
import { z } from 'zod';
import { expectTypeOf } from 'expect-type';

export interface UserCompaniesTable {
  id: GeneratedAlways<string>;
  userId: string;
  companyId: string;
  createdAt: Generated<Date>;
  updatedAt: Generated<Date>;
}

export const dbUserCompanySchema = z.object({
  id: z.string(),
  userId: z.string(),
  companyId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type DbUserCompanies = Selectable<UserCompaniesTable>;

expectTypeOf<DbUserCompanies>().toEqualTypeOf<
  z.infer<typeof dbUserCompanySchema>
>();
