import { TextField } from '@mui/material'
import Loading from '../Loading'

export const RenderInput = ({ isLoading, InputProps, ...props }) => (
  <TextField
    {...props}
    variant="outlined"
    InputProps={{
      ...InputProps,
      endAdornment: (
        <>
          {isLoading ? <Loading color="inherit" size={20} /> : null}
          {InputProps.endAdornment}
        </>
      ),
    }}
  />
)
