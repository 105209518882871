import { z } from 'zod';
import { legacySequelizeUserSchema } from './shared';

export const updateUserRequestSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
});
export type UpdateUserRequest = z.infer<typeof updateUserRequestSchema>;

export const updateUserResponseSchema = z.object({
  user: legacySequelizeUserSchema,
});
export type UpdateUserResponse = z.infer<typeof updateUserResponseSchema>;
