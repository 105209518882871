import { z } from 'zod';
import { dbAipSettingsSchema } from './db-aip-settings';

const baseSchema = dbAipSettingsSchema.omit({
  createdAt: true,
  updatedAt: true,
  sftpPasswordEncrypted: true,
  sftpUsernameEncrypted: true,
});

export const aipSettingsSchema = baseSchema.partial().extend({
  sftpUsername: z.string().nullable().optional(),
  sftpPassword: z.string().nullable().optional(),
});

export type AipSettings = z.infer<typeof aipSettingsSchema>;

export const aipSettingsCreateSchema = baseSchema
  .omit({
    id: true,
    isDefault: true,
  })
  .extend({
    sftpUsername: z.string().nullable().optional(),
    sftpPassword: z.string().nullable().optional(),
  });

export type AipSettingsCreate = z.infer<typeof aipSettingsCreateSchema>;

export const aipSettingsUpdateSchema = baseSchema.partial().extend({
  sftpUsername: z.string().nullable().optional(),
  sftpPassword: z.string().nullable().optional(),
});

export type AipSettingsUpdate = z.infer<typeof aipSettingsUpdateSchema>;
