import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useToast } from '../contexts'

export const useQueryParamNotification = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { toastHandlers, toastTypes } = useToast()
  const { hasNotification, notificationsObj } = useMemo(() => {
    let tempHasNotification = false
    const tempNotificationsObj = toastTypes.reduce((acc, notificationType) => {
      const notification = searchParams.get(notificationType)
      if (notification) {
        tempHasNotification = true
        acc[notificationType] = notification
        searchParams.delete(notificationType)
      }

      return acc
    }, {})
    return {
      hasNotification: tempHasNotification,
      notificationsObj: tempNotificationsObj,
    }
  }, [toastTypes, searchParams])

  useEffect(() => {
    if (hasNotification) {
      Object.entries(notificationsObj).forEach(
        ([notificationType, notification]) =>
          toastHandlers[notificationType](notification),
      )

      setSearchParams({})
    }
  }, [hasNotification, notificationsObj, setSearchParams, toastHandlers])

  return { hasNotification, notificationsObj }
}
