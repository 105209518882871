import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
} from '@mui/material'
import { prettyColor } from '../../styles/theme'

const defaultAvatarProp = '/images/avatar.png'

const getInitials = (name: string): string => {
  const nameArray = name.split(' ')
  const firstName = nameArray.shift()
  const lastName = nameArray.pop()

  const initials = [firstName, lastName].map(n => n?.charAt(0)).join('')

  return initials.toUpperCase()
}

type AvatarPropsInput = {
  fullName: string
  avatarUrl?: string
}

const avatarProps = ({
  fullName,
  avatarUrl = defaultAvatarProp,
}: AvatarPropsInput): MuiAvatarProps => {
  const initials = getInitials(fullName)
  const props: MuiAvatarProps = {
    sx: {
      bgcolor: avatarUrl ? 'transparent' : prettyColor(fullName),
      borderRadius: '50%',
    },
    alt: fullName,
    variant: 'rounded',
    src: avatarUrl,
    children: initials,
  }

  return props
}

type AvatarProps = Omit<MuiAvatarProps, 'alt' | 'src' | 'children'> & {
  fullName: string
  avatarUrl?: string
}

export default function Avatar({
  fullName,
  avatarUrl,
  ...props
}: AvatarProps): React.ReactElement | null {
  if (!fullName) return null
  return <MuiAvatar {...props} {...avatarProps({ fullName, avatarUrl })} />
}
