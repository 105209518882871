import {
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox as MuiCheckbox,
  RadioGroup,
  FormHelperText,
} from '@mui/material'
import { toTitleCase } from 'common'
import classes from './Radio.module.scss'

const checkboxGroupOptions = {
  maritalStatus: ['Not Married', 'Married'],
  insuredType: ['Farmer', 'Landlord'],
}

const getOptions = name => checkboxGroupOptions[name] || []

export const CheckboxGroup = ({
  name,
  label,
  value,
  row = false,
  labelProps = {},
  options = getOptions(name),
  onChange,
  helperText = '',
  InputProps: inputProps = {},
  ...radioProps
}) => {
  const handleChange = e => {
    const {
      target: { checked, value: val },
    } = e
    onChange({ target: { name, value: { ...value, [val]: checked } } })
  }

  return (
    <FormControl component="fieldset" {...radioProps} onChange={handleChange}>
      {label && (
        <FormLabel
          {...labelProps}
          component="legend"
          classes={{ focused: classes.focused }}
        >
          {label}
        </FormLabel>
      )}
      <RadioGroup label={label} name={name} value={value} row={row}>
        {options.map((option, index) => {
          const { value: v, label: l } =
            typeof option === 'object'
              ? option
              : { value: option, label: toTitleCase(option) }
          const isChecked = !!value[v]
          return (
            <FormControlLabel
              checked={isChecked}
              key={`checkbox-${name}-${index}`}
              value={v}
              control={<MuiCheckbox inputProps={inputProps} name={name} />}
              label={l}
            />
          )
        })}
      </RadioGroup>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default CheckboxGroup
