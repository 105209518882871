import { expectTypeOf } from 'expect-type';
import { GeneratedAlways, Generated, Selectable } from 'kysely';
import { z } from 'zod';
export type Json = {
  [K in string]?: boolean | number | string | null;
};

export interface EntityEventsTable {
  id: GeneratedAlways<string>;
  entityId: string;
  entityType: string;
  name: string | null;
  value: Json | null;
  createdAt: Generated<Date>;
  updatedAt: Generated<Date>;
}

export const dbEntityEventSchema = z.object({
  id: z.string().uuid(),
  entityId: z.string(),
  entityType: z.string(),
  name: z.string().nullable(),
  value: z.any().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type DbEntityEvents = Selectable<EntityEventsTable>;

expectTypeOf<DbEntityEvents>().toMatchTypeOf<
  z.infer<typeof dbEntityEventSchema>
>();
