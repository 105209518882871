import {
  ColumnType,
  GeneratedAlways,
  Insertable,
  Selectable,
  Updateable,
} from 'kysely';
import { Decimal } from 'decimal.js';
import { expectTypeOf } from 'expect-type';
import { z } from 'zod';
import { zCalendarDate, zDecimalNullable } from '@harvestiq/zod';
import {
  RmaClassCodes,
  RmaCommodityCodes,
  RmaCommodityTypeCodes,
  RmaInsurancePlanCodes,
  RmaSubclassCodes,
  RmaTypeCodes,
} from '@harvestiq/constants';
import {
  DbCalendarDateNotNull,
  DbCalendarDateNullable,
  MakeUndefinedOptional,
} from '@harvestiq/utils';

type DrawAmountNullable = ColumnType<
  Decimal | null,
  number | null,
  number | null
>;

/**
 * Types taken from RMA ADM Layout
 * See: https://pubfs-rma.fpac.usda.gov/pub/References/actuarial_data_master/2025/ADMLayout.zip
 */
export type DbLgmDrawDailyTable = {
  id: GeneratedAlways<string>;
  createdAt: ColumnType<Date, never, never>;
  updatedAt: ColumnType<Date, never, Date>;
  recordTypeCode: string;
  recordCategoryCode: string;
  reinsuranceYear: number;
  commodityYear: number;
  commodityCode: RmaCommodityCodes;
  insurancePlanCode: RmaInsurancePlanCodes;
  stateCode: string;
  countyCode: string;
  typeCode: RmaTypeCodes;
  practiceCode: string;
  marginDrawNumber: number;
  commodityTypeCode: RmaCommodityTypeCodes;
  classCode: RmaClassCodes;
  subClassCode: RmaSubclassCodes;
  intendedUseCode: string;
  irrigationPracticeCode: string;
  croppingPracticeCode: string;
  organicPracticeCode: string;
  intervalCode: string;
  month2MarginDrawAmount: ColumnType<
    Decimal | null,
    number | null,
    number | null
  >;
  month3MarginDrawAmount: DrawAmountNullable;
  month4MarginDrawAmount: DrawAmountNullable;
  month5MarginDrawAmount: DrawAmountNullable;
  month6MarginDrawAmount: DrawAmountNullable;
  month7MarginDrawAmount: DrawAmountNullable;
  month8MarginDrawAmount: DrawAmountNullable;
  month9MarginDrawAmount: DrawAmountNullable;
  month10MarginDrawAmount: DrawAmountNullable;
  month11MarginDrawAmount: DrawAmountNullable;
  cornMonth2MarginDrawAmount: DrawAmountNullable;
  cornMonth3MarginDrawAmount: DrawAmountNullable;
  cornMonth4MarginDrawAmount: DrawAmountNullable;
  cornMonth5MarginDrawAmount: DrawAmountNullable;
  cornMonth6MarginDrawAmount: DrawAmountNullable;
  cornMonth7MarginDrawAmount: DrawAmountNullable;
  cornMonth8MarginDrawAmount: DrawAmountNullable;
  cornMonth9MarginDrawAmount: DrawAmountNullable;
  cornMonth10MarginDrawAmount: DrawAmountNullable;
  cornMonth11MarginDrawAmount: DrawAmountNullable;
  dairyMonth2MarginDrawAmount: DrawAmountNullable;
  dairyMonth3MarginDrawAmount: DrawAmountNullable;
  dairyMonth4MarginDrawAmount: DrawAmountNullable;
  dairyMonth5MarginDrawAmount: DrawAmountNullable;
  dairyMonth6MarginDrawAmount: DrawAmountNullable;
  dairyMonth7MarginDrawAmount: DrawAmountNullable;
  dairyMonth8MarginDrawAmount: DrawAmountNullable;
  dairyMonth9MarginDrawAmount: DrawAmountNullable;
  dairyMonth10MarginDrawAmount: DrawAmountNullable;
  dairyMonth11MarginDrawAmount: DrawAmountNullable;
  soymMonth2MarginDrawAmount: DrawAmountNullable;
  soymMonth3MarginDrawAmount: DrawAmountNullable;
  soymMonth4MarginDrawAmount: DrawAmountNullable;
  soymMonth5MarginDrawAmount: DrawAmountNullable;
  soymMonth6MarginDrawAmount: DrawAmountNullable;
  soymMonth7MarginDrawAmount: DrawAmountNullable;
  soymMonth8MarginDrawAmount: DrawAmountNullable;
  soymMonth9MarginDrawAmount: DrawAmountNullable;
  soymMonth10MarginDrawAmount: DrawAmountNullable;
  soymMonth11MarginDrawAmount: DrawAmountNullable;
  salesEffectiveDate: DbCalendarDateNotNull;
  liveCattleMonth2MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth3MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth4MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth5MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth6MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth7MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth8MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth9MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth10MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth11MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth2MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth3MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth4MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth5MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth6MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth7MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth8MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth9MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth10MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth11MarginDrawAmount: DrawAmountNullable;
  releasedDate: DbCalendarDateNotNull;
  lastReleasedDate: DbCalendarDateNullable;
  deletedDate: DbCalendarDateNullable;
  filingDate: DbCalendarDateNotNull;
};

export type DbLgmDrawDaily = Selectable<DbLgmDrawDailyTable>;
export type DbLgmDrawDailyInsert = MakeUndefinedOptional<
  Insertable<DbLgmDrawDailyTable>
>;
export type DbLgmDrawDailyUpdate = MakeUndefinedOptional<
  Updateable<DbLgmDrawDailyTable>
>;

export const dbLgmDrawDailySchema = z.object({
  id: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  recordTypeCode: z.string(),
  recordCategoryCode: z.string(),
  reinsuranceYear: z.number(),
  commodityYear: z.number(),
  commodityCode: z.nativeEnum(RmaCommodityCodes),
  insurancePlanCode: z.nativeEnum(RmaInsurancePlanCodes),
  stateCode: z.string(),
  countyCode: z.string(),
  typeCode: z.nativeEnum(RmaTypeCodes),
  practiceCode: z.string(),
  marginDrawNumber: z.number(),
  commodityTypeCode: z.nativeEnum(RmaCommodityTypeCodes),
  classCode: z.nativeEnum(RmaClassCodes),
  subClassCode: z.nativeEnum(RmaSubclassCodes),
  intendedUseCode: z.string(),
  irrigationPracticeCode: z.string(),
  croppingPracticeCode: z.string(),
  organicPracticeCode: z.string(),
  intervalCode: z.string(),
  month2MarginDrawAmount: zDecimalNullable(),
  month3MarginDrawAmount: zDecimalNullable(),
  month4MarginDrawAmount: zDecimalNullable(),
  month5MarginDrawAmount: zDecimalNullable(),
  month6MarginDrawAmount: zDecimalNullable(),
  month7MarginDrawAmount: zDecimalNullable(),
  month8MarginDrawAmount: zDecimalNullable(),
  month9MarginDrawAmount: zDecimalNullable(),
  month10MarginDrawAmount: zDecimalNullable(),
  month11MarginDrawAmount: zDecimalNullable(),
  cornMonth2MarginDrawAmount: zDecimalNullable(),
  cornMonth3MarginDrawAmount: zDecimalNullable(),
  cornMonth4MarginDrawAmount: zDecimalNullable(),
  cornMonth5MarginDrawAmount: zDecimalNullable(),
  cornMonth6MarginDrawAmount: zDecimalNullable(),
  cornMonth7MarginDrawAmount: zDecimalNullable(),
  cornMonth8MarginDrawAmount: zDecimalNullable(),
  cornMonth9MarginDrawAmount: zDecimalNullable(),
  cornMonth10MarginDrawAmount: zDecimalNullable(),
  cornMonth11MarginDrawAmount: zDecimalNullable(),
  dairyMonth2MarginDrawAmount: zDecimalNullable(),
  dairyMonth3MarginDrawAmount: zDecimalNullable(),
  dairyMonth4MarginDrawAmount: zDecimalNullable(),
  dairyMonth5MarginDrawAmount: zDecimalNullable(),
  dairyMonth6MarginDrawAmount: zDecimalNullable(),
  dairyMonth7MarginDrawAmount: zDecimalNullable(),
  dairyMonth8MarginDrawAmount: zDecimalNullable(),
  dairyMonth9MarginDrawAmount: zDecimalNullable(),
  dairyMonth10MarginDrawAmount: zDecimalNullable(),
  dairyMonth11MarginDrawAmount: zDecimalNullable(),
  soymMonth2MarginDrawAmount: zDecimalNullable(),
  soymMonth3MarginDrawAmount: zDecimalNullable(),
  soymMonth4MarginDrawAmount: zDecimalNullable(),
  soymMonth5MarginDrawAmount: zDecimalNullable(),
  soymMonth6MarginDrawAmount: zDecimalNullable(),
  soymMonth7MarginDrawAmount: zDecimalNullable(),
  soymMonth8MarginDrawAmount: zDecimalNullable(),
  soymMonth9MarginDrawAmount: zDecimalNullable(),
  soymMonth10MarginDrawAmount: zDecimalNullable(),
  soymMonth11MarginDrawAmount: zDecimalNullable(),
  salesEffectiveDate: zCalendarDate(),
  liveCattleMonth2MarginDrawAmount: zDecimalNullable(),
  liveCattleMonth3MarginDrawAmount: zDecimalNullable(),
  liveCattleMonth4MarginDrawAmount: zDecimalNullable(),
  liveCattleMonth5MarginDrawAmount: zDecimalNullable(),
  liveCattleMonth6MarginDrawAmount: zDecimalNullable(),
  liveCattleMonth7MarginDrawAmount: zDecimalNullable(),
  liveCattleMonth8MarginDrawAmount: zDecimalNullable(),
  liveCattleMonth9MarginDrawAmount: zDecimalNullable(),
  liveCattleMonth10MarginDrawAmount: zDecimalNullable(),
  liveCattleMonth11MarginDrawAmount: zDecimalNullable(),
  feederCattleMonth2MarginDrawAmount: zDecimalNullable(),
  feederCattleMonth3MarginDrawAmount: zDecimalNullable(),
  feederCattleMonth4MarginDrawAmount: zDecimalNullable(),
  feederCattleMonth5MarginDrawAmount: zDecimalNullable(),
  feederCattleMonth6MarginDrawAmount: zDecimalNullable(),
  feederCattleMonth7MarginDrawAmount: zDecimalNullable(),
  feederCattleMonth8MarginDrawAmount: zDecimalNullable(),
  feederCattleMonth9MarginDrawAmount: zDecimalNullable(),
  feederCattleMonth10MarginDrawAmount: zDecimalNullable(),
  feederCattleMonth11MarginDrawAmount: zDecimalNullable(),
  releasedDate: zCalendarDate(),
  lastReleasedDate: zCalendarDate().nullable(),
  deletedDate: zCalendarDate().nullable(),
  filingDate: zCalendarDate(),
});

export const dbLgmDrawDailyInsertSchema = dbLgmDrawDailySchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    month2MarginDrawAmount: true,
    month3MarginDrawAmount: true,
  })
  .extend({
    // nullable fields are not required for insert, defaults to null
    month2MarginDrawAmount: z.coerce.number().nullable(),
    month3MarginDrawAmount: z.coerce.number().nullish(),
    month4MarginDrawAmount: z.coerce.number().nullish(),
    month5MarginDrawAmount: z.coerce.number().nullish(),
    month6MarginDrawAmount: z.coerce.number().nullish(),
    month7MarginDrawAmount: z.coerce.number().nullish(),
    month8MarginDrawAmount: z.coerce.number().nullish(),
    month9MarginDrawAmount: z.coerce.number().nullish(),
    month10MarginDrawAmount: z.coerce.number().nullish(),
    month11MarginDrawAmount: z.coerce.number().nullish(),
    cornMonth2MarginDrawAmount: z.coerce.number().nullish(),
    cornMonth3MarginDrawAmount: z.coerce.number().nullish(),
    cornMonth4MarginDrawAmount: z.coerce.number().nullish(),
    cornMonth5MarginDrawAmount: z.coerce.number().nullish(),
    cornMonth6MarginDrawAmount: z.coerce.number().nullish(),
    cornMonth7MarginDrawAmount: z.coerce.number().nullish(),
    cornMonth8MarginDrawAmount: z.coerce.number().nullish(),
    cornMonth9MarginDrawAmount: z.coerce.number().nullish(),
    cornMonth10MarginDrawAmount: z.coerce.number().nullish(),
    cornMonth11MarginDrawAmount: z.coerce.number().nullish(),
    dairyMonth2MarginDrawAmount: z.coerce.number().nullish(),
    dairyMonth3MarginDrawAmount: z.coerce.number().nullish(),
    dairyMonth4MarginDrawAmount: z.coerce.number().nullish(),
    dairyMonth5MarginDrawAmount: z.coerce.number().nullish(),
    dairyMonth6MarginDrawAmount: z.coerce.number().nullish(),
    dairyMonth7MarginDrawAmount: z.coerce.number().nullish(),
    dairyMonth8MarginDrawAmount: z.coerce.number().nullish(),
    dairyMonth9MarginDrawAmount: z.coerce.number().nullish(),
    dairyMonth10MarginDrawAmount: z.coerce.number().nullish(),
    dairyMonth11MarginDrawAmount: z.coerce.number().nullish(),
    soymMonth2MarginDrawAmount: z.coerce.number().nullish(),
    soymMonth3MarginDrawAmount: z.coerce.number().nullish(),
    soymMonth4MarginDrawAmount: z.coerce.number().nullish(),
    soymMonth5MarginDrawAmount: z.coerce.number().nullish(),
    soymMonth6MarginDrawAmount: z.coerce.number().nullish(),
    soymMonth7MarginDrawAmount: z.coerce.number().nullish(),
    soymMonth8MarginDrawAmount: z.coerce.number().nullish(),
    soymMonth9MarginDrawAmount: z.coerce.number().nullish(),
    soymMonth10MarginDrawAmount: z.coerce.number().nullish(),
    soymMonth11MarginDrawAmount: z.coerce.number().nullish(),
    liveCattleMonth2MarginDrawAmount: z.coerce.number().nullish(),
    liveCattleMonth3MarginDrawAmount: z.coerce.number().nullish(),
    liveCattleMonth4MarginDrawAmount: z.coerce.number().nullish(),
    liveCattleMonth5MarginDrawAmount: z.coerce.number().nullish(),
    liveCattleMonth6MarginDrawAmount: z.coerce.number().nullish(),
    liveCattleMonth7MarginDrawAmount: z.coerce.number().nullish(),
    liveCattleMonth8MarginDrawAmount: z.coerce.number().nullish(),
    liveCattleMonth9MarginDrawAmount: z.coerce.number().nullish(),
    liveCattleMonth10MarginDrawAmount: z.coerce.number().nullish(),
    liveCattleMonth11MarginDrawAmount: z.coerce.number().nullish(),
    feederCattleMonth2MarginDrawAmount: z.coerce.number().nullish(),
    feederCattleMonth3MarginDrawAmount: z.coerce.number().nullish(),
    feederCattleMonth4MarginDrawAmount: z.coerce.number().nullish(),
    feederCattleMonth5MarginDrawAmount: z.coerce.number().nullish(),
    feederCattleMonth6MarginDrawAmount: z.coerce.number().nullish(),
    feederCattleMonth7MarginDrawAmount: z.coerce.number().nullish(),
    feederCattleMonth8MarginDrawAmount: z.coerce.number().nullish(),
    feederCattleMonth9MarginDrawAmount: z.coerce.number().nullish(),
    feederCattleMonth10MarginDrawAmount: z.coerce.number().nullish(),
    feederCattleMonth11MarginDrawAmount: z.coerce.number().nullish(),
  })
  .partial(
    // nullable fields are not required for insert, defaults to null
    {
      lastReleasedDate: true,
      deletedDate: true,
      month2MarginDrawAmount: true,
    }
  );

export type DbLgmDrawDailyInsertSchema = z.input<
  typeof dbLgmDrawDailyInsertSchema
>;

export const dbLgmDrawDailyUpdateSchema = dbLgmDrawDailyInsertSchema
  .merge(dbLgmDrawDailySchema.pick({ updatedAt: true }))
  .partial();

// Verify derived type matches kysely
expectTypeOf<DbLgmDrawDaily>().branded.toEqualTypeOf<
  z.output<typeof dbLgmDrawDailySchema>
>();

// ensure keys match
expectTypeOf<keyof DbLgmDrawDailyInsert>().toEqualTypeOf<
  keyof DbLgmDrawDailyInsertSchema
>();
expectTypeOf<DbLgmDrawDailyInsert>().toMatchTypeOf<DbLgmDrawDailyInsertSchema>();

expectTypeOf<DbLgmDrawDailyUpdate>().toMatchTypeOf<
  z.input<typeof dbLgmDrawDailyUpdateSchema>
>();
