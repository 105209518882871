import { z } from 'zod';
import { entityHistorySchema } from './entity-history';

export const searchEntitiesRequestSchema = z.object({
  ids: z.array(z.string()).optional(),
  searchText: z.string().optional(),
  searchFields: z
    .array(z.enum(['firstName', 'lastName', 'businessName', 'email']))
    .optional(),
  ownerIds: z.array(z.string()).optional(),
  skip: z.coerce.number().int().nonnegative().optional(),
  limit: z.coerce.number().int().positive().optional(),
  reinsuranceYears: z.array(z.coerce.number()).optional(),
});

export type SearchEntitiesRequest = z.infer<typeof searchEntitiesRequestSchema>;

export const searchEntitiesResponseSchema = z.object({
  entities: z.array(entityHistorySchema),
  totalCount: z.number(),
});

export type SearchEntitiesResponse = z.infer<
  typeof searchEntitiesResponseSchema
>;
