import { z } from 'zod';
import {
  RmaLrpCommodityTypeCodes,
  InsureIQLrpCommodityNames,
  InsureIQLrpCommodityTypeNames,
} from '@harvestiq/constants';
import { zIsoDate, zDecimalRequired } from '@harvestiq/zod';

export const lrpQuoteByTypeCodeAndSedRequestSchema = z.object({
  salesEffectiveDate: zIsoDate(),
  commodityTypeCode: z.nativeEnum(RmaLrpCommodityTypeCodes),
});

export type LrpQuoteByTypeCodeAndSedRequest = z.infer<
  typeof lrpQuoteByTypeCodeAndSedRequestSchema
>;

export const lrpQuoteSchema = z.object({
  commodity: z.nativeEnum(InsureIQLrpCommodityNames),
  producerPremium: zDecimalRequired(),
  expectedEndingValue: zDecimalRequired(),
  savingsVsCmeTrade: zDecimalRequired(),
  createdAt: z.string().or(z.date()).pipe(z.coerce.date()).optional(), // datetime object
  commodityType: z.nativeEnum(InsureIQLrpCommodityTypeNames),
  salesEffectiveDate: zIsoDate(),
  savingsVsCmeTradeDollar: zDecimalRequired(),
  coveragePrice: zDecimalRequired(),
  coverageLevel: zDecimalRequired(),
  marketingDate: zIsoDate(),
  typeCode: z.nativeEnum(RmaLrpCommodityTypeCodes),
});

export type LrpQuote = z.infer<typeof lrpQuoteSchema>;

export const lrpQuoteByTypeCodeAndSedResponseSchema = z.object({
  data: lrpQuoteSchema.array(),
});

export type LrpQuoteByTypeCodeAndSedResponse = z.infer<
  typeof lrpQuoteByTypeCodeAndSedResponseSchema
>;
