import { z } from 'zod';

import { zIsoDate } from '@harvestiq/zod';
import {
  InsureIQCommodityNames,
  RmaLrpCommodityTypeCodes,
} from '@harvestiq/constants';

// request
export const lrpEndingValuesRequestSchema = z.object({
  coverages: z.array(
    z.object({
      commodity: z.string().pipe(z.nativeEnum(InsureIQCommodityNames)),
      commodityTypeCode: z.coerce
        .number()
        .pipe(z.nativeEnum(RmaLrpCommodityTypeCodes)),
      endDate: zIsoDate(),
      referenceId: z.string().optional(),
      numberOfHead: z.coerce.number().min(0),
      targetWeight: z.coerce.number().min(0),
    })
  ),
});

export type LrpEndingValuesRequest = z.infer<
  typeof lrpEndingValuesRequestSchema
>;

// response
export const lrpEndingValuesResponseSchema = z.object({
  data: z.array(
    z.object({
      commodity: z.string().pipe(z.nativeEnum(InsureIQCommodityNames)),
      commodityTypeCode: z.coerce
        .number()
        .pipe(z.nativeEnum(RmaLrpCommodityTypeCodes)),
      endDate: zIsoDate(),
      referenceId: z.string().optional(),
      numberOfHead: z.coerce.number(),
      targetWeight: z.coerce.number(),
      actualEndingValuePerCwt: z.number(),
      actualEndingValuePerHead: z.number(),
      actualEndingValueTotal: z.number(),
      expectedEndingValuePerCwt: z.number(),
      expectedEndingValuePerHead: z.number(),
      expectedEndingValueTotal: z.number(),
    })
  ),
});

export type LrpEndingValuesResponse = z.infer<
  typeof lrpEndingValuesResponseSchema
>;
