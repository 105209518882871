import { z } from 'zod';

import { zIsoDate } from '@harvestiq/zod';

export enum BreakevenOperationTypes {
  BACKGROUNDING = 'Backgrounding',
  FEEDYARD = 'Feedyard',
}

export const getBreakevenCalculationSchema = z.object({
  operationType: z.coerce.string().pipe(z.nativeEnum(BreakevenOperationTypes)),
  contractDate: zIsoDate(),
});

export type GetBreakevenCalculationRequest = z.infer<
  typeof getBreakevenCalculationSchema
>;
