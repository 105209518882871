import { Container } from '@mui/material'
import classNames from 'classnames'
import styles from './Main.module.css'
import { HTMLAttributes } from 'react'

type MainProps = HTMLAttributes<HTMLElement>

const Main = ({ children, className, ...props }: MainProps) => (
  <main
    className={classNames('flex flex-1 flex-col py1', styles.main, className)}
    {...props}
  >
    <Container className={styles.container}>{children}</Container>
  </main>
)

export default Main
