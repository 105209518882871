import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material'
import theme, { prettyColor } from '../../styles/theme'

type ChipProps = Omit<MuiChipProps, 'color'> & {
  label: string
  color?: string
}

export const Chip = ({
  label,
  color = prettyColor(label),
  ...restProps
}: ChipProps) => (
  <MuiChip
    label={label}
    style={{
      backgroundColor: color,
      color: theme.palette.getContrastText(color),
    }}
    {...restProps}
  />
)

export default Chip
