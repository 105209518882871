import { useState, useCallback } from 'react'
import { TextField, Popover, InputAdornment, IconButton } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { CompactPicker } from 'react-color'
import { colorPickerColors } from '../../styles/theme'

const ColorPickerAdornment = ({ value, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <InputAdornment
      position="start"
      sx={{ height: '100%', alignItems: 'stretch' }}
    >
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <CompactPicker onChange={onChange} colors={colorPickerColors} />
      </Popover>

      <div
        className="flex flex-1 border-1 border-color-black"
        onClick={handleClick}
        style={{
          minWidth: 32,
          backgroundColor: value,
        }}
      />
    </InputAdornment>
  )
}

export const ColorPicker = ({ label, name, value, onChange }) => {
  const handleClear = () => onChange?.({ target: { name, value: '' } })

  const handleChange = useCallback(
    ({ hex }) => {
      onChange?.({ target: { name, value: hex } })
    },
    [name, onChange],
  )

  const handleKeyDown = ({ target, key }) =>
    key === 'Enter' && handleChange({ hex: target.value })

  return (
    <TextField
      onKeyDown={handleKeyDown}
      label={label || name}
      value={value}
      sx={{ maxWidth: 178 }}
      data-cy={`color-picker-${name}`}
      onChange={({ target }) => handleChange({ hex: target.value })}
      InputProps={{
        startAdornment: (
          <ColorPickerAdornment value={value} onChange={handleChange} />
        ),
        endAdornment: value ? (
          <InputAdornment position="end" sx={{ marginLeft: 0 }}>
            <IconButton onClick={handleClear}>
              <Clear />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  )
}

export default ColorPicker
