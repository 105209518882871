import { Table as EnhancedTable } from './Table'
import { Body } from './TableBody'
import { Toolbar } from './TableToolbar'
import { Pagination } from './TablePagination'

EnhancedTable.Body = Body
EnhancedTable.Toolbar = Toolbar
EnhancedTable.Pagination = Pagination

export const Table = EnhancedTable
