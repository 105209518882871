export enum CampaignStatus {
  ACTIVE = 'ACTIVE', // currently running campaign
  PAUSED = 'PAUSED', // paused by user (would be running)
  SCHEDULED = 'SCHEDULED', // scheduled to start in future
  EXPIRED = 'EXPIRED', // expired or ended
}

// TODO: verify options
export enum CampaignFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum CampaignRecipientStatus {
  ACTIVE = 'ACTIVE',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  INVALID = 'INVALID', // contact info invalid
}

export enum CampaignCoverageTier {
  ALL = 'ALL',
  TOP = 'TOP',
}

export const CampaignCoverageTierOptions = [
  { value: CampaignCoverageTier.TOP, label: 'Top Level' },
  { value: CampaignCoverageTier.ALL, label: 'All Levels' },
];

export const InsureIQQuoteTableHeaderTexts = {
  LRP: {
    title: 'Livestock Risk Protection (LRP) Quote',
    columnHeaders: [
      'Marketing Date',
      'Coverage Level',
      'Coverage Price (/cwt)',
      'Coverage Premium (/cwt)',
    ],
    columnHeadersExpanded: [
      'Endorsement<br />Length (weeks)',
      'End Date',
      'Expected<br />Value (/cwt)',
      'Coverage<br />Price (/cwt)',
      'Coverage<br />Level',
      'Rate',
      'Insured<br />Value',
      'Total<br />Premium',
      'Subsidy',
      'Producer<br />Premium',
      'Producer<br />Premium (/cwt)',
      'Producer<br />Premium (/head)',
    ],
    uom: '',
  },
  LGM: {
    title: 'Livestock Gross Margin (LGM) Quote',
    columnHeaders: [
      'Marketing Month',
      'Deductible',
      'Gross Margin Guarantee',
      'Producer Premium',
    ],
    uom: '($/head)',
  },
};
