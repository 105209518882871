import {
  LivestockInsuranceTypes,
  MonthNames,
  RmaLgmCommodityTypeCodes,
  RmaLgmCommodityTypes,
  RmaLrpCommodityTypeCodes,
  RmaLrpCommodityTypes,
} from '@harvestiq/constants';
import { Campaign } from './Campaigns';
import { campaignResponseSchema, campaignSchema } from './campaign-schema';
import { z } from 'zod';
import Decimal from 'decimal.js';
import { CalendarDate } from '@harvestiq/zod';

const campaignMarketingMonths = MonthNames();

export const typeguardLRPCommodityType = (
  insuranceType: LivestockInsuranceTypes,
  commodityType: RmaLrpCommodityTypeCodes | RmaLgmCommodityTypeCodes
): commodityType is RmaLrpCommodityTypeCodes => {
  if (insuranceType === LivestockInsuranceTypes.LRP) {
    return Object.keys(RmaLrpCommodityTypes).includes(commodityType.toString());
  }
  return false;
};

export const typeguardLGMCommodityType = (
  insuranceType: LivestockInsuranceTypes,
  commodityType: RmaLrpCommodityTypeCodes | RmaLgmCommodityTypeCodes
): commodityType is RmaLgmCommodityTypeCodes => {
  if (insuranceType === LivestockInsuranceTypes.LGM) {
    return Object.keys(RmaLgmCommodityTypes).includes(commodityType.toString());
  }
  return false;
};

export type ParsedCampaign = Omit<
  Campaign,
  'marketingMonths' | 'commodity' | 'commodityType'
> & {
  marketingMonths: string[];
  recipientIds: string[];
  commodity: string;
  commodityType: string;
};

export const parseCampaignData = (
  campaignData?: z.input<typeof campaignResponseSchema>
): ParsedCampaign | undefined => {
  if (!campaignData) {
    return undefined;
  }
  const { recipients, ...campaign } = campaignData;

  const marketingMonthNames = campaign.marketingMonths.map((monthNumber) => {
    return campaignMarketingMonths[monthNumber];
  });
  let commodityInfo = null;
  const insuranceType = campaign.insuranceType;
  const commodityType = campaign.commodityType;
  if (typeguardLRPCommodityType(insuranceType, commodityType)) {
    commodityInfo = RmaLrpCommodityTypes[commodityType];
  } else if (typeguardLGMCommodityType(insuranceType, commodityType)) {
    commodityInfo = RmaLgmCommodityTypes[commodityType];
  } else {
    throw new Error(
      `Invalid commodity type ${commodityType} for insurance type ${insuranceType}`
    );
  }

  // TODO: just use zod...?
  const parsedCampaign: ParsedCampaign = {
    ...campaign,
    recipients,
    ...(commodityInfo && {
      commodity: commodityInfo.sgCommodityName,
      commodityType: commodityInfo.sgName,
    }),
    endDate: campaign.endDate as CalendarDate | null,
    marketingMonths: marketingMonthNames,
    recipientIds: recipients.map(({ userId }) => userId),
    targetWeight: campaign.targetWeight
      ? new Decimal(campaign.targetWeight)
      : null,
    liveCattleTargetCwtPerHead: campaign.liveCattleTargetCwtPerHead
      ? new Decimal(campaign.liveCattleTargetCwtPerHead)
      : null,
    feederCattleTargetCwtPerHead: campaign.feederCattleTargetCwtPerHead
      ? new Decimal(campaign.feederCattleTargetCwtPerHead)
      : null,
    cornTargetBuPerHead: campaign.cornTargetBuPerHead
      ? new Decimal(campaign.cornTargetBuPerHead)
      : null,
  };

  return parsedCampaign;
};
