import React, { useCallback, useContext, useState } from 'react'
import { ModalContainer } from '../../components/modals/ModalContainer'

const ModalContext = React.createContext()
const { Provider } = ModalContext

const useModal = () => useContext(ModalContext)

const ModalProvider = ({ children }) => {
  const [modalQueue, setModalQueue] = useState([])

  const [{ content: modal, props: modalProps } = {}] = modalQueue

  const closeModal = useCallback(
    (props = modalProps) => {
      // ignoreOnClose is useful for handling successful form submissions (e.g. AuthModal)
      if (props?.onClose && !props?.ignoreOnClose) {
        props.onClose()
      }
      setModalQueue(prevQueue => prevQueue.slice(1))
    },
    [modalProps],
  )

  const setModal = useCallback(
    (content, props = {}) => {
      if (!content) {
        return closeModal()
      }

      setModalQueue(prevQueue => {
        const isModalInQueue = prevQueue.some(
          ({ content: { type } }) => type.name === content.type.name,
        )
        if (isModalInQueue) {
          return prevQueue
        }
        return [...prevQueue, { content, props }]
      })
    },
    [closeModal],
  )

  return (
    <Provider value={{ modal, setModal, closeModal }}>
      <ModalContainer
        modal={modal}
        modalProps={modalProps}
        closeModal={closeModal}
      />
      {children}
    </Provider>
  )
}

export { ModalContext, ModalContainer, ModalProvider, useModal }
