import React, { useCallback, useMemo, useContext, useState } from 'react'
import { Toast } from '../../components/Toast'

const ToastContext = React.createContext()
const { Provider } = ToastContext

const toastTypes = ['success', 'error', 'info', 'warning']

const useProvideToast = () => {
  const [toastState, setToastState] = useState(null)
  const [toastProps, setToastProps] = useState({})

  const setToast = useCallback(
    (content, props = {}) => {
      setToastState(content)
      setToastProps(props)
    },
    [setToastState, setToastProps],
  )

  const toasts = useMemo(
    () =>
      toastTypes.reduce((acc, toastType) => {
        const handler = text =>
          setToast(true, {
            text,
            severity: toastType,
          })
        acc[`${toastType}Toast`] = handler
        acc[toastType] = handler
        return acc
      }, {}),
    [setToast],
  )

  return {
    toast: toastState,
    toastProps,
    setToast,
    toastTypes,
    toastHandlers: toasts,
    ...toasts,
  }
}

export const useToast = () => useContext(ToastContext)

const ToastProvider = ({ children }) => {
  const { toast, toastProps, setToast, ...rest } = useProvideToast()

  return (
    <Provider value={{ toast, setToast, ...rest }}>
      <Toast
        open={!!toast}
        handleClose={() => setToast(false)}
        {...toastProps}
      />
      {children}
    </Provider>
  )
}

export { ToastContext, ToastProvider }
