import { useMemo } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Field from '../Field'

export const InputSelect = ({
  label,
  value,
  onChange,
  options = [],
  inputFieldType,
  error,
  helperText,
  InputProps,
  ...fieldProps
}) => {
  const { name } = fieldProps
  const handleChange = (_, newVal) => {
    onChange({ target: { name, value: newVal || '' } })
  }

  const handleInputChange = (_, newVal) => {
    onChange({ target: { name, value: newVal || '' } })
  }

  const getValue = useMemo(
    () =>
      options.filter(option => value?.value === option.value)[0]?.label ||
      value,
    [options, value],
  )

  const hasInputField = !!inputFieldType
  return (
    <Autocomplete
      freeSolo
      {...fieldProps}
      options={options}
      value={getValue || ''}
      inputValue={getValue || ''}
      onChange={handleChange}
      onInputChange={handleInputChange}
      filterOptions={optionsToFilter => optionsToFilter.map(option => option)}
      renderInput={params =>
        hasInputField ? (
          <Field
            {...params}
            {...fieldProps}
            value={getValue || ''}
            error={error && helperText}
            type={inputFieldType}
            label={label}
            InputProps={InputProps}
          />
        ) : (
          <TextField
            {...fieldProps}
            {...params}
            error={error}
            helperText={helperText}
            label={label}
            InputProps={InputProps}
          />
        )
      }
    />
  )
}

export default InputSelect
