import { useCallback, useState } from 'react'
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material'
import {
  Logout,
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  Settings as SettingsIcon,
  Key as KeyIcon,
} from '@mui/icons-material'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { pages, slugify } from 'common'

import { useUser } from '../../contexts'
import Avatar from '../Avatar'
import styles from './AccountMenu.module.scss'

type AccountMenuProps = {
  user: {
    fullName: string
    avatarUrl: string
  }
}

type MenuLink = {
  label: string
  icon: React.ElementType
  href: string
}

export function AccountMenu({
  user: { fullName, avatarUrl },
}: AccountMenuProps) {
  const { signOut, user } = useUser()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    [],
  )
  const handleClose = () => setAnchorEl(null)

  const adminLinks: MenuLink[] = [
    {
      label: 'Administration',
      icon: SettingsIcon,
      href: pages.settings.path,
    },
  ]

  const accountMenuLinks: MenuLink[] = [
    {
      label: 'My Profile',
      icon: AccountCircleOutlinedIcon,
      href: pages.profile.path,
    },
    ...(user.isAdmin ? adminLinks : []),
    ...(user.isPartner
      ? [
          {
            label: 'API Keys',
            icon: KeyIcon,
            href: pages.apiKeys.path,
          },
        ]
      : []),
  ]

  return (
    <Box>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          id="account-menu"
          size="small"
          sx={{ ml: 2 }}
        >
          <Avatar fullName={fullName} avatarUrl={avatarUrl} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {user.isConfirmed &&
          accountMenuLinks.map(({ icon: Icon, ...menuLink }) => {
            const menuItemId = slugify(`account-menu-item-${menuLink.label}`)
            return (
              <MenuItem
                id={menuItemId}
                key={menuItemId}
                className={classNames(styles.menuItem)}
                onClick={handleClose}
              >
                <Link to={menuLink.href} className="flex items-center flex-1">
                  <ListItemIcon>
                    <Icon fontSize="small" />
                  </ListItemIcon>
                  {menuLink.label}
                </Link>
              </MenuItem>
            )
          })}
        <Divider />
        <MenuItem id="accountMenuSignOut" onClick={signOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default AccountMenu
