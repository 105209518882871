import {
  GeneratedAlways,
  Generated,
  Selectable,
  Insertable,
  Updateable,
} from 'kysely';
import { AddressTypes } from './address-types';
import { expectTypeOf } from 'expect-type';
import { z } from 'zod';

export interface LocationsTable {
  id: GeneratedAlways<string>;
  careOfName: string | null;
  line1: string | null;
  line2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  county: string | null;
  country: string | null;
  addressType: AddressTypes | null;
  coordinates: number[] | null;
  createdAt: GeneratedAlways<Date>;
  updatedAt: Generated<Date>;
  deletedAt: Date | null;
}

export type DbLocation = Selectable<LocationsTable>;
export const dbLocationSchema = z.object({
  careOfName: z.string().nullable(),
  line1: z.string().nullable(),
  line2: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  zip: z.string().nullable(),
  county: z.string().nullable(),
  country: z.string().nullable(),
  addressType: z.nativeEnum(AddressTypes).nullable(),
  coordinates: z.array(z.number()).nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullable(),
});
expectTypeOf<DbLocation>().toMatchTypeOf<z.infer<typeof dbLocationSchema>>();

export type DbLocationInsert = Insertable<LocationsTable>;
export type DbLocationUpdate = Updateable<LocationsTable>;
