import { z } from 'zod';
import { orgAppSettingsSchema } from '../org-app-settings/org-app-settings';

export const resetPasswordEmailTemplateDataSchema = z.object({
  data: z.object({ ip: z.string().ip(), code: z.string() }),
  orgAppSettings: orgAppSettingsSchema,
});

export type ResetPasswordEmailTemplateData = z.infer<
  typeof resetPasswordEmailTemplateDataSchema
>;
