import { z } from 'zod';
import { dbPolicySchema, dbPolicyUpdateSchema } from './db-policy';
import { zCoercedBoolean } from '@harvestiq/zod';

export const updatePolicyRequestSchema = dbPolicyUpdateSchema.extend({
  enableEntities: zCoercedBoolean().optional(),
});
export type UpdatePolicyRequest = z.infer<typeof updatePolicyRequestSchema>;

export const updatePolicyResponseSchema = z.object({
  policy: dbPolicySchema,
});
export type UpdatePolicyResponse = z.infer<typeof updatePolicyResponseSchema>;
