import { harvestiqEmailAddress } from 'common'

const EmailLink = ({ email = harvestiqEmailAddress }) => {
  return (
    <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
      {email}
    </a>
  )
}

export default EmailLink
