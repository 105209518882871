import { z } from 'zod';
import { FmhEntityStatus, FmhPartyTypeCode } from './constants';

export const fmhDocuSignSchema = z.object({
  routeOrderPreference: z.nativeEnum(FmhPartyTypeCode), // Who gets the document first
  producerSignerEmail: z.string().email(),
  producerSignerMobile: z.string().regex(/^\d{10}$/),
  producerSignerName: z.string(),
  agentSignerEmail: z.string().email(),
  agentSignerMobile: z.string().regex(/^\d{10}$/),
  agentSignerName: z.string(),
});

export type FmhDocuSign = z.infer<typeof fmhDocuSignSchema>;

export const commonFmhEndorsementSchema = z.object({
  aipPremiumKey: z.string(),
  aipInsuranceInForceKey: z.string(),
  aipPolicyProducerKey: z.string(),
  aipInsuranceAgentKey: z.string(),
  fmhProducerPartyKey: z.number(),
  fmhAgentPartyKey: z.number(),
  policyNumber: z.string(),
  reinsuranceYear: z.number().int(),
  countyCode: z.string().regex(/^\d{3}$/), // last three digits of the FIPS county code
  insuredSignatureDate: z.string(),
  agentSignatureDate: z.string(),
  salesEffectiveDate: z.string(),
  insuredSharePercent: z.number().max(1),
  endDate: z.string(),
  premium: z.number(),
  liability: z.number(),
  status: z.nativeEnum(FmhEntityStatus),
});
