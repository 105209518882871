import { DateFormatShort } from '@harvestiq/constants';
import { LgmQuoteData } from './lgm-quote';
import { LegacyLgmSeries, legacyLgmSeriesSchema } from './helpers';
import { dayjs } from '@harvestiq/utils';
import Decimal from 'decimal.js';
import { z } from 'zod';

export const legacyLgmPremiumResponseSchema = z.object({
  basePremium: legacyLgmSeriesSchema,
  producerPremium: legacyLgmSeriesSchema,
  subsidy: legacyLgmSeriesSchema,
});

export type LegacyLgmPremiumResponse = z.infer<
  typeof legacyLgmPremiumResponseSchema
>;

/**
 * @deprecated For use in legacy LGM UI only
 */
export function createLegacyPremiumResponse(
  quoteData: LgmQuoteData
): LegacyLgmPremiumResponse {
  const formattedMarketingDates = quoteData.marketingDates.map(
    (marketingDate) => dayjs(marketingDate).format(DateFormatShort)
  );
  const premiumData: LegacyLgmPremiumResponse = {
    basePremium: formattedMarketingDates.reduce<LegacyLgmSeries>(
      (acc, date) => {
        acc[date] = new Decimal(quoteData.avgTotalPremiumPerHead).toNumber();
        return acc;
      },
      { series: 'Base Premium' } as LegacyLgmSeries
    ),
    subsidy: formattedMarketingDates.reduce<LegacyLgmSeries>(
      (acc, date) => {
        acc[date] = new Decimal(quoteData.avgSubsidyPerHead).toNumber();
        return acc;
      },
      { series: 'Subsidy' } as LegacyLgmSeries
    ),
    producerPremium: formattedMarketingDates.reduce(
      (acc, date) => {
        acc[date] = new Decimal(quoteData.avgProducerPremiumPerHead).toNumber();
        return acc;
      },
      { series: 'Producer Premium' } as LegacyLgmSeries
    ),
  };
  return premiumData;
}
