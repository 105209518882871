import { ColumnType, Selectable } from 'kysely';
import { toZod } from 'tozod';
import { z } from 'zod';

// ColumnType<SelectType, InsertType, UpdateType>

export interface DbRmaHolidayTable {
  holidayDate: ColumnType<string, string, string>;
  holidayName: ColumnType<string, string, string>;
  nextBusinessDate: ColumnType<string, string, string>;
  lrpFlag: ColumnType<boolean>;
  lgmFlag: ColumnType<boolean>;
  drpFlag: ColumnType<boolean>;
  federalHoliday: ColumnType<string, string, string>;
}

export type DbRmaHolidayTableSelect = Selectable<DbRmaHolidayTable>;

export const rmaHolidaySchema: toZod<DbRmaHolidayTableSelect> = z.object({
  holidayDate: z.string(),
  holidayName: z.string(),
  nextBusinessDate: z.string(),
  lrpFlag: z.boolean(),
  lgmFlag: z.boolean(),
  drpFlag: z.boolean(),
  federalHoliday: z.string(),
});
