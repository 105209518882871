import { z } from 'zod';

export const orgFmhKeysSchema = z.object({
  fmhApiKey: z.string().nullable(),
  fmhSigningKey: z.string().nullable(),
});
export type OrgFmhKeys = z.infer<typeof orgFmhKeysSchema>;

export const getOrgFmhKeysResponseSchema = z.object({
  data: z.array(orgFmhKeysSchema),
});
export type GetOrgFmhKeysResponse = z.infer<typeof getOrgFmhKeysResponseSchema>;
