import { Typography, Divider, TypographyProps } from '@mui/material'
import { toTitleCase } from 'common'
import classNames from 'classnames'

interface HeadingProps extends TypographyProps {
  name?: string
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children?: React.ReactNode
  containerClass?: string
  enableDivider?: boolean
  label?: string
}

const Heading = ({
  name,
  variant = 'h6',
  children,
  containerClass,
  enableDivider = true,
  label,
  ...props
}: HeadingProps) => {
  return (
    <div className={classNames(containerClass, 'width-100')}>
      <Typography variant={variant} {...props}>
        {children ?? label ?? toTitleCase(name ?? '')}
      </Typography>
      {enableDivider && <Divider orientation="horizontal" />}
    </div>
  )
}

export default Heading
