import { z } from 'zod';

export const accountLoginLinkParamsSchema = z.object({
  code: z.string(),
  redirect: z.string().optional(),
  userFullName: z.string(),
});

export type AccountLoginLinkParams = z.infer<
  typeof accountLoginLinkParamsSchema
>;
