import { Typography } from '@mui/material'
import classNames from 'classnames'
import { LoadingIndicator } from '../LoadingIndicator'

const Loading = ({
  title,
  className,
  size,
}: {
  title?: string
  className?: string
  size?: number
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col justify-center',
        'items-center width-100',
        className,
      )}
    >
      <LoadingIndicator size={size} />
      {title && (
        <Typography
          className="mt2 color-primary"
          variant="overline"
          display="block"
          gutterBottom
        >
          {title}
        </Typography>
      )}
    </div>
  )
}

export default Loading
