import { z } from 'zod';
import {
  AipNames,
  InsureIQPolicyTypes,
  PolicyStatuses,
} from '@harvestiq/constants';
import {
  GeneratedAlways,
  Selectable,
  Insertable,
  Updateable,
  ColumnType,
} from 'kysely';
import { expectTypeOf } from 'expect-type';
import { PolicyData, policyDataSchema } from './policy-data';
import { zDate, zIsoDate } from '@harvestiq/zod';

export interface PoliciesTable {
  id: GeneratedAlways<string>;
  name: string;
  type: InsureIQPolicyTypes;
  status: PolicyStatuses;
  data: PolicyData;
  previousPolicyId: string | null;
  previousPolicyProvider: string | null;
  insurerName: AipNames | null;
  insurerPolicyId: string | null;
  isExternallyManaged: boolean;
  customerSignedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  agentSignedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  externalStatus: string | null;
  reinsuranceYear: number;
  entityId: string | null;
  conditionsOfAcceptanceA: boolean | null;
  conditionsOfAcceptanceB: boolean | null;
  conditionsOfAcceptanceC: boolean | null;
  conditionsOfAcceptanceD: boolean | null;
  conditionsOfAcceptanceE: boolean | null;
  conditionsOfAcceptanceF: boolean | null;
  applicantDob: string | null; // isoDate string
  applicantIsEighteen: boolean | null;
  importSourceId: string | null;
  createdAt: ColumnType<Date, Date | string | undefined, Date | string>;
  updatedAt: ColumnType<Date, Date | string | undefined, Date | string>;
  deletedAt: ColumnType<
    Date | null,
    Date | string | null | undefined,
    Date | string | null
  >;
}

export type DbPolicy = Selectable<PoliciesTable>;
export const dbPolicySchema = z
  .object({
    id: z.string(),
    name: z.string(),
    previousPolicyId: z.string().nullable(),
    previousPolicyProvider: z.string().nullable(),
    insurerPolicyId: z.string().nullable(),
    isExternallyManaged: z.boolean(),
    customerSignedAt: z.coerce.date().nullable(),
    agentSignedAt: z.coerce.date().nullable(),
    externalStatus: z.string().nullable(),
    reinsuranceYear: z.number(),
    entityId: z.string().nullable(),
    conditionsOfAcceptanceA: z.boolean().nullable(),
    conditionsOfAcceptanceB: z.boolean().nullable(),
    conditionsOfAcceptanceC: z.boolean().nullable(),
    conditionsOfAcceptanceD: z.boolean().nullable(),
    conditionsOfAcceptanceE: z.boolean().nullable(),
    conditionsOfAcceptanceF: z.boolean().nullable(),
    applicantDob: zIsoDate().nullable(),
    applicantIsEighteen: z.boolean().nullable(),
    importSourceId: z.string().nullable(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    deletedAt: z.coerce.date().nullable(),
  })
  .extend({
    type: z.nativeEnum(InsureIQPolicyTypes),
    status: z.nativeEnum(PolicyStatuses),
    data: policyDataSchema,
    insurerName: z.nativeEnum(AipNames).nullable(),
  });
expectTypeOf<DbPolicy>().toMatchTypeOf<z.infer<typeof dbPolicySchema>>();

export type DbPolicyInsert = Insertable<PoliciesTable>;
export const dbPolicyInsertSchema = dbPolicySchema
  .omit({
    id: true,
    createdAt: true,
  })
  .extend({
    customerSignedAt: zDate().nullable(),
    agentSignedAt: zDate().nullable(),
    createdAt: zDate(),
    updatedAt: zDate(),
    deletedAt: zDate().nullable(),
  })
  .partial()
  // Required fields
  .extend({
    name: z.string(),
    type: z.nativeEnum(InsureIQPolicyTypes),
    status: z.nativeEnum(PolicyStatuses),
    data: policyDataSchema,
    insurerName: z.nativeEnum(AipNames).nullish(),
    isExternallyManaged: z.boolean(),
    reinsuranceYear: z.number(),
  });
expectTypeOf<DbPolicyInsert>().toMatchTypeOf<
  z.infer<typeof dbPolicyInsertSchema>
>();

export type DbPolicyUpdate = Updateable<PoliciesTable>;
export const dbPolicyUpdateSchema = dbPolicySchema
  .omit({
    id: true,
    createdAt: true,
  })
  .extend({
    customerSignedAt: zDate().nullable(),
    agentSignedAt: zDate().nullable(),
    createdAt: zDate(),
    updatedAt: zDate(),
    deletedAt: zDate().nullable(),
  })
  .partial();
expectTypeOf<DbPolicyUpdate>().toMatchTypeOf<
  z.infer<typeof dbPolicyUpdateSchema>
>();
