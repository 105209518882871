import { AppBar, Button, Container, Toolbar } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { pages } from 'common'

import HeaderMobileMenu from './HeaderMobileMenu'
import { useAppSettings, useUser } from '../../contexts'
import AccountMenu from '../AccountMenu'
import classes from './Header.module.scss'
import Menu from '../Menu'
import { useMenuItems } from '../../hooks'
import { Logo } from '../Logo'

const agentMenu = [pages.agentDashboard]

/**
 * 'links' property is used when menu items don't share the same URL structure as their parent menus naming.
 * For example, '/breakeven-calculator' is a child of the 'tools' menu but the url is not '/tools/breakeven-calculator'
 * 'links' ensures the correct parent menu item is highlighted while preserving existing urls and bookmarks.
 */

const headerMenuItems = [
  {
    ...pages.lrpQuote,
    label: 'Quotes',
    links: [pages.lrpQuote.path, pages.lgmQuote.path],
  },
  {
    ...pages.coverage,
    label: 'Coverages',
    links: [pages.coverage.path, pages.watchlist.path],
  },
  {
    ...pages.breakevenCalculator,
    label: 'Tools',
    links: [
      pages.breakevenCalculator.path,
      pages.lgmQuoteScenarioAnalysis.path,
    ],
  },
  {
    ...pages.faq,
    label: 'More',
    links: [
      pages.faq.path,
      pages.contactUs.path,
      pages.apply.path,
      pages.applyBusiness.path,
      pages.applyIndividual.path,
    ],
  },
]

const Header = () => {
  const navigate = useNavigate()
  const { user } = useUser()
  const { isMobile, appSettings } = useAppSettings()
  const { fullLogo, name } = appSettings

  const headerMenu = [
    ...(user && user.isAgent ? agentMenu : []),
    ...headerMenuItems,
  ]
  const menuItems = useMenuItems(headerMenu)

  return (
    <AppBar position="static" color="transparent" className={classes.appBar}>
      <Container className={classes.container}>
        <Toolbar className="flex flex-row justify-between">
          {isMobile && <HeaderMobileMenu items={menuItems} />}
          <Logo logoUrl={fullLogo} imgAlt={name} />
          {!isMobile && <Menu items={menuItems} id="main-menu" />}
          {user ? (
            <AccountMenu user={user} />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(pages.signIn.path)}
            >
              Sign in
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Header
