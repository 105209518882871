import { ChangeEvent } from 'react'
import classNames from 'classnames'
import { usePrevious } from 'react-use'
import { applyFieldGroupExtensions } from 'common'

import FieldGroup from '../FieldGroup'
import {
  RepeatableFieldGroup,
  RepeatableFieldGroupProps,
} from '../RepeatableFieldGroup'
import { FieldGroupDetails, FieldGroupDetailsProps } from '../FieldGroupDetails'
import { FieldType } from '../FieldGroup/FieldGroup'

type FieldGroupDetailsList = Record<
  string,
  Omit<FieldGroupDetailsProps, 'children'>
>

type FieldGroups = (
  | Record<string, FieldType>
  | [string, Record<string, FieldType>, RepeatableFieldGroupProps]
)[]

type FieldGroupsProps = {
  fieldGroups: FieldGroups
  state: Record<string, any>
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  requiredFields: string[]
  className?: string
  errors?: Record<string, string>
  fieldGroupDetails?: FieldGroupDetailsList
  fieldGroupExtensions?: Record<string, any>
}

export const FieldGroups = ({
  fieldGroups,
  state,
  onChange,
  requiredFields,
  className,
  errors = {},
  fieldGroupDetails = {},
  fieldGroupExtensions = {},
}: FieldGroupsProps) => {
  const prevState = usePrevious(state)
  const extendedFieldGroups = applyFieldGroupExtensions(
    fieldGroups,
    fieldGroupExtensions,
    state,
    prevState,
  )

  return (
    <div className={classNames(className, 'flex flex-1 flex-col gap-2')}>
      {extendedFieldGroups.map(
        (fields: Record<string, FieldType>, fieldRowIndex: number) => {
          if (Array.isArray(fields) && fields.length === 3) {
            const [
              fieldGroupName,
              fieldGroupFields,
              repeatableFieldGroupProps,
            ] = fields

            const repeatableFieldGroup = (
              <RepeatableFieldGroup
                key={`RepeatableFieldGroup-${fieldGroupName}-${fieldRowIndex}`}
                state={state[fieldGroupName]}
                fieldGroupName={fieldGroupName}
                fieldGroupFields={fieldGroupFields}
                requiredFields={requiredFields}
                onChange={onChange}
                errors={errors[fieldGroupName]}
                {...repeatableFieldGroupProps}
              />
            )

            return fieldGroupDetails[fieldGroupName] ? (
              <FieldGroupDetails
                key={`RepeatableFieldGroupDetails-${fieldGroupName}-${fieldRowIndex}`}
                {...fieldGroupDetails[fieldGroupName]}
              >
                {repeatableFieldGroup}
              </FieldGroupDetails>
            ) : (
              repeatableFieldGroup
            )
          }

          return (
            <FieldGroup
              key={`field-row-${fieldRowIndex}`}
              fields={fields}
              state={state}
              onChange={onChange}
              requiredFields={requiredFields}
              errors={errors}
            />
          )
        },
      )}
    </div>
  )
}

export default FieldGroups
