import { expectTypeOf } from 'expect-type';
import {
  GeneratedAlways,
  Generated,
  Selectable,
  Insertable,
  ColumnType,
} from 'kysely';
import { z } from 'zod';
import { UserProfilePhones, userProfilePhonesSchema } from './phones';

export interface UserProfilesTable {
  id: GeneratedAlways<string>;
  userId: string | null;
  dob: ColumnType<Date | null, Date | string | null, Date | string | null>;
  farmerType: string | null;
  liveStock: string | null;
  maritalStatus: string | null;
  phones: UserProfilePhones;
  ssn: string | null;
  updatedAt: Generated<Date>;
  createdAt: Generated<Date>;
  deletedAt: Date | null;
}

export type DbUserProfile = Selectable<UserProfilesTable>;
export type DbUserProfileInsert = Insertable<UserProfilesTable>;

export const dbUserProfileSchema = z.object({
  id: z.string().uuid(),
  userId: z.string().nullable(),
  dob: z.coerce.date().nullable(),
  farmerType: z.string().nullable(),
  liveStock: z.string().nullable(),
  maritalStatus: z.string().nullable(),
  phones: userProfilePhonesSchema,
  ssn: z.string().nullable(),
  updatedAt: z.coerce.date(),
  createdAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
});

// Remove sensitive fields from user profile
export const safeDbUserProfileSchema = dbUserProfileSchema.omit({ ssn: true });
export type SafeDbUserProfile = Selectable<
  z.infer<typeof safeDbUserProfileSchema>
>;

// Verify derived type matches kysely
expectTypeOf<DbUserProfile>().toEqualTypeOf<
  z.infer<typeof dbUserProfileSchema>
>();
