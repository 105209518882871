import { z } from 'zod';

export const policyProducerEventReqSchema = z.object({
  aipPolicyProducerKey: z.string().refine((val) => val.trim() !== '', {
    message: 'Cannot be empty or whitespace',
  }),
  reinsuranceYear: z.number().int(),
});
export type PolicyProducerEventReq = z.infer<
  typeof policyProducerEventReqSchema
>;

export const policyProducerEventReqHeadersSchema = z.object({
  authorization: z.string(),
});
