import { COMMODITY_CATTLE } from './commodity'
import { isNullOrUndefined } from './validations'

const tooltipTitleMap = {
  endingValue: ['Expected Ending Value', 'Actual Ending Value'],
  indemnity: ['Projected Indemnity', 'Actual Indemnity'],
  grossMargin: ['Projected Gross Margin', 'Actual Gross Margin'],
}

export const getToolTipTitle = (key, value) =>
  tooltipTitleMap[key]?.[+!isNullOrUndefined(value)] // value to index

export const agentSignedDateRequiredMessage =
  'Agent sign date is required for submission. It has been set to the date of AIP submission.'
