import { Typography } from '@mui/material'

export const ModalTitle = ({ title }) =>
  title && (
    <Typography
      variant="h4"
      className="flex flex-col width-100 mb1 title-container"
      data-cy="modal-title"
    >
      {title}
    </Typography>
  )
