import {
  ColumnType,
  GeneratedAlways,
  Insertable,
  Selectable,
  Updateable,
} from 'kysely';
import { z } from 'zod';
import { toZod } from 'tozod';

export interface AipIdentifierTable {
  id: GeneratedAlways<string>;
  aipSlug: string;
  aipInternalId: string;
  aipType: string;
  entityId: string;
  entityType: string;
  createdAt: ColumnType<Date, never, never>;
  updatedAt: ColumnType<Date, never, Date>;
}

export type AipIdentifierRow = Selectable<AipIdentifierTable>;
export const aipIdentifierRowSchema: toZod<AipIdentifierRow> = z.object({
  id: z.string(),
  aipSlug: z.string(),
  aipInternalId: z.string(),
  aipType: z.string(),
  entityId: z.string().uuid(),
  entityType: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type InsertableAipIdentifierRow = Insertable<AipIdentifierTable>;
export const insertableAipIdentifierRowSchema: toZod<InsertableAipIdentifierRow> =
  z.object({
    aipSlug: z.string(),
    aipInternalId: z.string(),
    aipType: z.string(),
    entityId: z.string().uuid(),
    entityType: z.string(),
  });

export type UpdateableAipIdentifierRow = Updateable<AipIdentifierTable>;
export const updateableAipIdentifierRowSchema: toZod<UpdateableAipIdentifierRow> =
  z.object({
    aipSlug: z.string().optional(),
    aipInternalId: z.string().optional(),
    aipType: z.string().optional(),
    entityId: z.string().uuid().optional(),
    entityType: z.string().optional(),
    updatedAt: z.date().optional(),
  });
