import { legacySequelizeUserSchema } from './shared';
import { z } from 'zod';

export const getUserRequestSchema = z.object({
  search: z.string(),
});
export type GetUserRequest = z.infer<typeof getUserRequestSchema>;

export const getUserResponseSchema = z.object({
  data: z.object({
    users: z.array(
      legacySequelizeUserSchema.omit({ profile: true }).optional()
    ),
  }),
});
export type GetUserResponse = z.infer<typeof getUserResponseSchema>;

export const getUserByIdResponseSchema = z.object({
  user: legacySequelizeUserSchema,
});
export type GetUserByIdResponse = z.infer<typeof getUserByIdResponseSchema>;
