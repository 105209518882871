import { z } from 'zod';

export const mediaMetaDataSchema = z.object({
  encoding: z.string().optional(),
  // Incorrect casing matches DB
  fieldname: z.string().optional(),
  filename: z.string().optional(),
  mimetype: z.string().optional(),
  originalname: z.string().optional(),
  size: z.number().optional(),
});
export type MediaMetaData = z.infer<typeof mediaMetaDataSchema>;
