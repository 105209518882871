import { jwtScopeAccessLevels, jwtScopeEntities } from '@harvestiq/constants'

export const IS_DEV =
  process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test'
export const IS_TEST = process.env.NODE_ENV === 'test'
export const IS_PROD = process.env.NODE_ENV === 'production'
export const IS_DEBUG_PDF_ENABLED = IS_DEV && process.env.DEBUG_GENERATE_PDF
export const IS_DEBUG_XLSX_ENABLED = IS_DEV && process.env.DEBUG_GENERATE_XLSX

export const {
  ENABLE_STOCKGUARD_ADMIN = IS_DEV,
  AUTH_SERVICE_URL = 'http://localhost:8080',
  CDN_URL = 'https://cdn.stockguard.io',
  IS_E2E = false,
} = process.env

export const CLIENT_PORT = process.env.CLIENT_PORT ?? 3000
export const SERVER_PORT =
  process.env.REACT_APP_SERVER_PORT ??
  process.env.SERVER_PORT ??
  (IS_DEV ? 3001 : CLIENT_PORT)

// https://docs.datadoghq.com/real_user_monitoring/session_replay/browser/troubleshooting/#fonts-or-images-do-not-render-properly
export const DD_SESSION_REPLAY_DOMAIN = 'https://session-replay-datadoghq.com'
export const TRUSTED_ORIGINS = [
  AUTH_SERVICE_URL,
  CDN_URL,
  DD_SESSION_REPLAY_DOMAIN,
].filter(Boolean)

export const APP_NAME = process.env.APP_NAME || 'HarvestIQ'
export const APP_LOGO =
  process.env.APP_LOGO ||
  'https://storage.googleapis.com/cdn-stockguard-io/branding/harvestiq/harvestiq-logo-inline.png'
export const APP_LOGO_COMPACT =
  process.env.APP_LOGO_COMPACT ||
  'https://storage.googleapis.com/cdn-stockguard-io/branding/harvestiq/hiq-logo-compact.svg'

export const stockguardDomain = 'stockguard.io'
export const harvestiqDomain = 'harvestiq.com'
export const harvestiqDevDomain = 'harvestiq.dev'

export const isAuthProxyEnabled = process.env.ENABLE_AUTH_SERVICE || IS_PROD

const apiVersion = 'v1'
export const apiRoot = `/api/${apiVersion}`
export const uuidRegexp =
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

export const passwordPolicy = [
  {
    regex: /.{8,}/,
    text: 'Must have a minimum of 8 characters',
    isSatisfied: false,
  },
  {
    regex: /[a-z]/,
    text: 'Must include at least one lower case character',
    isSatisfied: false,
  },
  {
    regex: /[A-Z]/,
    text: 'Must include at least one upper case character',
    isSatisfied: false,
  },
  {
    regex: /[0-9]/,
    text: 'Must include at least one number',
    isSatisfied: false,
  },
  {
    // eslint-disable-next-line no-useless-escape
    regex: /[`~!@#$%^&*()_=+{}[\]\|\-;:"\\'<>,.\?\/]/, // lint says unnecessary escape, but without scaping the regex is incorrect and not works
    text: 'Have at least one special character',
    isSatisfied: false,
  },
]

// NOTE: see @harvestiq/utils PHONE_NUMBER_REGEX
export const phoneRegexp =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/

export const phoneMask = '000-000-0000'
export const phoneLength = 10
export const einLength = 9
export const permissions = {
  PERMISSION_TO_ACCESS_LRP_QUOTE: 'ACCESS_LRP_QUOTE',
}

export const pdfInlineHeader = {
  ContentDisposition: 'inline',
  ContentType: 'application/pdf',
}

export const applicationS3Path = 'insurance/policies/apply/RMA'
export const rmaQuotesS3Path = 'insurance/quotes'

export const mediaTypeS3Path = {
  LGM: `${applicationS3Path}/LGM`,
  LRP: `${applicationS3Path}/LRP`,
  quotes: `${rmaQuotesS3Path}`,
}

export const gmt0timezone = 'Etc/GMT+0'
export const centralTimezone = 'America/Chicago'

// @todo move to external dictionary
export const textStrings = {
  emailNotAvailable: 'This email is not available.',
}

export const loginUnauthorizedMessage = 'Invalid email or password'
export const lgmSalesHelpText =
  'LGM is sold every Thursday when the latest data is published until 9:00 AM Central Time of the following day.'

export const marketIsClosedMsg =
  'Oops… the Product purchase window has closed. Click continue to notify an agent about your inquiry'
const minDate = new Date()
minDate.setTime(minDate.valueOf() - 100 * 365 * 24 * 60 * 60 * 1000) // hundred years
const maxDate = new Date()
maxDate.setTime(maxDate.valueOf() - 24 * 60 * 60 * 1000) // one day

export const defaultFilterValue = 'All'
export const emailTypes = ['personal', 'work']
export const phoneTypes = {
  home: 'home',
  work: 'work',
  mobile: 'mobile',
}
export const timeFormats = {
  shortHoursMinutesAmPmTz: 'h:mma z',
  shortHoursMinutesAmPm: 'h:mm a',
  hoursMinutesSeconds: 'hh:mm:ss',
}
export const debounceTimeout = 500
export const dateFormat = 'yyyy/MM/dd'
export const dateFormatTraditional = 'MM/dd/yy'
export const dateTimeFormatTraditional = `MM/dd/yy ${timeFormats.shortHoursMinutesAmPmTz}`
export const lrpDateFormat = 'yyyy-MM-dd'
export const lgmDateFormat = 'MMM YYYY'
export const lgmDateFormatFns = 'MMM yyyy'
export const breakevenDateFormat = 'MMM yy'
export const dateFormatShort = 'yyyyMMdd'
export const defaultTimeZone = 'Europe/London'
export const defaultDateTimeFormat = `yyyy/MM/dd ${timeFormats.hoursMinutesSeconds}`
export const breakevenCalculatorTooltipDateFormat = `${lrpDateFormat} ${timeFormats.shortHoursMinutesAmPm}`
export const minDateOfBirth = minDate
export const maxDateOfBirth = maxDate
export const SECOND = 1000
export const stockguardEmailAddress = `info@${stockguardDomain}`
export const harvestiqEmailAddress = `info@${harvestiqDomain}`
export const stockguardPhoneNumber = '5152725177'
export const harvestiqPhoneNumber = stockguardPhoneNumber
export const oneHourInSecs = 60 * 60 // 1 hour in seconds
export const saleWeightMultiplier = 0.98
export const spouseType = {
  applicant: 'applicant',
  nonApplicant: 'non-applicant',
}
export const livestockGroups = {
  lgm: ['Swine', 'Cattle'],
  lrp: ['Swine', 'Feeder Cattle', 'Fed Cattle'],
}
export const livestockClasses = [
  ...new Set(Object.values(livestockGroups).flat()),
]
export const policyTypes = { lrp: 'LRP', lgm: 'LGM' }
export const policyOwnerTypes = {
  individual: 'Individual',
  business: 'Business',
}

export const lgmCacheKeys = {
  lgmPremiumDataCacheKey: 'lgm:premium-data',
  lgmQuoteDataCacheKey: 'lgm:quote-data',
  lgmExpectedDataBySedCacheKey: 'lgm:sed-expected-data',
  lgmExpectedGrossMarginQueryCacheKey: 'lgm:expected-gross-margin-query',
  lgmExpectedGrossMarginResultCacheKey: 'lgm:expected-gross-margin-result',
  lgmGuaranteeDataCacheKey: 'lgm:guarantee-data',
  lgmSalesEffectiveDatesPastYearCacheKey: 'lgm:sales-effective-dates-past-year',
  lgmPricesCacheKey: 'lgm:prices',
  lgmVsCmeCacheKey: 'lgm:lgm-vs-cme-data',
}

export const lrpCacheKeys = {
  lrpOptionsDailyCacheKey: 'lrp:options-daily',
  lrpRateDailyCacheKey: 'lrp:rate-daily',
  lrpPlotDataCacheKey: 'lrp:plot-data',
  lrpSalesEffectiveDatesPastYearCacheKey: 'lrp:sales-effective-dates-past-year',
  lrpMarketingDates: 'lrp:commodity-marketing-dates',
  lrpRateDailyDownloadCacheKey: 'lrp:rate-daily-download',
}

export const modelNames = {
  apiKey: 'API_Key',
  appSetting: 'App_Setting',
  aipSettings: 'AipSettings', // Model is named "AipSettings", but the table is named "Aip_Settings"
  company: 'Company',
  coverage: 'Coverage',
  entityEvent: 'Entity_Event',
  entityLocation: 'Entity_Location',
  entityOwner: 'Entity_Owner',
  entityPermission: 'Entity_Permission',
  faq: 'Faq',
  location: 'Location',
  media: 'Media',
  permission: 'Permission',
  policy: 'Policy',
  policyOwner: 'Policy_Owner',
  recordMedia: 'Record_Media',
  role: 'Role',
  signature: 'Signature',
  token: 'Token',
  user: 'User',
  userCompany: 'User_Company',
  userProfile: 'User_Profile',
  userRole: 'User_Role',
}

export const currentYear = new Date().getFullYear()
export const longDateTimeFormat = 'PPP pp'

export const testUserEmail = 'test-user@stockguard.io'

export const monthIndicesByShortNames = {
  Jan: 0,
  Feb: 1,
  Mar: 2,
  Apr: 3,
  May: 4,
  Jun: 5,
  Jul: 6,
  Aug: 7,
  Sep: 8,
  Oct: 9,
  Nov: 10,
  Dec: 11,
}

export const paginationDefault = {
  page: 0,
  pageSize: 10,
}

export const orderTypes = {
  asc: 'asc',
  desc: 'desc',
}

export const jwtExpiresInSeconds = oneHourInSecs * 24

export const defaultJwtScopes = [
  // Give explicit read and write access only the endpoints needed by 3rd party partners
  `${jwtScopeAccessLevels.read}:${jwtScopeEntities.quotes}`,
  `${jwtScopeAccessLevels.write}:${jwtScopeEntities.aipEvents}`,
]

export const referrerCookieName = 'sgref'
const techSupportUsername = ['tech-support']
const serviceAccountUsername = ['service-account']
const devTeamUsernames = [
  'eric',
  'josh',
  'jessica',
  'todd',
  'andrews',
  'nimesh',
]
const adminTeamUsernames = [
  'dakota',
  'nic',
  'euweng',
  'kierste',
  'rowan',
  'support',
]
export const defaultAdminEmailsWhitelist = [
  ...adminTeamUsernames,
  ...devTeamUsernames,
  ...techSupportUsername,
].map(username => {
  return `${username}@${harvestiqDomain}`
})

const serviceAccountUsernames = IS_DEV
  ? serviceAccountUsername.concat(devTeamUsernames)
  : serviceAccountUsername
const techSupportUsernames = IS_DEV
  ? techSupportUsername.concat(devTeamUsernames)
  : techSupportUsername

export const serviceAccountEmails = serviceAccountUsernames.map(
  username => `${username}@${harvestiqDomain}`,
)
// Admin Import
export const techSupportEmails = techSupportUsernames.map(
  username => `${username}@${harvestiqDomain}`,
)

/**
 * Third party emails allowed to access internal environments
 */
export const allowedInternalEnv3rdPartyEmails = [
  'nate.adamson@fmh.com',
  'madan@fmh.com',
  'rafeeq@fmh.com',
  'nicki@fmh.com',
  'hunter.hall@fmh.com',
  'lriggs@fmh.com',
  'mwares@fmh.com',
  'menzjoshk@fmh.com',
]

export const MultiSelectAll = 'All'
export const MultiSelectClear = 'Clear All'

export const isCI = () => {
  return !!process.env.CI
}

export const isTestRunner = () => {
  return !!process.env.JEST_WORKER_ID || !!process.env.VITEST_POOL_ID
}

export const isProduction = () => {
  return IS_PROD
}

export const isDevelopment = () => {
  return IS_DEV
}

export const getLogLevel = () => {
  return process.env.LOG_LEVEL || 'debug'
}
