import { z } from 'zod';
import { zIsoDate } from '@harvestiq/zod';

export const lgmQuoterSalesEffectiveDateResponseSchema = z.object({
  data: zIsoDate(),
});

export type LgmQuoterSalesEffectiveDateResponse = z.infer<
  typeof lgmQuoterSalesEffectiveDateResponseSchema
>;
