import {
  InsureIQLrpCommodityNames,
  InsureIQLrpCommodityTypeNames,
  validSgCommodityTypesByCommodityName,
} from '@harvestiq/constants';
import { z } from 'zod';
import { zIsoDate, zDecimalRequired } from '@harvestiq/zod';

export const lrpQuoteRequestSchema = z
  .object({
    commodity: z.nativeEnum(InsureIQLrpCommodityNames),
    commodityType: z.nativeEnum(InsureIQLrpCommodityTypeNames),
    marketingDate: zIsoDate(),
    salesEffectiveDate: zIsoDate(),
    numberOfHead: z.coerce.number().int().positive().optional(),
    targetWeight: z.coerce.number().positive().optional(),
  })
  .superRefine((val, ctx) => {
    if (
      !validSgCommodityTypesByCommodityName(val.commodity).includes(
        val.commodityType
      )
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Commodity Type ${val.commodityType} doesn't match commodity ${val.commodity}`,
      });
    }
  });

export type LrpQuoteRequest = z.infer<typeof lrpQuoteRequestSchema>;

export const lrpQuotesResponseSchema = z.object({
  // FIXME
  data: z.object({
    quotes: z
      .object({
        expectedEndingValue: zDecimalRequired(),
        coverageLevel: zDecimalRequired(),
        coveragePrice: zDecimalRequired(),
        producerPremium: zDecimalRequired(),
        savingsVsCmeTrade: zDecimalRequired(),
      })
      .array(),
    url: z.string().url(),
  }),
});

export type LrpQuotesResponse = z.infer<typeof lrpQuotesResponseSchema>;
