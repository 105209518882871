import { useState, useCallback, useMemo } from 'react'
import { DateRangePicker as ReactDateRangePicker } from 'react-date-range'
import { TextField, Popover, InputAdornment, IconButton } from '@mui/material'
import { addDays, getYear, startOfTomorrow } from 'date-fns'
import { CalendarToday, Clear } from '@mui/icons-material'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import {
  formatDateRange,
  dateFormat,
  parseDateRange,
  stringifyDateRange,
} from 'common'

const dateRangeInDays = days => {
  const startDate = startOfTomorrow()
  const endDate = addDays(startDate, days - 1)
  return {
    startDate,
    endDate,
  }
}

const getThisYear = () => {
  const date = new Date()
  const year = getYear(date)

  return {
    startDate: new Date(year, 0, 1),
    endDate: new Date(year, 11, 31),
  }
}

const staticRangesOptions = {
  dateRange: [
    ...[7, 30, 60, 90].map(dayCount => ({
      label: `Next ${dayCount} days`,
      range: () => dateRangeInDays(dayCount),
      isSelected() {
        return false
      },
    })),
    {
      label: 'This Year',
      range: () => getThisYear(),
      isSelected() {
        return false
      },
    },
  ],
}

const defaultSelectionRange = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
}

export const DateRangePicker = ({
  label,
  name,
  value: valueProp,
  onChange,
  staticRanges = staticRangesOptions[name] || [],
  defaultValue = defaultSelectionRange,
  ...rest
}) => {
  const [value, setValue] = useState(parseDateRange(valueProp))
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClear = event => {
    event.stopPropagation()
    setValue(null)
    onChange({ target: { name, value: '' } })
  }

  const handleChange = useCallback(
    ({ selection, range1: range }) => {
      const combinedValues = { ...selection, ...range }
      const stringifiedDateRange = stringifyDateRange(combinedValues)
      setValue(combinedValues)
      onChange({ target: { name, value: stringifiedDateRange } })
    },
    [name, onChange],
  )

  const open = Boolean(anchorEl)
  const val = value ? [value] : [defaultValue]
  const { startDate, endDate } = val[0]
  const textFieldValue = value ? formatDateRange(startDate, endDate) : ''

  return (
    <>
      <TextField
        {...rest}
        label={label || name}
        value={textFieldValue}
        placeholder={`${dateFormat.toLowerCase()} - ${dateFormat.toLowerCase()}`}
        onClick={handleClick}
        fullWidth
        focused={open}
        data-cy={`picker-${name}`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarToday />
            </InputAdornment>
          ),
          endAdornment: value ? (
            <InputAdornment position="end">
              <IconButton onClick={handleClear}>
                <Clear />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ReactDateRangePicker
          ranges={val}
          inputRanges={[]}
          months={2}
          direction="horizontal"
          staticRanges={staticRanges}
          onChange={handleChange}
        />
      </Popover>
    </>
  )
}

export default DateRangePicker
