import { useMemo } from 'react'
import { Autocomplete, TextField, InputLabel, FormControl } from '@mui/material'
import Chip from '../Chip'
import { getChipColor } from '../../styles/theme'
import { useThemeManager } from '../../contexts'

const getUniqueValues = values =>
  values.filter(
    (value, index, self) =>
      self.findIndex(v => {
        const label = value?.label ?? value
        const vLabel = v?.label ?? v
        return vLabel === label
      }) === index,
  )

export const TagsInput = ({
  onAdd,
  onRemove,
  onClear,
  options,
  defaultValue,
  onChange,
  sx = {},
  name,
  placeholder,
  error,
  helperText,
  disableColor,
  label,
  InputProps: _,
  value: valueProp,
  ...restProps
}) => {
  const { theme } = useThemeManager()

  const chipColorMap = useMemo(() => {
    return options.reduce((acc, option) => {
      acc[option?.label ?? option] = getChipColor(acc.length)
      return acc
    }, {})
  }, [options])

  const value = useMemo(() => {
    if (typeof valueProp === 'string') {
      return []
    }
    return valueProp
  }, [valueProp])

  return (
    <FormControl variant="outlined" className="flex-1 width-100">
      {label && (
        <InputLabel shrink htmlFor={name} className="white-bg">
          {label}
        </InputLabel>
      )}
      <Autocomplete
        freeSolo
        multiple
        options={options}
        getOptionLabel={option => (option.label ? option.label : `${option}`)}
        defaultValue={defaultValue}
        onChange={(_evt, values, reason) => {
          const option = values.slice().pop()
          const uniqueValues = getUniqueValues(values)
          if (reason === 'selectOption' || reason === 'createOption') {
            onAdd?.(option, uniqueValues)
          }
          if (reason === 'clear') {
            onClear?.(uniqueValues)
          }
          const target = { name, value: uniqueValues }
          onChange?.({ target })
        }}
        renderTags={(val, getChipProps) => {
          return val.map((option, index) => {
            const tagLabel = option?.label ?? option
            const { onDelete, ...chipProps } = getChipProps({ index })
            return (
              <Chip
                label={tagLabel}
                onDelete={() => {
                  onRemove?.(option)
                  onDelete()
                }}
                size="small"
                key={`${name}-tag-${index}`}
                color={
                  disableColor
                    ? theme.palette.grey[300]
                    : chipColorMap[label] || getChipColor(index)
                }
                {...chipProps}
              />
            )
          })
        }}
        renderInput={({ InputProps, ...params }) => (
          <TextField
            {...params}
            InputProps={InputProps}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
          />
        )}
        sx={{ minWidth: 250, ...sx }}
        value={value}
        {...restProps}
      />
    </FormControl>
  )
}
