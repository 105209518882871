import { useMemo } from 'react'
import { useUser } from '../contexts'

const filterItems = ({ user, items, hasRoles }) => {
  const filterItem = item => {
    const { children, accessRoles, ...processedItem } = item

    if (children) {
      processedItem.children = filterItems({ user, items: children, hasRoles })
    }

    if (accessRoles) {
      if (!user?.isConfirmed) {
        return false
      }

      return hasRoles(accessRoles) && processedItem
    }

    return processedItem
  }

  return items.reduce((acc, item) => {
    const filteredItem = filterItem(item)
    if (filteredItem) {
      acc.push(filteredItem)
    }
    return acc
  }, [])
}

export const useMenuItems = menuItems => {
  const { user, hasRoles } = useUser()

  return useMemo(
    () => filterItems({ user, items: menuItems, hasRoles }),
    [user, menuItems, hasRoles],
  )
}

export default useMenuItems
