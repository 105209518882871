import {
  InsureIQLgmCommodityTypeNames,
  insureiqLgmCommodities,
  InsurancePricesUom,
} from '@harvestiq/constants';
import { z } from 'zod';
import { zDecimalRequired, zIsoDate } from '@harvestiq/zod';

export const lgmPricesRequestSchema = z.object({
  finishingType: z.nativeEnum(InsureIQLgmCommodityTypeNames),
  salesEffectiveDate: zIsoDate(),
});

export type LgmPricesRequest = z.infer<typeof lgmPricesRequestSchema>;

const lgmPricesSchema = z
  .object({
    title: z.enum(insureiqLgmCommodities),
    unit: z.nativeEnum(InsurancePricesUom),
    marketingMonths: z
      .object({
        month: zIsoDate(),
        price: zDecimalRequired(),
      })
      .array(),
  })
  .array();

export const lgmPricesResponseSchema = z.object({
  data: z
    .object({
      expectedPricePerHead: lgmPricesSchema,
      expectedPricePerWeight: lgmPricesSchema,
    })
    .array(),
});

export type LgmPricesResponse = z.infer<typeof lgmPricesResponseSchema>;
