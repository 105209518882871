import {
  IconButton,
  InputAdornment,
  TextField,
  Toolbar as MuiToolbar,
  Tooltip,
  Typography,
  Button,
} from '@mui/material'
import { Clear as ClearIcon } from '@mui/icons-material'
import pluralize from 'pluralize'
import { useContext, useMemo } from 'react'
import { TableContext } from './context'

export const Toolbar = ({ children }) => {
  const {
    title,
    selectedRows,
    setSelectedRows,
    searchColumns,
    filterValue,
    setFilterValue,
    bulkActions = [],
  } = useContext(TableContext)

  const selectedCount = useMemo(() => selectedRows.size, [selectedRows])

  const tableTitle = useMemo(
    () => (selectedCount > 0 ? `${selectedCount} selected` : title),
    [selectedCount, title],
  )

  return (
    <MuiToolbar className="flex flex-row justify-between items-center p0">
      {children}
      {searchColumns && (
        <TextField
          label={`Filter ${pluralize(title)}`}
          value={filterValue}
          onChange={({ target: { value } }) => setFilterValue(value)}
          InputProps={{
            endAdornment: filterValue && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear input"
                  onClick={() => setFilterValue('')}
                  edge="end"
                >
                  <ClearIcon data-cy="ClearIcon" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}

      {tableTitle && (
        <Typography
          className="flex flex-1 justify-center items-center text-center"
          variant="body"
          id="tableTitle"
          component="div"
          sx={{ flexGrow: 1 }}
        >
          {tableTitle}
        </Typography>
      )}

      {bulkActions.map(
        (
          {
            label,
            tooltip = label,
            onClick,
            Icon,
            isEnabled = false,
            buttonType = 'icon',
          },
          index,
        ) => {
          const handleBulkAction = () => {
            onClick({
              selected: selectedRows,
              setSelected: setSelectedRows,
            })
          }
          return (
            <div key={`bulk-actions-${index}`}>
              {buttonType === 'button' ? (
                <Button
                  variant="contained"
                  startIcon={<Icon />}
                  onClick={handleBulkAction}
                  disabled={!selectedCount && !isEnabled}
                  className="ml1"
                >
                  {label}
                </Button>
              ) : (
                <Tooltip key={`${label}-${index}`} title={tooltip}>
                  <span>
                    <IconButton
                      onClick={handleBulkAction}
                      disabled={!selectedCount && !isEnabled}
                    >
                      <Icon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </div>
          )
        },
      )}
    </MuiToolbar>
  )
}
