import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import DraftsIcon from '@mui/icons-material/Drafts'

import { formatPhone, currentYear, pages } from 'common'
import EmailLink from '../EmailLink'
import { useAppSettings } from '../../contexts'
import styles from './Footer.module.scss'
import { Logo } from '../Logo'

const Footer = () => {
  const {
    appSettings: { compactLogo, name, phone, email },
  } = useAppSettings()

  return (
    <footer className={styles.footer}>
      <div className={styles.footerReserved}>
        <Typography>© {currentYear} HarvestIQ, Inc.</Typography>
        <Typography>All Rights Reserved</Typography>
        <Link to={pages.termsAndConditions.path}>Terms of Service</Link>
      </div>
      <div className="hidden md:visible items-center">
        <Logo logoUrl={compactLogo} imgAlt={name} size={32} />
      </div>
      <div className={styles.footerContact}>
        {phone && (
          <Typography>
            <LocalPhoneIcon fontSize="inherit" data-cy="LocalPhoneIcon" />
            <a href={`tel:1${phone}`}>{formatPhone(phone)}</a>
          </Typography>
        )}
        {email && (
          <Typography>
            <DraftsIcon fontSize="inherit" data-cy="DraftsIcon" />{' '}
            <EmailLink email={email} />
          </Typography>
        )}
      </div>
    </footer>
  )
}

export default Footer
