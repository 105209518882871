import { useAppSettings } from '../../contexts'
import {
  BaseLoadingIndicator,
  loadingIndicatorTypeMap,
  loadingIndicatorTypes,
} from './BaseLoadingIndicator'

export const LoadingIndicator = ({ indicatorStyle, size = 48, color }: any) => {
  const {
    appSettings: { appSettingIndicatorStyle, appSettingColorScheme = {} },
  } = useAppSettings() as any

  return (
    <BaseLoadingIndicator
      indicatorStyle={indicatorStyle || appSettingIndicatorStyle}
      size={size}
      color={color || appSettingColorScheme.primary}
    ></BaseLoadingIndicator>
  )
}

export { loadingIndicatorTypes, loadingIndicatorTypeMap }
