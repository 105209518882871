import { createContext, useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from '../../hooks/useLocalStorage'

export const useProvideRedirect = () => {
  const { value, setValue, removeValue } = useLocalStorage('redirect')
  const navigate = useNavigate()

  const setRedirect = useCallback(
    redirectPath => {
      setValue(redirectPath)
    },
    [setValue],
  )

  const redirectOrNavigate = useCallback(
    (to, options) => {
      if (value) {
        navigate(value, { replace: true })
      } else if (to) {
        navigate(to, options)
      }
      removeValue()
    },
    [value, removeValue, navigate],
  )

  return { value, setRedirect, redirectOrNavigate }
}

const RedirectContext = createContext({})
const { Provider } = RedirectContext

export const RedirectProvider = ({ children }) => {
  const useRedirectContext = useProvideRedirect()
  return <Provider value={useRedirectContext}>{children}</Provider>
}

export const useRedirect = () => useContext(RedirectContext)
