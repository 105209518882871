import { InsureIQLgmCommodityTypeNames } from '../InsureIQLgmCommodityTypeNames';
import { InsureIQLrpCommodityTypeNames } from '../LrpInsurance';
import { RmaClassCodes } from './ClassCodes';
import { RmaCommodityCodes } from './CommodityCodes';
import { RmaCommodityTypeCodes } from './CommodityTypeCodes';

/**
 * See: RMA ADM A00540
 * @note WEIGHT_1 is lighter than WEIGHT_2
 * @deprecated Use RmaCommodityTypeCodes/RmaClassCodes/RmaSubclassCodes instead
 */
export enum RmaTypeCodes {
  // NOTE: RMA contains additional type codes that are not included here as they are not used
  FARROW_TO_FINISH = '804',
  FINISHING = '805',
  SEW_PIG_FINISHING = '806',
  CALF_FINISHING = '807',
  YEARLING_FINISHING = '808',
  STEERS_WEIGHT_1 = '809',
  STEERS_WEIGHT_2 = '810',
  HEIFERS_WEIGHT_1 = '811',
  HEIFERS_WEIGHT_2 = '812',
  BRAHMAN_WEIGHT_1 = '813',
  BRAHMAN_WEIGHT_2 = '814',
  DAIRY_WEIGHT_1 = '815',
  DAIRY_WEIGHT_2 = '816',
  UNBORN_STEERS_AND_HEIFERS = '817',
  UNBORN_BRAHMAN = '818',
  UNBORN_DAIRY = '819',
  STEERS_AND_HEIFERS = '820',
  UNBORN_SWINE = '821',
  NO_TYPE_SPECIFIED = '997',
}

export const getRmaTypeCodeFromIIQCommodityTypeName = (
  commodityTypeName: string
) => {
  switch (commodityTypeName) {
    // FEEDER CATTLE
    case InsureIQLrpCommodityTypeNames.STEERS_LIGHT:
      return RmaTypeCodes.STEERS_WEIGHT_1;
    case InsureIQLrpCommodityTypeNames.STEERS:
      return RmaTypeCodes.STEERS_WEIGHT_2;
    case InsureIQLrpCommodityTypeNames.HEIFERS_LIGHT:
      return RmaTypeCodes.HEIFERS_WEIGHT_1;
    case InsureIQLrpCommodityTypeNames.HEIFERS:
      return RmaTypeCodes.HEIFERS_WEIGHT_2;
    case InsureIQLrpCommodityTypeNames.BRAHMAN_LIGHT:
      return RmaTypeCodes.BRAHMAN_WEIGHT_1;
    case InsureIQLrpCommodityTypeNames.BRAHMAN:
      return RmaTypeCodes.BRAHMAN_WEIGHT_2;
    case InsureIQLrpCommodityTypeNames.DAIRY_LIGHT:
      return RmaTypeCodes.DAIRY_WEIGHT_1;
    case InsureIQLrpCommodityTypeNames.DAIRY:
      return RmaTypeCodes.DAIRY_WEIGHT_2;
    case InsureIQLrpCommodityTypeNames.UNBORN_STEERS_HEIFERS:
      return RmaTypeCodes.UNBORN_STEERS_AND_HEIFERS;
    case InsureIQLrpCommodityTypeNames.UNBORN_BRAHMAN:
      return RmaTypeCodes.UNBORN_BRAHMAN;
    case InsureIQLrpCommodityTypeNames.UNBORN_DAIRY:
      return RmaTypeCodes.UNBORN_DAIRY;

    // FED CATTLE
    case InsureIQLrpCommodityTypeNames.STEERS_HEIFERS:
      return RmaTypeCodes.STEERS_AND_HEIFERS;

    // SWINE
    case InsureIQLrpCommodityTypeNames.UNBORN_SWINE:
      return RmaTypeCodes.UNBORN_SWINE;
    case InsureIQLrpCommodityTypeNames.UNKNOWN:
      return RmaTypeCodes.NO_TYPE_SPECIFIED;

    // LGM CATTLE
    case InsureIQLgmCommodityTypeNames.CALF_FINISHING:
      return RmaTypeCodes.CALF_FINISHING;
    case InsureIQLgmCommodityTypeNames.YEARLING_FINISHING:
      return RmaTypeCodes.YEARLING_FINISHING;

    // LGM SWINE
    case InsureIQLgmCommodityTypeNames.FARROW_TO_FINISH:
      return RmaTypeCodes.FARROW_TO_FINISH;
    case InsureIQLgmCommodityTypeNames.FINISHING_OPERATION:
      return RmaTypeCodes.FINISHING;
    case InsureIQLgmCommodityTypeNames.SEW_OPERATION:
      return RmaTypeCodes.SEW_PIG_FINISHING;

    default:
      throw new Error(`Commodity name ${commodityTypeName} is not supported`);
  }
};

// @note do the opposite of the above function
export const getIIQCommodityTypeNameFromRmaTypeCode = (
  commodityTypeCode: RmaTypeCodes | string
) => {
  switch (commodityTypeCode) {
    // FEEDER CATTLE
    case RmaTypeCodes.STEERS_WEIGHT_1:
      return InsureIQLrpCommodityTypeNames.STEERS_LIGHT;
    case RmaTypeCodes.STEERS_WEIGHT_2:
      return InsureIQLrpCommodityTypeNames.STEERS;
    case RmaTypeCodes.HEIFERS_WEIGHT_1:
      return InsureIQLrpCommodityTypeNames.HEIFERS_LIGHT;
    case RmaTypeCodes.HEIFERS_WEIGHT_2:
      return InsureIQLrpCommodityTypeNames.HEIFERS;
    case RmaTypeCodes.BRAHMAN_WEIGHT_1:
      return InsureIQLrpCommodityTypeNames.BRAHMAN_LIGHT;
    case RmaTypeCodes.BRAHMAN_WEIGHT_2:
      return InsureIQLrpCommodityTypeNames.BRAHMAN;
    case RmaTypeCodes.DAIRY_WEIGHT_1:
      return InsureIQLrpCommodityTypeNames.DAIRY_LIGHT;
    case RmaTypeCodes.DAIRY_WEIGHT_2:
      return InsureIQLrpCommodityTypeNames.DAIRY;
    case RmaTypeCodes.UNBORN_STEERS_AND_HEIFERS:
      return InsureIQLrpCommodityTypeNames.UNBORN_STEERS_HEIFERS;
    case RmaTypeCodes.UNBORN_BRAHMAN:
      return InsureIQLrpCommodityTypeNames.UNBORN_BRAHMAN;
    case RmaTypeCodes.UNBORN_DAIRY:
      return InsureIQLrpCommodityTypeNames.UNBORN_DAIRY;

    // FED CATTLE
    case RmaTypeCodes.STEERS_AND_HEIFERS:
      return InsureIQLrpCommodityTypeNames.STEERS_HEIFERS;

    // SWINE
    case RmaTypeCodes.UNBORN_SWINE:
      return InsureIQLrpCommodityTypeNames.UNBORN_SWINE;
    case RmaTypeCodes.NO_TYPE_SPECIFIED:
      return InsureIQLrpCommodityTypeNames.UNKNOWN;

    // LGM CATTLE
    case RmaTypeCodes.CALF_FINISHING:
      return InsureIQLgmCommodityTypeNames.CALF_FINISHING;
    case RmaTypeCodes.YEARLING_FINISHING:
      return InsureIQLgmCommodityTypeNames.YEARLING_FINISHING;

    // LGM SWINE
    case RmaTypeCodes.FARROW_TO_FINISH:
      return InsureIQLgmCommodityTypeNames.FARROW_TO_FINISH;
    case RmaTypeCodes.FINISHING:
      return InsureIQLgmCommodityTypeNames.FINISHING_OPERATION;
    case RmaTypeCodes.SEW_PIG_FINISHING:
      return InsureIQLgmCommodityTypeNames.SEW_OPERATION;

    default:
      throw new Error(
        `Commodity type code ${commodityTypeCode} is not supported`
      );
  }
};

/**
 * @deprecated Use commodityCode, commodityTypeCode, and classCode directly
 */
export function getLegacyLrpTypeCodeFromCommodityTypeCodeAndClass({
  commodityCode,
  commodityTypeCode,
  classCode,
}: {
  commodityCode: RmaCommodityCodes;
  commodityTypeCode: RmaCommodityTypeCodes;
  classCode: RmaClassCodes;
}): RmaTypeCodes {
  switch (commodityCode) {
    case RmaCommodityCodes.FED_CATTLE:
      // only selection for fed cattle
      return RmaTypeCodes.STEERS_AND_HEIFERS;
    case RmaCommodityCodes.FEEDER_CATTLE:
      switch (commodityTypeCode) {
        case RmaCommodityTypeCodes.Steers:
          if (classCode === RmaClassCodes.Weight1)
            return RmaTypeCodes.STEERS_WEIGHT_1;
          return RmaTypeCodes.STEERS_WEIGHT_2;
        case RmaCommodityTypeCodes.Heifers:
          if (classCode === RmaClassCodes.Weight1)
            return RmaTypeCodes.HEIFERS_WEIGHT_1;
          return RmaTypeCodes.HEIFERS_WEIGHT_2;
        case RmaCommodityTypeCodes.Brahman:
          if (classCode === RmaClassCodes.Unborn)
            return RmaTypeCodes.UNBORN_BRAHMAN;
          if (classCode === RmaClassCodes.Weight1)
            return RmaTypeCodes.BRAHMAN_WEIGHT_1;
          return RmaTypeCodes.BRAHMAN_WEIGHT_2;
        case RmaCommodityTypeCodes.Dairy:
          if (classCode === RmaClassCodes.Unborn)
            return RmaTypeCodes.UNBORN_DAIRY;
          if (classCode === RmaClassCodes.Weight1)
            return RmaTypeCodes.DAIRY_WEIGHT_1;
          return RmaTypeCodes.DAIRY_WEIGHT_2;
        case RmaCommodityTypeCodes.SteersAndHeifers:
          return RmaTypeCodes.UNBORN_STEERS_AND_HEIFERS;
      }
      break;
    case RmaCommodityCodes.SWINE:
      // commodityTypeCode is '997' for all swine
      if (classCode === RmaClassCodes.Unborn) return RmaTypeCodes.UNBORN_SWINE;
      return RmaTypeCodes.NO_TYPE_SPECIFIED;
  }
  throw new Error(
    `Unknown Legacy Type Code for commodity code ${commodityCode}, commodity type code ${commodityTypeCode}, and class code ${classCode}`
  );
}

export const getRmaCommodityCodeFromRmaTypeCode = (
  typeCode: RmaTypeCodes | string
) => {
  switch (typeCode) {
    // FEEDER CATTLE
    case RmaTypeCodes.STEERS_WEIGHT_1:
      return RmaCommodityCodes.FEEDER_CATTLE;
    case RmaTypeCodes.STEERS_WEIGHT_2:
      return RmaCommodityCodes.FEEDER_CATTLE;
    case RmaTypeCodes.HEIFERS_WEIGHT_1:
      return RmaCommodityCodes.FEEDER_CATTLE;
    case RmaTypeCodes.HEIFERS_WEIGHT_2:
      return RmaCommodityCodes.FEEDER_CATTLE;
    case RmaTypeCodes.BRAHMAN_WEIGHT_1:
      return RmaCommodityCodes.FEEDER_CATTLE;
    case RmaTypeCodes.BRAHMAN_WEIGHT_2:
      return RmaCommodityCodes.FEEDER_CATTLE;
    case RmaTypeCodes.DAIRY_WEIGHT_1:
      return RmaCommodityCodes.FEEDER_CATTLE;
    case RmaTypeCodes.DAIRY_WEIGHT_2:
      return RmaCommodityCodes.FEEDER_CATTLE;
    case RmaTypeCodes.UNBORN_STEERS_AND_HEIFERS:
      return RmaCommodityCodes.FEEDER_CATTLE;
    case RmaTypeCodes.UNBORN_BRAHMAN:
      return RmaCommodityCodes.FEEDER_CATTLE;
    case RmaTypeCodes.UNBORN_DAIRY:
      return RmaCommodityCodes.FEEDER_CATTLE;

    // FED CATTLE
    case RmaTypeCodes.STEERS_AND_HEIFERS:
      return RmaCommodityCodes.FED_CATTLE;

    // SWINE
    case RmaTypeCodes.UNBORN_SWINE:
      return RmaCommodityCodes.SWINE;
    case RmaTypeCodes.NO_TYPE_SPECIFIED:
      return RmaCommodityCodes.SWINE;

    // LGM CATTLE
    case RmaTypeCodes.CALF_FINISHING:
      return RmaCommodityCodes.CATTLE;
    case RmaTypeCodes.YEARLING_FINISHING:
      return RmaCommodityCodes.CATTLE;

    // LGM SWINE
    case RmaTypeCodes.FARROW_TO_FINISH:
      return RmaCommodityCodes.SWINE;
    case RmaTypeCodes.FINISHING:
      return RmaCommodityCodes.SWINE;
    case RmaTypeCodes.SEW_PIG_FINISHING:
      return RmaCommodityCodes.SWINE;

    default:
      throw new Error(`RMA type code ${typeCode} is not supported`);
  }
};
