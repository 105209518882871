import { useCallback } from 'react'
import { FormControl, InputLabel, TableContainer } from '@mui/material'
import { Table } from '../Table'

export const TableSelect = ({
  value = [],
  options = [],
  onChange,
  name,
  label = '',
  ...props
}) => {
  const handleChange = useCallback(
    selectedRows => {
      onChange({
        target: {
          name,
          value: Array.from(selectedRows.values()),
        },
      })
    },
    [onChange, name],
  )

  return (
    <FormControl variant="outlined" className="flex-1">
      {label && (
        <InputLabel shrink htmlFor={name}>
          {`${label} ${value.length}/${options.length}`}
        </InputLabel>
      )}
      <TableContainer>
        <Table
          rows={options}
          isSelectable={true}
          onSelectRow={handleChange}
          selectedRows={value}
          {...props}
        />
      </TableContainer>
    </FormControl>
  )
}

export default TableSelect
