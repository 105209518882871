// See: RMA https://pubfs-rma.fpac.usda.gov/pub/References/insurance_control_elements/PASS/2024/2024_D00005_IceTaxIDType_YTD.txt
export enum RmaTaxIdTypes {
  // Intentionally strings to match RMA
  SSN = '1',
  EIN = '2',
  RMAIssued = '3',
  BureauOfIndianAffairs = '5',
}

export const RmaTaxIdTypesNames = {
  [RmaTaxIdTypes.SSN]: 'SSN',
  [RmaTaxIdTypes.EIN]: 'EIN',
  [RmaTaxIdTypes.RMAIssued]: 'RMA Issued',
  [RmaTaxIdTypes.BureauOfIndianAffairs]: 'Bureau of Indian Affairs',
};
