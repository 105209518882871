import { z } from 'zod';

export const lrpPremiumEventReqSchema = z.object({
  aipLrpPremiumKey: z.string(),
  aipPolicyProducerKey: z.string().refine((val) => val.trim() !== '', {
    message: 'Cannot be empty or whitespace',
  }),
  reinsuranceYear: z.number().int(),
});
export type LrpPremiumEventReq = z.infer<typeof lrpPremiumEventReqSchema>;

export const lrpPremiumEventReqHeadersSchema = z.object({
  authorization: z.string(),
});
