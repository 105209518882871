import { Outlet, matchPath, useLocation } from 'react-router-dom'
import { pages } from 'common'
import Menu from '../Menu'

type MenuPage = {
  title: string
  path: string
}

type MenuItems = Record<string, MenuPage[]>

const menuItems: MenuItems = {
  quotes: [pages.lrpQuote, pages.lgmQuote],
  coverage: [pages.coverage, pages.watchlist],
  tools: [
    pages.breakevenCalculator,
    { ...pages.lgmQuoteScenarioAnalysis, title: 'LGM SCENARIO ANALYSIS' },
  ],
  more: [
    pages.faq,
    pages.contactUs,
    pages.apply,
    pages.applyBusiness,
    pages.applyIndividual,
  ],
}

// list of pages that should not be shown in the menu but must determine the active category
const blacklist = [pages.applyBusiness, pages.applyIndividual]

const MenuRoutes = () => {
  const { pathname } = useLocation()

  const getActiveCategory = (): string | undefined => {
    for (const [category, items] of Object.entries(menuItems)) {
      if (items.some(item => matchPath(pathname, item.path))) {
        return category
      }
    }
    return undefined
  }

  const activeCategory = getActiveCategory()

  const menuItemsFiltered = activeCategory
    ? menuItems[activeCategory].filter(item => !blacklist.includes(item))
    : []

  return (
    <div className="flex flex-1 flex-col width-100 flex-responsive">
      <div className="flex justify-between items-center gap-4">
        <div className="mb2 no-print">
          {activeCategory && (
            <Menu
              items={menuItemsFiltered}
              id={`${activeCategory}-menu`}
              className="flex gap-1"
              variant="outlined"
            />
          )}
        </div>
      </div>
      <Outlet />
    </div>
  )
}

export default MenuRoutes
