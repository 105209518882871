import React from 'react'
import { Button, Typography } from '@mui/material'

import { pages } from 'common'
import { Link } from 'react-router-dom'
import { useRedirect } from '../../contexts'

function ProtectedRoute({ user }) {
  const { setRedirect } = useRedirect()

  const currentPath = `${window.location.pathname}${window.location.search}${window.location.hash}`
  setRedirect(currentPath)

  let message
  if (user && !user.isConfirmed) {
    message = 'Please confirm your email address to access this content.'
  } else if (user) {
    message = 'Please contact an administrator if you feel this is an error.'
  } else {
    message = 'Please Sign In to view this page.'
  }

  return (
    <div className="flex flex-col items-center">
      <Typography variant="h3" className="mb3">
        {message}
      </Typography>

      {!user && (
        <div className="flex flex-col">
          <Button
            variant="contained"
            color="primary"
            component={Link}
            data-cy="protected-route-sign-in-btn"
            to={pages.signIn.path}
          >
            Sign In
          </Button>

          <Button
            className="mt3"
            variant="text"
            component={Link}
            to={pages.signUp.path}
          >
            Don&apos;t have an account?
          </Button>
          <Button component={Link} to={pages.signUp.path} variant="outlined">
            Sign Up Now
          </Button>
        </div>
      )}
    </div>
  )
}

export default ProtectedRoute
