import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useCookie } from 'react-use'
import { referrerCookieName } from 'common'

export const useReferrerCookie = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [_, setCookie, deleteCookie] = useCookie(referrerCookieName)

  const setReferrerCookie = useCallback(() => {
    const referrerQueryParam = searchParams.get(referrerCookieName)
    if (referrerQueryParam) {
      setCookie(referrerQueryParam, { expires: 90, sameSite: 'lax' })
      searchParams.delete(referrerCookieName)
      setSearchParams(searchParams, { replace: true })
    }
  }, [searchParams, setCookie, setSearchParams])

  const deleteReferrerCookie = useCallback(() => {
    deleteCookie()
  }, [deleteCookie])

  return { setReferrerCookie, deleteReferrerCookie }
}
