import { z } from 'zod';

import { zIsoDate } from '@harvestiq/zod';
import {
  InsureIQCommodityNames,
  InsureIQLrpCommodityTypeNames,
} from '@harvestiq/constants';

export const getMarketingDatesRequestSchema = z.object({
  salesEffectiveDate: zIsoDate().optional(),
  commodity: z.nativeEnum(InsureIQCommodityNames),
  commodityType: z.nativeEnum(InsureIQLrpCommodityTypeNames),
});

export type GetMarketingDatesRequest = z.infer<
  typeof getMarketingDatesRequestSchema
>;
