/**
 * See RMA ADM A00410
 */
export enum RmaClassCodes {
  Weight1 = '031',
  Weight2 = '032',
  Unborn = '033',
  NoClassSpecified = '997',
  // below are not used for livestock
  FieldGrown = '007',
  Native = '012',
  Hybrid = '016',
  Fall = '019',
  NonStorage = '020',
  Scotch = '024',
  Spring = '053',
  Winter = '091',
}
