import { phoneSchema, profileSchema } from './shared';
import { z } from 'zod';

export const updateUserProfileRequestSchema = z.object({
  phones: z.array(phoneSchema).optional(),
});
export type UpdateUserProfileRequest = z.infer<
  typeof updateUserProfileRequestSchema
>;

export const updateUserProfileResponseSchema = profileSchema;
export type UpdateUserProfileResponse = z.infer<
  typeof updateUserProfileResponseSchema
>;
