import * as React from 'react'
import TextField from '@mui/material/TextField'
import { isDecimal, noop } from 'common'
import { InputAdornment } from '@mui/material'

export function Percent({ value: valueProp, onChange = noop, ...props }) {
  const [value, setValue] = React.useState(valueProp)

  const handleChange = event => {
    const numVal = !event.target.value ? 0 : +event.target.value
    if (
      (event.target.value && !isDecimal(event.target.value)) ||
      numVal > 100 ||
      numVal < 0
    ) {
      return null
    }
    const newValue = numVal === 100 ? '100' : event.target.value // max value reached, no reason to keep decimals
    setValue(newValue)
    onChange(event)
  }

  return (
    <TextField
      value={value}
      onChange={handleChange}
      InputProps={{
        type: 'number',
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
        min: 0,
        max: 100,
      }}
      {...props}
    />
  )
}
