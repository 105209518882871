import { useCallback, useState, useMemo } from 'react'
import { FormControl, FormLabel } from '@mui/material'
import classNames from 'classnames'
import { noop, toTitleCase } from 'common'
import { countyFipsCodes } from '@harvestiq/constants'
import Field from '../Field'

const generateFieldLabel = (labelPrefix, label) => {
  if (labelPrefix) {
    return `${labelPrefix} ${label}`
  }
  return label
}

export const AddressGroup = ({
  onChange = noop,
  name: nameProp,
  value: valueProp,
  label = toTitleCase(nameProp),
  enabledFields = [
    'addressLine1',
    'addressLine2',
    'city',
    'county',
    'state',
    'zip',
  ],
  labelPrefix = '',
  labelProps,
  disabled = false,
}) => {
  const [state, setState] = useState(valueProp)

  const handleChange = useCallback(
    evt => {
      const {
        target: { name, value },
      } = evt
      setState(prevState => ({
        ...prevState,
        [name]: value,
        ...(name === 'state' && { county: '' }),
      }))
      onChange({
        target: {
          name: nameProp,
          value: {
            ...valueProp,
            [name]: value,
            ...(name === 'state' && { county: '' }),
          },
        },
      })
    },
    [nameProp, valueProp, onChange, setState],
  )

  const countyOptions = useMemo(() => {
    if (!countyFipsCodes[state.state]) {
      return []
    }
    const options = Object.keys(countyFipsCodes[state.state]).reduce(
      (acc, county) => {
        acc.push({ value: county, label: county })
        return acc
      },
      [],
    )
    return options
  }, [state])

  return (
    <FormControl
      component="fieldset"
      className={classNames('flex flex-1 gap-1 flex-responsive')}
    >
      {label && (
        <FormLabel {...labelProps} component="legend">
          {label}
        </FormLabel>
      )}

      <div className={classNames('flex flex-row flex-1 width-100')}>
        {enabledFields.includes('addressLine1') && (
          <Field
            className="mt1"
            type="text"
            value={state.line1}
            name="addressLine1"
            label={generateFieldLabel(labelPrefix, 'Address Line 1')}
            onChange={handleChange}
            disabled={disabled}
          />
        )}
        {enabledFields.includes('addressLine2') && (
          <Field
            type="text"
            value={state.line2}
            name="addressLine2"
            label={generateFieldLabel(labelPrefix, 'Address Line 2')}
            onChange={handleChange}
            disabled={disabled}
          />
        )}
        {enabledFields.includes('city') && (
          <Field
            className="mb1"
            type="text"
            value={state.city}
            name="city"
            label={generateFieldLabel(labelPrefix, 'City')}
            onChange={handleChange}
            disabled={disabled}
          />
        )}
      </div>
      <div className={classNames('flex flex-row flex-1 gap-1')}>
        {enabledFields.includes('state') && (
          <Field
            type="select"
            value={state.state}
            name="state"
            label={generateFieldLabel(labelPrefix, 'State')}
            fullWidth
            sx={{ minWidth: 180 }}
            onChange={handleChange}
            disabled={disabled}
          />
        )}
        {enabledFields.includes('county') && (
          <Field
            type="select"
            value={state.county}
            name="county"
            label={generateFieldLabel(labelPrefix, 'County')}
            fullWidth
            sx={{ minWidth: 180 }}
            onChange={handleChange}
            options={countyOptions}
            disabled={!countyOptions.length || disabled}
          />
        )}
        {enabledFields.includes('zip') && (
          <Field
            type="zip"
            value={state.zip}
            name="zip"
            label={generateFieldLabel(labelPrefix, 'Zip Code')}
            fullWidth
            onChange={handleChange}
            disabled={disabled}
          />
        )}
      </div>
    </FormControl>
  )
}
