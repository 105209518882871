import { TableCell } from '@mui/material'
import { getVal } from 'common'

export const Cell = ({
  row,
  column: { field, id, render },
  cellFormatter,
  actions = {},
}) => {
  const dataKey = field || id
  const cellValue = getVal(dataKey, row)
  const formattedCellValue = cellFormatter?.(row, actions) || cellValue
  return (
    <TableCell data-cy={`table-cell-${dataKey}`}>
      {render?.(formattedCellValue, row, actions) || formattedCellValue}
    </TableCell>
  )
}
