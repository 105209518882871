import { createTheme } from '@mui/material/styles'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import { colorTypes, flatPalette } from 'common'

type ColorType = (typeof colorTypes)[number]

type ColorPalette = Record<string, string>

type FormattedPalette = {
  [key: string]: { main: string } | number
  contrastThreshold: number
}

const defaultContrastThreshold = 2.3

export const formatColorPalette = (
  colorPalette: ColorPalette = flatPalette,
): FormattedPalette =>
  colorTypes.reduce<FormattedPalette>(
    (acc, colorType: ColorType) => ({
      ...acc,
      [colorType]: {
        main: colorPalette[colorType],
      },
    }),
    { contrastThreshold: defaultContrastThreshold },
  )

export const palette = formatColorPalette(flatPalette)

const typography: TypographyOptions = {
  h1: {
    fontSize: '36px',
  },
  h2: {
    fontSize: '32px',
  },
  h3: {
    fontSize: '28px',
  },
  h4: {
    fontSize: '24px',
  },
  h5: {
    fontSize: '22px',
  },
  caption: {
    fontSize: '11px',
  },
}

// Create a theme
const theme = createTheme({
  palette,
  typography,
})

export const colorPickerColors: string[] = [
  '#525252',
  '#8F8F8F',
  '#E0E0E0',
  '#D85344',
  '#E08914',
  '#DEC414',
  '#C4C714',
  '#98C514',
  '#68B8B6',
  '#70C1E0',
  '#A095E0',
  '#DF95E0',
  '#3D3D3D',
  '#7B7B7B',
  '#B8B8B8',
  '#BD3C25',
  '#C97014',
  '#DEB114',
  '#A1AB14',
  '#68AB14',
  '#269898',
  '#1491C8',
  '#7764E0',
  '#DC34E0',
  '#141414',
  '#666666',
  '#A4A4A4',
  '#941814',
  '#B15514',
  '#DD9314',
  '#7B8214',
  '#28523D',
  '#1E7578',
  '#1463A2',
  '#653C8B',
  '#9D2493',
]

const chipColors: string[] = colorPickerColors.slice(15, -12)

export const getChipColor = (index: number): string =>
  chipColors[(index * 2) % chipColors.length]

export const alphabetPosition = (input: string): number =>
  parseInt(input, 36) - 10

export const prettyColor = (input: string): string =>
  chipColors[alphabetPosition(input) % chipColors.length]

const paletteKeys: string[] = [...colorTypes, 'grey', 'action']

export const setCSSVariables = (
  obj: Record<string, any>,
  rootName = 'palette',
  keys: string[] = paletteKeys,
): void => {
  keys.forEach(key => {
    const value = obj[key]
    const newRootName = rootName ? `${rootName}-${key}` : key

    if (typeof value === 'object') {
      return setCSSVariables(value, newRootName, Object.keys(value))
    }

    document.documentElement.style.setProperty(`--${newRootName}`, value)
  })
}

export default theme
