import { InsureIQCommodityNames } from '../InsuranceTypes';

// See: RMA ADM A00420 https://drive.google.com/file/d/1g7IMKdQqTPvdkEIjywzxCbimcJ4ibcYM/view
export enum RmaCommodityCodes {
  // NOTE: RMA contains additional commodity codes that are not included here as they are not used
  FEEDER_CATTLE = '0801',
  FED_CATTLE = '0802',
  CATTLE = '0803',
  SWINE = '0815',
  DAIRY_CATTLE = '0847',
}

export function isValidRmaCommodityCode(value: string): boolean {
  return Object.values(RmaCommodityCodes).includes(value as RmaCommodityCodes);
}

export const getRmaCommodityCodeFromIIQCommodityName = (
  commodityName: InsureIQCommodityNames | string
) => {
  switch (commodityName) {
    case InsureIQCommodityNames.FEEDER_CATTLE:
      return RmaCommodityCodes.FEEDER_CATTLE;
    case InsureIQCommodityNames.FED_CATTLE:
      return RmaCommodityCodes.FED_CATTLE;
    case InsureIQCommodityNames.CATTLE:
      return RmaCommodityCodes.CATTLE;
    case InsureIQCommodityNames.SWINE:
      return RmaCommodityCodes.SWINE;
    default:
      throw new Error(`Commodity name ${commodityName} is not supported`);
  }
};

export const getIIQCommodityNameFromRmaCommodityCode = (
  commodityCode: RmaCommodityCodes | string
) => {
  switch (commodityCode) {
    case RmaCommodityCodes.FEEDER_CATTLE:
      return InsureIQCommodityNames.FEEDER_CATTLE;
    case RmaCommodityCodes.FED_CATTLE:
      return InsureIQCommodityNames.FED_CATTLE;
    case RmaCommodityCodes.CATTLE:
      return InsureIQCommodityNames.CATTLE;
    case RmaCommodityCodes.SWINE:
      return InsureIQCommodityNames.SWINE;
    default:
      throw new Error(`Commodity code ${commodityCode} is not supported`);
  }
};
