import {
  InsureIQLrpCommodityNames,
  InsureIQLrpCommodityTypeNames,
  validSgCommodityTypesByCommodityName,
} from '@harvestiq/constants';
import { z } from 'zod';
import { zIsoDate } from '@harvestiq/zod';

export const lrpOptionsRequestSchema = z.object({
  commodity: z.nativeEnum(InsureIQLrpCommodityNames),
  marketingDate: zIsoDate(),
  optionType: z.string(),
  minCoveragePrice: z.coerce.number(),
  maxCoveragePrice: z.coerce.number(),
});

export type LrpOptionsRequest = z.infer<typeof lrpOptionsRequestSchema>;

export const lrpOptionsResponseSchema = z.object({
  data: z
    .object({
      commodity: z.nativeEnum(InsureIQLrpCommodityNames),
      commodityType: z.nativeEnum(InsureIQLrpCommodityTypeNames),
      marketingDates: z.string().array(),
      salesEffectiveDate: zIsoDate(),
    })
    .superRefine((val, ctx) => {
      if (
        !validSgCommodityTypesByCommodityName(val.commodity).includes(
          val.commodityType
        )
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Commodity Type ${val.commodityType} doesn't match commodity ${val.commodity}`,
        });
      }
    })
    .array(),
});

export type LrpOptionsResponse = z.infer<typeof lrpOptionsResponseSchema>;
