import { makeFullName } from '@harvestiq/utils';
import { Entity } from './entity';

type EntityValues = Pick<Entity, 'businessName' | 'firstName' | 'lastName'>;

export function getEntityName(entity: EntityValues | null | undefined) {
  if (!entity) {
    return '';
  }

  if (entity.businessName) {
    return entity.businessName;
  }

  return makeFullName(entity.firstName, entity.lastName);
}
