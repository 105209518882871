import { AipIds, aipIdsThatCanSync } from '@harvestiq/constants';
import { z } from 'zod';

export const syncAipReqSchema = z.object({
  aipId: z.nativeEnum(AipIds).refine((val) => aipIdsThatCanSync.includes(val), {
    message: `AIP ID must be one of ${aipIdsThatCanSync.join(', ')}`,
  }),
  reinsuranceYear: z.number().int(),
});
export type SyncAipReq = z.infer<typeof syncAipReqSchema>;
