import { ApplicationTypes } from '@harvestiq/constants';
import { z } from 'zod';

// Just for emails, not formal policy schema
export const emailPolicyDataSchema = z.object({
  data: z.object({
    applicationType: z.string(),
    business: z.string().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    insuredName: z.string().optional(),
  }),
});

export type EmailPolicyData = z.infer<typeof emailPolicyDataSchema>;

export function getEntityNameFromPolicy(policy: EmailPolicyData | undefined) {
  return policy
    ? policy.data.applicationType === ApplicationTypes.BUSINESS
      ? policy.data.insuredName
      : `${policy.data.firstName} ${policy.data.lastName}`
    : 'Unknown';
}
