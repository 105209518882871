import { forwardRef } from 'react'
import { IMaskInput } from 'react-imask'
import PropTypes from 'prop-types'

export const TextMask = forwardRef(function TextMask(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask={props.placeholder}
      inputRef={ref}
      onAccept={value => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})

TextMask.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
