import { InsureIQLgmCommodityTypeNames } from '@harvestiq/constants';
import { zDecimalRequired, zIsoDate } from '@harvestiq/zod';
import { z } from 'zod';
import { addLgmTargetSchemaValidation } from './helpers';

const lgmVsCmeRequestSchemaObj = z.object({
  deductible: z.coerce.number(),
  finishingType: z.nativeEnum(InsureIQLgmCommodityTypeNames, {
    message: 'Invalid finishing type',
  }),
  salesEffectiveDate: zIsoDate(),
  liveCattleTargetCwtPerHead: zDecimalRequired().optional(),
  feederCattleTargetCwtPerHead: zDecimalRequired().optional(),
  cornTargetBuPerHead: zDecimalRequired().optional(),
});

// /quoter/lgm-vs-cme
export const lgmVsCmeRequestSchema = addLgmTargetSchemaValidation({
  schema: lgmVsCmeRequestSchemaObj,
  lgmCommodityTypeNameField: 'finishingType',
});

// Using different schema than above to avoid zod issue with returning
// wrong type from refine see: https://github.com/colinhacks/zod/issues/2474
export type LgmVsCmeRequest = z.infer<typeof lgmVsCmeRequestSchemaObj>;
