export enum UnitOfMeasure {
  Bushel = 'bu',
  Pounds = 'lbs',
  Head = 'hd',
  ShortTon = 'ton', // 2,000 lbs
  LongTon = 'long ton', // 2,240 lbs
  HundredWeight = 'cwt', // 100 lbs
  MetricTon = 'tonne', // 1,000 kg, 2,204.6226 lbs
  //Bales = 'bales',
  Acres = 'ac',
}

export enum InsurancePricesUom {
  PerHead = '$/head',
  PerCwt = '$/cwt',
  PerBu = '$/bu',
  PerTon = '$/ton', // I assume this is short ton
}
