import { commodityTypesOrderedOptions } from 'common'

export const commodityTypeFeederCattle = Object.keys(
  commodityTypesOrderedOptions['Feeder Cattle'],
)

export const commodityTypeFedCattle = Object.keys(
  commodityTypesOrderedOptions['Fed Cattle'],
)

export const commodityTypeSwine = Object.keys(
  commodityTypesOrderedOptions['Swine'],
)
