import { z } from 'zod';

export const lgmPremiumEventReqSchema = z.object({
  aipLgmPremiumKey: z.string(),
  aipPolicyProducerKey: z.string().refine((val) => val.trim() !== '', {
    message: 'Cannot be empty or whitespace',
  }),
  reinsuranceYear: z.number().int(),
});
export type LgmPremiumEventReq = z.infer<typeof lgmPremiumEventReqSchema>;

export const lgmPremiumEventReqHeadersSchema = z.object({
  authorization: z.string(),
});
