import { z } from 'zod';
import { safeUserSchema } from '../users/user';
import { dbEntityHistorySchema } from './db-entity-history';

export const entityHistorySchema = dbEntityHistorySchema.extend({
  owner: safeUserSchema.nullable(),
  secondaryEntities: z.array(dbEntityHistorySchema),
});

export type EntityHistory = z.infer<typeof entityHistorySchema>;
