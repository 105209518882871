import { z } from 'zod';

export const deleteApiKeyRequestSchema = z.object({
  apiKeyId: z.string().uuid(),
});
export type DeleteApiKeyRequest = z.infer<typeof deleteApiKeyRequestSchema>;

export const deleteApiKeyResponseSchema = z.object({
  data: z.array(z.any()),
});

export const deleteApiKeyErrorResponseSchema = z.object({
  data: z.tuple([]),
  errors: z.array(z.object({ message: z.string() })),
});
