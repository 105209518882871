import { ReactComponent as Bars } from '../../assets/images/loading-indicators/bars.svg'
import { ReactComponent as Clock } from '../../assets/images/loading-indicators/clock.svg'
import { ReactComponent as Eclipse } from '../../assets/images/loading-indicators/eclipse.svg'
import { ReactComponent as Pulse } from '../../assets/images/loading-indicators/pulse.svg'
import { ReactComponent as Ring } from '../../assets/images/loading-indicators/ring.svg'

type LoadingIndicatorType = 'bars' | 'clock' | 'eclipse' | 'pulse' | 'ring'

type LoadingIndicatorTypeMap = Record<
  LoadingIndicatorType,
  React.FC<React.SVGProps<SVGSVGElement>>
>

export const loadingIndicatorTypeMap: LoadingIndicatorTypeMap = {
  bars: Bars,
  clock: Clock,
  eclipse: Eclipse,
  pulse: Pulse,
  ring: Ring,
}

export const loadingIndicatorTypes: LoadingIndicatorType[] = Object.keys(
  loadingIndicatorTypeMap,
) as LoadingIndicatorType[]

const defaultIndicatorStyle: LoadingIndicatorType = 'ring'

type BaseLoadingIndicatorProps = {
  indicatorStyle?: LoadingIndicatorType
  size?: number | string
  color?: string
}

// BaseLoadingIndicator should be used when we do not have appSettings loaded yet
export const BaseLoadingIndicator = ({
  indicatorStyle = defaultIndicatorStyle,
  size = 48,
  color,
}: BaseLoadingIndicatorProps) => {
  const Indicator = loadingIndicatorTypeMap[indicatorStyle]

  return (
    <span
      data-cy="loading-indicator"
      style={{
        width: size,
        height: size,
        color: color || 'currentColor',
      }}
    >
      <Indicator />
    </span>
  )
}
