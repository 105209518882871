import React, { useState } from 'react'
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material'
import { Menu as MenuIcon, ExpandLess, ExpandMore } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { slugify } from 'common'

export default function HeadMobileMenu({ items, id = 'mobile-menu' }) {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [activeSubmenu, setActiveSubmenu] = useState(null)
  const toggleDrawer = isDrawerOpen => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setIsOpen(isDrawerOpen)
  }

  const handleSubmenuClick = (evt, { onClick, ...item }) => {
    const { hasChildren, menuItemId, isExpanded, path } = item

    if (hasChildren) {
      setActiveSubmenu(isExpanded ? null : menuItemId)
    }

    if (path) {
      navigate({ pathname: path })
      setIsOpen(false)
    }
    onClick?.(evt, item)
  }

  const getLists = (menuItems, depth = 0) => {
    return (
      <List
        sx={{
          width: depth ? '100%' : 195,
          bgcolor: depth % 2 === 0 ? 'background.paper' : 'grey.100',
          color: depth % 2 === 0 ? 'primary.main' : 'text.primary',
        }}
        component="nav"
        className="flex flex-col flex-1"
      >
        {menuItems.map(item => {
          const {
            title,
            label = title,
            children,
            Icon,
            hasChildren = !!children?.length,
          } = item
          const menuItemId = `${id}-${slugify(label)}-${depth}`
          const isExpanded = activeSubmenu === menuItemId

          return (
            <div key={menuItemId}>
              <ListItemButton
                id={menuItemId}
                onClick={evt =>
                  handleSubmenuClick(evt, {
                    ...item,
                    menuItemId,
                    hasChildren,
                    isExpanded,
                  })
                }
              >
                {Icon && (
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                )}
                <ListItemText primary={label} />
                {hasChildren && (isExpanded ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
              {hasChildren && (
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  {getLists(children, depth + 1)}
                </Collapse>
              )}
            </div>
          )
        })}
      </List>
    )
  }

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        size="large"
        onClick={toggleDrawer(true)}
        id={id}
      >
        <MenuIcon data-cy="MenuIcon" />
      </IconButton>
      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
        {items && getLists(items)}
      </Drawer>
    </>
  )
}
