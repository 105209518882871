import { z } from 'zod';

import { InsureIQPolicyTypes } from '@harvestiq/constants';

export const importCoverageRequestSchema = z.object({
  file: z.string(),
  policyType: z.nativeEnum(InsureIQPolicyTypes),
});

export type ImportCoverageRequest = z.infer<typeof importCoverageRequestSchema>;
