import {
  CampaignFrequency,
  CampaignRecipientStatus,
  CampaignStatus,
  LivestockInsuranceTypes,
  RmaCommodityCodes,
  RmaLgmCommodityTypeCodes,
  RmaLrpCommodityTypeCodes,
  CampaignCoverageTier,
} from '@harvestiq/constants';
import {
  zCalendarDate,
  zDate,
  zDecimalNullable,
  zDecimalRequired,
} from '@harvestiq/zod';
import { z } from 'zod';
import { expectTypeOf } from 'expect-type';
import { Campaign, CampaignRow } from './Campaigns';

export const campaignSchema = z.object({
  id: z.string().uuid(),
  ownerId: z.string().uuid(),
  name: z.string(),
  status: z.nativeEnum(CampaignStatus),
  frequency: z.nativeEnum(CampaignFrequency),
  insuranceType: z.nativeEnum(LivestockInsuranceTypes),
  commodity: z.nativeEnum(RmaCommodityCodes),
  commodityType: z.union([
    z.nativeEnum(RmaLrpCommodityTypeCodes),
    z.nativeEnum(RmaLgmCommodityTypeCodes),
  ]),
  targetWeight: zDecimalNullable(),
  liveCattleTargetCwtPerHead: zDecimalRequired().nullable().optional(),
  feederCattleTargetCwtPerHead: zDecimalRequired().nullable().optional(),
  cornTargetBuPerHead: zDecimalRequired().nullable().optional(),
  numberOfHead: z.number().int().min(1).nullable(),
  marketingMonths: z.array(z.number().int().min(0).max(11)),
  deductibles: z.array(z.number().int().min(0).max(150)).nullable(), // TODO: validity check
  coverageTier: z.nativeEnum(CampaignCoverageTier).nullable(),
  endDate: zCalendarDate().nullable(),
  copyOwner: z.boolean().nullable(),
  emailNotes: z.string().nullable(),
  createdAt: zDate(),
  updatedAt: zDate(),
  deletedAt: zDate().nullable(),
});

expectTypeOf<CampaignRow>().toMatchTypeOf<z.output<typeof campaignSchema>>();

export const campaignRecipientsSchema = z.object({
  id: z.string().uuid(),
  campaignId: z.string().uuid(),
  userId: z.string().uuid(),
  status: z.nativeEnum(CampaignRecipientStatus),
  createdAt: zDate(),
  updatedAt: zDate(),
  deletedAt: zDate().nullable(),
});

export type CampaignRecipient = z.infer<typeof campaignRecipientsSchema>;

export const createCampaignRequestSchema = campaignSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
    targetWeight: true,
  })
  .extend({
    liveCattleTargetCwtPerHead: zDecimalNullable()
      .optional()
      .transform((x) => x?.toNumber()),
    feederCattleTargetCwtPerHead: zDecimalNullable()
      .optional()
      .transform((x) => x?.toNumber()),
    cornTargetBuPerHead: zDecimalNullable()
      .optional()
      .transform((x) => x?.toNumber()),
    targetWeight: zDecimalNullable()
      .optional()
      .transform((x) => x?.toNumber()),
    recipientIds: z.array(z.string().uuid()),
  });

export type CreateCampaignRequest = z.input<typeof createCampaignRequestSchema>;
export type CreateCampaignRequestOutput = z.output<
  typeof createCampaignRequestSchema
>;

export const updateCampaignRequestSchema = campaignSchema
  .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
    targetWeight: true,
  })
  .extend({
    liveCattleTargetCwtPerHead: zDecimalNullable()
      .optional()
      .transform((x) => x?.toNumber()),
    feederCattleTargetCwtPerHead: zDecimalNullable()
      .optional()
      .transform((x) => x?.toNumber()),
    cornTargetBuPerHead: zDecimalNullable()
      .optional()
      .transform((x) => x?.toNumber()),
    targetWeight: zDecimalNullable()
      .optional()
      .transform((x) => x?.toNumber()),
    recipientIds: z.array(z.string().uuid()),
  });

export type UpdateCampaignRequest = z.input<typeof updateCampaignRequestSchema>;
export type UpdateCampaignRequestOutput = z.output<
  typeof updateCampaignRequestSchema
>;

export const campaignResponseSchema = campaignSchema.extend({
  recipients: z.array(campaignRecipientsSchema),
});

export type CampaignResponse = z.output<typeof campaignResponseSchema>;
expectTypeOf<Campaign>().toMatchTypeOf<CampaignResponse>();

export const campaignListResponseSchema = z.array(campaignResponseSchema);

export type CampaignListResponse = z.output<typeof campaignListResponseSchema>;
