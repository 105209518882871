import { useCallback, useState, useEffect } from 'react'
import { useDebounce } from 'react-use'
import {
  Slider,
  SliderMarkLabel,
  SliderMark,
  TextField,
  Typography,
} from '@mui/material'
import classNames from 'classnames'

import { debounceTimeout } from 'common'
import classes from './Slider.module.scss'

const markLabelStyleMap = {
  '0%': { left: 0, transform: 'none' },
  '100%': { right: 0, transform: 'none' },
}

const getSlotComponent =
  Component =>
  ({ style, ...props }) =>
    <Component style={markLabelStyleMap[style.left] || style} {...props} />

export const SliderInput = ({
  name,
  label,
  value: valueProp = 0,
  onChange,
  withInput = false,
  format = val => val,
  error = false,
  helperText = '',
  ...sliderProps
}) => {
  const { min, max, step } = sliderProps
  const [val, setVal] = useState(valueProp)
  useEffect(() => {
    setVal(valueProp)
  }, [valueProp])

  useDebounce(
    () => onChange({ target: { name, value: val } }),
    debounceTimeout,
    [val],
  )

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const targetValue = parseFloat(value)
      const valIsInterger = Number.isInteger(targetValue)

      if (!valIsInterger && targetValue < min) {
        return
      }
      if (targetValue === max || targetValue < max) {
        setVal(targetValue)
      }
    },
    [max, min],
  )

  const handleBlur = useCallback(
    ({ target: { value } }) => {
      const targetValue = parseFloat(value)
      if (targetValue < min) {
        setVal(min)
      }
    },
    [min],
  )

  return (
    <div className={classNames('flex flex-col width-100', classes.inputSlider)}>
      <Typography variant="subtitle2" id="input-slider" gutterBottom>
        <strong>
          {label}
          {!withInput && `: ${format(val ?? min)}`}
        </strong>
      </Typography>
      <div className="flex items-center gap-2">
        {withInput && (
          <TextField
            error={error}
            type="number"
            sx={{ minWidth: 100 }}
            value={format(val || min)}
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{
              min,
              max,
              step,
            }}
          />
        )}
        <Slider
          name={name}
          value={val}
          onChange={handleChange}
          valueLabelDisplay="auto"
          slots={{
            markLabel: getSlotComponent(SliderMarkLabel),
            mark: getSlotComponent(SliderMark),
          }}
          {...sliderProps}
        />
      </div>
      {!!helperText && (
        <Typography
          variant="overline"
          className={classNames(
            classes.sliderHelpertext,
            error ? 'color-red' : classes.helpertextColor,
          )}
        >
          {helperText}
        </Typography>
      )}
    </div>
  )
}
