import { z } from 'zod';
import { FmhEndorsementLengthCode } from './constants';
import {
  commonFmhEndorsementSchema,
  fmhDocuSignSchema,
} from './endorsement-common';
import {
  RmaLrpCommodityCodes,
  RmaLrpCommodityTypeCodes,
} from '@harvestiq/constants';

export const fmhLrpEndorsementSchema = commonFmhEndorsementSchema.extend({
  commodityCode: z.nativeEnum(RmaLrpCommodityCodes),
  // FMH wants commodity type code as string
  commodityTypeCode: z.string().refine((val) => {
    return Object.values(RmaLrpCommodityTypeCodes).includes(parseInt(val, 10));
  }),
  endorsementLength: z.number().int().gt(0),
  endorsementLengthCodeId: z.nativeEnum(FmhEndorsementLengthCode),
  headCount: z.number().int().gt(0),
  targetWeightQuantity: z.number(),
  coveragePrice: z.number(),
});
export type FmhLrpEndorsement = z.infer<typeof fmhLrpEndorsementSchema>;

export const createFmhLrpEndorsementSchema = fmhLrpEndorsementSchema
  .pick({
    // Required null fields
    aipPremiumKey: true,
    aipInsuranceInForceKey: true,
    fmhProducerPartyKey: true,
    fmhAgentPartyKey: true,
    policyNumber: true,
    insuredSignatureDate: true,
    agentSignatureDate: true,
    endDate: true,
    premium: true,
    liability: true,
    status: true,
    // Required fields
    aipPolicyProducerKey: true,
    aipInsuranceAgentKey: true,
    reinsuranceYear: true,
    commodityCode: true,
    countyCode: true,
    commodityTypeCode: true,
    salesEffectiveDate: true,
    insuredSharePercent: true,
    endorsementLength: true,
    endorsementLengthCodeId: true,
    headCount: true,
    targetWeightQuantity: true,
    coveragePrice: true,
  })
  // FMH Requires that all optional fields be null instead of undefined
  .extend({
    aipPremiumKey: z.literal(null),
    aipInsuranceInForceKey: z.literal(null),
    fmhProducerPartyKey: z.literal(null),
    fmhAgentPartyKey: z.literal(null),
    policyNumber: z.literal(null),
    insuredSignatureDate: z.literal(null),
    agentSignatureDate: z.literal(null),
    endDate: z.literal(null),
    premium: z.literal(null),
    liability: z.literal(null),
    status: z.literal(null),
    targetMarketings: z.literal(null),
    deductible: z.literal(null),
    // DocuSign is only included for create requests
    docuSign: fmhDocuSignSchema,
  });
export type CreateFmhLrpEndorsement = z.infer<
  typeof createFmhLrpEndorsementSchema
>;
