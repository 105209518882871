import { z } from 'zod';
import { aipIdentifierSchema } from './aip-identifier';

export const getAipIdentifiersRequestSchema = aipIdentifierSchema
  .pick({
    aipSlug: true,
    aipInternalId: true,
    aipType: true,
    entityId: true,
    entityType: true,
  })
  .partial();

export type GetAipIdentifiersRequest = z.infer<
  typeof getAipIdentifiersRequestSchema
>;
