import { Outlet } from 'react-router-dom'

import Footer from '../Footer'
import Header from '../Header/Header'
import Main from '../Main/Main'
import { ErrorBoundary } from '../ErrorBoundary'

export default function Layout({ children }) {
  return (
    <>
      <Header />
      <div id="banner-portal" />
      <Main className="flex-1 items-center justify-center">
        <ErrorBoundary>
          {children}
          <Outlet />
        </ErrorBoundary>
      </Main>
      <Footer />
    </>
  )
}
