import { useEffect } from 'react'
import { noop } from 'common'
import { useModal, useUser } from '../contexts'
import CompleteProfileModal from '../components/modals/CompleteProfileModal'

export const useModalReminders = () => {
  const { user, getMe, isUserProfileComplete } = useUser()
  const { setModal } = useModal()

  useEffect(() => {
    if (user && !isUserProfileComplete) {
      setModal(<CompleteProfileModal user={user} onUserUpdated={getMe} />, {
        onClose: noop,
        disableEscapeKeyDown: true,
        hideCloseButton: true,
      })
    }
  }, [user, isUserProfileComplete, setModal, getMe])

  return null
}
