import {
  RmaClassCodes,
  RmaCommodityCodes,
  RmaCommodityTypeCodes,
  RmaInsurancePlanCodes,
  RmaSubclassCodes,
  RmaTypeCodes,
} from '@harvestiq/constants';
import { expectTypeOf } from 'expect-type';
import {
  ColumnType,
  GeneratedAlways,
  Insertable,
  Selectable,
  Updateable,
} from 'kysely';
import { z } from 'zod';

/**
 * Types taken from RMA ADM Layout
 * See: https://pubfs-rma.fpac.usda.gov/pub/References/actuarial_data_master/2025/ADMLayout.zip
 */
export interface DbLrpActualEndingValueDailyTable {
  id: GeneratedAlways<string>;
  createdAt: ColumnType<Date, never, never>;
  updatedAt: ColumnType<Date, never, Date>;
  recordTypeCode: ColumnType<string, string, string | null>;
  recordCategoryCode: ColumnType<string, string, string | null>;
  reinsuranceYear: ColumnType<number, number, number | null>;
  commodityYear: ColumnType<number, number, number | null>;
  commodityCode: ColumnType<
    RmaCommodityCodes,
    RmaCommodityCodes,
    RmaCommodityCodes | null
  >;
  insurancePlanCode: ColumnType<
    RmaInsurancePlanCodes,
    RmaInsurancePlanCodes,
    RmaInsurancePlanCodes | null
  >;
  stateCode: ColumnType<string, string, string | null>;
  countyCode: ColumnType<string, string, string | null>;
  typeCode: ColumnType<RmaTypeCodes, RmaTypeCodes, RmaTypeCodes | null>;
  practiceCode: ColumnType<string, string, string | null>;
  endDate: ColumnType<Date, Date, Date>;
  commodityTypeCode: ColumnType<
    RmaCommodityTypeCodes,
    RmaCommodityTypeCodes,
    RmaCommodityTypeCodes | null
  >;
  classCode: ColumnType<RmaClassCodes, RmaClassCodes, RmaClassCodes | null>;
  subClassCode: ColumnType<
    RmaSubclassCodes,
    RmaSubclassCodes,
    RmaSubclassCodes | null
  >;
  intendedUseCode: ColumnType<string, string, string | null>;
  irrigationPracticeCode: ColumnType<string, string, string | null>;
  croppingPracticeCode: ColumnType<string, string, string | null>;
  organicPracticeCode: ColumnType<string, string, string | null>;
  intervalCode: ColumnType<string, string, string | null>;
  actualEndingValueAmount: ColumnType<number, number, number | null>;
  lastReleasedDate: ColumnType<Date, Date, Date | null>;
  releasedDate: ColumnType<Date, Date, Date | null>;
  deletedDate: ColumnType<Date, Date, Date | null>;
  filingDate: ColumnType<Date, Date, Date | null>;
}

export type DbLrpActualEndingValueDaily =
  Selectable<DbLrpActualEndingValueDailyTable>;
export type DbLrpActualEndingValueDailyInsert =
  Insertable<DbLrpActualEndingValueDailyTable>;
export type DbLrpActualEndingValueDailyUpdate =
  Updateable<DbLrpActualEndingValueDailyTable>;

export const lrpActualEndingValueDailySchema = z.object({
  id: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  recordTypeCode: z.string(),
  recordCategoryCode: z.string(),
  reinsuranceYear: z.number(),
  commodityYear: z.number(),
  commodityCode: z.nativeEnum(RmaCommodityCodes),
  insurancePlanCode: z.nativeEnum(RmaInsurancePlanCodes),
  stateCode: z.string(),
  countyCode: z.string(),
  typeCode: z.nativeEnum(RmaTypeCodes),
  practiceCode: z.string(),
  endDate: z.date(),
  commodityTypeCode: z.nativeEnum(RmaCommodityTypeCodes),
  classCode: z.nativeEnum(RmaClassCodes),
  subClassCode: z.nativeEnum(RmaSubclassCodes),
  intendedUseCode: z.string(),
  irrigationPracticeCode: z.string(),
  croppingPracticeCode: z.string(),
  organicPracticeCode: z.string(),
  intervalCode: z.string(),
  actualEndingValueAmount: z.number(),
  lastReleasedDate: z.date(),
  releasedDate: z.date(),
  deletedDate: z.date(),
  filingDate: z.date(),
});

export const lrpActualEndingValueDailyInsertSchema =
  lrpActualEndingValueDailySchema.omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  });

export const lrpActualEndingValueDailyUpdateSchema =
  lrpActualEndingValueDailySchema
    .omit({
      id: true,
      createdAt: true,
    })
    .partial();

// Verify derived type matches kysely
expectTypeOf<DbLrpActualEndingValueDaily>().branded.toEqualTypeOf<
  z.infer<typeof lrpActualEndingValueDailySchema>
>();
