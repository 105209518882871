const waitTimeout = 300
const waitForElementTimeout = 100

export const wait = (ms = waitTimeout) =>
  new Promise(resolve => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout)
      return resolve()
    }, ms)
  })

export const waitForElement = (selector, maxRetries = 5) =>
  new Promise((resolve, reject) => {
    const checkForElement = () => {
      const element = document.querySelector(selector)
      if (element) {
        resolve(element)
      } else if (maxRetries > 0) {
        const timeout = setTimeout(() => {
          clearTimeout(timeout)
          checkForElement()
        }, waitForElementTimeout)
        maxRetries--
      } else {
        reject(
          new Error(
            `Element ${selector} not found after ${maxRetries} retries`,
          ),
        )
      }
    }
    checkForElement()
  })
