import { zPhoneNumber } from '@harvestiq/zod';
import { z } from 'zod';
import { legacySequelizeUserSchema } from './shared';

export const createUserRequestSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  phone: zPhoneNumber(),
  email: z.string().email(),
  confirmedAt: z.string().datetime().optional(),
  roles: z.array(z.string()).min(1),
});
export type CreateUserRequest = z.infer<typeof createUserRequestSchema>;

export const createUserResponseSchema = z.object({
  data: z.object({
    user: legacySequelizeUserSchema,
  }),
});
export type CreateUserResponse = z.infer<typeof createUserResponseSchema>;
