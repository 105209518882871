import Decimal from 'decimal.js'
import React, { useState, useMemo, useCallback } from 'react'
import { Autocomplete } from '@mui/material'
import { useDebounce } from 'react-use'

import { debounceTimeout, paginationDefault } from 'common'
import { useAutoCompleteValue, useAutoCompleteInfiniteQuery } from '../../hooks'
import { RenderOption as RenderOptionComponent } from '../RenderOption'
import { RenderInput as RenderInputComponent } from '../RenderInput'

// @todo improve types and remove any's
type ApiAutocompleteProps = {
  endpoint: string
  error?: boolean
  helperText?: string
  label: string
  name: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: any) => void
  onInputChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => void
  placeholder?: string
  query?: Record<string, any>
  RenderOption?: React.ComponentType<any>
  value?: any
  valueLabelMap?: { value: string; label: string }
  pageSize?: number
}

export const ApiAutocomplete = ({
  endpoint,
  error,
  helperText,
  label,
  name,
  onChange = () => null,
  onInputChange = () => null,
  placeholder = '',
  query = {},
  RenderOption = RenderOptionComponent,
  value: initialValue,
  valueLabelMap = { value: 'id', label: 'name' },
  pageSize = paginationDefault.pageSize,
  ...props
}: ApiAutocompleteProps) => {
  const [inputValue, setInputValue] = useState('')
  const [debouncedInputValue, setDebouncedInputValue] = useState('')
  const dataKey = useMemo(() => endpoint.split('/').pop() ?? '', [endpoint])

  const { getValue, getLabel } = useMemo(() => {
    return {
      getValue: (record: { value: any }) => record?.value[valueLabelMap.value],
      getLabel: (record: { value: any }) => record?.value[valueLabelMap.label],
    }
  }, [valueLabelMap])

  const {
    isLoading: isLoadingInitialValue,
    value,
    setValue,
  } = useAutoCompleteValue({
    entityId: initialValue,
    dataKey,
    labelKey: valueLabelMap.label,
    onChange: (newValue: { value: any }) => {
      const target = { name, value: getValue(newValue) }
      onChange(
        { target } as React.ChangeEvent<HTMLInputElement>,
        newValue?.value,
      )
    },
  })

  useDebounce(() => setDebouncedInputValue(inputValue), debounceTimeout, [
    inputValue,
  ])

  const { options, fetchNextPage, hasNextPage, isInitialLoading, isFetching } =
    useAutoCompleteInfiniteQuery({
      dataKey,
      endpoint,
      inputValue: debouncedInputValue,
      isEnabled: !!debouncedInputValue || !isLoadingInitialValue,
      labelKey: valueLabelMap.label,
      pageSize,
      query,
    })

  const handleIsOptionEqualToValue = useCallback(
    (option: { value: any }, record: { value: any }) =>
      (!!record && getValue(option) === getValue(record)) ||
      getLabel(option) === getLabel(record),
    [getValue, getLabel],
  )

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string,
  ) => {
    setInputValue(newInputValue)
    onInputChange(event as React.ChangeEvent<HTMLInputElement>, newInputValue)
  }

  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: { label: any; value: any } | null,
  ) => {
    setValue(newValue)
  }

  const handleListboxScroll = useCallback(
    async (event: React.UIEvent<HTMLElement>) => {
      if (!hasNextPage) {
        return
      }
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget

      // isBottom
      if (
        !isFetching && // prevent multiple fetches
        scrollTop !== 0 &&
        new Decimal(scrollHeight)
          .minus(clientHeight)
          .minus(Decimal.round(scrollTop))
          .eq(0)
      ) {
        await fetchNextPage()
      }
    },
    [isFetching, fetchNextPage, hasNextPage],
  )

  return (
    <Autocomplete
      id={`${name}-api-autocomplete`}
      inputValue={inputValue}
      options={options}
      loading={isInitialLoading}
      onInputChange={handleInputChange}
      onChange={handleChange}
      value={value}
      data-cy={`autocomplete-${name}`}
      renderOption={(optionProps, record) => (
        <RenderOption
          {...optionProps}
          key={optionProps.id}
          record={record?.value}
          label={getLabel(record)}
          data-cy="render-option"
        />
      )}
      ListboxProps={{
        onScroll: handleListboxScroll,
        role: 'list-box',
        className: 'm0 p0', // avoid calculation errors with scrollTop
      }}
      filterOptions={val => val}
      renderInput={renderInputProps => (
        <RenderInputComponent
          {...renderInputProps}
          error={error}
          helperText={helperText}
          label={label}
          placeholder={placeholder}
          isLoading={isInitialLoading}
        />
      )}
      isOptionEqualToValue={handleIsOptionEqualToValue}
      {...props}
    />
  )
}

export default ApiAutocomplete
