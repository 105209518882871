import { z } from 'zod';
import { sharedEndorsementSchema } from './shared';
import { lgmCoverageTotalValuesSchema } from './coverage-total-values';
import { dbLgmEndorsementSchema } from './db-lgm-endorsement';

export const lgmEndorsementSchema = dbLgmEndorsementSchema
  .merge(sharedEndorsementSchema)
  .extend({
    // Virtual fields that need to be moved to DB
    totalValues: lgmCoverageTotalValuesSchema.optional(),
  });

export type LgmEndorsement = z.infer<typeof lgmEndorsementSchema>;
