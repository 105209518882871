import { InsureIQCommodityNames } from '@harvestiq/constants';
import { z } from 'zod';

export const livestockPolicyTypesSchema = z.object({
  [InsureIQCommodityNames.FED_CATTLE]: z.boolean().optional(),
  [InsureIQCommodityNames.FEEDER_CATTLE]: z.boolean().optional(),
  [InsureIQCommodityNames.SWINE]: z.boolean().optional(),
  [InsureIQCommodityNames.CATTLE]: z.boolean().optional(),
});
export type LivestockPolicyTypes = z.infer<typeof livestockPolicyTypesSchema>;

export const livestockCapacitySchema = z.object({
  [InsureIQCommodityNames.FED_CATTLE]: z.number().optional(),
  [InsureIQCommodityNames.FEEDER_CATTLE]: z.number().optional(),
  [InsureIQCommodityNames.SWINE]: z.number().optional(),
  [InsureIQCommodityNames.CATTLE]: z.number().optional(),
});
export type LivestockCapacity = z.infer<typeof livestockCapacitySchema>;
