import { TextareaAutosize, Typography, FormHelperText } from '@mui/material'
import classNames from 'classnames'
import styles from './inputs.module.scss'

export const Textarea = ({
  className,
  helperText,
  error,
  fullWidth: _,
  ...props
}) => {
  return (
    <div className="flex flex-col width-100">
      <Typography variant="body2" component="label">
        {props.label}
      </Typography>
      <TextareaAutosize
        label="Textarea"
        className={classNames('p1', styles.Textarea, className)}
        {...props}
      />
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      {!!error && <FormHelperText>{error}</FormHelperText>}
    </div>
  )
}
