import { useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { paginationDefault, trimObj } from 'common'
import { api } from '../utils'

type Page = {
  records: any[]
  totalCount: number
}

function flatMapRecords(pages: Page[]) {
  const validPages = pages.filter(Boolean)
  return validPages.flatMap(({ records }) => records)
}

function parseRecords({ data, labelKey }: { data: any; labelKey: string }) {
  const pages = data?.data?.pages ?? data?.pages
  if (pages) {
    return flatMapRecords(pages).map(record => ({
      label: record[labelKey],
      value: record,
    }))
  }
  return []
}

type UseAutoCompleteInfiniteQueryProps<T> = {
  endpoint: string
  dataKey: keyof T
  inputValue: string
  query: Record<string, any>
  labelKey: string
  isEnabled?: boolean
  pageSize?: number
  cacheTime?: number
}

export function useAutoCompleteInfiniteQuery<T>({
  endpoint,
  dataKey,
  inputValue,
  query,
  labelKey,
  isEnabled = true,
  pageSize = paginationDefault.pageSize,
  cacheTime = 0,
}: UseAutoCompleteInfiniteQueryProps<T>) {
  const fetchRecords = async ({ pageParam = 0 }): Promise<Page> => {
    const { data } = await api
      .get<
        T & {
          totalCount: number
          data?: T & {
            totalCount: number
          }
        }
      >(
        endpoint,
        trimObj({
          ...query,
          search: inputValue,
          page: pageParam,
          pageSize,
        }),
      )
      .catch(error => {
        console.error(
          'Error fetching records for useAutoCompleteInfiniteQuery',
          error,
        )
        return { data: null }
      })

    if (!data) {
      console.error(
        'No data returned from API for useAutoCompleteInfiniteQuery',
      )
      return { records: [], totalCount: 0 }
    }

    // it handles generic results with different keys - e.g. { users: [] } or { companies: [] }
    const records = data[dataKey] as T[]

    if (!records) {
      // ApiResponse compatibility
      const dataRecords = data.data?.[dataKey] as T[]

      return {
        records: dataRecords,
        totalCount: data?.data?.totalCount ?? dataRecords.length,
      }
    }

    return { records, totalCount: data?.totalCount ?? records.length }
  }

  const { data, fetchNextPage, hasNextPage, isInitialLoading, isFetching } =
    useInfiniteQuery(
      [`${String(dataKey)}Search`, dataKey, inputValue],
      fetchRecords,
      {
        getNextPageParam: (lastPage: Page, allPages: Page[]) => {
          const allRecords = flatMapRecords(allPages)

          if (!lastPage || isNaN(lastPage?.totalCount)) {
            console.error(
              'Last Page not found. No totalCount returned from API.',
            )
            return false
          }

          const morePagesExist = lastPage.totalCount > allRecords.length

          return morePagesExist ? allPages.length : false
        },
        enabled: isEnabled,
        refetchOnWindowFocus: false,
        cacheTime,
      },
    )

  const options = useMemo(
    () => parseRecords({ data, labelKey }),
    [data, labelKey, dataKey],
  )

  return { options, fetchNextPage, hasNextPage, isInitialLoading, isFetching }
}
