import {
  FormControl,
  FormLabel,
  Typography,
  FormControlProps,
} from '@mui/material'

export type FieldGroupDetailsProps = FormControlProps & {
  description?: string
}

export const FieldGroupDetails = ({
  title,
  description,
  children,
  ...props
}: FieldGroupDetailsProps) => (
  <FormControl variant="filled" {...props}>
    <div className="mb1">
      {title && (
        <FormLabel component="legend" className="subheading">
          {title}
        </FormLabel>
      )}
      {description && (
        <Typography variant="body1" component="p">
          {description}
        </Typography>
      )}
    </div>
    {children}
  </FormControl>
)
