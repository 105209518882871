// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const updateCheckTimeout = 1000 * 60 * 10 // 10 minutes in milliseconds

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
)

function onFailure(error) {
  // See: https://stackoverflow.com/questions/63301353/service-worker-registeration-failed-when-indexed-in-google-search-console#comment111946777_63301353
  if (error.message === 'Rejected') {
    console.info(
      'Service worker registration was rejected, likely due to user being bot',
    )
  } else {
    console.error('Error during service worker registration:', error)
  }
}

const handleStateChange = registration => {
  const newWorker = registration.installing
  if (newWorker) {
    newWorker.addEventListener('statechange', () => {
      if (newWorker.state === 'installed') {
        console.log('New service worker is ready to skip waiting')
        newWorker.postMessage({ type: 'SKIP_WAITING' })
      }
    })
  }
}

setInterval(async () => {
  const registration = await navigator.serviceWorker.ready

  if (registration) {
    await registration.update()
    const newWorker = registration.waiting

    if (newWorker) {
      newWorker.postMessage({ type: 'SKIP_WAITING' })
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        // Check if the new service worker is active and controlling the page
        if (navigator.serviceWorker.controller.state === 'activated') {
          // Force a refresh
          window.location.reload(true)
        }
      })
    }
  }
}, updateCheckTimeout)

async function registerValidSW(swUrl) {
  const registration = await navigator.serviceWorker
    .register(swUrl)
    .catch(onFailure)

  if (!registration) {
    return
  }

  registration.addEventListener('updatefound', () =>
    handleStateChange(registration),
  )
}

function checkValidServiceWorker(swUrl) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type')
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        return navigator.serviceWorker.ready
          .then(registration => {
            return registration
              .unregister()
              .then(unregisterResult => {
                window.location.reload(true)
                return unregisterResult
              })
              .catch(onFailure)
          })
          .catch(onFailure)
      }
      // Service worker found. Proceed as normal.
      return registerValidSW(swUrl)
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.',
      )
    })
}

export function register() {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = '/service-worker.js'

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl)

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready
          .then(data => {
            console.log(
              'This web app is being served cache-first by a service ' +
                'worker. To learn more, visit https://cra.link/PWA',
            )
            return data
          })
          .catch(onFailure)
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl)
      }
    })
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        return registration.unregister()
      })
      .catch(error => {
        console.error(error.message)
      })
  }
}
