import { z } from 'zod';
import { zIsoDate } from '@harvestiq/zod';

export const salesEffectiveDatesRequestSchema = z.object({
  limit: z.number().optional(),
});

export type SalesEffectiveDatesRequest = z.infer<
  typeof salesEffectiveDatesRequestSchema
>;

export const salesEffectiveDatesResponseSchema = z.object({
  data: zIsoDate().array(),
});

export type SalesEffectiveDatesResponse = z.infer<
  typeof salesEffectiveDatesResponseSchema
>;
