import { z } from 'zod';
import { apiKeySchema } from './get-api-keys';

export const createApiKeyRequestSchema = z.object({
  userId: z.string().uuid(),
  enabled: z.boolean(),
  label: z.string().optional(),
});
export type CreateApiKeyRequest = z.infer<typeof createApiKeyRequestSchema>;

export const createApiKeyResponseSchema = z.object({
  data: z.array(apiKeySchema),
});

export const createApiKeyErrorResponseSchema = z.object({
  data: z.tuple([]),
  errors: z.array(z.object({ message: z.string() })),
});
