import { v4 } from 'uuid'
import { mediaTypeS3Path } from './constants'

export { slugify, makeFullName } from '@harvestiq/utils'

const toTitleCaseLowers =
  'a an and as at but by for for from in into near nor of on onto or the to with'
    .split(' ')
    .map(lower => `\\s${lower}\\s`)
const toTitleCaseRegex = new RegExp(
  `(?!${toTitleCaseLowers.join('|')})\\w\\S*`,
  'g',
)
const regexSpace = /\s+/g

export function toTitleCase(str: string): string {
  if (typeof str !== 'string') {
    return str
  }

  if (str.trim().match(regexSpace)) {
    return str
  }

  const newString = str.replace(
    toTitleCaseRegex,
    txt =>
      txt.charAt(0).toUpperCase() +
      txt.substr(1).replace(/[A-Z]/g, word => ` ${word}`),
  )
  return newString
}

export const uuid = v4
export const shortId = () => uuid().slice(0, 8)
const uuidChars = [
  ...new Set(
    [...[...Array(10)].map(() => uuid())].join('').replace(/-/gm, '').split(''),
  ),
].join('')

export const randomKeyLength = 8

export const randomKey = (length = randomKeyLength) =>
  [...Array(length)]
    .map(() => uuidChars.charAt(Math.floor(Math.random() * uuidChars.length)))
    .join('')

export const formatUuid = (str: string) => {
  const indexes = new Set([8, 12, 16, 20])
  return str
    .replace(/-/gm, '')
    .split('')
    .reduce((acc, cur, i) => {
      if (indexes.has(i)) {
        acc += '-'
      }
      acc += cur
      return acc
    }, '')
}

/**
 * convert a string to camelCase
 * @param  {String} str
 * @return {String}
 */
export const toCamelCase = (str: string) => {
  str = str.replace(/\s/g, '').replace(/[_-]+(\w)/g, (_m, w) => w.toUpperCase())
  return str.charAt(0).toLowerCase() + str.slice(1)
}
export const toPascalCase = (str: string) =>
  str
    .split('-')
    .map(tempStr => tempStr.charAt(0).toUpperCase() + tempStr.slice(1))
    .join('')

export const makeInitials = (name: string) => {
  return name
    .split(' ')
    .map(namePart => namePart.charAt(0))
    .join('')
}

export const strCapitalize = ([first, ...rest]: string[]) =>
  first.toUpperCase() + rest.join('').toLowerCase()

export const makeAddress = ({ address = '', number = '' }) => {
  if (!number) {
    return address
  }
  return `${number} ${address}`
}

// NOTE: see @harvestiq/utils removePhoneMask
export const removePhoneMask = (phoneNumber: string) =>
  phoneNumber.replaceAll('-', '')

export const makeS3FilePath = (
  type: 'LGM' | 'LRP' | 'quotes',
  mediaFile: string,
) => {
  const s3FilePath =
    type && mediaTypeS3Path[type]
      ? encodeURIComponent(`${mediaTypeS3Path[type]}/${mediaFile}`)
      : mediaFile
  return s3FilePath
}
