import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material'
import { useMemo } from 'react'

import {
  emailTypes,
  phoneTypes,
  livestockClasses,
  parseOptions,
  commodityTypesOrderedOptions,
  policyOwnerTypeOptions,
  abbreviationToStatesMap,
} from 'common'
import { businessTypes } from '@harvestiq/iiq/common'
import {
  PolicyStatuses,
  ProgramIndicatorDescriptions,
} from '@harvestiq/constants'
import {
  commodityTypeFedCattle,
  commodityTypeFeederCattle,
  commodityTypeSwine,
} from './options'

// Firefox doesn't support Intl timeZones yet
const commonUsTimeZones = [
  'America/Adak',
  'America/Anchorage',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/New_York',
  'America/Phoenix',
  'Pacific/Honolulu',
]

const intlUS = new Intl.Locale('en-US')
const usTimeZones =
  intlUS.timeZones || intlUS.getTimeZones?.() || commonUsTimeZones

const selectOptions = {
  suffix: ['Sr.', 'Jr.', 'I', 'II', 'III'],
  taxIdType: { SSN: 'SSN', EIN: 'EIN' },
  phoneType: Object.values(phoneTypes),
  emailType: emailTypes,
  state: abbreviationToStatesMap,
  billingState: () => selectOptions.state,
  stateOfIncorporation: () => selectOptions.state,
  questionType: ['Question about my policy', 'Question about my application'],
  commodity: Object.keys(commodityTypesOrderedOptions),
  feederCattle: commodityTypeFeederCattle,
  fedCattle: commodityTypeFedCattle,
  swine: commodityTypeSwine,
  businessType: businessTypes,
  entityType: () => [
    ...selectOptions.businessType,
    'Individuals',
    'Spousal/Married',
    'Transfer of Right to Indemnity (SBI only)',
  ],
  ownerType: policyOwnerTypeOptions,
  maritalStatus: ['Not Married', 'Married'],
  cmePosition: {
    shortCall: 'Short Call',
    shortPut: 'Short Put',
    longCall: 'Long Call',
  },
  status: [...Object.values(PolicyStatuses), 'All'],
  entityState: () => selectOptions.state,
  applicationTypeOption: Object.values(ProgramIndicatorDescriptions),
  applicationOwnerType: ['Farmer', 'Landlord'],
  isEighteen: ['yes', 'no'],
  livestock: livestockClasses,
  timezone: [
    { label: 'Default', value: '' },
    ...(usTimeZones.map(tz => ({
      label: tz,
      value: tz,
    })) ?? []),
  ], // if not mapping it displays the values with a bunch of spaces
}

export const getOptions = name => {
  const options =
    typeof selectOptions[name] === 'function'
      ? selectOptions[name](name)
      : selectOptions[name]
  return options || []
}

export const Select = ({
  name,
  label,
  value,
  isLoading = false,
  InputProps = {},
  InputLabelProps = {},
  options: optionsProp = getOptions(name),
  row: _ = undefined,
  ...selectProps
}) => {
  const inputProps = { ...InputProps }
  const inputLabelProps = { ...InputLabelProps }
  const options = useMemo(() => parseOptions(optionsProp), [optionsProp])

  if (isLoading) {
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <CircularProgress />
      </InputAdornment>
    )
  }

  return (
    <TextField
      select
      label={label}
      name={name}
      data-cy={`select-${name}`}
      value={value}
      InputProps={inputProps}
      InputLabelProps={inputLabelProps}
      className="width-100"
      {...selectProps}
    >
      {options.map(({ value: optionValue, label: optionLabel }, index) => (
        <MenuItem
          key={`${name}-${optionValue}`}
          data-cy={`${name}-${index}`}
          value={optionValue}
          className="color-black"
        >
          {optionLabel}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default Select
