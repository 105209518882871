import { useCallback } from 'react'
import { TextField } from '@mui/material'
import isEmail from 'validator/lib/isEmail'

export const Email = ({ error, helperText, onChange, ...props }) => {
  const isValid =
    props.value === undefined ||
    props.value === '' ||
    (isEmail(props.value) && !error)
  const localProps = {
    helperText: helperText || (!isValid && 'Invalid email'),
    error: !isValid,
  }

  const handleChange = useCallback(
    event => {
      // eslint-disable-next-line no-param-reassign
      event.target.value = (event.target.value ?? '').toLowerCase() // ensure email is always lowercase

      onChange(event)
    },
    [onChange],
  )

  return (
    <TextField
      inputProps={{ pattern: '^[^@s]+@[^@s]+.[^@s]+$', type: 'email' }}
      onChange={handleChange}
      {...localProps}
      {...props}
    />
  )
}
