import { pages } from 'common'
import { useLocation } from 'react-router-dom'

/**
 * Returns a redirect URL based on the current location and a given path.
 * If the current pathname includes the given path, the redirect URL will be the home page.
 * Otherwise, the redirect URL will be the current pathname and search query.
 *
 * @param {string} path - The path to check for in the current pathname.
 * @returns {string} The redirect URL.
 */
export const useRedirectUrl = (
  path: string,
  defaultPath: string = pages.home.path,
) => {
  const { pathname, search } = useLocation()
  const redirectUrl = pathname.includes(path)
    ? defaultPath
    : `${pathname}${search}`
  return redirectUrl
}
