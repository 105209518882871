import { EVENTS, ACTIONS } from 'react-joyride'
import { createEvent } from '../../../http/events'
import { wait, waitForElement } from './utils'

const { STEP_AFTER } = EVENTS
const { PREV } = ACTIONS

const accountMenuIdSelector = '#account-menu'
const quotesMenuIdSelector = '#main-menu-quotes'
const myCoveragesIdSelector = '#main-menu-coverages'
const mobileMenuIdSelector = '#mobile-menu'
const mobileMyCoveragesIdSelector = '#mobile-menu-coverages-0'
const muiBackdropSelector = '.MuiBackdrop-root'
const joyRideOverlaySelector = '#react-joyride-portal'

const eventName = 'hasCompletedOnboardingTour'

const finishOnboardingStep = `${STEP_AFTER}-next(6)`

const steps = ({ isMobile, appName }) => [
  {
    content: (
      <>
        <h2>Welcome to {appName}!</h2>
        <p>Let us show you around.</p>
      </>
    ),
    placement: 'center',
    target: 'body',
    showProgress: false,
    locale: { next: 'Start' },
  },
  {
    target: '#home-apply-btn',
    content: 'Apply for an LRP or LGM policy',
  },
  {
    target: '#lrp-quote-btn',
    content: 'Explore LRP pricing for your livestock',
  },
  {
    target: '#lgm-quote-btn',
    content: 'Calculate livestock coverage options for LGM',
  },
  {
    target: isMobile ? mobileMyCoveragesIdSelector : myCoveragesIdSelector,
    content: `Once you've placed an order, manage your coverage in "Coverages"`,
    placement: isMobile ? 'bottom' : 'right',
    styles: {
      options: {
        zIndex: 1301,
      },
    },
  },
  {
    target: accountMenuIdSelector,
    content: 'Settings and special tools can be found here',
  },
  {
    target: `${accountMenuIdSelector}-item-my-profile`,
    content: 'Manage personal, business and contact settings',
    placement: 'left',
    styles: {
      options: {
        zIndex: 1301,
      },
    },
    showProgress: false,
    locale: { last: 'Finish' },
  },
]

export const onboardingTour = {
  condition: ({ user, isUserProfileComplete, pathname }) => {
    return (
      pathname === '/' &&
      !!user &&
      user?.isConfirmed &&
      isUserProfileComplete &&
      !user?.events[eventName]
    )
  },

  tour: ({ setTourProps, isMobile, endTour, isSkipped, name: appName }) => {
    const clickElementBeforeStep = async (
      elementSelector,
      { index, action },
    ) => {
      const elementSelectors = Array.isArray(elementSelector)
        ? elementSelector
        : [elementSelector]
      setTourProps({ run: false })
      const pendingActions = elementSelectors.map(async selector => {
        if (selector) {
          const element = await waitForElement(selector)
          element.click()
          return wait()
        }
      })
      await Promise.all(pendingActions)
      setTourProps({
        run: true,
        stepIndex: action === PREV ? index - 1 : index + 1,
      })
    }

    return {
      continuous: true,
      showProgress: true,
      showSkipButton: true,
      run: true,
      steps: steps({ isMobile, appName }),
      /*
      despite implementing step:before and step:after event types, react-joyride evaluates the selector
      for the next step during the current step. This means UI that only appears after a click
      (like a sub menu) will not be found and cause the next step to be skipped. To work around
      this we implement this anti-pattern to wait for the ui before proceeding to the next step.
      */
      callback: async data => {
        const { type, index, action } = data

        if (isSkipped({ type, action })) {
          await createEvent({ name: eventName })
          return endTour()
        }

        const backdropSelector = isMobile
          ? muiBackdropSelector
          : joyRideOverlaySelector

        const step = `${type}-${action}(${index})`

        // @todo: refactor this to use better naming as "finishOnboardingStep"
        switch (step) {
          case `${STEP_AFTER}-next(0)`:
            return window?.HubSpotConversations?.widget?.remove()
          case `${STEP_AFTER}-next(3)`:
          case `${STEP_AFTER}-prev(5)`: {
            const selectors = isMobile
              ? [mobileMenuIdSelector]
              : [quotesMenuIdSelector]
            return clickElementBeforeStep(selectors, data)
          }
          case `${STEP_AFTER}-next(4)`:
          case `${STEP_AFTER}-prev(4)`: {
            return clickElementBeforeStep(backdropSelector, data)
          }

          case `${STEP_AFTER}-start(5)`:
            return clickElementBeforeStep(
              ['nav', 'body', '.MuiBackdrop-root'],
              data,
            )
          case `${STEP_AFTER}-prev(6)`:
            return clickElementBeforeStep(backdropSelector, data)
          case `${STEP_AFTER}-next(5)`:
            window?.HubSpotConversations?.widget?.load()
            return clickElementBeforeStep(accountMenuIdSelector, data)

          case finishOnboardingStep: {
            await createEvent({ name: eventName })
            return endTour()
          }

          default:
            break
        }
      },
    }
  },
}

export default onboardingTour
